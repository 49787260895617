import { configureStore } from "@reduxjs/toolkit";
import requestSlice from "../../common/request/redux";

const store = configureStore({
  // this merges all reducers provided, so they can be used at once
  reducer: {
    [requestSlice.constants.COMMON.ROOT_REDUCER]: requestSlice.reducer,
    // add another reducers here
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
