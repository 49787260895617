import React from "react";
import { Box, LinearProgress } from "@mui/material";
import { ReactComponent as ZVJSLogo } from "../../assets/icons/Other/zvjs.svg";
import ZvjsPullToRefresh from "../../common/components/ZvjsPullToRefresh";

export interface LoadingPageProps {
  initialValue: number;
  maxValue: number;
}

const LoadingPage = (props: LoadingPageProps) => {
  const { initialValue, maxValue } = props;
  const [progress, setProgress] = React.useState(initialValue);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= maxValue) {
          return oldProgress;
        }
        return Math.min(oldProgress + 10, maxValue);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [maxValue]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
      }}
    >
      <ZvjsPullToRefresh>
        <>
          <LinearProgress variant={"determinate"} value={progress} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              width: "100%",
              height: "calc(100vh * 0.618)", // Golden ratio: approximately 1/3 of screen height
            }}
          >
            <ZVJSLogo width="100%" height="100%" style={{ maxHeight: "40%" }} />
          </Box>
        </>
      </ZvjsPullToRefresh>
    </Box>
  );
};

export default LoadingPage;
