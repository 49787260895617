import {
  State,
  UserCalcData,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";
import { ElectricDevice } from "../../../../utils/calculations/reusable";
import {
  CiselnikListResultType,
  MAX_NUMBER_OF_ITEMS,
} from "../../../../../../../../store/context/dataApi/CIS_Ciselnik";
import { LoaderError } from "../../../../../../../../router/LoaderError";
import { API_Clients } from "../../../../../../../../store/context/dataApi/Data";

const ELECTRIC_DEVICES_TYPES_COUNTER = "ELECTRIC_DEVICES_TYPES_COUNTER";

export const DevicesRevisionControlGridConstants = {
  ELECTRIC_DEVICES_TYPES_COUNTER,
};

/**
 * OnInit
 */

export const SC_KIO_050501_DevicesRevisionControlGrid_onInit = async (
  calcData: UserCalcData
): Promise<void> => {
  const { CIS_Post } = await API_Clients();

  const data = await Promise.all([
    CIS_Post("/api/CisDruhElektrospotrebica/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
  ]);

  if (data[0].error) {
    // if some data were not fetch, do not display request and throw error
    throw new LoaderError();
  }

  calcData[DevicesRevisionControlGridConstants.ELECTRIC_DEVICES_TYPES_COUNTER] =
    data[0].data;
};

/**
 * Selectors
 */

const getDevicesRevisionControlGridDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      electricDevices: state.userCalcData[
        "ALL_USER_ELECTRIC_DEVICES_LIST"
      ] as ElectricDevice[],
      electricDevicesCounter: state.userCalcData[
        DevicesRevisionControlGridConstants.ELECTRIC_DEVICES_TYPES_COUNTER
      ] as CiselnikListResultType<"/api/CisDruhElektrospotrebica/List">["data"],
    };
  });

export const allSelectors = {
  getDevicesRevisionControlGridDisplayData,
};

/**
 * Model
 */
export interface SC_KIO_050501_DevicesRevisionControlGrid_answerType {
  deviceId: number;
  deviceTypeCode: string;
}

/**
 * CustomSubmitAnswerData
 */
export const SC_KIO_050501_DevicesRevisionControlGrid_getCustomSubmitAnswerData =
  (
    answer: SC_KIO_050501_DevicesRevisionControlGrid_answerType,
    questionId: string
  ): {
    [key: string]: string | number;
  } => {
    return {
      [questionId]: answer.deviceId,
      druhElektrickehoSpotrebica: answer.deviceTypeCode,
    };
  };
