import {
  UserCalcData,
  ZvjsAnswers,
  ZvjsAnswerValueType,
  ZvjsQuestionnaireTemplate,
  ZvjsValidationError,
} from "../../../redux/model";
import reusable from "./validations/reusable";
import custom from "./validations/custom";

const validations: ValidationType = {
  ...reusable,
  ...custom,
};

export type ValidationType = {
  [key: string]: ({
    newAnswer,
    questionId,
    questionnaire,
    userCalcData,
    answers,
  }: {
    newAnswer: ZvjsAnswerValueType;
    questionId: string;
    questionnaire: ZvjsQuestionnaireTemplate;
    userCalcData: UserCalcData;
    answers: ZvjsAnswers;
  }) => ZvjsValidationError | undefined;
};

export default validations;
