import React, { createElement, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { ReactComponent as KioskIcon } from "../../assets/icons/Other/kiosk.svg";

interface ZvjsKeyboardReaderProps {
  onScan: (qrCode: string) => void;
}

const ZvjsKeyboardReader: React.FC<ZvjsKeyboardReaderProps> = ({ onScan }) => {
  const [value, setValue] = useState<string>("");
  const [firstKeyPressed, setFirstKeyPressed] = useState<boolean>(false);
  const [isLoadingFinished, setIsLoadingFinished] = useState<boolean>(false);

  const keyPressed = (event: Event) => {
    if (!firstKeyPressed) {
      setFirstKeyPressed(true);
      setTimeout(() => {
        setFirstKeyPressed(false);
        setIsLoadingFinished(true);
      }, 500);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const key = (event as any).key;
    setValue((prevVal: string) => prevVal + String(key));
  };

  useEffect(() => {
    if (isLoadingFinished) {
      setIsLoadingFinished(false);
      onScan(value.replaceAll("Shift", "").zvjsReplaceSlovakDiacriticsToNumeric());
      setValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingFinished, value]);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    addEventListener("keydown", keyPressed, false);
    return () => {
      // eslint-disable-next-line no-restricted-globals
      removeEventListener("keydown", keyPressed);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstKeyPressed]);

  return (
    <Stack spacing={3} alignItems="center">
      {createElement(KioskIcon)}
    </Stack>
  );
};

export default ZvjsKeyboardReader;
