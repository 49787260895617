import React, { useEffect } from "react";
import { ZvjsAlert, ZvjsSelect } from "../../../../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../redux";
import { Box, Grid, SelectChangeEvent } from "@mui/material";
import { AppDispatch } from "../../../../../../store/redux";
import { getSeverityOfValidationFlag } from "../../../../redux/model";
import { useUITranslation } from "../../../../../../store/context/translation-context";

interface DropdownQuestionProps {
  location: number[];
}

const DropdownQuestion: React.FC<DropdownQuestionProps> = ({ location }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { tuiz } = useUITranslation();

  console.log(`DROPDOWN QUESTION RERENDER ${location.join("-")}`);

  const dropdownQuestionData = useSelector(
    questionnaireRedux.selectors.getDropdownQuestionDisplayData(location)
  );
  // use shallowEqual so question is not re-rendered each time selector returns new array instance: https://react-redux.js.org/api/hooks#equality-comparisons-and-updates
  const isOptionDisplayedArray = useSelector(
    questionnaireRedux.selectors.getDropdownOptionsDisplayedArray(location),
    shallowEqual
  );
  const answerVal = useSelector(
    questionnaireRedux.selectors.getDropdownQuestionAnswerData(
      location,
      dropdownQuestionData.id
    )
  );
  const validationError = useSelector(
    questionnaireRedux.selectors.getValidationError(dropdownQuestionData.id)
  );

  const filteredOptions = dropdownQuestionData.options.filter(
    (_, i) => isOptionDisplayedArray[i]
  );

  const onChangeHandler = (event: SelectChangeEvent) => {
    dispatch(
      questionnaireRedux.actions.addNewAnswer(
        location,
        dropdownQuestionData.id,
        event.target.value
      )
    );
  };

  // if only one option is available to user, auto-select it
  useEffect(() => {
    if (filteredOptions.length === 1) {
      dispatch(
        questionnaireRedux.actions.addNewAnswer(
          location,
          dropdownQuestionData.id,
          filteredOptions[0].value
        )
      );
    }
  }, [dispatch, dropdownQuestionData.id, filteredOptions, location]);

  return (
    <Grid container>
      <ZvjsSelect
        id={`dropdown-question-${location.join("-")}`}
        items={filteredOptions}
        text={tuiz(dropdownQuestionData.title)}
        changeHandler={onChangeHandler}
        selectedValue={answerVal ?? ""}
        hintText={
          dropdownQuestionData.hintText
            ? tuiz(dropdownQuestionData.hintText)
            : undefined
        }
        hintSize={20}
        answerRequired={dropdownQuestionData.isRequired}
        error={validationError !== undefined}
      />
      {/*{validationError && (*/}
      {/*  <SimpleValidationText*/}
      {/*    text={validationError.message}*/}
      {/*    flag={validationError.type}*/}
      {/*  />*/}
      {/*)}*/}
      {validationError && (
        <Box mt={1}>
          <ZvjsAlert
            severity={getSeverityOfValidationFlag(validationError?.type)}
          >
            {validationError.message}
          </ZvjsAlert>
        </Box>
      )}
    </Grid>
  );
};

export default DropdownQuestion;
