import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../../../redux";
import { AppDispatch } from "../../../../../../../../store/redux";
import {
  ZvjsAlert,
  ZvjsCheckBox,
  ZvjsCustomQuestionFullWidthBox,
  ZvjsSectionUI,
  ZvjsTable,
} from "../../../../../../../components";
import {
  CellData,
  RequestFontSizes,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../../../../components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { useTranslation } from "react-i18next";
import { ZvjsCheckBoxStateValues } from "../../../../../../../components/ZvjsCheckBox";
import CustomQuestionProps from "../CustomQuestionProps";
import {
  getSeverityOfValidationFlag,
  LabelType,
  ZvjsValidationFlagTypes,
} from "../../../../../../redux/model";
import { Box, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import {
  SC_KIO_051003_getRequestedItemsTotalEstimatedPrice,
  SC_KIO_051003_isSelectedItemsPriceGreaterAsAvailableBalance,
} from "./redux";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";

const SC_KIO_051003_PersonalHygieneNeedsGrid: React.FC<CustomQuestionProps> = ({
  location,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { tuiz } = useUITranslation();

  console.log(
    `SC_KIO_051003 PERSONAL HYGIENE NEEDS GRID QUESTION RERENDER ${location.join(
      "-"
    )}`
  );

  const questionData = useSelector(
    questionnaireRedux.selectors.getPersonalHygieneNeedsGridDisplayData(
      location
    ),
    shallowEqual
  );

  const answerVal = useSelector(
    questionnaireRedux.selectors.getQuestionAnswerData(questionData.id),
    shallowEqual
  ) as undefined | string[];

  const onChangeHandler = (
    label: string | undefined,
    key: string,
    checked: boolean,
    checkedValues: ZvjsCheckBoxStateValues[]
  ) => {
    if (checked) {
      const answersCopy: string[] = answerVal
        ? JSON.parse(JSON.stringify(answerVal))
        : [];
      answersCopy.push(key);
      dispatch(
        questionnaireRedux.actions.addNewAnswer(
          location,
          questionData.id,
          answersCopy
        )
      );
    } else {
      let answersCopy: string[] = answerVal
        ? JSON.parse(JSON.stringify(answerVal))
        : [];
      answersCopy = answersCopy.filter((answer) => answer !== key);
      dispatch(
        questionnaireRedux.actions.addNewAnswer(
          location,
          questionData.id,
          answersCopy
        )
      );
    }
  };

  const getCannotRequestItemText = (consumptionStandardInMonths: number) => {
    if (consumptionStandardInMonths === 1) {
      return tuiz(
        "ziadost.ziadostOposkytnutiePotriebOsHygieny.napovedaPoziadatAzPoMesiaci"
      );
    } else {
      return tuiz(
        "ziadost.ziadostOposkytnutiePotriebOsHygieny.napovedaPoziadatAzPoRoku"
      ).replace(
        "###CONSUMPTION_STANDARD###",
        consumptionStandardInMonths.toString()
      );
    }
  };

  const getRequestItemStyle = (enabled: boolean) => {
    if (enabled) {
      return dataStyle[3];
    } else {
      return {
        align: "left",
        width: 400,
      } as {
        align: "left" | "center" | "right" | "inherit" | "justify" | undefined;
        width: number;
      };
    }
  };

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "right",
      sx: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(
        tuiz("ziadost.ziadostOposkytnutiePotriebOsHygieny.labelNazovPolozky")
      ),
      ...dataStyle[0],
    },
    {
      // TODO replace with correct translation
      value: capitalize(t("Termín posledného vydania")),
      ...dataStyle[1],
    },
    {
      value: capitalize(
        tuiz(
          "ziadost.ziadostOposkytnutiePotriebOsHygieny.labelPredpokladanaCenaPolozky"
        )
      ),
      ...dataStyle[2],
    },
    {
      value: capitalize(
        tuiz(
          "ziadost.ziadostOposkytnutiePotriebOsHygieny.labelPridatDoZiadosti"
        )
      ),
      ...dataStyle[3],
    },
  ];

  const bodyData: Array<RowData> = new Array<RowData>();
  for (const hygieneItem of questionData.personalHygieneList) {
    const itemThreshold = new Date().setMonth(
      new Date().getMonth() - hygieneItem.consumptionStandardInMonths
    );
    bodyData.push({
      row: [
        {
          value: hygieneItem.typeName,
          ...dataStyle[0],
        },
        {
          value: hygieneItem.dateOfLastIssue
            ? new Date(hygieneItem.dateOfLastIssue).zvjsToString()
            : "",
          ...dataStyle[1],
        },
        {
          value: `${hygieneItem.estimatedPrice} ${LabelType.EURO}`,
          ...dataStyle[2],
        },
        {
          value:
            hygieneItem.dateOfLastIssue === undefined ||
            itemThreshold > new Date(hygieneItem.dateOfLastIssue).getTime() ? (
              <ZvjsCheckBox
                onChange={onChangeHandler}
                data={[
                  {
                    key: hygieneItem.typeCode,
                    checked: answerVal?.some((answer) => {
                      return answer === hygieneItem.typeCode;
                    }),
                  },
                ]}
              />
            ) : (
              getCannotRequestItemText(hygieneItem.consumptionStandardInMonths)
            ),
          ...getRequestItemStyle(
            hygieneItem.dateOfLastIssue === undefined ||
              itemThreshold > new Date(hygieneItem.dateOfLastIssue).getTime()
          ),
        },
      ],
    });
  }

  const tableData: TableData = {
    header: headerData,
    body: bodyData,
    label: tuiz(questionData.title),
  };

  return (
    <Grid item xs>
      <ZvjsCustomQuestionFullWidthBox location={location}>
        <ZvjsTable
          data={tableData}
          variant={ZvjsTableVariant.NORMAL}
          fontSizes={RequestFontSizes}
        />
      </ZvjsCustomQuestionFullWidthBox>
      <ZvjsSectionUI
        sectionTitles={[
          tuiz(
            "ziadost.ziadostOposkytnutiePotriebOsHygieny.labelPredpokladanaCenaSpolu"
          ),
        ]}
      >
        <Grid item xs>
          <Stack direction={"row"} spacing={3}>
            <Typography fontSize={19}>
              {tuiz("ziadost.ziadostOposkytnutiePotriebOsHygieny.labelSuma")}
            </Typography>
            <Typography fontSize={19}>
              {" "}
              <b>
                {SC_KIO_051003_getRequestedItemsTotalEstimatedPrice(
                  questionData.personalHygieneList,
                  answerVal ?? []
                )}{" "}
                {LabelType.EURO}
              </b>
            </Typography>
          </Stack>
          {/*{SC_KIO_051003_isSelectedItemsPriceGreaterAsAvailableBalance(*/}
          {/*  questionData.personalHygieneList,*/}
          {/*  questionData.clientAvailableBalance,*/}
          {/*  answerVal ?? []*/}
          {/*) && (*/}
          {/*  <Box mt={1}>*/}
          {/*    <ZvjsAlert*/}
          {/*      severity={getSeverityOfValidationFlag(*/}
          {/*        ZvjsValidationFlagTypes.ERROR*/}
          {/*      )}*/}
          {/*    >*/}
          {/*      {tuiz(*/}
          {/*        "ziadost.ziadostOposkytnutiePotriebOsHygieny.napovedaNedostatokPenaznychProstriedkov"*/}
          {/*      )}*/}
          {/*    </ZvjsAlert>*/}
          {/*  </Box>*/}
          {/*)}*/}
        </Grid>
      </ZvjsSectionUI>
    </Grid>
  );
};

export default SC_KIO_051003_PersonalHygieneNeedsGrid;
