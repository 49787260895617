import {
  NestedDropdownAnswerValueType,
  State,
  UserCalcData,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";
import {
  CiselnikListResultType,
  MAX_NUMBER_OF_ITEMS,
} from "../../../../../../../../store/context/dataApi/CIS_Ciselnik";
import { LoaderError } from "../../../../../../../../router/LoaderError";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";
import { API_Clients } from "../../../../../../../../store/context/dataApi/Data";
import { CisUstavZvjs_GR_Kod } from "../../../../../../../specs/countersValues";
import { ZvjsNestedDropdownViewOption } from "../../../../../../../components/ZvjsNestedDropDown";

const LIST_OF_INSTITUTIONS = "LIST_OF_INSTITUTIONS";
const TRESTNOPRAVNE_UDAJE = "TRESTNOPRAVNE_UDAJE";
const QUESTION_ANSWER_ID = "miestoPozadovanehoPremiestnenia";

export const PlaceOfRelocationQuestionConstants = {
  LIST_OF_INSTITUTIONS,
  TRESTNOPRAVNE_UDAJE,
  QUESTION_ANSWER_ID,
};

/**
 * OnInit
 */

export interface PlaceOfRelocationQuestionInstitutionType {
  id: string;
  name: string;
  degreeOfProtection: string;
  differentiationGroup: string;
  hasExpectedFreeCapacity: boolean;
}

export const SC_KIO_051403_PlaceOfRelocationQuestion_onInit = async (
  calcData: UserCalcData
): Promise<void> => {
  const { CIS_Post, EPVVVT_Post } = await API_Clients();

  const data = await Promise.all([
    EPVVVT_Post("/api/stitkovnicaklientov/ListVsetciKlienti", {
      body: {
        ustavId:
          Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
      },
    }),
    CIS_Post("/api/CisUstavZvjs/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
  ]);

  const epvvvtKlient = data[0].data
    ?.filter(
      (klient) =>
        klient.klientObjectId === localStorage.getItem("klientObjectId")
    )
    .at(0);

  if (epvvvtKlient === undefined || data[1] === undefined) {
    // if some data were not fetch, do not display request and throw error
    throw new LoaderError();
  }

  calcData[PlaceOfRelocationQuestionConstants.TRESTNOPRAVNE_UDAJE] =
    epvvvtKlient;

  // TODO replace with actual fetch - remember to remove grzvjs from list of institutions
  const listOfInstitutions: PlaceOfRelocationQuestionInstitutionType[] = [
    {
      id: "44",
      name: "Ústav na výkon väzby a Ústav na výkon trestu odňatia slobody Banská Bystrica",
      degreeOfProtection: "1. stupeň",
      differentiationGroup: "915 01",
      hasExpectedFreeCapacity: true,
    },
    {
      id: "42",
      name: "Ústav na výkon trestu odňatia slobody a Ústav na výkon väzby Leopoldov",
      degreeOfProtection: "1. stupeň",
      differentiationGroup: "915 01",
      hasExpectedFreeCapacity: false,
    },
    {
      id: "51",
      name: "Ústav na výkon väzby a Ústav na výkon trestu odňatia slobody Žilina",
      degreeOfProtection: "2. stupeň",
      differentiationGroup: "915 03",
      hasExpectedFreeCapacity: false,
    },
  ];

  // TODO merge institution counter list with other data (degreeOfProtection, differentiationGroup and hasExpectedFreeCapacity)
  calcData["Ustavy counter"] = (
    data[1] as CiselnikListResultType<"/api/CisUstavZvjs/List">
  )?.data?.records
    ?.map((item) => {
      return { id: item.kod, name: item.nazov };
    })
    // filter out GR, which should have code 75
    .filter((item) => item.id !== CisUstavZvjs_GR_Kod);

  calcData[PlaceOfRelocationQuestionConstants.LIST_OF_INSTITUTIONS] =
    listOfInstitutions;
};

/**
 * Selectors
 */

const getPlaceOfRelocationQuestionDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const { tuiz } = useUITranslation();
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    const answers = state.answers[question.id] as
      | NestedDropdownAnswerValueType[]
      | undefined;

    const selectedLabels: string[] = [];

    const options = state.userCalcData[
      PlaceOfRelocationQuestionConstants.LIST_OF_INSTITUTIONS
    ].map(
      (
        item: PlaceOfRelocationQuestionInstitutionType
      ): ZvjsNestedDropdownViewOption => {
        const isChecked =
          answers?.some(
            (x) =>
              x[PlaceOfRelocationQuestionConstants.QUESTION_ANSWER_ID] ===
              item.id
          ) ?? false;

        if (isChecked) {
          selectedLabels.push(item.name);
        }

        return {
          label: item.name,
          key: item.id,
          checked: isChecked,
        };
      }
    );

    return {
      id: question.id,
      title: tuiz(question.title),
      answerRequired: question.isRequired,
      options: {
        answerId: PlaceOfRelocationQuestionConstants.QUESTION_ANSWER_ID,
        children: options,
      },
      selectedOptionsText: selectedLabels.join(","),
      selectedOptionsCount: selectedLabels.length,
    };
  });

export const getInstitutionWithId = (
  userCalcData: UserCalcData,
  id: string
): PlaceOfRelocationQuestionInstitutionType | undefined => {
  const institutions = userCalcData[
    PlaceOfRelocationQuestionConstants.LIST_OF_INSTITUTIONS
  ] as PlaceOfRelocationQuestionInstitutionType[];

  for (const institution of institutions) {
    if (institution.id === id) {
      return institution;
    }
  }

  return undefined;
};

const displayDifferentDegreeOfProtectionOrDifferentiationGroupWarning = (
  location: number[]
) =>
  createSelector(selectSelf, (state: State): boolean => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    const answers = state.answers[question.id];

    if (answers !== undefined) {
      if (Array.isArray(answers) && answers.length === 0) {
        return false;
      }
      for (const answer of answers as NestedDropdownAnswerValueType[]) {
        const institution = getInstitutionWithId(
          state.userCalcData,
          answer[PlaceOfRelocationQuestionConstants.QUESTION_ANSWER_ID]
        );

        if (
          institution?.degreeOfProtection !==
            state.userCalcData[
              PlaceOfRelocationQuestionConstants.TRESTNOPRAVNE_UDAJE
            ].stupenStrazenia ||
          institution?.differentiationGroup !==
            state.userCalcData[
              PlaceOfRelocationQuestionConstants.TRESTNOPRAVNE_UDAJE
            ].diferenciacnaSkupina
        ) {
          return true;
        }
      }
      return false;
    }

    return false;
  });

const displayNoExpectedFreeCapacityWarning = (location: number[]) =>
  createSelector(selectSelf, (state: State): boolean => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    const answers = state.answers[question.id];

    if (answers !== undefined) {
      if (Array.isArray(answers) && answers.length === 0) {
        return false;
      }
      for (const answer of answers as NestedDropdownAnswerValueType[]) {
        const institution = getInstitutionWithId(
          state.userCalcData,
          answer[PlaceOfRelocationQuestionConstants.QUESTION_ANSWER_ID]
        );

        if (institution?.hasExpectedFreeCapacity === false) {
          return true;
        }
      }
      return false;
    }

    return false;
  });

export const allSelectors = {
  getPlaceOfRelocationQuestionDisplayData,
  displayDifferentDegreeOfProtectionOrDifferentiationGroupWarning,
  displayNoExpectedFreeCapacityWarning,
};

/**
 * CustomSubmitAnswerData
 */
export const SC_KIO_051403_PlaceOfRelocationQuestion_getCustomSubmitAnswerData =
  (
    answers: NestedDropdownAnswerValueType[],
    questionId: string
  ): {
    [key: string]: string[];
  } => {
    const toReturn = [];
    for (const answer of answers) {
      toReturn.push(
        answer[PlaceOfRelocationQuestionConstants.QUESTION_ANSWER_ID]
      );
    }
    return { [questionId]: toReturn };
  };
