import React from "react";
import { Backdrop, Fade, Modal, Paper } from "@mui/material";
import { useModal } from "../../../store/context/modal-context";

const ZvjsModal = () => {
  const { closeModal, modalBody, modalOpen } = useModal();
  const handleClose = () => closeModal();

  return (
    <Modal
      aria-labelledby={"KIOSKY modal context"}
      open={modalOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={modalOpen}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "1rem",
            borderRadius: "1rem",
            boxShadow: 24,
            "&:focus": {
              outline: "none",
            },
          }}
        >
          {/*<Stack spacing={1}>*/}
          {/*  {variant?.type === "hint" && (*/}
          {/*    <Box*/}
          {/*      style={{*/}
          {/*        backgroundColor: "rgba(0,112,60,0.3)",*/}
          {/*        minWidth: "10rem",*/}
          {/*        borderRadius: "0.5rem",*/}
          {/*        border: `0.1rem solid ${ZVJS_COLORS.GREEN}`,*/}
          {/*        padding: "0.5rem",*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <Stack direction="row" spacing={2} justifyContent={"center"}>*/}
          {/*        <Hint fill={ZVJS_COLORS.GREEN} />*/}
          {/*        <Typography*/}
          {/*          variant={"h4"}*/}
          {/*          fontWeight={700}*/}
          {/*          color={ZVJS_COLORS.GREEN}*/}
          {/*        >*/}
          {/*          {capitalize(title)}*/}
          {/*        </Typography>*/}
          {/*      </Stack>*/}
          {/*    </Box>*/}
          {/*  )}*/}
          {/*  {*/}
          {/*    //todo some hints have link inside*/}
          {/*  }*/}
          {/*  {variant?.type !== "hint" && (*/}
          {/*    <Typography variant={"h4"}>{title}</Typography>*/}
          {/*  )}*/}
          {/*  <Typography*/}
          {/*    id="transition-modal-description"*/}
          {/*    sx={{ mt: 2 }}*/}
          {/*    paddingLeft={1}*/}
          {/*    paddingRight={1}*/}
          {/*  >*/}
          {/*    {capitalize(text)}*/}
          {/*  </Typography>*/}
          {/*  <Box sx={{ display: "flex", justifyContent: "center" }}>*/}
          {/*    <ZvjsButton*/}
          {/*      zvjsVariant={*/}
          {/*        variant?.type === "hint" ? "secondaryAction" : "backAction"*/}
          {/*      }*/}
          {/*      onClick={handleClose}*/}
          {/*      text={"OK"}*/}
          {/*      sx={{ height: "3rem" }}*/}
          {/*    />*/}
          {/*  </Box>*/}
          {/*</Stack>*/}
          {modalBody}
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ZvjsModal;
