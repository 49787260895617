import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import questionnaireRedux from "../../../../redux";
import { Grid, Typography } from "@mui/material";
import { ZvjsAlert } from "../../../../../components";
import { getSeverityOfZvjsFlag } from "../../../../redux/model";
import { Stack } from "@mui/system";
import { useUITranslation } from "../../../../../../store/context/translation-context";

interface LabelProps {
  location: number[];
}

const LabelWithSingleValue: React.FC<LabelProps> = ({ location }) => {
  const { tuiz } = useUITranslation();

  const labelData = useSelector(
    questionnaireRedux.selectors.getLabelTextWithSingleValue(location),
    shallowEqual
  );

  console.log(`LABEL RERENDER ${location.join("-")}`);

  if (labelData.flag !== undefined) {
    return (
      <Grid item xs>
        <ZvjsAlert severity={getSeverityOfZvjsFlag(labelData.flag)}>
          {labelData.text}
        </ZvjsAlert>
      </Grid>
    );
  } else {
    return (
      <Grid item xs>
        <Stack direction={"row"} spacing={3}>
          <Typography fontSize={19}>{tuiz(labelData.text)}</Typography>
          <Typography fontSize={19}>
            {" "}
            <b>
              {labelData.value}
              {labelData.valueType && ` ${labelData.valueType}`}
            </b>
          </Typography>
        </Stack>
      </Grid>
    );
  }
};

export default LabelWithSingleValue;
