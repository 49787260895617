import { ZakladneUdajeKlientaProps } from "../ZakladneUdajeKlienta";
import React, { useEffect, useState } from "react";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsTable,
} from "../../../../common";
import { capitalize } from "@mui/material/utils";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";

const VyzivovaciePovinnostiTab: React.FC<ZakladneUdajeKlientaProps> = ({
  data,
}) => {
  const navigate = useNavigate();
  const { tui } = useUITranslation();

  const dataStyleVyzivovaciePovinnosti: TableStyle[] = [
    {
      align: "left",
      width: "50%",
    },
    {
      align: "left",
      width: "50%",
    },
  ];

  const headerDataVyzivovaciePovinnosti: Array<CellData> = [
    {
      value: capitalize(tui("osobneUdaje.vseobecneOsobneUdaje.meno")),
      ...dataStyleVyzivovaciePovinnosti[0],
    },
    {
      value: capitalize(tui("osobneUdaje.vseobecneOsobneUdaje.priezvisko")),
      ...dataStyleVyzivovaciePovinnosti[1],
    },
  ];

  const [tableDataVyzivovaciePovinnosti, setTableDataVyzivovaciePovinnosti] =
    useState<TableData>({
      label: capitalize(tui("Prehľad vyživovacích povinností")),
      header: headerDataVyzivovaciePovinnosti,
      body: [],
    });

  useEffect(() => {
    if (data.vyzivovaciePovinnosti.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      data.vyzivovaciePovinnosti.data.records
        // .sort((p1, p2) =>
        //    p1.name > p2.name)
        ?.forEach((vyzivovaciaPovinnost) => {
          bodyData.push({
            row: [
              {
                value:
                  vyzivovaciaPovinnost.vyzivovanaOsoba?.split(" ").at(0) ?? "",
                ...dataStyleVyzivovaciePovinnosti[0],
              },
              {
                value:
                  vyzivovaciaPovinnost.vyzivovanaOsoba?.split(" ").at(1) ?? "",
                ...dataStyleVyzivovaciePovinnosti[1],
              },
            ],
          });
        });
      setTableDataVyzivovaciePovinnosti({
        ...tableDataVyzivovaciePovinnosti,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Stack direction="column" mt={1} spacing={1}>
      <ZvjsTable
        data={tableDataVyzivovaciePovinnosti}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
        hintTitle={tui("nápoveda").toString()}
        // TODO add translation
        hintText={" "}
      />
      <ZvjsButton
        text={capitalize(
          tui(
            "žiadosť o vykonanie zrážok z čistej pracovnej odmeny odsúdeného na úhradu výživného nezaopatrených detí"
          )
        )}
        zvjsVariant="secondaryAction"
        endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
        onClick={() => {
          navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/09.01.01`);
        }}
        style={{
          lineHeight: "1",
          textTransform: "none",
          textAlign: "left",
          width: "30rem",
          height: "6rem",
        }}
      />
    </Stack>
  );
};

export default VyzivovaciePovinnostiTab;
