import React, { useEffect, useState } from "react";
import { ResocializacneAVolnocasoveAktivityProps } from "../ResocializacneAVolnocasoveAktivity";
import { Stack } from "@mui/material";
import { ZvjsTable } from "../../../../common";
import { capitalize } from "@mui/material/utils";
import { useTranslation } from "react-i18next";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";

const PracovneZaradenieTab: React.FC<
  ResocializacneAVolnocasoveAktivityProps
> = ({ data }) => {
  const { t } = useTranslation();

  const dataStylePracovneZaradenie: TableStyle[] = [
    {
      align: "left",
      width: "25%",
    },
    {
      align: "center",
      width: "25%",
    },
    {
      align: "right",
      width: "25%",
    },
    {
      align: "right",
      width: "25%",
    },
  ];

  const headerDataPracovneZaradenie: Array<CellData> = [
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.JobAssignment.workplaceName"
        )
      ),
      ...dataStylePracovneZaradenie[0],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.JobAssignment.penitentiary"
        )
      ),
      ...dataStylePracovneZaradenie[1],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.JobAssignment.dateFrom"
        )
      ),
      ...dataStylePracovneZaradenie[2],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.JobAssignment.dateTo"
        )
      ),
      ...dataStylePracovneZaradenie[3],
    },
  ];

  const [tableDataPracovneZaradenie, setTableDataPracovneZaradenie] =
    useState<TableData>({
      header: headerDataPracovneZaradenie,
      body: [],
    });

  useEffect(() => {
    if (data.pracovneZaradenia.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      data.pracovneZaradenia.data
        // .sort((p1, p2) =>
        //   p1.name > p2.name)
        .forEach((pracovneZaradenie) => {
          bodyData.push({
            row: [
              {
                value: pracovneZaradenie.pracoviskoNazov ?? "",
                ...dataStylePracovneZaradenie[0],
              },
              {
                value: pracovneZaradenie.ustavZvjsId ?? "",
                ...dataStylePracovneZaradenie[1],
              },
              {
                value: pracovneZaradenie.datumZaradeniaOd
                  ? new Date(pracovneZaradenie.datumZaradeniaOd).zvjsToString()
                  : "",
                ...dataStylePracovneZaradenie[2],
              },
              {
                value: pracovneZaradenie.datumZaradeniaDo
                  ? new Date(pracovneZaradenie.datumZaradeniaDo).zvjsToString()
                  : "",
                ...dataStylePracovneZaradenie[3],
              },
            ],
          });
        });
      setTableDataPracovneZaradenie({
        ...tableDataPracovneZaradenie,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsTable
        data={tableDataPracovneZaradenie}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default PracovneZaradenieTab;
