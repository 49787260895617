import React, { createElement, FunctionComponent, SVGProps } from "react";
import { ButtonBase, Grid, Paper, PaperProps, Typography } from "@mui/material";
import { ReactComponent as KeyboardArrowRightIcon } from "../../assets/icons/Other/keyboard_arrow_right.svg";
import { ZVJS_COLORS } from "../../theme/zvjs_theme";
import { Stack } from "@mui/system";

interface ZvjsPaperProps extends PaperProps {
  icon?: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  title: string;
  description?: string;
}

const ZvjsCard: React.FC<ZvjsPaperProps> = ({
  icon,
  title,
  description,
  ...rest
}) => {
  // const theme = useTheme();

  return (
    <Paper
      variant={"info"}
      sx={{
        width: "100%",
        height: "100%",
      }}
      {...rest}
    >
      <ButtonBase
        sx={{
          width: "100%",
          height: "100%",
          padding: "12px",
        }}
      >
        <Grid width={"100%"} height={"100%"} textAlign={"left"}>
          {description ? (
            <Stack direction="column" m={1} mr={2} mt={2} spacing={2}>
              <Stack direction="row" alignItems="center">
                {icon &&
                  createElement(icon, {
                    fill: ZVJS_COLORS.BLUE_2,
                    width: 50,
                    height: 50,
                  })}
                <Typography ml={3} variant={"h3"}>
                  {title}
                </Typography>
              </Stack>
              <Typography
                variant={"subtitle1"}
                lineHeight={"normal"}
                fontSize={32}
              >
                {description}
              </Typography>
            </Stack>
          ) : (
            <Stack
              mr={2}
              height={"100%"}
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                {icon &&
                  createElement(icon, {
                    fill: ZVJS_COLORS.BLUE_2,
                    width: 35,
                    height: 35,
                  })}
              </Grid>
              <Typography variant={"h3"}>{title}</Typography>
            </Stack>
          )}
        </Grid>
        {description ? (
          <KeyboardArrowRightIcon width={20} height={20} />
        ) : (
          <KeyboardArrowRightIcon width={30} height={30} />
        )}
      </ButtonBase>
    </Paper>
  );
};

export default ZvjsCard;
