import React from "react";
import {
  Baliky,
  BezpecnyInternetKiosk,
  BezpecnyInternetTablet,
  DisciplinarneTrestyAIneRozhodnutia,
  ErrorPage,
  ExamplesPage,
  HelpPage,
  Holup,
  Kniznica,
  KniznicaKatalogTitulov,
  KniznicaVypozicneHodiny,
  Login,
  MiestoVykonuVazbyAVykonuTrestu,
  MojeUdaje,
  Nastenka,
  Navstevy,
  OsobneVeci,
  PeniazeAPoplatky,
  PohladavkyGRZVJS,
  PohladavkyOstatnychOpravnenych,
  PohladavkyUstavu,
  PohladavkyVyzivovacichPovinnosti,
  Poucenia,
  Requests,
  ResocializacneAVolnocasoveAktivity,
  SplatkyPohladavokGRZVJS,
  SplatkyPohladavokOstatnychOpravnenych,
  SplatkyPohladavokUstavu,
  SplatkyPohladavokVyzivovacichPovinnosti,
  Telefonovanie,
  VseobecneInformacie,
  ZakladneUdajeKlienta,
  ZakladneUdajeOTreste,
  ZakladneUdajeOVazbe,
  ZmenaUdajovZiadost,
} from "../pages";
import { createBrowserRouter } from "react-router-dom";
import { AppLayout } from "../layouts/AppLayout";
import {
  Dashboard_SC_KIO_0200,
  Library_SC_KIO_0460,
  MoneyClaimAndAlimony_SC_KIO_03603,
  Noticeboard_SC_KIO_0400,
  PersonalDataCategory_SC_KIO_0313,
  Request,
} from "../common";
import { TelefonovanieLoader } from "../pages/auth/MojeUdajePages/Telefonovanie";
import { BalikyLoader } from "../pages/auth/MojeUdajePages/Baliky";
import { NavstevyLoader } from "../pages/auth/MojeUdajePages/Navstevy";
import { OsobneVeciLoader } from "../pages/auth/MojeUdajePages/OsobneVeci";
import { MiestoVykonuVazbyAVykonuTrestuLoader } from "../pages/auth/MojeUdajePages/MiestoVykonuVazbyAVykonuTrestu";
import { DisciplinarneTrestyAIneRozhodnutiaLoader } from "../pages/auth/MojeUdajePages/DisciplinarneTrestyAIneRozhodnutia";
import { ZakladneUdajeKlientaLoader } from "../pages/auth/MojeUdajePages/ZakladneUdajeKlienta";
import { ZakladneUdajeOTresteLoader } from "../pages/auth/MojeUdajePages/ZakladneUdajeOTreste";
import { ZakladneUdajeOVazbeLoader } from "../pages/auth/MojeUdajePages/ZakladneUdajeOVazbe";
import { PouceniaLoader } from "../pages/auth/MojeUdajePages/Poucenia";
import { ResocializacneAVolnocasoveAktivityLoader } from "../pages/auth/MojeUdajePages/ResocializacneAVolnocasoveAktivity";
import PoucenieDetail, {
  PoucenieDetailLoader,
} from "../pages/auth/MojeUdajePages/PoucenieDetail";
import { PeniazeAPoplatkyLoader } from "../pages/auth/MojeUdajePages/PeniazeAPoplatky";
import { VseobecneInformacieLoader } from "../pages/auth/NastenkaPages/VseobecneInformacie";
import { KniznicaLoader } from "../pages/auth/NastenkaPages/Kniznica";
import { KniznicaKatalogTitulovLoader } from "../pages/auth/NastenkaPages/KniznicaKatalogTitulov";
import { CheckIsLoggedInGuardLoader } from "../store/context/auth-context";
import { ProtectedLayout } from "../layouts/ProtectedLayout";
import { RequestLoader } from "../common/request/Request";
import { RequestActions } from "../common/request/requestPages/summary/Summary";
import { ExamplesActions, ExamplesLoader } from "../pages/auth/ExamplesPage";
import { PohladavkyUstavuLoader } from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/OstatnePages/PohladavkyUstavu";
import { PohladavkyGRZVJSLoader } from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/OstatnePages/PohladavkyGRZVJS";
import { PohladavkyOstatnychOpravnenychLoader } from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/OstatnePages/PohladavkyOstatnychOpravnenych";
import { PohladavkyVyzivovacichPovinnostiLoader } from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/OstatnePages/PohladavkyVyzivovacichPovinnosti";
import { SplatkyPohladavokUstavuLoader } from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/OstatnePages/SplatkyPohladavokUstavu";
import { SplatkyPohladavokGRZVJSLoader } from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/OstatnePages/SplatkyPohladavokGRZVJS";
import { SplatkyPohladavokOstatnychOpravnenychLoader } from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/OstatnePages/SplatkyPohladavokOstatnychOpravnenych";
import { SplatkyPohladavokVyzivovacichPovinnostiLoader } from "../pages/auth/MojeUdajePages/PeniazeAPoplatkyTabs/OstatnePages/SplatkyPohladavokVyzivovacichPovinnosti";
import { Requests_SC_KIO_0481 } from "../common/specs/sections/Requests_SC_KIO_0481";
import RequestCategory, {
  RequestCategoryLoader,
} from "../pages/auth/Requests/RequestCategory";
import { HolupLoader } from "../pages/auth/Holup";
import { ZmenaUdajovRequestLoader } from "../pages/auth/MojeUdajePages/ZmenaOsobnychUdajov/ZmenaUdajovZiadost";
import { RequestDetail } from "../pages/auth/Requests";
import PrehliadacSuborov, {
  PrehliadacSuborovLoader,
} from "../pages/auth/NastenkaPages/PrehliadacSuborov";
import { RequestsTableActions } from "../pages/auth/Requests/UserRequestsList";
import { RequestsLoader } from "../pages/auth/Requests/Requests";
import {
  RequestDetailActions,
  RequestDetailLoader,
} from "../pages/auth/Requests/RequestDetail";
import Listy, { ListyLoader } from "../pages/auth/MojeUdajePages/Listy";
import DisciplinarnaOdmenaDetail, {
  DisciplinarnaOdmenaDetailLoader,
} from "../pages/auth/MojeUdajePages/DisciplinarnaOdmenaDetail";
import DisciplinarnyTrestDetail, {
  DisciplinarnyTrestDetailLoader,
} from "../pages/auth/MojeUdajePages/DisciplinarnyTrestDetail";
import IneRozhodnutieDetail, {
  IneRozhodnutieDetailLoader,
} from "../pages/auth/MojeUdajePages/IneRozhodnutieDetail";
import OchranneOpatrenieDetail, {
  OchranneOpatrenieDetailLoader,
} from "../pages/auth/MojeUdajePages/OchranneOpatrenieDetail";
import RozhodnutieDetail, {
  RozhodnutieDetailLoader,
} from "../pages/auth/MojeUdajePages/RozhodnutieDetail";
import TrestDetail, {
  TrestDetailLoader,
} from "../pages/auth/MojeUdajePages/TrestDetail";
import VazbaDetail, {
  VazbaDetailLoader,
} from "../pages/auth/MojeUdajePages/VazbaDetail";
import Stravovanie, {
  StravovanieLoader,
} from "../pages/auth/NastenkaPages/Stravovanie";
import Dashboard, { DashboardLoader } from "../pages/auth/Dashboard";
import { LoginLoader } from "../pages/Login";
import { isKiosk } from "../store/context/envConfigContext";
import { BezpecnyInternetKioskLoader } from "../pages/auth/BezpecnyInternetPages/BezpecnyInternetKiosk";

/**
 * Loaders classes / interfaces
 */
export interface GenericLoadedData<T> {
  data: T;
}

/**
 * Actions interfaces / types
 */
export enum ActionResultTypes {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export interface ActionResultAbstract {
  type: ActionResultTypes;
  message?: string;
}

export type ActionResult = ErrorActionResult | SuccessActionResult;

export interface ErrorActionResult extends ActionResultAbstract {
  type: ActionResultTypes.ERROR;
  message?: string;
}

export interface SuccessActionResult extends ActionResultAbstract {
  type: ActionResultTypes.SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export const Router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Login />,
        loader: LoginLoader,
      },
      {
        path: "examples",
        element: <ExamplesPage />,
        loader: ExamplesLoader,
        action: ExamplesActions,
        shouldRevalidate: (args) => false,
      },
      {
        path: "help/:pageID",
        element: <HelpPage />,
      },
      {
        path: "auth",
        // ensures that after a user is logged out he is redirected to login page
        element: <ProtectedLayout />,
        // loader protects all auth routes from being displayed - instead user is redirected to log in before first render
        loader: CheckIsLoggedInGuardLoader,
        children: [
          {
            index: true,
            element: <Dashboard />,
            loader: DashboardLoader,
          },
          {
            path: Dashboard_SC_KIO_0200.Requests,
            children: [
              {
                index: true,
                element: <Requests />,
                loader: RequestsLoader,
                action: RequestsTableActions,
                shouldRevalidate: (args) => false,
              },
              {
                path: ":requestCode",
                element: <Request />,
                loader: RequestLoader,
                action: RequestActions,
                shouldRevalidate: (args) => false,
              },
              {
                path: "Detail/:requestID",
                element: <RequestDetail />,
                loader: RequestDetailLoader,
                action: RequestDetailActions,
                shouldRevalidate: (args) => false,
              },
              {
                path: "universalRequest",
                children: [
                  {
                    path: "personalData",
                    element: <ZmenaUdajovZiadost />,
                    shouldRevalidate: (args) => false,
                    loader: ZmenaUdajovRequestLoader,
                  },
                ],
              },
            ],
          },
          {
            path:
              Requests_SC_KIO_0481.RequestsCategories + "/:requestCategoryCode",
            element: <RequestCategory />,
            loader: RequestCategoryLoader,
          },
          {
            path: Dashboard_SC_KIO_0200.NoticeBoard,
            children: [
              {
                index: true,
                element: <Nastenka />,
              },
              {
                path:
                  Noticeboard_SC_KIO_0400.ConstitutionalOrder + "/:filePath",
                element: <PrehliadacSuborov />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  PrehliadacSuborovLoader(
                    Noticeboard_SC_KIO_0400.ConstitutionalOrder,
                    params.filePath!
                  ),
              },
              {
                path:
                  Noticeboard_SC_KIO_0400.CulturalAndEducationalActivities +
                  "/:filePath",
                element: <PrehliadacSuborov />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  PrehliadacSuborovLoader(
                    Noticeboard_SC_KIO_0400.CulturalAndEducationalActivities,
                    params.filePath!
                  ),
              },
              {
                path: Noticeboard_SC_KIO_0400.PacketsAndLetters + "/:filePath",
                element: <PrehliadacSuborov />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  PrehliadacSuborovLoader(
                    Noticeboard_SC_KIO_0400.PacketsAndLetters,
                    params.filePath!
                  ),
              },
              {
                path: Noticeboard_SC_KIO_0400.Telephony + "/:filePath",
                element: <PrehliadacSuborov />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  PrehliadacSuborovLoader(
                    Noticeboard_SC_KIO_0400.Telephony,
                    params.filePath!
                  ),
              },
              {
                path:
                  Noticeboard_SC_KIO_0400.DailyPressAndMagazines + "/:filePath",
                element: <PrehliadacSuborov />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  PrehliadacSuborovLoader(
                    Noticeboard_SC_KIO_0400.DailyPressAndMagazines,
                    params.filePath!
                  ),
              },
              {
                path: Noticeboard_SC_KIO_0400.ProtectionOfRights + "/:filePath",
                element: <PrehliadacSuborov />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  PrehliadacSuborovLoader(
                    Noticeboard_SC_KIO_0400.ProtectionOfRights,
                    params.filePath!
                  ),
              },
              {
                path: Noticeboard_SC_KIO_0400.GeneralInformation,
                element: <VseobecneInformacie />,
                loader: VseobecneInformacieLoader,
              },
              {
                path: Noticeboard_SC_KIO_0400.Meal,
                element: <Stravovanie />,
                loader: StravovanieLoader,
              },
              {
                path:
                  Noticeboard_SC_KIO_0400.GeneralInformation +
                  "/:section/:filePath",
                element: <PrehliadacSuborov />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  PrehliadacSuborovLoader(params.section!, params.filePath!),
              },
              {
                path: Noticeboard_SC_KIO_0400.Library,
                element: <Kniznica />,
                loader: KniznicaLoader,
              },
              {
                path:
                  Noticeboard_SC_KIO_0400.Library +
                  "/" +
                  Library_SC_KIO_0460.Catalog,
                element: <KniznicaKatalogTitulov />,
                loader: KniznicaKatalogTitulovLoader,
              },
              {
                path:
                  Noticeboard_SC_KIO_0400.Library +
                  "/" +
                  Library_SC_KIO_0460.OfficeHours,
                element: <KniznicaVypozicneHodiny />,
              },
            ],
          },
          {
            path: Dashboard_SC_KIO_0200.MyData,
            children: [
              {
                index: true,
                element: <MojeUdaje />,
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.DisciplinarySanctionsAndOtherDecisions,
                element: <DisciplinarneTrestyAIneRozhodnutia />,
                loader: DisciplinarneTrestyAIneRozhodnutiaLoader,
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.DisciplinarySanctionsAndOtherDecisions +
                  "/DisciplinaryReward" +
                  "/:disciplinarnaOdmenaId",
                element: <DisciplinarnaOdmenaDetail />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  DisciplinarnaOdmenaDetailLoader(
                    params.disciplinarnaOdmenaId!
                  ),
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.DisciplinarySanctionsAndOtherDecisions +
                  "/DisciplinarySanction" +
                  "/:disciplinarnyTrestId",
                element: <DisciplinarnyTrestDetail />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  DisciplinarnyTrestDetailLoader(params.disciplinarnyTrestId!),
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.DisciplinarySanctionsAndOtherDecisions +
                  "/DisciplinaryOtherDecision" +
                  "/:ineRozhodnutieId",
                element: <IneRozhodnutieDetail />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  IneRozhodnutieDetailLoader(params.ineRozhodnutieId!),
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.ResocializationAndLeisureActivities,
                element: <ResocializacneAVolnocasoveAktivity />,
                loader: ResocializacneAVolnocasoveAktivityLoader,
                shouldRevalidate: (args) => false,
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.MoneyAndFees,
                element: <PeniazeAPoplatky />,
                loader: PeniazeAPoplatkyLoader,
                shouldRevalidate: (args) => false,
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.MoneyAndFees +
                  "/" +
                  MoneyClaimAndAlimony_SC_KIO_03603.PenitentiaryClaims,
                element: <PohladavkyUstavu />,
                loader: PohladavkyUstavuLoader,
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.MoneyAndFees +
                  "/" +
                  MoneyClaimAndAlimony_SC_KIO_03603.PenitentiaryClaims +
                  "/:pohladavkaId",
                element: <SplatkyPohladavokUstavu />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  SplatkyPohladavokUstavuLoader(params.pohladavkaId!),
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.MoneyAndFees +
                  "/" +
                  MoneyClaimAndAlimony_SC_KIO_03603.GRZVJSClaims,
                element: <PohladavkyGRZVJS />,
                loader: PohladavkyGRZVJSLoader,
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.MoneyAndFees +
                  "/" +
                  MoneyClaimAndAlimony_SC_KIO_03603.GRZVJSClaims +
                  "/:pohladavkaId",
                element: <SplatkyPohladavokGRZVJS />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  SplatkyPohladavokGRZVJSLoader(params.pohladavkaId!),
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.MoneyAndFees +
                  "/" +
                  MoneyClaimAndAlimony_SC_KIO_03603.OtherAuthorizedClaims,
                element: <PohladavkyOstatnychOpravnenych />,
                loader: PohladavkyOstatnychOpravnenychLoader,
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.MoneyAndFees +
                  "/" +
                  MoneyClaimAndAlimony_SC_KIO_03603.OtherAuthorizedClaims +
                  "/:pohladavkaId",
                element: <SplatkyPohladavokOstatnychOpravnenych />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  SplatkyPohladavokOstatnychOpravnenychLoader(
                    params.pohladavkaId!
                  ),
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.MoneyAndFees +
                  "/" +
                  MoneyClaimAndAlimony_SC_KIO_03603.Alimonys,
                element: <PohladavkyVyzivovacichPovinnosti />,
                loader: PohladavkyVyzivovacichPovinnostiLoader,
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.MoneyAndFees +
                  "/" +
                  MoneyClaimAndAlimony_SC_KIO_03603.Alimonys +
                  "/:pohladavkaId",
                element: <SplatkyPohladavokVyzivovacichPovinnosti />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  SplatkyPohladavokVyzivovacichPovinnostiLoader(
                    params.pohladavkaId!
                  ),
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.Packets,
                element: <Baliky />,
                loader: BalikyLoader,
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.Letters,
                element: <Listy />,
                loader: ListyLoader,
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.BasicClientData,
                element: <ZakladneUdajeKlienta />,
                loader: ZakladneUdajeKlientaLoader,
                shouldRevalidate: (args) => false,
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.BasicArrestData,
                element: <ZakladneUdajeOVazbe />,
                loader: ZakladneUdajeOVazbeLoader,
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.BasicPunishmentData,
                element: <ZakladneUdajeOTreste />,
                loader: ZakladneUdajeOTresteLoader,
                shouldRevalidate: (args) => false,
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.BasicPunishmentData +
                  "/ProtectiveMeasure" +
                  "/:ochranneOpatrenieID",
                element: <OchranneOpatrenieDetail />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  OchranneOpatrenieDetailLoader(params.ochranneOpatrenieID!),
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.BasicPunishmentData +
                  "/Detention" +
                  "/:vazbaID",
                element: <VazbaDetail />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  VazbaDetailLoader(params.vazbaID!),
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.BasicPunishmentData +
                  "/Punishment" +
                  "/:trestID",
                element: <TrestDetail />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  TrestDetailLoader(params.trestID!),
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.BasicPunishmentData +
                  "/Verdict" +
                  "/:rozhodnutieID",
                element: <RozhodnutieDetail />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  RozhodnutieDetailLoader(params.rozhodnutieID!),
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.PlaceOfCustodyAndExecutionOfSentence,
                element: <MiestoVykonuVazbyAVykonuTrestu />,
                loader: MiestoVykonuVazbyAVykonuTrestuLoader,
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.PersonalBelongings,
                element: <OsobneVeci />,
                loader: OsobneVeciLoader,
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.Telephony,
                element: <Telefonovanie />,
                loader: TelefonovanieLoader,
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.Visits,
                element: <Navstevy />,
                loader: NavstevyLoader,
              },
              {
                path: PersonalDataCategory_SC_KIO_0313.Guidances,
                element: <Poucenia />,
                loader: PouceniaLoader,
              },
              {
                path:
                  PersonalDataCategory_SC_KIO_0313.Guidances + "/:guidanceID",
                element: <PoucenieDetail />,
                loader: ({ params }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  PoucenieDetailLoader(params.guidanceID!),
              },
            ],
          },
          {
            path: Dashboard_SC_KIO_0200.SafeInternet,
            element: isKiosk() ? (
              <BezpecnyInternetKiosk />
            ) : (
              <BezpecnyInternetTablet />
            ),
            loader: isKiosk() ? BezpecnyInternetKioskLoader : undefined,
          },
        ],
      },
    ],
  },
  {
    path: "/auth/" + Dashboard_SC_KIO_0200.Holup,
    element: <ProtectedLayout />,
    // loader protects all auth routes from being displayed - instead user is redirected to login before first render
    loader: CheckIsLoggedInGuardLoader,
    children: [
      {
        index: true,
        element: <Holup />,
        loader: HolupLoader,
        shouldRevalidate: (args) => false,
        // errorElement: <ErrorPage />,
      },
    ],
  },
]);
