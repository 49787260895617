import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import {
  REQUEST_PAGE_PADDING,
  SECTION_LEFT_PADDING,
} from "../../request/requestPages/requestStyles/requestStyles";

interface ZvjsSectionUIProps {
  children?: ReactNode;
  location: number[];
}

const ZvjsCustomQuestionFullWidthBox: React.FC<ZvjsSectionUIProps> = ({
  children,
  location,
}) => {
  return (
    // maxWidth needs to be set to 0px otherwise vertical dividers are not displayed when using vw
    <Box sx={{ maxWidth: { md: "0px" } }}>
      <Box
        sx={{
          width: {
            md: `calc(100vw - ${2 * REQUEST_PAGE_PADDING}px - ${
              (location.length - 1) * SECTION_LEFT_PADDING
            }px)`,
            xs: "100%",
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ZvjsCustomQuestionFullWidthBox;
