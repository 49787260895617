import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "./locales/i18n";
import { Provider } from "react-redux";
import store from "./store/redux";
import AppContextProvider from "./store/context/app-context";
import { CssBaseline, ThemeProvider } from "@mui/material";
import zvjs_theme from "./theme/zvjs_theme";
import { RouterProvider } from "react-router-dom";
import { Router } from "./router/Router";
import { isElectron } from "./utils/helpers";
import { EnvConfigContextProvider } from "./store/context/envConfigContext";
import AppConnectivityGuard from "./utils/AppConnectivityGuard";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <EnvConfigContextProvider>
        <AppContextProvider>
          <ThemeProvider theme={zvjs_theme}>
            <CssBaseline enableColorScheme />
            <AppConnectivityGuard>
              <RouterProvider router={Router} />
            </AppConnectivityGuard>
          </ThemeProvider>
        </AppContextProvider>
      </EnvConfigContextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onSuccess: (registration: ServiceWorkerRegistration) => {
    console.debug("serviceWorkerRegistration onSuccess");

    if ("serviceWorker" in navigator && "SyncManager" in window) {
      navigator.serviceWorker.ready.then((value: ServiceWorkerRegistration) => {
        console.info("Register Background Sync");
        if ("sync" in value) {
          (value as ServiceWorkerRegistration).sync.register("SYNC DATA");
        }
      });
    } else {
      console.error("Failed to register Background Sync");
    }

    if (isElectron()) {
      // if you are running inside electron let electron to display alert dialog (to avoid bug of not working input fields when using alert dialog directly in react)
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send("showDialog", "Application upgraded");
    } else {
      alert("Application upgraded");
    }
  },
  onUpdate(registration: ServiceWorkerRegistration) {
    console.debug("serviceWorkerRegistration onUpdate");

    if (isElectron()) {
      // if you are running inside electron let electron to display alert dialog (to avoid bug of not working input fields when using alert dialog directly in react)
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send("showDialog", "Application upgrade available");
    } else {
      alert("Application upgrade available");
    }

    registration
      .unregister()
      .then(() => {
        window.location.reload();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      })
      .catch((error) => {
        if (error instanceof Error) {
          console.error(
            `serviceWorkerRegistration onUpdate error[${error.message}]`
          );
        } else {
          console.error(
            `serviceWorkerRegistration onUpdate error[${String(error)}]`
          );
        }
      });
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
