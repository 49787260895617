import {
  Calculate,
  ZvjsItemTypes,
  ZvjsLabelFlagTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050205",
  basicInfo: [
    {
      text: "ziadost.ziadostOzaevidovaniePPnaVreckoveKonto.zakladneInfo",
    },
  ],
  isAvailableToUser: "SC_KIO_050205_isAvailableToUser",
  dataCalculations: {
    "3_x_MINIMUM_SUBSISTENCE_AMOUNT": {
      calculateAt: Calculate.AT_INIT,
    },
  },
  items: [
    {
      id: "sumaNaVreckoveKonto",
      title: "ziadost.ziadostOzaevidovaniePPnaVreckoveKonto.labelVyskaVkladu",
      type: ZvjsQuestionTypes.TEXT,
      inputType: "number",
      isRequired: true,
    },
    {
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOzaevidovaniePPnaVreckoveKonto.napovedaVreckove",
      flag: ZvjsLabelFlagTypes.INFO,
    },
  ],
};
