import { API_Clients } from "../../../../../store/context/dataApi/Data";
import { CisPohlavie_ZENA_ID } from "../../../../specs/countersValues";
import { getIsFemale } from "./calculations/reusable";

export type IsAvailableToUserType =
  | "SC_KIO_051001_isAvailableToUser"
  | "SC_KIO_051402_isAvailableToUser"
  | "SC_KIO_051401_isAvailableToUser"
  | "SC_KIO_051201_isAvailableToUser"
  | "SC_KIO_051104_isAvailableToUser"
  | "SC_KIO_051004_isAvailableToUser"
  | "SC_KIO_050602_isAvailableToUser"
  | "SC_KIO_050402_isAvailableToUser"
  | "SC_KIO_050207_isAvailableToUser"
  | "SC_KIO_050206_isAvailableToUser"
  | "SC_KIO_050205_isAvailableToUser";

const SC_KIO_051001_isAvailableToUser = async () => {
  const isUserFemale = await getIsFemale();
  if (isUserFemale) {
    return false;
  }
  return true;
};

const SC_KIO_051402_isAvailableToUser = async () => {
  // TODO add real api calls
  const hasOtherRequestOfThisTypeInProgress = false;
  if (hasOtherRequestOfThisTypeInProgress) {
    return false;
  }

  return true;
};

const SC_KIO_051401_isAvailableToUser = async () => {
  // TODO add real api calls
  const isUserAccused = true;
  if (!isUserAccused) {
    return false;
  }

  const isUserInCollusiveBond = false;
  if (isUserInCollusiveBond) {
    return false;
  }

  const hasUserSeriousLevelAccusation = false;
  if (hasUserSeriousLevelAccusation) {
    return false;
  }

  return true;
};

const SC_KIO_051201_isAvailableToUser = async () => {
  // TODO add real api calls
  const userHasSpecialDiet = false;
  if (userHasSpecialDiet) {
    return false;
  }

  const hasAlreadyRequestedThisCalendarMonth = false;
  if (hasAlreadyRequestedThisCalendarMonth) {
    return false;
  }

  return true;
};

const SC_KIO_051104_isAvailableToUser = async () => {
  // TODO add real api calls
  const userUseInstitutionClothes = true;
  if (!userUseInstitutionClothes) {
    return false;
  }

  return true;
};

const SC_KIO_051004_isAvailableToUser = async () => {
  // TODO add real api calls
  const hasAlreadyRequestedThisCalendarMonth = false;
  if (hasAlreadyRequestedThisCalendarMonth) {
    return false;
  }

  const shoppedThisCalendarMonth = false;
  if (shoppedThisCalendarMonth) {
    return false;
  }

  const otherChecks = true;
  if (!otherChecks) {
    return false;
  }

  return true;
};

const SC_KIO_050602_isAvailableToUser = async () => {
  const isAnyProgramAvailableToClient = true;
  // TODO check whether there is in a client's institution any program that he can attend and have not applied for yet
  if (!isAnyProgramAvailableToClient) {
    return false;
  }
  return true;
};

const SC_KIO_050402_isAvailableToUser = async () => {
  // TODO add real api calls
  const hasAlreadyRequestedToday = false;
  if (hasAlreadyRequestedToday) {
    return false;
  }

  return true;
};

const SC_KIO_050207_isAvailableToUser = async () => {
  // TODO add real api calls
  const isUserAllowedToManipulateWithFunds = true;
  if (!isUserAllowedToManipulateWithFunds) {
    return false;
  }

  const userHasAnyActiveExtraInstitutionLeaveAllowance = true;
  if (!userHasAnyActiveExtraInstitutionLeaveAllowance) {
    return false;
  }

  const isUserSentenced = true;
  if (!isUserSentenced) {
    return false;
  }

  return true;
};

const SC_KIO_050206_isAvailableToUser = async () => {
  // TODO add real api calls
  const isUserSentenced = true;
  if (!isUserSentenced) {
    return false;
  }

  const hasAnyChildSupportObligation = true;
  if (!hasAnyChildSupportObligation) {
    return false;
  }

  return true;
};

const SC_KIO_050205_isAvailableToUser = async () => {
  // TODO add real api calls
  const hasAlreadyRequestedThisMonth = false;
  if (hasAlreadyRequestedThisMonth) {
    return false;
  }

  const hasMoneyOnAccount = true;
  if (!hasMoneyOnAccount) {
    return false;
  }

  return true;
};

const isAvailableToUserFunctions: IsAvailableToUserFunctionsType = {
  SC_KIO_051001_isAvailableToUser: SC_KIO_051001_isAvailableToUser,
  SC_KIO_051402_isAvailableToUser: SC_KIO_051402_isAvailableToUser,
  SC_KIO_051401_isAvailableToUser: SC_KIO_051401_isAvailableToUser,
  SC_KIO_051201_isAvailableToUser: SC_KIO_051201_isAvailableToUser,
  SC_KIO_051104_isAvailableToUser: SC_KIO_051104_isAvailableToUser,
  SC_KIO_051004_isAvailableToUser: SC_KIO_051004_isAvailableToUser,
  SC_KIO_050602_isAvailableToUser: SC_KIO_050602_isAvailableToUser,
  SC_KIO_050402_isAvailableToUser: SC_KIO_050402_isAvailableToUser,
  SC_KIO_050207_isAvailableToUser: SC_KIO_050207_isAvailableToUser,
  SC_KIO_050206_isAvailableToUser: SC_KIO_050206_isAvailableToUser,
  SC_KIO_050205_isAvailableToUser: SC_KIO_050205_isAvailableToUser,
};

type IsAvailableToUserFunctionsType = {
  [key in IsAvailableToUserType]: () => Promise<boolean>;
};

export default isAvailableToUserFunctions;
