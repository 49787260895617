import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { capitalize } from "@mui/material/utils";
import React, { ChangeEvent, Fragment, useState } from "react";
import { useUITranslation } from "../../store/context/translation-context";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { ZvjsButton, ZvjsSelect } from "./index";
import ZvjsDateSelect from "./ZvjsDateSelect";
import { ZvjsSelectItem } from "./ZvjsSelect";

/***
 * Variant A: Date picker - Date picker,
 * Variant B: Date picker - Date picker - Combobox,
 * Variant C: Date picker - Date picker - Checkbox,
 * Variant D: Combobox - Checkbox,
 * Variant E: Combobox - Combobox
 * Variant F: Combobox - Combobox - Combobox
 * Dp - date picker
 * Co - combobox
 * Cb - checkbox
 */
export enum ZvjsSearchBarVariant {
  /**
   * Date picker - Date picker
   */
  VARIANT_A_DpDp,
  /**
   * Date picker - Date picker - Combobox
   */
  VARIANT_B_DpDpCo,
  /**
   * Date picker - Date picker - Checkbox
   */
  VARIANT_C_DpDpCb,
  /**
   * Combobox - Checkbox
   */
  VARIANT_D_CoCb,
  /**
   * Combobox - Combobox
   */
  VARIANT_E_CoCo,
  /**
   * Combobox - Combobox - Combobox
   */
  VARIANT_F_CoCoCo,
}

interface ZvjsSerachBarProps {
  title: string;
  type: ZvjsSearchBarVariant;
}

interface ZvjsDateInterface extends ZvjsSerachBarProps {
  minDate1?: Date;
  maxDate1?: Date;
  minDate2?: Date;
  maxDate2?: Date;
}

interface ZvjsDropDown extends ZvjsSerachBarProps {
  comboboxLabel: string;
  items: ZvjsSelectItem[];
}

interface ZvjsSearchBarProps_A extends ZvjsDateInterface {
  type: ZvjsSearchBarVariant.VARIANT_A_DpDp;
  onSubmit: (dateFrom: Date, dateTo: Date) => unknown;
}

interface ZvjsSearchBarProps_B extends ZvjsDateInterface, ZvjsDropDown {
  type: ZvjsSearchBarVariant.VARIANT_B_DpDpCo;
  onSubmit: (dateFrom: Date, dateTo: Date, comboboxLabel: string) => unknown;
}

interface ZvjsSearchBarProps_C extends ZvjsDateInterface {
  type: ZvjsSearchBarVariant.VARIANT_C_DpDpCb;
  checkBoxLabel: string;
  onSubmit: (dateFrom: Date, dateTo: Date, isChecked: boolean) => unknown;
}

interface ZvjsSearchBarProps_D extends ZvjsDropDown {
  type: ZvjsSearchBarVariant.VARIANT_D_CoCb;
  checkBoxLabel: string;
  onSubmit: (comboboxLabel: string, isChecked: boolean) => unknown;
}

interface ZvjsSearchBarProps_E extends ZvjsDropDown {
  type: ZvjsSearchBarVariant.VARIANT_E_CoCo;
  comboboxLabel2: string;
  items2: ZvjsSelectItem[];
  onSubmit: (comboboxLabel1: string, comboboxLabel2: string) => unknown;
}

interface ZvjsSearchBarProps_F extends ZvjsDropDown {
  type: ZvjsSearchBarVariant.VARIANT_F_CoCoCo;
  comboboxLabel2: string;
  items2: ZvjsSelectItem[];
  comboboxLabel3: string;
  items3: ZvjsSelectItem[];
  onSubmit: (
    comboboxLabel1: string,
    comboboxLabel2: string,
    comboboxLabel3: string
  ) => unknown;
}

type ZvjsSearchBartTypes =
  | ZvjsSearchBarProps_A
  | ZvjsSearchBarProps_B
  | ZvjsSearchBarProps_C
  | ZvjsSearchBarProps_D
  | ZvjsSearchBarProps_E
  | ZvjsSearchBarProps_F;

const ZvjsSearchBar: React.FC<ZvjsSearchBartTypes> = ({
  title,
  type,
  ...rest
}) => {
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [comboboxValue, setComboboxValue] = useState("");
  const [comboboxValue2, setComboboxValue2] = useState("");
  const [comboboxValue3, setComboboxValue3] = useState("");
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);

  const { tui } = useUITranslation();

  const onSubmitHandler = () => {
    switch (type) {
      case ZvjsSearchBarVariant.VARIANT_A_DpDp:
        (rest as ZvjsSearchBarProps_A).onSubmit(
          new Date(dateFrom),
          new Date(dateTo)
        );
        break;
      case ZvjsSearchBarVariant.VARIANT_B_DpDpCo:
        (rest as ZvjsSearchBarProps_B).onSubmit(
          new Date(dateFrom),
          new Date(dateTo),
          comboboxValue
        );
        break;
      case ZvjsSearchBarVariant.VARIANT_C_DpDpCb:
        (rest as ZvjsSearchBarProps_C).onSubmit(
          new Date(dateFrom),
          new Date(dateTo),
          checkBoxChecked
        );
        break;
      case ZvjsSearchBarVariant.VARIANT_D_CoCb:
        (rest as ZvjsSearchBarProps_D).onSubmit(comboboxValue, checkBoxChecked);
        break;
      case ZvjsSearchBarVariant.VARIANT_E_CoCo:
        (rest as ZvjsSearchBarProps_E).onSubmit(comboboxValue, comboboxValue2);
        break;
      case ZvjsSearchBarVariant.VARIANT_F_CoCoCo:
        (rest as ZvjsSearchBarProps_F).onSubmit(
          comboboxValue,
          comboboxValue2,
          comboboxValue3
        );
        break;
      default:
        break;
    }
  };

  const onSelectChangeHandler = (event: SelectChangeEvent<unknown>) => {
    setComboboxValue(event.target.value as string);
  };
  const onSelectChangeHandler2 = (event: SelectChangeEvent<unknown>) => {
    setComboboxValue2(event.target.value as string);
  };
  const onSelectChangeHandler3 = (event: SelectChangeEvent<unknown>) => {
    setComboboxValue3(event.target.value as string);
  };

  const onDateFromChangeHandler = (selecteDate: string) => {
    setDateFrom(selecteDate);
  };

  const onDateToChangeHandler = (selecteDate: string) => {
    setDateTo(selecteDate);
  };

  const onCheckBoxChangeHandler = (
    event: ChangeEvent<unknown>,
    checked: boolean
  ) => {
    setCheckBoxChecked(checked);
  };

  const onResetHandler = () => {
    setDateFrom("");
    setDateTo("");
    setComboboxValue("");
    setComboboxValue2("");
    setComboboxValue3("");
    setCheckBoxChecked(false);
  };

  const advancedRender1 = () => {
    const data_A = rest as ZvjsSearchBarProps_A;
    const data_B = rest as ZvjsSearchBarProps_B;
    const data_C = rest as ZvjsSearchBarProps_C;
    return (
      <Fragment>
        {type === ZvjsSearchBarVariant.VARIANT_A_DpDp && (
          <Grid item xs={6}></Grid>
        )}
        {type === ZvjsSearchBarVariant.VARIANT_B_DpDpCo && (
          <Grid item xs={6}>
            <ZvjsSelect
              id={"select"}
              text={data_B.comboboxLabel}
              items={data_B.items}
              changeHandler={onSelectChangeHandler}
              selectedValue={comboboxValue}
            />
          </Grid>
        )}
        {type === ZvjsSearchBarVariant.VARIANT_C_DpDpCb && (
          <Fragment>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Stack
                mt={3}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkBoxChecked}
                      onChange={onCheckBoxChangeHandler}
                      color="secondary"
                    />
                  }
                  label={capitalize(data_C.checkBoxLabel)}
                />
              </Stack>
            </Grid>
          </Fragment>
        )}
        <Grid item xs={3}>
          <ZvjsDateSelect
            text={capitalize(tui("dátum od"))}
            id="DateFrom"
            onChange={onDateFromChangeHandler}
            value={dateFrom}
            minDate={data_A.minDate1}
            maxDate={data_A.maxDate1}
          />
        </Grid>
        <Grid item xs={3}>
          <ZvjsDateSelect
            text={capitalize(tui("dátum do"))}
            id="DateTo"
            onChange={onDateToChangeHandler}
            value={dateTo}
            minDate={data_A.minDate2}
            maxDate={data_A.maxDate2}
          />
        </Grid>
      </Fragment>
    );
  };
  const advancedRender2 = () => {
    const data = rest as ZvjsSearchBarProps_D | ZvjsSearchBarProps_E;
    const data_D = rest as ZvjsSearchBarProps_D;
    const data_E = rest as ZvjsSearchBarProps_E;
    const data_F = rest as ZvjsSearchBarProps_F;
    return (
      <Fragment>
        <Grid item xs={6}>
          <ZvjsSelect
            id={"select"}
            text={data.comboboxLabel}
            items={data.items}
            changeHandler={onSelectChangeHandler}
            selectedValue={comboboxValue}
          />
        </Grid>
        {type === ZvjsSearchBarVariant.VARIANT_D_CoCb && (
          <Grid item xs={6}>
            <Stack
              mt={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxChecked}
                    onChange={onCheckBoxChangeHandler}
                    color="secondary"
                  />
                }
                label={capitalize(data_D.checkBoxLabel)}
              />
            </Stack>
          </Grid>
        )}
        {type === ZvjsSearchBarVariant.VARIANT_E_CoCo && (
          <Grid item xs={6}>
            <ZvjsSelect
              id={"select"}
              text={data_E.comboboxLabel2}
              items={data_E.items2}
              changeHandler={onSelectChangeHandler2}
              selectedValue={comboboxValue2}
            />
          </Grid>
        )}
        {type === ZvjsSearchBarVariant.VARIANT_F_CoCoCo && (
          <Fragment>
            <Grid item xs={3}>
              <ZvjsSelect
                id={"select"}
                text={data_E.comboboxLabel2}
                items={data_E.items2}
                changeHandler={onSelectChangeHandler2}
                selectedValue={comboboxValue2}
              />
            </Grid>
            <Grid item xs={3}>
              <ZvjsSelect
                id={"select"}
                text={data_F.comboboxLabel3}
                items={data_F.items3}
                changeHandler={onSelectChangeHandler3}
                selectedValue={comboboxValue3}
              />
            </Grid>
          </Fragment>
        )}
      </Fragment>
    );
  };

  return (
    <Paper
      sx={{
        justifyContent: "left",
        borderRadius: "4px !important",
      }}
    >
      <Stack ml={2} mr={2} mt={1} mb={3} direction="column" spacing={2}>
        <Typography variant="h3" fontSize={30}>
          {capitalize(title)}
        </Typography>
        <form>
          <Grid container spacing={2} width="stretch" justifyContent="center">
            {type <= ZvjsSearchBarVariant.VARIANT_C_DpDpCb
              ? advancedRender1()
              : advancedRender2()}
          </Grid>
        </form>
        <Stack direction="row" spacing={2}>
          <ZvjsButton
            zvjsVariant="secondaryAction"
            text={capitalize(tui("vyhľadať"))}
            startIcon={<SearchOutlinedIcon style={{ height: 38, width: 38 }} />}
            onClick={onSubmitHandler}
          />
          <ZvjsButton
            zvjsVariant="cancelAction"
            text={capitalize(tui("tlacidla.vynulovatFilter"))}
            startIcon={
              <DeleteForeverRoundedIcon style={{ height: 36, width: 36 }} />
            }
            onClick={onResetHandler}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ZvjsSearchBar;
