export enum Noticeboard_SC_KIO_0400 {
  ConstitutionalOrder = "ConstitutionalOrder",
  PacketsAndLetters = "PacketsAndLetters",
  Telephony = "Telephony",
  Meal = "Meal",
  CulturalAndEducationalActivities = "CulturalAndEducationalActivities",
  Library = "Library",
  DailyPressAndMagazines = "DailyPressAndMagazines",
  ProtectionOfRights = "ProtectionOfRights",
  GeneralInformation = "GeneralInformation",
}
