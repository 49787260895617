import React, { useEffect, useState } from "react";
import { ZvjsSearchBar, ZvjsTable } from "../../../../common";
import { ZvjsSearchBarVariant } from "../../../../common/components/ZvjsSearchBar";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { ListyProps } from "../Listy";

const PrijataKorespondenciaTab: React.FC<ListyProps> = ({ data }) => {
  const { t } = useTranslation();
  const { tui } = useUITranslation();

  const dataStyle: TableStyle[] = [
    {
      align: "left",
      width: "26%",
    },
    {
      align: "left",
      width: "37%",
    },
    {
      align: "left",
      width: "37%",
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Letters_SC_KIO_0360.Received.dateOfAcceptance"
        )
      ),
      ...dataStyle[0],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Letters_SC_KIO_0360.Received.correspondenceType"
        )
      ),
      ...dataStyle[1],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Letters_SC_KIO_0360.Received.receiver"
        )
      ),
      ...dataStyle[2],
    },
  ];

  const [tableData, setTableData] = useState<TableData>({
    header: headerData,
    body: [],
  });

  useEffect(() => {
    if (data.zoznamPrijatejKorespondencie.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      data.zoznamPrijatejKorespondencie.data
        ?.sort(
          (a, b) =>
            new Date(b.datumPrijatia ?? new Date()).valueOf() -
            new Date(a.datumPrijatia ?? new Date()).valueOf()
        )
        .forEach((prijataKorespondencia) => {
          bodyData.push({
            row: [
              {
                value: new Date(
                  prijataKorespondencia.datumPrijatia ?? new Date()
                ).zvjsToString(),
                ...dataStyle[0],
              },
              {
                value: prijataKorespondencia.typKorespondencieNazov ?? "",
                ...dataStyle[1],
              },
              {
                value: prijataKorespondencia.prijmatelFyzickaOsoba ?? "",
                ...dataStyle[2],
              },
            ],
          });
        });
      setTableData({
        ...tableData,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const mySubmitHandlerPayslip = (dateFrom: Date, dateTo: Date) => {
    console.log(dateFrom);
    console.log(dateTo);
  };

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsSearchBar
        title={t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Letters_SC_KIO_0360.Received.search"
        )}
        type={ZvjsSearchBarVariant.VARIANT_A_DpDp}
        onSubmit={mySubmitHandlerPayslip}
      />
      <ZvjsTable
        data={tableData}
        height={180}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default PrijataKorespondenciaTab;
