import React from "react";
import PullToRefresh from "react-simple-pull-to-refresh";

interface ZvjsPullToRefreshProps {
  children: React.ReactElement;
}

const ZvjsPullToRefresh: React.FC<ZvjsPullToRefreshProps> = ({ children }) => {
  return (
    <PullToRefresh
      onRefresh={async () => window.location.reload()}
      pullingContent={""}
    >
      {children}
    </PullToRefresh>
  );
};

export default ZvjsPullToRefresh;
