import { useEffect, useState } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ReactComponent as DetailIcon } from "../../../assets/icons/Other/detail.svg";
import { useNavigate } from "react-router-dom";
import {
  GeneralInformation_SC_KIO_0490,
  ZvjsButton,
  ZvjsTable,
} from "../../../common";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router";
import { withLoader } from "../../../hoc/withLoader";

export const VseobecneInformacieLoader = async () => {
  // TODO replace with actual fetch
  return defer({
    data: [
      GeneralInformation_SC_KIO_0490.AudioRecordings,
      GeneralInformation_SC_KIO_0490.InformationalBrochures,
      GeneralInformation_SC_KIO_0490.InternalRegulationsZVJS,
      GeneralInformation_SC_KIO_0490.OtherFiles,
    ],
  });
};

interface VseobecneInformacieProps {
  data: [string] | undefined;
}

const VseobecneInformacie = (props: VseobecneInformacieProps) => {
  const items = props.data;
  const { t, i18n } = useTranslation();
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "right",
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(t("spec.section.GeneralInformation_SC_KIO_0490.name")),
      ...dataStyle[0],
    },
    {
      value: capitalize(
        t("spec.section.GeneralInformation_SC_KIO_0490.action")
      ),
      ...dataStyle[1],
    },
  ];

  const [tableData, setTableData] = useState<TableData>({
    header: headerData,
    body: [],
  });

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (items) {
      const bodyData: Array<RowData> = new Array<RowData>();
      items.forEach((item) => {
        bodyData.push({
          row: [
            {
              value: capitalize(
                t(`spec.section.GeneralInformation_SC_KIO_0490.${item}`)
              ),
              ...dataStyle[0],
            },
            {
              value: (
                <ZvjsButton
                  text={capitalize(
                    t("spec.section.GeneralInformation_SC_KIO_0490.detail")
                  )}
                  zvjsVariant="secondaryAction"
                  startIcon={
                    <DetailIcon
                      style={{ fill: "white", width: 28, height: 28 }}
                    />
                  }
                  onClick={() => {
                    switch (item) {
                      case GeneralInformation_SC_KIO_0490.AudioRecordings:
                        navigate(`${item}/${encodeURIComponent("/")}`);
                        break;
                      case GeneralInformation_SC_KIO_0490.InformationalBrochures:
                        navigate(`${item}/${encodeURIComponent("/")}`);
                        break;
                      case GeneralInformation_SC_KIO_0490.InternalRegulationsZVJS:
                        navigate(`${item}/${encodeURIComponent("/")}`);
                        break;
                      case GeneralInformation_SC_KIO_0490.OtherFiles:
                        navigate(`${item}/${encodeURIComponent("/")}`);
                        break;
                    }
                  }}
                />
              ),
              ...dataStyle[1],
            },
          ],
        });
      });
      setTableData({
        ...tableData,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, i18n.language]);

  // const mySubmitHandler = (
  //   dateFrom: Date,
  //   dateTo: Date,
  //   dropDownValue: string
  // ) => {
  //   console.log(dateFrom);
  //   console.log(dateTo);
  // };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(
              t("spec.section.Noticeboard_SC_KIO_0400.GeneralInformation")
            )}
          </Typography>
        </Stack>
      </Grid>

      <ZvjsTable data={tableData} variant={ZvjsTableVariant.NORMAL} />
    </Grid>
  );
};

export default withLoader(VseobecneInformacie);
