import { Grid, Typography } from "@mui/material";
import { ZVJS_COLORS } from "../../theme/zvjs_theme";
import React from "react";
import { Stack } from "@mui/system";
import { ReactComponent as MoneyIcon } from "../../assets/icons/Other/money.svg";
import { ReactComponent as RequestIcon } from "../../assets/icons/Other/requests_categories.svg";
import { ReactComponent as BookIcon } from "../../assets/icons/Other/book.svg";
import { ReactComponent as ComplainIncon } from "../../assets/icons/Other/request.svg";
import { ReactComponent as NoticeIcon } from "../../assets/icons/Other/notice_board.svg";
import { ZvjsButton } from "./index";
import { ReactComponent as DetailIcon } from "../../assets/icons/Other/detail.svg";
import { ReactComponent as AcceptIcon } from "../../assets/icons/Other/accept.svg";

//todo add correct translations
export enum ZvjsNotificationType {
  COMPLAINT = "Sťažnosť",
  REQUEST = "Žiadosť",
  LESSONS = "Poučenia",
  MONEY_AND_FEES = "Peniaze",
  BOARD = "Nástenka",
}

interface ZvjsNotificationProps {
  type: ZvjsNotificationType;
  title: string;
  dateAndTime: string;
  description: string;
  showButton?: boolean;
}

const ZvjsNotification: React.FC<ZvjsNotificationProps> = ({
  type,
  title,
  dateAndTime,
  description,
  showButton,
}) => {
  const getColor = (notificationType: ZvjsNotificationType): string => {
    switch (notificationType) {
      case ZvjsNotificationType.COMPLAINT:
        return ZVJS_COLORS.RED;
      case ZvjsNotificationType.REQUEST:
        return ZVJS_COLORS.BLUE_2;
      case ZvjsNotificationType.LESSONS:
        return ZVJS_COLORS.GREEN_2;
      case ZvjsNotificationType.MONEY_AND_FEES:
        return ZVJS_COLORS.ORANGE;
      case ZvjsNotificationType.BOARD:
        return ZVJS_COLORS.PURPLE;
      default:
        return ZVJS_COLORS.WHITE;
    }
  };

  const getIcon = (notificationType: ZvjsNotificationType): React.ReactNode => {
    switch (notificationType) {
      case ZvjsNotificationType.COMPLAINT:
        return (
          <ComplainIncon
            fill={getColor(notificationType)}
            style={{
              width: "1.5rem",
              height: "1.5rem",
            }}
          />
        );
      case ZvjsNotificationType.REQUEST:
        return (
          <RequestIcon
            fill={getColor(notificationType)}
            style={{
              width: "1.5rem",
              height: "1.5rem",
            }}
          />
        );
      case ZvjsNotificationType.LESSONS:
        return (
          <BookIcon
            fill={getColor(notificationType)}
            style={{
              width: "1.5rem",
              height: "1.5rem",
            }}
          />
        );
      case ZvjsNotificationType.MONEY_AND_FEES:
        return (
          <MoneyIcon
            fill={getColor(notificationType)}
            style={{
              width: "1.5rem",
              height: "1.5rem",
            }}
          />
        );
      case ZvjsNotificationType.BOARD:
        return (
          <NoticeIcon
            fill={getColor(notificationType)}
            style={{
              width: "1.5rem",
              height: "1.5rem",
            }}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Grid
      container
      sx={{
        height: "13rem",
        width: "100%",
        backgroundColor: ZVJS_COLORS.WHITE,
        borderRadius: "1rem",
        borderLeft: "1rem solid",
        borderColor: getColor(type),
      }}
      flexWrap={"nowrap"}
    >
      <Grid
        item
        xs={1.5}
        sx={{
          backgroundColor: ZVJS_COLORS.GREY_5,
        }}
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <Typography variant="h4">{type.toString()}</Typography>
      </Grid>
      <Grid item margin={2} xs={10.5}>
        <Stack
          justifyContent={"space-between"}
          direction={"column"}
          height={"100%"}
        >
          <div>
            <Stack direction="row" spacing={2} justifyContent={"space-between"}>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                {getIcon(type)}
                <Typography variant="h5">{title}</Typography>
              </Stack>
              <Typography variant={"body1"} style={{ textTransform: "none" }}>
                <em>{dateAndTime}</em>
              </Typography>
            </Stack>
            <Typography variant="body1" marginTop={0.5}>
              {description}
            </Typography>
          </div>
          {showButton === true ||
            (showButton === undefined && (
              <Stack
                direction={"row"}
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
                spacing={2}
              >
                <ZvjsButton
                  zvjsVariant={"secondaryAction"}
                  startIcon={
                    <AcceptIcon
                      style={{ fill: "white", width: 28, height: 28 }}
                    />
                  }
                >
                  Beriem na vedomie
                </ZvjsButton>
                <ZvjsButton
                  startIcon={
                    <DetailIcon
                      style={{ fill: "white", width: 28, height: 28 }}
                    />
                  }
                  zvjsVariant={"secondaryAction"}
                >
                  Detail
                </ZvjsButton>
              </Stack>
            ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ZvjsNotification;
