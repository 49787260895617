import { PromiseElement } from "../../../utils/types";
import { API_Clients } from "./Data";

export const MAX_NUMBER_OF_ITEMS = 999999;

export const CisSynchronizeReturnTypes = async () => {
  const { CIS_Post } = await API_Clients();

  return {
    "/api/DalsiePotrebyOh/List": CIS_Post("/api/DalsiePotrebyOh/List"),
    "/api/CisDochodokDruh/List": CIS_Post("/api/CisDochodokDruh/List"),
    "/api/CisDovodCastejsiehoSprchovania/List": CIS_Post(
      "/api/CisDovodCastejsiehoSprchovania/List"
    ),
    "/api/CisDovodMimoriadnejNavstevy/List": CIS_Post(
      "/api/CisDovodMimoriadnejNavstevy/List"
    ),
    "/api/CisDovodNaOdoslanieKorespondencie/List": CIS_Post(
      "/api/CisDovodNaOdoslanieKorespondencie/List"
    ),
    "/api/CisDovodPodaniaZiadostiElektrospotrebic/List": CIS_Post(
      "/api/CisDovodPodaniaZiadostiElektrospotrebic/List"
    ),
    "/api/CisDovodPodaniaZiadosti/List": CIS_Post(
      "/api/CisDovodPodaniaZiadosti/List"
    ),
    "/api/CisDovodPozadovanejObmeny/List": CIS_Post(
      "/api/CisDovodPozadovanejObmeny/List"
    ),
    "/api/CisDovodZiadostiOrozhovor/List": CIS_Post(
      "/api/CisDovodZiadostiOrozhovor/List"
    ),
    "/api/CisDovodZiadostiSprchovanie/List": CIS_Post(
      "/api/CisDovodZiadostiSprchovanie/List"
    ),
    "/api/CisDovodZiadosti/List": CIS_Post("/api/CisDovodZiadosti/List"),
    "/api/CisDruhBalika/List": CIS_Post("/api/CisDruhBalika/List"),
    "/api/CisDruhElektrospotrebica/List": CIS_Post(
      "/api/CisDruhElektrospotrebica/List"
    ),
    "/api/CisFormaRealizacieNavstevy/List": CIS_Post(
      "/api/CisFormaRealizacieNavstevy/List"
    ),
    "/api/CisFyzickePrejavyProblemov/List": CIS_Post(
      "/api/CisFyzickePrejavyProblemov/List"
    ),
    "/api/CisKategoriaZiadosti/List": CIS_Post(
      "/api/CisKategoriaZiadosti/List"
    ),
    "/api/CisKrajina/List": CIS_Post("/api/CisKrajina/List"),
    "/api/CisOblastiPsychickychProblemov/List": CIS_Post(
      "/api/CisOblastiPsychickychProblemov/List"
    ),
    "/api/CisOblastSocialnehoPoradenstva/List": CIS_Post(
      "/api/CisOblastSocialnehoPoradenstva/List"
    ),
    "/api/CisOblastZdravotnychProblemov/List": CIS_Post(
      "/api/CisOblastZdravotnychProblemov/List"
    ),
    "/api/CisOdievanie/List": CIS_Post("/api/CisOdievanie/List"),
    "/api/CisOdpovedPreKlienta/List": CIS_Post(
      "/api/CisOdpovedPreKlienta/List"
    ),
    "/api/CisPodkategoriaZiadosti/List": CIS_Post(
      "/api/CisPodkategoriaZiadosti/List"
    ),
    "/api/CisPodnetNaOchranu/List": CIS_Post("/api/CisPodnetNaOchranu/List"),
    "/api/CisPreventivneZdravPrehliadky/List": CIS_Post(
      "/api/CisPreventivneZdravPrehliadky/List"
    ),
    "/api/CisPrijimaniePenazProstriedkov/List": CIS_Post(
      "/api/CisPrijimaniePenazProstriedkov/List"
    ),
    "/api/CisPsychickyStav/List": CIS_Post("/api/CisPsychickyStav/List"),
    "/api/CisSpanok/List": CIS_Post("/api/CisSpanok/List"),
    "/api/CisSposobOdovzdaniaCeniny/List": CIS_Post(
      "/api/CisSposobOdovzdaniaCeniny/List"
    ),
    "/api/CisSposobZabezpeceniaHygNezavadnosti/List": CIS_Post(
      "/api/CisSposobZabezpeceniaHygNezavadnosti/List"
    ),
    "/api/StavOsobnejVeci/List": CIS_Post("/api/StavOsobnejVeci/List"),
    "/api/CisStavZiadosti/List": CIS_Post("/api/CisStavZiadosti/List"),
    "/api/CisStrach/List": CIS_Post("/api/CisStrach/List"),
    "/api/CisStravovanie/List": CIS_Post("/api/CisStravovanie/List"),
    "/api/CisStupenVzdelania/List": CIS_Post("/api/CisStupenVzdelania/List"),
    "/api/CisTrvanieNavstevy/List": CIS_Post("/api/CisTrvanieNavstevy/List"),
    "/api/CisTypOsobnejVeci/List": CIS_Post("/api/CisTypOsobnejVeci/List"),
    "/api/CisTypPremiestnenia/List": CIS_Post("/api/CisTypPremiestnenia/List"),
    "/api/CisTypZiadosti/List": CIS_Post("/api/CisTypZiadosti/List"),
    "/api/CisUbytovanie/List": CIS_Post("/api/CisUbytovanie/List"),
    "/api/CisUIrozhranieTextacie/List": CIS_Post(
      "/api/CisUIrozhranieTextacie/List"
    ),
    "/api/CisUIrozhranieZiadosti/List": CIS_Post(
      "/api/CisUIrozhranieZiadosti/List"
    ),
    "/api/CisUpresnenieOblastiZdravotnychProblemov/List": CIS_Post(
      "/api/CisUpresnenieOblastiZdravotnychProblemov/List"
    ),
    "/api/CisUstavZvjs/List": CIS_Post("/api/CisUstavZvjs/List"),
    "/api/CisVeciOsobnejPotreby/List": CIS_Post(
      "/api/CisVeciOsobnejPotreby/List"
    ),
    "/api/CisZabezpeceniePrav/List": CIS_Post("/api/CisZabezpeceniePrav/List"),
    "/api/CisZdravotnaStarostlivost/List": CIS_Post(
      "/api/CisZdravotnaStarostlivost/List"
    ),
    "/api/CisZdrojNapajania/List": CIS_Post("/api/CisZdrojNapajania/List"),
    "/api/CisZdrojZiadosti/List": CIS_Post("/api/CisZdrojZiadosti/List"),
    "/api/CisZiadosti/List": CIS_Post("/api/CisZiadosti/List"),
  };
};

export type Ciselnik = keyof PromiseElement<
  ReturnType<typeof CisSynchronizeReturnTypes>
>;

export const CiselnikyArray: Ciselnik[] = [
  "/api/DalsiePotrebyOh/List",
  "/api/CisDochodokDruh/List",
  "/api/CisDovodMimoriadnejNavstevy/List",
  "/api/CisDovodNaOdoslanieKorespondencie/List",
  "/api/CisDovodPodaniaZiadostiElektrospotrebic/List",
  "/api/CisDovodPodaniaZiadosti/List",
  "/api/CisDovodPozadovanejObmeny/List",
  "/api/CisDovodZiadostiOrozhovor/List",
  "/api/CisDovodZiadostiSprchovanie/List",
  "/api/CisDovodZiadosti/List",
  "/api/CisDruhBalika/List",
  "/api/CisDruhElektrospotrebica/List",
  "/api/CisFormaRealizacieNavstevy/List",
  "/api/CisFyzickePrejavyProblemov/List",
  "/api/CisKategoriaZiadosti/List",
  "/api/CisKrajina/List",
  "/api/CisOblastiPsychickychProblemov/List",
  "/api/CisOblastSocialnehoPoradenstva/List",
  "/api/CisOblastZdravotnychProblemov/List",
  "/api/CisOdievanie/List",
  "/api/CisOdpovedPreKlienta/List",
  "/api/CisPodkategoriaZiadosti/List",
  "/api/CisPodnetNaOchranu/List",
  "/api/CisPreventivneZdravPrehliadky/List",
  "/api/CisPrijimaniePenazProstriedkov/List",
  "/api/CisPsychickyStav/List",
  "/api/CisSpanok/List",
  "/api/CisSposobOdovzdaniaCeniny/List",
  "/api/CisSposobZabezpeceniaHygNezavadnosti/List",
  "/api/StavOsobnejVeci/List",
  "/api/CisStavZiadosti/List",
  "/api/CisStrach/List",
  "/api/CisStravovanie/List",
  "/api/CisStupenVzdelania/List",
  "/api/CisTrvanieNavstevy/List",
  "/api/CisTypOsobnejVeci/List",
  "/api/CisTypPremiestnenia/List",
  "/api/CisTypZiadosti/List",
  "/api/CisUbytovanie/List",
  "/api/CisUIrozhranieTextacie/List",
  "/api/CisUIrozhranieZiadosti/List",
  "/api/CisUstavZvjs/List",
  "/api/CisVeciOsobnejPotreby/List",
  "/api/CisZabezpeceniePrav/List",
  "/api/CisZdravotnaStarostlivost/List",
  "/api/CisZdrojNapajania/List",
  "/api/CisZdrojZiadosti/List",
  "/api/CisZiadosti/List",
];

export type CiselnikListResultType<T extends Ciselnik> = PromiseElement<
  PromiseElement<ReturnType<typeof CisSynchronizeReturnTypes>>[T]
>;
