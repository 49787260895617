import { allSelectors as SC_KIO_050101_VisitDateQuestion_allSelectors } from "./SC_KIO_050101_VisitDateQuestion/redux";
import { allSelectors as SC_KIO_050802_TravelExpensesRadioQuestion_allSelectors } from "./SC_KIO_050802_TravelExpensesRadio/redux";
import { allSelectors as SC_KIO_051403_PlaceOfRelocationQuestion_allSelectors } from "./SC_KIO_051403_PlaceOfRelocationQuestion/redux";
import { allSelectors as SC_KIO_051404_PlaceOfLocationQuestion_allSelectors } from "./SC_KIO_051404_PlaceOfLocationQuestion/redux";
import { allSelectors as SC_KIO_051403_PriorityQuestion_allSelectors } from "./SC_KIO_051403_PriorityQuestion/redux";
import { allSelectors as SC_KIO_051403_NotAllowedToSendRequestLabels_allSelectors } from "./SC_KIO_051403_NotAllowedToSendRequestLabels/redux";
import { allSelectors as SC_KIO_050602_ProgramsGrid_allSelectors } from "./SC_KIO_050602_ProgramsGrid/redux";
import { allSelectors as SC_KIO_050601_ActivitiesGrid_allSelectors } from "./SC_KIO_050601_ActivitiesGrid/redux";
import { allSelectors as SC_KIO_050201_BanknotesGrid_allSelectors } from "./SC_KIO_050201_BanknotesGrid/redux";
import { allSelectors as SC_KIO_050604_GroupQuestion_allSelectors } from "./SC_KIO_050604_GroupQuestion/redux";
import { allSelectors as SC_KIO_050206_RegisteredChildrenGrid_allSelectors } from "./SC_KIO_050206_RegisteredChildrenGrid/redux";
import { allSelectors as SC_KIO_050501_DevicesRevisionControlGrid_allSelectors } from "./SC_KIO_050501_DevicesRevisionControlGrid/redux";
import { allSelectors as SC_KIO_051003_NotAllowedToSendRequestLabels_allSelectors } from "./SC_KIO_051003_NotAllowedToSendRequestLabels/redux";
import { allSelectors as SC_KIO_051003_PersonalHygieneNeedsGrid_allSelectors } from "./SC_KIO_051003_PersonalHygieneNeedsGrid/redux";
import { allSelectors as SC_KIO_051102_CivilianClothesGrid_allSelectors } from "./SC_KIO_051102_CivilianClothesGrid/redux";

/**
 * List of custom selectors used in custom questions
 */
const allCustomQuestionSelectors = {
  ...SC_KIO_050101_VisitDateQuestion_allSelectors,
  ...SC_KIO_050802_TravelExpensesRadioQuestion_allSelectors,
  ...SC_KIO_051403_PlaceOfRelocationQuestion_allSelectors,
  ...SC_KIO_051404_PlaceOfLocationQuestion_allSelectors,
  ...SC_KIO_051403_PriorityQuestion_allSelectors,
  ...SC_KIO_051403_NotAllowedToSendRequestLabels_allSelectors,
  ...SC_KIO_050602_ProgramsGrid_allSelectors,
  ...SC_KIO_050601_ActivitiesGrid_allSelectors,
  ...SC_KIO_050201_BanknotesGrid_allSelectors,
  ...SC_KIO_050604_GroupQuestion_allSelectors,
  ...SC_KIO_050206_RegisteredChildrenGrid_allSelectors,
  ...SC_KIO_050501_DevicesRevisionControlGrid_allSelectors,
  ...SC_KIO_051003_NotAllowedToSendRequestLabels_allSelectors,
  ...SC_KIO_051003_PersonalHygieneNeedsGrid_allSelectors,
  ...SC_KIO_051102_CivilianClothesGrid_allSelectors,
};

export default allCustomQuestionSelectors;
