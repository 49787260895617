import React from "react";
import { useUITranslation } from "../../store/context/translation-context";
import { capitalize } from "@mui/material/utils";
import { Grid } from "@mui/material";
import ZvjsButton from "./ZvjsButton";
import { ArrowForwardIos } from "@mui/icons-material";

interface ZvjsTesterReaderProps {
  onScan: (qrCode: string) => void;
}

const ZvjsTesterReader: React.FC<ZvjsTesterReaderProps> = ({ onScan }) => {
  const { tui } = useUITranslation();

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      direction="row"
      alignItems="stretch"
    >
      {[
        { user: "Tester1", cardId: "24701" },
        { user: "Tester2", cardId: "34701" },
        { user: "Tester3", cardId: "44703" },
        { user: "Invalid", cardId: "Invalid" },
      ].map((item, index) => (
        <Grid key={index} item>
          <ZvjsButton
            name="item.user"
            text={`${capitalize(tui("prihlasenie.prihlasitSa"))} ${item.user}`}
            endIcon={<ArrowForwardIos />}
            zvjsVariant={"secondaryAction"}
            onClick={() => onScan(item.cardId)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ZvjsTesterReader;
