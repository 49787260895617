import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { capitalize } from "@mui/material/utils";
import { useNavigate } from "react-router-dom";
import { ZvjsTabs } from "../../../common";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router";
import { withLoader } from "../../../hoc/withLoader";
import {
  AbsolvovaneAktivityTab,
  PracovneZaradenieTab,
  ProgramZaobchadzaniaTab,
} from "./ResocializacneAVolnocasoveAktivityTabs";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { PromiseElement } from "../../../utils/types";

const loadData = async () => {
  const { EPVVVT_Post, SHARE_INT_Post } = await API_Clients();

  const data = await Promise.all([
    EPVVVT_Post("/api/interfaces/Ezoo/ListPracovneZaradenia", {
      body: {
        klientId: {
          eq: Number.parseInt(localStorage.getItem("klientId") ?? ""),
        },
        ustavZvjsId: {
          eq: Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
        },
      },
    }),
    SHARE_INT_Post(
      "/api/interfaces/Epvvvt/ListZoznamCielovSUlohamiKlientaVProgrameZaobchadzania",
      {
        body: {
          klientId: {
            eq: localStorage.getItem("klientObjectId") ?? "",
          },
        },
      }
    ),
    SHARE_INT_Post(
      "/api/interfaces/Epvvvt/ListZoznamAbsolvovanychProgramovAAktivit",
      {
        body: {
          identifikatorKlienta: {
            eq: localStorage.getItem("klientObjectId") ?? "",
          },
          identifikatorUstavu: {
            eq: localStorage.getItem("klientUstavId"),
          },
        },
      }
    ),
    SHARE_INT_Post(
      "/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitKruzky",
      {
        body: {
          klientId: {
            eq: localStorage.getItem("klientObjectId") ?? "",
          },
          ustavId: {
            eq:
              Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
          },
        },
      }
    ),
    SHARE_INT_Post(
      "/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitOstatne",
      {
        body: {
          klientId: {
            eq: localStorage.getItem("klientObjectId") ?? "",
          },
          ustavId: {
            eq:
              Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
          },
        },
      }
    ),
    SHARE_INT_Post(
      "/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVychovnoOsvetovychAktivit",
      {
        body: {
          klientId: {
            eq: localStorage.getItem("klientObjectId") ?? "",
          },
          ustavId: {
            eq:
              Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
          },
        },
      }
    ),
  ]);

  return {
    pracovneZaradenia: data[0],
    programZaobchadzaniaCieleSUlohami: data[1],
    absolvovaneProgramyAAktivity: data[2],
    absolvovaneVolnocasoveAktivityKruzky: data[3],
    absolvovaneVolnocasoveAktivityOstatne: data[4],
    absolvovaneVychovnoOsvetoveAktivity: data[5],
  };
};

export const ResocializacneAVolnocasoveAktivityLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface ResocializacneAVolnocasoveAktivityProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const ResocializacneAVolnocasoveAktivity = (
  props: ResocializacneAVolnocasoveAktivityProps
) => {
  const loaderData = props.data;
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(
                tui(
                  "osobneUdaje.kategorieOsobnychUdajov.resocializacneAktivity"
                )
              )}
            </Typography>
          </Stack>
        </Grid>
        <Grid item mt={4}>
          <ZvjsTabs
            onChange={() => {
              return;
            }}
            data={[
              {
                label: tui("osobneUdaje.pracovneZaradanie.pracovneZaradenie"),
                element: <PracovneZaradenieTab data={loaderData} />,
              },
              {
                label: tui(
                  "osobneUdaje.absolvovaneAktivity.absolvovaneAktivity"
                ),
                element: <AbsolvovaneAktivityTab data={loaderData} />,
              },
              {
                label: tui(
                  "osobneUdaje.programZaobchadzania.programZaobchadzania"
                ),
                element: <ProgramZaobchadzaniaTab data={loaderData} />,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withLoader(ResocializacneAVolnocasoveAktivity);
