import React from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useNavigate } from "react-router-dom";
import { ZvjsTabs } from "../../../common";
import { useUITranslation } from "../../../store/context/translation-context";
import { withLoader } from "../../../hoc/withLoader";
import { defer } from "react-router";
import PrijataKorespondenciaTab from "./ListyTabs/PrijataKorespondenciaTab";
import OdoslanaKorespondenciaTab from "./ListyTabs/OdoslanaKorespondenciaTab";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { PromiseElement } from "../../../utils/types";

const loadData = async () => {
  const { SHARE_INT_Post } = await API_Clients();

  const data = await Promise.all([
    SHARE_INT_Post("/api/interfaces/Epvvvt/ListZoznamPrijatejKorenspondencie", {
      body: {
        klientId: {
          eq: localStorage.getItem("klientObjectId"),
        },
      },
    }),
    SHARE_INT_Post(
      "/api/interfaces/Epvvvt/ListZoznamOdoslanejKorenspondencie",
      {
        body: {
          klientId: {
            eq: localStorage.getItem("klientObjectId"),
          },
        },
      }
    ),
  ]);
  return {
    zoznamPrijatejKorespondencie: data[0],
    zoznamOdoslanejKorenspondencie: data[1],
  };
};

export const ListyLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface ListyProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const Listy = (props: ListyProps) => {
  const loaderData = props.data;
  const { t } = useTranslation();
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(tui("osobneUdaje.kategorieOsobnychUdajov.listy"))}
            </Typography>
          </Stack>
        </Grid>
        <Grid item mt={4}>
          <ZvjsTabs
            onChange={() => {
              return;
            }}
            data={[
              {
                label: t(
                  "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Letters_SC_KIO_0360.Received.tabLabel"
                ),
                element: <PrijataKorespondenciaTab data={loaderData} />,
              },
              {
                label: t(
                  "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Letters_SC_KIO_0360.Send.tabLabel"
                ),
                element: <OdoslanaKorespondenciaTab data={loaderData} />,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withLoader(Listy);
