import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsLabelFlagTypes,
  ZvjsItemTypes,
  ZvjsQuestionnaireTemplate,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_051401",
  basicInfo: [
    {
      text: "ziadost.ziadostOumiestnenieStandardnyRezim.zakladneInfo",
    },
  ],
  isAvailableToUser: "SC_KIO_051401_isAvailableToUser",
  dataCalculations: {
    IS_USER_ACCUSED_IN_STANDARD_MODE: {
      calculateAt: Calculate.AT_INIT,
    },
    IS_USER_ACCUSED_IN_SOFTENED_MODE: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  items: [
    {
      type: ZvjsItemTypes.SECTION,
      items: [
        {
          text: "ziadost.ziadostOumiestnenieStandardnyRezim.napovedaNesuhlasimSumiestnenim",
          type: ZvjsItemTypes.LABEL,
          flag: ZvjsLabelFlagTypes.HINT,
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "IS_USER_ACCUSED_IN_STANDARD_MODE",
              conditionMetWhenValueIs: true,
            },
          ],
        },
        {
          text: "ziadost.ziadostOumiestnenieStandardnyRezim.napovedaNesuhlasimSumiestnenimZmiernene",
          type: ZvjsItemTypes.LABEL,
          flag: ZvjsLabelFlagTypes.HINT,
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "IS_USER_ACCUSED_IN_SOFTENED_MODE",
              conditionMetWhenValueIs: true,
            },
          ],
        },
      ],
    },
  ],
};
