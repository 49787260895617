import { Button, Grid, Stack, Typography } from "@mui/material";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { REQUEST_PAGE_PADDING } from "../../../../common/request/requestPages/requestStyles/requestStyles";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useUITranslation } from "../../../../store/context/translation-context";
import Summary from "./Summary";
import Confirmation from "./Confirmation";
import { withLoader } from "../../../../hoc/withLoader";
import { ActionResultTypes } from "../../../../router/Router";
import { defer } from "react-router";
import { API_Clients } from "../../../../store/context/dataApi/Data";
import { PromiseElement } from "../../../../utils/types";

const LoadData = async () => {
  try {
    const { EOO_Get } = await API_Clients();
    // await delay(3000);
    console.log(
      "-----------------------ZMENAUDAJOV LOAD START-----------------------"
    );

    const eooKlient = await EOO_Get(
      "/api/Klient/DetailZakladneUdajeKlientaData",
      {
        params: {
          query: {
            Id: Number.parseInt(localStorage.getItem("klientId") ?? "") ?? 1,
            UstavZvjsId:
              Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
          },
        },
      }
    );

    console.log("eooKlient", eooKlient);
    console.log(
      "-----------------------ZMENAUDAJOV LOAD DONE-----------------------"
    );
    return {
      eooKlient: eooKlient,
    };
  } catch (e) {
    // example how to throw exception if loader
    console.log(e, "Loader: ", "Something went wrong");
    return {
      type: ActionResultTypes.ERROR,
      message: "Something went wrong",
    };
  }
  // example how to throw exception if loader
  //   throw new LoaderError()
};

export const ZmenaUdajovRequestLoader = async () => {
  return defer({
    data: LoadData(),
  });
};

export const enum RequestPagesZmenaUdajovZiadost {
  // QUESTIONNAIRE,
  SUMMARY,
  CONFIRMATION,
}

export interface ZmenaUdajovZiadostData {
  nameRequest: string;
  data: string;
  name: string;
  date: string;
}

interface ZmenaUdajovZiadostProps {
  data?: PromiseElement<ReturnType<typeof LoadData>>;
}

const ZmenaUdajovZiadost = (props: ZmenaUdajovZiadostProps) => {
  const navigate = useNavigate();
  const { tui } = useUITranslation();
  const location = useLocation();

  const [currentPage, setCurrentPage] =
    useState<RequestPagesZmenaUdajovZiadost>(
      RequestPagesZmenaUdajovZiadost.SUMMARY
    );
  const [osobneUdaje, setOsobneUdaje] = useState<ZmenaUdajovZiadostData>({
    nameRequest: location.state?.nameRequest,
    data: location.state?.data,
    name: `${props.data?.eooKlient?.data?.data?.meno} ${props.data?.eooKlient?.data?.data?.priezvisko}`,
    date: new Date(
      props.data?.eooKlient?.data?.data?.datumNarodenia ?? new Date()
    ).zvjsToString(),
  });

  const onClickNavigationBackButton = () => {
    // if (currentPage === RequestPagesZmenaUdajovZiadost.QUESTIONNAIRE) {
    //   navigate("/auth/MyData/BasicClientData");
    // } else if (currentPage === RequestPagesZmenaUdajovZiadost.SUMMARY) {
    if (currentPage === RequestPagesZmenaUdajovZiadost.SUMMARY) {
      // setCurrentPage(RequestPagesZmenaUdajovZiadost.QUESTIONNAIRE);
      navigate("/auth/MyData/BasicClientData", { replace: true });
    } else {
      navigate("/auth/MyData/BasicClientData", { replace: true });
    }
    return;
  };

  const onBackButtonEvent = () => {
    // if (currentPage === RequestPagesZmenaUdajovZiadost.QUESTIONNAIRE) {
    //   navigate("/auth/MyData/BasicClientData");
    // } else if (currentPage === RequestPagesZmenaUdajovZiadost.SUMMARY) {
    if (currentPage === RequestPagesZmenaUdajovZiadost.SUMMARY) {
      // setCurrentPage(RequestPagesZmenaUdajovZiadost.QUESTIONNAIRE);
      console.log(location);
      redirect("/auth/MyData/BasicClientData");
    } else {
      redirect("/auth/MyData/BasicClientData");
    }
    return;
    // onClickNavigationBackButton()
  };

  useEffect(() => {
    // console.log("--------------------------useEffect");
    window.addEventListener("popstate", onBackButtonEvent);

    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [currentPage]);

  useEffect(() => {
    if (osobneUdaje.nameRequest === undefined) {
      navigate("/auth/MyData/BasicClientData", { replace: true });
    } else {
      navigate("#summary", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid p={`${REQUEST_PAGE_PADDING}px`} pt={3} mb={12}>
      <Grid mb={3}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="start"
          alignItems="flex-start"
        >
          {currentPage !== RequestPagesZmenaUdajovZiadost.CONFIRMATION && (
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
              style={{ minWidth: "5rem", textTransform: "none" }}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
          )}
          <Typography variant={"h2"} mb={3}>
            {capitalize("Univerzálna žiadosť" ?? "")}
          </Typography>
        </Stack>
      </Grid>
      <Grid>
        {/*{currentPage === RequestPagesZmenaUdajovZiadost.QUESTIONNAIRE && (*/}
        {/*  <Formular setCurrentPage={setCurrentPage} osobneUdaje={osobneUdaje} />*/}
        {/*)}*/}
        {currentPage === RequestPagesZmenaUdajovZiadost.SUMMARY && (
          <Summary data={osobneUdaje} setCurrentPage={setCurrentPage} />
        )}
        {currentPage === RequestPagesZmenaUdajovZiadost.CONFIRMATION && (
          <Confirmation />
        )}
      </Grid>
    </Grid>
  );
};

export default withLoader(ZmenaUdajovZiadost);
