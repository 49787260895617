import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsItemTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_051201",
  basicInfo: [
    {
      text: "ziadost.ziadostOzmenuStravnejDavky.zakladneInfo",
    },
  ],
  isAvailableToUser: "SC_KIO_051201_isAvailableToUser",
  dataCalculations: {
    CURRENT_DIET_TYPE: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_051201_SHOULD_DISPLAY_RAMADAN_QUESTION: {
      calculateAt: Calculate.LIVE,
    },
  },
  items: [
    {
      type: ZvjsItemTypes.SINGLE_VALUE_LABEL,
      text: "ziadost.ziadostOzmenuStravnejDavky.labelAktualnyDruhStravy",
      value: "CURRENT_DIET_TYPE",
    },
    {
      id: "pozadovanyDruhStravy",
      title: "ziadost.ziadostOzmenuStravnejDavky.labelPozadovanyDruhStravy",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      options: [
        {
          id: "E_KIO_XY_01",
          label: "Štandardná strava",
        },
        {
          id: "E_KIO_XY_02",
          label: "Strava s vylúčením mäsa",
        },
        {
          id: "E_KIO_XY_03",
          label: "Strava s vylúčením bravčového mäsa",
        },
      ],
    },
    {
      id: "ramadan",
      title: "ziadost.ziadostOzmenuStravnejDavky.labelRamadan",
      type: ZvjsQuestionTypes.SINGLE_CHECKBOX,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051201_SHOULD_DISPLAY_RAMADAN_QUESTION",
          conditionMetWhenValueIs: true,
        },
      ],
    },
  ],
};
