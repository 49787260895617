import {
  UserCalcData,
  ZvjsCustomQuestionTypes,
} from "../../../../../redux/model";
import { SC_KIO_050802_TravelExpensesRadioQuestion_onInit } from "./SC_KIO_050802_TravelExpensesRadio/redux";
import { SC_KIO_051403_PlaceOfRelocationQuestion_onInit } from "./SC_KIO_051403_PlaceOfRelocationQuestion/redux";
import { SC_KIO_051404_PlaceOfLocationQuestion_onInit } from "./SC_KIO_051404_PlaceOfLocationQuestion/redux";
import { SC_KIO_050602_ProgramsGrid_onInit } from "./SC_KIO_050602_ProgramsGrid/redux";
import { SC_KIO_050601_ActivitiesGrid_onInit } from "./SC_KIO_050601_ActivitiesGrid/redux";
import { SC_KIO_050201_BanknotesGrid_onInit } from "./SC_KIO_050201_BanknotesGrid/redux";
import { SC_KIO_050604_GroupQuestion_onInit } from "./SC_KIO_050604_GroupQuestion/redux";
import { SC_KIO_050206_RegisteredChildrenGrid_onInit } from "./SC_KIO_050206_RegisteredChildrenGrid/redux";
import { SC_KIO_051102_CivilianClothesGrid_onInit } from "./SC_KIO_051102_CivilianClothesGrid/redux";
import { SC_KIO_050501_DevicesRevisionControlGrid_onInit } from "./SC_KIO_050501_DevicesRevisionControlGrid/redux";

/**
 * List of all init functions that typically loads additional data that are needed for custom question purposes
 */
const onCustomInit: onCustomInitType = {
  [ZvjsCustomQuestionTypes.SC_KIO_050802_TRAVEL_EXPENSES_RADIO]:
    SC_KIO_050802_TravelExpensesRadioQuestion_onInit,
  [ZvjsCustomQuestionTypes.SC_KIO_051403_PLACE_OF_RELOCATION_QUESTION]:
    SC_KIO_051403_PlaceOfRelocationQuestion_onInit,
  [ZvjsCustomQuestionTypes.SC_KIO_051404_PLACE_OF_LOCATION_QUESTION]:
    SC_KIO_051404_PlaceOfLocationQuestion_onInit,
  [ZvjsCustomQuestionTypes.SC_KIO_050602_PROGRAMS_GRID]:
    SC_KIO_050602_ProgramsGrid_onInit,
  [ZvjsCustomQuestionTypes.SC_KIO_050601_ACTIVITIES_GRID]:
    SC_KIO_050601_ActivitiesGrid_onInit,
  [ZvjsCustomQuestionTypes.SC_KIO_050201_BANKNOTES_GRID]:
    SC_KIO_050201_BanknotesGrid_onInit,
  [ZvjsCustomQuestionTypes.SC_KIO_050604_GROUP_QUESTION]:
    SC_KIO_050604_GroupQuestion_onInit,
  [ZvjsCustomQuestionTypes.SC_KIO_050206_REGISTERED_CHILDREN_GRID]:
    SC_KIO_050206_RegisteredChildrenGrid_onInit,
  [ZvjsCustomQuestionTypes.SC_KIO_051102_CIVILIAN_CLOTHES_GRID]:
    SC_KIO_051102_CivilianClothesGrid_onInit,
  [ZvjsCustomQuestionTypes.SC_KIO_050501_DEVICES_REVISION_CONTROL_GRID]:
    SC_KIO_050501_DevicesRevisionControlGrid_onInit,
};

type onCustomInitType = {
  [key in ZvjsCustomQuestionTypes]?: (calcData: UserCalcData) => Promise<void>;
};

export default onCustomInit;
