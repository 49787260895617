import React, { useEffect, useState } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useNavigate } from "react-router-dom";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsSearchBar,
  ZvjsTable,
} from "../../../common";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { withLoader } from "../../../hoc/withLoader";
import { defer } from "react-router";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { ZvjsSearchBarVariant } from "../../../common/components/ZvjsSearchBar";
import { MAX_NUMBER_OF_ITEMS } from "../../../store/context/dataApi/CIS_Ciselnik";
import { getCiselnikJazyk } from "../../../locales/i18n";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { PromiseElement } from "../../../utils/types";

const loadData = async () => {
  const { CIS_Post, SHARE_INT_Post } = await API_Clients();

  const data = await Promise.all([
    CIS_Post("/api/CisDruhBalika/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
    // TODO: chyba druh balika vo vysledku
    SHARE_INT_Post("/api/interfaces/Epvvvt/ListZoznamPrijatychBalikovKlienta", {
      body: {
        klientId: {
          eq: localStorage.getItem("klientObjectId"),
        },
      },
    }),
  ]);
  return {
    cisDruhBalika: data[0],
    baliky: data[1],
  };
};

export const BalikyLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface BalikyProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const Baliky = (props: BalikyProps) => {
  const loaderData = props.data;
  const { t, i18n } = useTranslation();
  const { tui, getFallbackJazyk } = useUITranslation();
  const navigate = useNavigate();

  const dataStyle: TableStyle[] = [
    {
      align: "left",
      width: "calc(100% / 3)",
    },
    {
      align: "left",
      width: "calc(100% / 3)",
    },
    {
      align: "left",
      width: "calc(100% / 3)",
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Packets_SC_KIO_0370.dateOfReceive"
        )
      ),
      ...dataStyle[0],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Packets_SC_KIO_0370.typeOfPacket"
        )
      ),
      ...dataStyle[1],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Packets_SC_KIO_0370.seneder"
        )
      ),
      ...dataStyle[2],
    },
  ];

  const [tableData, setTableData] = useState<TableData>({
    header: headerData,
    body: [],
  });

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (loaderData.baliky.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      loaderData.baliky.data
        .sort(
          (p1, p2) =>
            new Date(p2.dorucenyDatum ?? new Date()).valueOf() -
            new Date(p1.dorucenyDatum ?? new Date()).valueOf()
        )
        // .map((balik) => {
        //   if (loaderData.cisDruhBalika) {
        //     const druhBalika =
        //       loaderData.cisDruhBalika.data?.records?.find(
        //         (i) =>
        //           i.kod === balik.typ &&
        //           i.jazyk === getCiselnikJazyk(i18n.language)
        //       )?.druh_balika ??
        //       loaderData.cisDruhBalika.data?.records?.find(
        //         (i) => i.kod === balik.typ && i.jazyk === getFallbackJazyk()
        //       )?.druh_balika ??
        //       "<unknown>";
        //     return { ...balik, typ: druhBalika };
        //   }
        //   return balik;
        // })
        .forEach((balik) => {
          bodyData.push({
            row: [
              {
                value: new Date(
                  balik.dorucenyDatum ?? new Date()
                ).zvjsToString(),
                ...dataStyle[0],
              },
              {
                value: "<API?>", //balik.typ,
                ...dataStyle[1],
              },
              {
                value: balik.dorucenyRealOdosielatelAdresa ?? "",
                ...dataStyle[2],
              },
            ],
          });
        });
      setTableData({
        ...tableData,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderData.baliky, loaderData.cisDruhBalika, i18n.language]);

  const mySubmitHandler = (dateFrom: Date, dateTo: Date) => {
    console.log(dateFrom);
    console.log(dateTo);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(tui("osobneUdaje.kategorieOsobnychUdajov.baliky"))}
          </Typography>
        </Stack>
      </Grid>
      <Stack direction="column" spacing={2}>
        <ZvjsSearchBar
          title={t(
            "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Packets_SC_KIO_0370.searchLabel"
          )}
          type={ZvjsSearchBarVariant.VARIANT_A_DpDp}
          onSubmit={mySubmitHandler}
        />

        <ZvjsTable
          data={tableData}
          height={360}
          variant={ZvjsTableVariant.NORMAL}
          // TODO add translation
          hintText={" "}
        />

        <Grid width="25rem">
          <ZvjsButton
            text={capitalize(
              t(
                "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Packets_SC_KIO_0370.requestPackets"
              )
            )}
            endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
            style={{
              lineHeight: "1",
              textAlign: "left",
              textTransform: "none",
            }}
            zvjsVariant="secondaryAction"
            onClick={() => {
              navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/01.01.01`);
            }}
          />
        </Grid>
      </Stack>
    </Grid>
  );
};

export default withLoader(Baliky);
