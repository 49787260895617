import React, { Fragment } from "react";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

export interface ZvjsCheckBoxData {
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  children?: ZvjsCheckBoxData[];
  onChange?: (value: boolean) => void;
  key: string;
}

export interface ZvjsCheckBoxStateValues {
  uniqueKey: string;
  checked: boolean;
  label?: string;
}

interface ZvjsCheckBoxDataIndex {
  label?: string;
  disabled?: boolean;
  children?: ZvjsCheckBoxDataIndex[];
  index: number;
  onChange?: (value: boolean) => void;
  key: string;
}

interface ZvjsCheckBoxProp {
  onChange?:
    | ((
        label: string | undefined,
        key: string,
        checked: boolean,
        checkedValues: ZvjsCheckBoxStateValues[]
      ) => void)
    | undefined;
  onDisabledCheckBoxClick?: (key: string) => void;
  data: ZvjsCheckBoxData[];
  label?: string;
  offset?: number;
  disabled?: boolean;
}

const ZvjsCheckBox: React.FC<ZvjsCheckBoxProp> = ({ data, label, ...rest }) => {
  const countRecursion = (dataP: ZvjsCheckBoxData[]): number => {
    let number = 0;
    for (const mapData of dataP) {
      number += mapData.children ? countRecursion(mapData.children) + 1 : 1;
    }
    return number;
  };

  const createStateArray = (
    dataP: ZvjsCheckBoxData[]
  ): ZvjsCheckBoxStateValues[] => {
    const arr: ZvjsCheckBoxStateValues[] = [];

    const recursionCreate = (data: ZvjsCheckBoxData[]): void => {
      for (const mapData of data) {
        arr.push({
          checked: mapData.checked ? mapData.checked : false,
          uniqueKey: mapData.key,
          label: mapData.label,
        });
        if (mapData.children) {
          recursionCreate(mapData.children);
        }
      }
    };
    recursionCreate(dataP);
    return arr;
  };

  const [checked, setChecked] = React.useState<ZvjsCheckBoxStateValues[]>(
    createStateArray(data)
  );

  const handleClick = (
    index: number,
    indexStart?: number,
    indexEnd?: number
  ) => {
    const prevState = [...checked];
    prevState[index].checked = !prevState[index].checked;
    if (indexStart && indexEnd) {
      for (let i = indexStart; i < indexEnd; i++) {
        prevState[i].checked = prevState[index].checked;
      }
    }
    setChecked(prevState);
    if (rest.onChange) {
      const newArr = prevState.filter((values) => values.checked);
      rest.onChange(
        prevState[index].label,
        prevState[index].uniqueKey,
        prevState[index].checked,
        newArr
      );
    }
  };

  const processData = (dataP: ZvjsCheckBoxData[]): React.ReactNode => {
    let index = 0;
    const indexing = (dataP: ZvjsCheckBoxData[]): ZvjsCheckBoxDataIndex[] => {
      const tmp: ZvjsCheckBoxDataIndex[] = [];
      dataP.map((mapData) => {
        const dataTmp: ZvjsCheckBoxDataIndex = {
          label: mapData.label,
          index: index++,
          disabled:
            rest.disabled !== undefined
              ? rest.disabled || mapData.disabled
              : mapData.disabled,
          children: mapData.children ? indexing(mapData.children) : undefined,
          onChange: mapData.onChange,
          key: mapData.key,
        };
        tmp.push(dataTmp);
        return dataTmp;
      });
      return tmp;
    };

    return recursion(indexing(dataP), 1);
  };

  const calculateIndeterminate = (
    indexStat: number,
    indexEnd: number
  ): boolean => {
    let falseN = 0;
    let trueN = 0;

    for (let i = indexStat; i < indexEnd; i++) {
      checked[i] ? trueN++ : falseN++;
    }
    return trueN !== 0 && falseN !== 0;
  };

  const recursion = (
    dataP: ZvjsCheckBoxDataIndex[],
    level: number
  ): React.ReactNode => {
    return dataP.map((mapData) => {
      if (mapData.children) {
        return (
          <Fragment key={mapData.index}>
            <FormControlLabel
              label={mapData.label}
              control={
                <Checkbox
                  checked={checked[mapData.index].checked}
                  indeterminate={calculateIndeterminate(
                    mapData.index + 1,
                    mapData.index +
                      (mapData.children
                        ? countRecursion(mapData.children) + 1
                        : 0)
                  )}
                  disabled={mapData.disabled ? mapData.disabled : false}
                  onClick={() => {
                    handleClick(
                      mapData.index,
                      mapData.index + 1,
                      mapData.index +
                        (mapData.children
                          ? countRecursion(mapData.children) + 1
                          : 0)
                    );
                    if (mapData.onChange) {
                      mapData.onChange(checked[mapData.index].checked);
                    }
                  }}
                />
              }
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                ml: level * (rest.offset !== undefined ? rest.offset : 3),
              }}
            >
              {recursion(mapData.children, level + 1)}
            </Box>
          </Fragment>
        );
      } else {
        return (
          <FormControlLabel
            key={mapData.index}
            label={mapData.label}
            control={
              <div
                onClick={() => {
                  // in some scenarios, we want to handle click on disabled checkbox (in requests)
                  if (mapData.disabled) {
                    if (rest.onDisabledCheckBoxClick) {
                      rest.onDisabledCheckBoxClick(mapData.key);
                    }
                  } else {
                    handleClick(
                      mapData.index,
                      mapData.index + 1,
                      mapData.index +
                        (mapData.children
                          ? countRecursion(mapData.children) + 1
                          : 0)
                    );
                    if (mapData.onChange) {
                      mapData.onChange(checked[mapData.index].checked);
                    }
                  }
                }}
              >
                <Checkbox
                  checked={checked[mapData.index].checked}
                  disabled={mapData.disabled ? mapData.disabled : false}
                />
              </div>
            }
          />
        );
      }
    });
  };

  return (
    <Stack direction={"column"}>
      {label && <Typography variant={"h2"}>{label}</Typography>}
      {processData(data)}
    </Stack>
  );
};
export default ZvjsCheckBox;
