export enum Module {
  INTERNAL = "internal",
  AUDITING = "auditing",
  CIS = "cis",
  EDPOO = "edpoo",
  EOO = "eoo",
  EPVVVT = "epvvvt",
  EZOO = "ezoo",
  FEOO = "feoo",
  HASURA = "hasura",
  MESSAGING = "messaging",
  REZERVACIA_KNIH = "rezervacia_knih",
  SEARCH = "search",
  SHARE_INT = "share_int",
  SIDKOO = "sidkoo",
  SZOO = "szoo",
  ZVJS_MP = "zvjs_mp",
}
