import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsQuestionnaireTemplate,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050701",
  basicInfo: [
    {
      text: "ziadost.ziadostOtelefonovanie.zakladneInfoObvineny",
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "IS_USER_ACCUSED",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      text: "ziadost.ziadostOtelefonovanie.zakaldneInfoOdsudeny",
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "IS_USER_SENTENCED",
          conditionMetWhenValueIs: true,
        },
      ],
    },
  ],
  dataCalculations: {
    IS_USER_ACCUSED: {
      calculateAt: Calculate.AT_INIT,
    },
    IS_USER_SENTENCED: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  items: [],
};
