import React, { useRef } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { useNavigate, useRouteError } from "react-router-dom";
import { ReactComponent as KeyboardArrowLeftIcon } from "../assets/icons/Other/keyboard_arrow_left.svg";
import { useUITranslation } from "../store/context/translation-context";
import { Header } from "../header";
import { Footer } from "../footer";
import { isEmptyString } from "../utils/helpers";
import { FooterRef } from "../footer/Footer";
import { LoaderError } from "../router/LoaderError";

const ErrorPage = () => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const error = useRouteError();
  const footerRef = useRef<FooterRef>(null);

  let errorMessage = tui("stránka neexistuje");
  if (error instanceof LoaderError && !isEmptyString(error.message)) {
    errorMessage = tui(error.message);
  }

  const getFooterHeight = () => {
    return footerRef.current?.getHeight() ?? 0;
  };

  return (
    <>
      <Header />
      <div
        style={{
          marginTop: "8rem",
          marginBottom: getFooterHeight(),
        }}
      >
        <Grid p={5} pt={3} mb={12}>
          <Grid mb={3}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
                startIcon={<KeyboardArrowLeftIcon height={15} />}
              >
                {capitalize(tui("tlacidla.spat"))}
              </Button>
              {/*TODO add translation*/}
              <Typography variant="h3">{capitalize(errorMessage)}</Typography>
            </Stack>
          </Grid>
        </Grid>
      </div>
      <Footer
        ref={footerRef}
        zoomIn={() => {
          return;
        }}
      />
    </>
  );
};

export default ErrorPage;
