import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../../../redux";
import { AppDispatch } from "../../../../../../../../store/redux";
import {
  ZvjsAlert,
  ZvjsCustomQuestionFullWidthBox,
  ZvjsSelect,
  ZvjsTable,
} from "../../../../../../../components";
import {
  CellData,
  RequestFontSizes,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../../../../components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { useTranslation } from "react-i18next";
import { Box, Radio, SelectChangeEvent } from "@mui/material";
import {
  getSeverityOfValidationFlag,
  getSeverityOfZvjsFlag,
  ZvjsLabelFlagTypes,
  ZvjsValidationFlagTypes,
} from "../../../../../../redux/model";
import CustomQuestionProps from "../CustomQuestionProps";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";

const SC_KIO_050206_RegisteredChildrenGrid: React.FC<CustomQuestionProps> = ({
  location,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { tuiz } = useUITranslation();

  console.log(
    `SC_KIO_050206 REGISTERED CHILDREN GRID QUESTION RERENDER ${location.join(
      "-"
    )}`
  );

  const questionData = useSelector(
    questionnaireRedux.selectors.getRegisteredChildrenGridDisplayData(location),
    shallowEqual
  );

  const shippingOptionsCounter = useSelector(
    questionnaireRedux.selectors.getShippingOptionsCounter(),
    shallowEqual
  );

  const answerVal = useSelector(
    questionnaireRedux.selectors.getAnswer(questionData.id),
    shallowEqual
  );

  const onRadioChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    dispatch(
      questionnaireRedux.actions.addNewAnswer(location, questionData.id, {
        childId: event.target.value,
      })
    );
  };

  const onSelectChangeHandler = (event: SelectChangeEvent): void => {
    dispatch(
      questionnaireRedux.actions.addNewAnswer(location, questionData.id, {
        childId: answerVal?.answer?.childId,
        shippingOption: event.target.value,
      })
    );
  };

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "center",
      sx: {
        display: "flex",
        justifyContent: "center",
      },
    },
    {
      width: 175,
      align: "right",
      sx: {
        display: "grid",
      },
    },
  ];

  const headerData: Array<CellData> = [
    // TODO replace with correct translation
    {
      value: capitalize(t("Meno")),
      ...dataStyle[0],
    },
    {
      value: capitalize(t("Priezvisko")),
      ...dataStyle[1],
    },
    {
      value: capitalize(t("Dátum narodenia")),
      ...dataStyle[2],
    },
    {
      value: capitalize(
        tuiz("ziadost.ziadostOvykonanieZrazokZcpo.labelEvidovana")
      ),
      ...dataStyle[3],
    },
    {
      value: capitalize(t("Číslo účtu v SR v tvare IBAN")),
      ...dataStyle[4],
    },
    {
      value: capitalize(t("Zaevidovať výživné")),
      ...dataStyle[5],
    },
    {
      value: capitalize(t("Akým spôsobom chcem odoslať")),
      ...dataStyle[6],
    },
  ];

  const bodyData: Array<RowData> = new Array<RowData>();
  for (const child of questionData.registeredChildren) {
    bodyData.push({
      row: [
        {
          value: child.name ?? "",
          ...dataStyle[0],
        },
        {
          value: child.surname ?? "",
          ...dataStyle[1],
        },
        {
          value: child.dateOfBirth
            ? new Date(child.dateOfBirth).zvjsToString()
            : "",
          ...dataStyle[2],
        },
        {
          value: child.registeredBy ?? "",
          ...dataStyle[3],
        },
        {
          value: child.ibanInSlovakia ?? "",
          ...dataStyle[4],
        },
        {
          value: (
            <Radio
              value={child.id}
              onChange={onRadioChangeHandler}
              checked={child.id === answerVal?.answer?.childId}
              disabled={child.isRegistered}
            />
          ),
          ...dataStyle[5],
        },
        {
          value: (
            <ZvjsSelect
              id={`transfer-option-question-${location.join("-")}`}
              items={
                answerVal?.answer?.childId === child.id
                  ? shippingOptionsCounter.filter((option) => {
                      if (child.addressInForeign) {
                        // TODO replace with counter code
                        if (option.value === "E_KIO_23_02") {
                          return false;
                        }
                      }
                      return true;
                    })
                  : []
              }
              changeHandler={onSelectChangeHandler}
              selectedValue={
                answerVal?.answer?.childId === child.id
                  ? answerVal?.answer?.shippingOption ?? ""
                  : ""
              }
              disabled={answerVal?.answer?.childId !== child.id}
            />
          ),
          ...dataStyle[6],
        },
      ],
    });
  }

  const tableData: TableData = {
    header: headerData,
    body: bodyData,
    label: questionData.title,
  };

  return (
    <ZvjsCustomQuestionFullWidthBox location={location}>
      <ZvjsTable
        data={tableData}
        variant={ZvjsTableVariant.NORMAL}
        fontSizes={RequestFontSizes}
      />
      {answerVal.selectedChild?.addressInForeign && (
        <Box mt={1}>
          <ZvjsAlert
            severity={getSeverityOfZvjsFlag(ZvjsLabelFlagTypes.WARNING)}
          >
            {tuiz(
              "ziadost.ziadostOvykonanieZrazokZcpo.napovedaSposobOdoslaniaNaUcet"
            )}
          </ZvjsAlert>
        </Box>
      )}
      {/*TODO replace with actual counter code*/}
      {answerVal?.answer?.shippingOption === "E_KIO_23_01" &&
        !answerVal.selectedChild?.hasBankAccount && (
          <Box mt={1}>
            <ZvjsAlert
              severity={getSeverityOfValidationFlag(
                ZvjsValidationFlagTypes.ERROR
              )}
            >
              {tuiz(
                "ziadost.ziadostOvykonanieZrazokZcpo.napovedaSposobOdoslaniaNaUcet"
              )}
            </ZvjsAlert>
          </Box>
        )}
      {/*TODO replace with actual counter code*/}
      {answerVal?.answer?.shippingOption === "E_KIO_23_02" &&
        !answerVal.selectedChild?.completeAddress && (
          <Box mt={1}>
            <ZvjsAlert
              severity={getSeverityOfValidationFlag(
                ZvjsValidationFlagTypes.ERROR
              )}
            >
              {tuiz(
                "ziadost.ziadostOvykonanieZrazokZcpo.napovedaSposobOdoslaniaPoukazka"
              )}
            </ZvjsAlert>
          </Box>
        )}
    </ZvjsCustomQuestionFullWidthBox>
  );
};

export default SC_KIO_050206_RegisteredChildrenGrid;
