import React, { useEffect, useState } from "react";
import { PeniazeAPoplatkyProps } from "../PeniazeAPoplatky";
import { ZvjsSearchBar, ZvjsTable } from "../../../../common";
import { ZvjsSearchBarVariant } from "../../../../common/components/ZvjsSearchBar";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { capitalize } from "@mui/material/utils";

const VyplatnePaskyTab: React.FC<PeniazeAPoplatkyProps> = ({ data }) => {
  const { t } = useTranslation();
  const { tui } = useUITranslation();

  const dataStyle: TableStyle[] = [
    {
      align: "left",
      width: "26%",
    },
    {
      align: "left",
      width: "37%",
    },
    {
      align: "left",
      width: "37%",
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(tui("osobneUdaje.vyplatnePasky.rok")),
      ...dataStyle[0],
    },
    {
      value: capitalize(tui("osobneUdaje.vyplatnePasky.mesiac")),
      ...dataStyle[1],
    },
    {
      value: capitalize(
        tui(
          // TODO: osobneUdaje.vyplatnePasky VS osobneUdaje.blokacie
          "osobneUdaje.blokacie.sumaPohybu"
        )
      ),
      ...dataStyle[2],
    },
  ];

  const [tableData, setTableData] = useState<TableData>({
    header: headerData,
    body: [],
  });

  useEffect(() => {
    if (data.vyplatnePasky.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      data.vyplatnePasky.data
        // .sort((p1, p2) =>
        //   p1.name > p2.name)
        .forEach((vyplatnaPaska) => {
          bodyData.push({
            row: [
              {
                value: vyplatnaPaska.rok ?? "",
                ...dataStyle[0],
              },
              {
                value: vyplatnaPaska.mesiac ?? "",
                ...dataStyle[1],
              },
              {
                value: vyplatnaPaska.cistaPracovnaOdmenaAFo
                  ? vyplatnaPaska.cistaPracovnaOdmenaAFo.toFixed(2) + " EUR"
                  : "",
                ...dataStyle[2],
              },
            ],
          });
        });
      setTableData({
        ...tableData,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const mySubmitHandlerPayslip = (dateFrom: Date, dateTo: Date) => {
    console.log(dateFrom);
    console.log(dateTo);
  };

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsSearchBar
        title={t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.MoneyAndFees_SC_KIO_0360.Account.searchMovement"
        )}
        type={ZvjsSearchBarVariant.VARIANT_A_DpDp}
        onSubmit={mySubmitHandlerPayslip}
      />
      <ZvjsTable
        data={tableData}
        height={180}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default VyplatnePaskyTab;
