import React, {
  ComponentType,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Await,
  useLoaderData,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { GenericLoadedData } from "../router/Router";
import { IconButton, LinearProgress, Stack, Typography } from "@mui/material";
import { isSubmitting } from "../utils/helpers";
import { ZVJS_COLORS } from "../theme/zvjs_theme";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as Decline } from "../assets/icons/Other/decline.svg";

interface WrapperProps {
  // loaded data (needs to be included otherwise you will get an error when trying to use wrapped component)
  data: never;
}

// HoC which waits until generic loader function is evaluated, after that resolved data is passed to wrapped component
export function withLoaderHolup<T>(
  WrappedComponent: ComponentType<T & WrapperProps>
) {
  return function WrapperComponent(props: Omit<T, keyof WrapperProps>) {
    // load generic data (type of the loaded data will be determined in wrapped component)
    const loaderData = useLoaderData() as GenericLoadedData<never>;
    const navigation = useNavigation();
    const navigate = useNavigate();
    const [height, setHeight] = useState(80);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (ref.current) {
        setHeight(ref.current.clientHeight);
      }
    }, [ref]);

    return (
      <Stack>
        <Stack
          direction="row"
          style={{
            position: "fixed",
            width: "100vw",
            height: "5rem",
            backgroundColor: ZVJS_COLORS.BLACK_2,
          }}
          ref={ref}
          alignItems="center"
        >
          <IconButton
            sx={{ backgroundColor: ZVJS_COLORS.GREY_6, marginLeft: "2rem" }}
            onClick={() => navigate("/auth")}
          >
            <Decline style={{ fill: ZVJS_COLORS.WHITE_2 }} />
          </IconButton>
          <Typography
            variant="h4"
            style={{ color: ZVJS_COLORS.WHITE_2, padding: "1rem" }}
          >
            {capitalize("zavrieť túto aplikáci")} {/*TODO use proper key*/}
          </Typography>
        </Stack>
        <Suspense fallback={<LinearProgress style={{ marginTop: height }} />}>
          {/*if action is submitting display linear progress*/}
          {isSubmitting(navigation) && (
            <LinearProgress style={{ marginTop: height }} />
          )}
          <Await resolve={loaderData.data}>
            {(data) => {
              return (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <WrappedComponent {...props} data={data} />
              );
            }}
          </Await>
        </Suspense>
      </Stack>
    );
  };
}
