import {
  ZvjsConditionTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050102",
  basicInfo: [
    {
      text: "",
    },
  ],
  items: [
    {
      id: "SC_KIO_050101_QUES_02",
      title: "Forma realizácie návštevy",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      options: [
        {
          id: "E_KIO_11_01",
          label: "Fyzická návšteva",
        },
        {
          id: "E_KIO_11_02",
          label: "Videonávšteva",
        },
      ],
    },
    {
      id: "SC_KIO_050101_QUES_03",
      title: "Spôsob odoslania pozvánky na návštevu",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      options: [
        {
          id: "E_KIO_13_01",
          label: "Poštou",
        },
        {
          id: "E_KIO_13_02",
          label: "Mailom",
        },
      ],
    },
    {
      id: "SC_KIO_050101_QUES_04",
      title: "Mail",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050101_QUES_03",
          value: ["E_KIO_13_02"],
        },
      ],
    },
  ],
};
