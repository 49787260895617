import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ZvjsButton } from "../../../components";
import ClearIcon from "@mui/icons-material/Clear";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { capitalize } from "@mui/material/utils";
import questionnaireRedux from "../../redux";
import { RequestPages } from "../../Request";

interface QuestionnaireControlBtnsProps {
  setCurrentPage: React.Dispatch<React.SetStateAction<RequestPages>>;
}

const QuestionnaireControlBtns: React.FC<QuestionnaireControlBtnsProps> = ({
  setCurrentPage,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const allQuestionsAnswered = useSelector(
    questionnaireRedux.selectors.allQuestionsAnswered
  );

  console.log("QUESTIONNIARE CONTROL BUTTONS RERENDER");

  const onContinueRequestClickHandler = () => {
    setCurrentPage((prevState) => prevState + 1);
    navigate("#summary");
  };

  return (
    <Stack spacing={2} direction={"row"}>
      <ZvjsButton
        zvjsVariant={"secondaryAction"}
        text={capitalize(t("spec.section.Request.Buttons.ContinueRequest"))}
        endIcon={<ChevronRight style={{ height: 45, width: 45 }} />}
        disabled={!allQuestionsAnswered}
        onClick={onContinueRequestClickHandler}
      />
      <ZvjsButton
        zvjsVariant={"cancelAction"}
        text={capitalize(t("spec.section.Request.Buttons.CancelRequest"))}
        startIcon={<ClearIcon style={{ height: 45, width: 45 }} />}
        onClick={() => navigate(-2)}
      />
    </Stack>
  );
};

export default QuestionnaireControlBtns;
