import React, { useContext, useEffect } from "react";
import AuthContext from "../store/context/auth-context";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const ProtectedLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    if (!isLoggedIn) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      navigate("/", {
        state: { from: location },
        replace: true,
      });
    }
  }, [isLoggedIn, location, navigate]);

  return <Outlet />;
};
