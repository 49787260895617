import React, { useContext, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { LoginStepProps } from "../Login";
import ZvjsQrReader from "../../common/components/ZvjsQrReader";
import ZvjsKeyboardReader from "../../common/components/ZvjsKeyboardReader";
import ZvjsTesterReader from "../../common/components/ZvjsTesterReader";
import { useUITranslation } from "../../store/context/translation-context";

const LoginStep1: React.FC<LoginStepProps> = ({ onLoginStepData }) => {
  const { tui } = useUITranslation();

  const isWindows = navigator.userAgent.match(/Windows/i);
  const isDevelopment = process.env.NODE_ENV === "development";

  const onScan = (qrCode: string) => {
    onLoginStepData(1, qrCode);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      height="75vh"
      maxWidth="70rem"
      spacing={5}
      mb={25}
    >
      <Grid item>
        <Typography variant="h3" width="50rem" textAlign="center">
          {tui("prihlasenie.prilozitKartu")}
        </Typography>
      </Grid>
      <Grid item>
        <ZvjsQrReader onScan={onScan} />
      </Grid>

      {isWindows && (
        <Grid item>
          <ZvjsKeyboardReader onScan={onScan} />
        </Grid>
      )}
      {/* TODO use isDevelopment var once camera on windows is fixed*/}
      {true && (
        <Grid item>
          <ZvjsTesterReader onScan={onScan} />
        </Grid>
      )}
    </Grid>
  );
};

export default LoginStep1;
