import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as ZVJSLogo } from "../assets/icons/Other/zvjs.svg";
import { ZVJS_COLORS } from "../theme/zvjs_theme";

const ScreenSaverPage = () => {
  return (
    <Box
      sx={{
        backgroundColor: ZVJS_COLORS.BLACK,
        display: "block",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          width: "100%",
          height: "calc(100vh * 0.618)",
        }}
      >
        <ZVJSLogo width="100%" height="100%" style={{ maxHeight: "40%" }} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "10%",
          left: "50%",
          transform: "translateX(-50%)",
          fontSize: "24px",
          letterSpacing: "2px",
          padding: "10px 20px",
          border: "2px solid #fff",
          borderRadius: "12px",
          cursor: "pointer",
          transition: "ackground-color 0.3s, color 0.3s",
          color: ZVJS_COLORS.WHITE,
        }}
      >
        Stlačením aktivuj
      </Box>
    </Box>
  );
};

export default ScreenSaverPage;
