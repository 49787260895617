import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsCustomQuestionTypes,
  ZvjsItemTypes,
  ZvjsLabelFlagTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";
import { CisDruhElektrospotrebica_Iny_Elektricky_Spotrebic_Kod } from "../../specs/countersValues";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050501",
  basicInfo: [
    {
      text: "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.zakladneInfo",
    },
  ],
  dataCalculations: {
    ALL_USER_ELECTRIC_DEVICES_LIST: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050501_DISPLAY_USER_ALREADY_USE_DEVICE_WARNING: {
      calculateAt: Calculate.LIVE,
    },
  },
  items: [
    {
      id: "dovodPodaniaZiadosti",
      title:
        "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.labelDovodPodaniaZiadosti",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      options: [
        {
          id: "POUZ",
          label: "Chcem začať používať elektrický spotrebič",
        },
        {
          id: "REVIZ",
          label:
            "Potrebujem vykonať opakovanú revíznu kontrolu už používaného spotrebiča",
        },
      ],
    },
    {
      type: ZvjsItemTypes.SECTION,
      title: "Chcem začať používať elektrický spotrebič",
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "dovodPodaniaZiadosti",
          value: ["POUZ"],
        },
      ],
      items: [
        {
          id: "druhElektrickehoSpotrebica",
          title:
            "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.labelDruhElektrickehoSpotrebica",
          type: ZvjsQuestionTypes.DROPDOWN,
          isRequired: true,
          options: [
            {
              id: "HOLS",
              label: "Elektrický holiaci strojček",
            },
            {
              id: "VLASS",
              label: "Strojček na strihanie vlasov",
            },
            {
              id: "PVRK",
              label: "Ponorný varič / rýchlovarná kanvica",
            },
            {
              id: "RADIO",
              label: "Rádioprijímač",
            },
            {
              id: "TV",
              label: "Televízny prijímač",
            },
            {
              id: CisDruhElektrospotrebica_Iny_Elektricky_Spotrebic_Kod,
              label: "Iný elektrický spotrebič",
            },
          ],
        },
        {
          id: "NazovInehoElektrickehoSpotrebica",
          title:
            "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.labelInyElektrickySpotrebic",
          type: ZvjsQuestionTypes.TEXT,
          isRequired: true,
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "druhElektrickehoSpotrebica",
              value: [CisDruhElektrospotrebica_Iny_Elektricky_Spotrebic_Kod],
            },
          ],
        },
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.napovedaRadioPrijimac",
          flag: ZvjsLabelFlagTypes.WARNING,
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "druhElektrickehoSpotrebica",
              value: ["RADIO"],
            },
          ],
        },
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.napovedaTV",
          flag: ZvjsLabelFlagTypes.WARNING,
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "druhElektrickehoSpotrebica",
              value: ["TV"],
            },
          ],
        },
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.napovedaInyTypSpotrebica",
          flag: ZvjsLabelFlagTypes.WARNING,
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "druhElektrickehoSpotrebica",
              value: [CisDruhElektrospotrebica_Iny_Elektricky_Spotrebic_Kod],
            },
          ],
        },
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.napovedaDuplicitneEvidovanySpotrebic",
          flag: ZvjsLabelFlagTypes.WARNING,
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "SC_KIO_050501_DISPLAY_USER_ALREADY_USE_DEVICE_WARNING",
              conditionMetWhenValueIs: true,
            },
          ],
        },

        {
          id: "zdrojNapajania",
          title:
            "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.labelZdrojNapajania",
          type: ZvjsQuestionTypes.DROPDOWN,
          isRequired: true,
          options: [
            {
              id: "SIET",
              label:
                "Elektrický spotrebič je funkčný iba počas pripojenia na elektrickú sieť",
            },
            {
              id: "BATER",
              label: "Elektrický spotrebič využíva batériové napájanie",
            },
          ],
        },
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.napovedaZasadyPouzivania",
          flag: ZvjsLabelFlagTypes.INFO,
        },
      ],
    },
    {
      type: ZvjsItemTypes.SECTION,
      title:
        "Potrebujem vykonať opakovanú (revíznu) kontrolu už používaného spotrebiča",
      items: [
        {
          type: ZvjsItemTypes.LABEL,
          text: "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.napovedaNaVedomieRevizia",
          flag: ZvjsLabelFlagTypes.INFO,
        },
        {
          id: "evidovanyElektrickySpotrebicId",
          type: ZvjsCustomQuestionTypes.SC_KIO_050501_DEVICES_REVISION_CONTROL_GRID,
          isRequired: true,
          title: "Evidované elektrické spotrebiče",
        },
      ],
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "dovodPodaniaZiadosti",
          value: ["REVIZ"],
        },
      ],
    },
  ],
};
