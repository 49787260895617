import * as idb from "idb";
import { KioskZVJSDBSchema } from "./databaseSchema";
import { CiselnikyArray } from "../store/context/dataApi/CIS_Ciselnik";
import { DataArray } from "../store/context/dataApi/Data";
import { Module } from "../utils/module";

const DatabaseName = "kioskzvjs";
const DatabaseVersion = 1;

export const dbPromise = idb.openDB<KioskZVJSDBSchema>(
  DatabaseName,
  DatabaseVersion,
  {
    upgrade(database, oldVersion, newVersion, transaction, event) {
      console.log(
        `[DB] upgrade: database[${database}] oldVersion[${oldVersion}] newVersion[${newVersion}]`
      );
      switch (oldVersion) {
        case 0:
          // create initial stores

          database.createObjectStore("EnvironmentVariables");

          CiselnikyArray.forEach((ciselnik) => {
            database.createObjectStore(`${Module.CIS}${ciselnik}`);
          });

          DataArray.forEach((data) => {
            database.createObjectStore(data);
          });

          database.createObjectStore("File");
          break;
        case 1:
          break;
      }
    },
  }
);
