import {
  ZvjsCustomQuestionTypes,
  ZvjsQuestionnaireTemplate,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050201",
  basicInfo: [
    {
      text: "ziadost.ziadostOvymenuCudzPlatidiel.zakladneInfo",
    },
  ],
  items: [
    {
      id: "SC_KIO_050201_QUES_01",
      type: ZvjsCustomQuestionTypes.SC_KIO_050201_BANKNOTES_GRID,
      isRequired: true,
      title: "Evidované bankovky",
    },
  ],
};
