import {
  Calculate,
  LabelType,
  ZvjsConditionTypes,
  ZvjsItemTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050209",
  basicInfo: [
    {
      text: "ziadost.ziadostOpouzitieVratenejPlatby.zakladneInfo",
    },
  ],
  dataCalculations: {
    REFUND_AMOUNT: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  items: [
    {
      type: ZvjsItemTypes.SINGLE_VALUE_LABEL,
      text: "ziadost.ziadostOpouzitieVratenejPlatby.labelSumaVratnePlatby",
      value: "REFUND_AMOUNT",
      valueType: LabelType.EURO,
    },
    {
      id: "SC_KIO_050209_QUES_01",
      title: "ziadost.ziadostOpouzitieVratenejPlatby.VratenuPlatbuChcemPouzit",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      options: [
        {
          id: "E_KIO_26_01",
          label: "Úhrada ďalšieho výživného/pohľadávky",
        },
        {
          id: "E_KIO_26_02",
          label: "Časť úhrada výživného/pohľadávky a časť na konto klienta",
        },
        {
          id: "E_KIO_26_03",
          label: "Na konto klienta",
        },
      ],
    },
    {
      type: ZvjsItemTypes.SECTION,
      title: "Časť úhrada výživného/pohľadávky a časť na konto klienta",
      items: [
        {
          id: "SC_KIO_050209_QUES_02",
          title: "Suma, ktorá bude pripísaná na moje konto",
          type: ZvjsQuestionTypes.TEXT,
          isRequired: true,
        },
      ],
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050209_QUES_01",
          value: ["E_KIO_26_02"],
        },
      ],
    },
    {
      type: ZvjsItemTypes.SECTION,
      title: "Úhrada ďalšieho výživného/pohľadávky",
      items: [],
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050209_QUES_01",
          value: ["E_KIO_26_01", "E_KIO_26_02"],
        },
      ],
    },
  ],
};
