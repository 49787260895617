import React from "react";
import { Box } from "@mui/material";
import { ZVJS_COLORS } from "../../theme/zvjs_theme";

const LoadingErrorPage = () => {
  return (
    <Box
      sx={{
        width: "300px",
        marginTop: "15%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        fontFamily: "'Roboto', sans-serif",
        margin: "0 auto",
      }}
    >
      <h2>Chyba</h2>
      <h4>Aplikáciu sa nepodarilo načítať</h4>
      <button
        onClick={() => window.location.reload()}
        style={{
          backgroundColor: "#82934f",
          color: ZVJS_COLORS.WHITE,
          border: "none",
          padding: "10px 20px",
          borderRadius: "5px",
        }}
      >
        Obnoviť
      </button>
    </Box>
  );
};

export default LoadingErrorPage;
