import {
  ZvjsConditionTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050402",
  basicInfo: [
    {
      text: "ziadost.ziadostOpovolenieZdravPomocky.zakladneInfo",
    },
  ],
  isAvailableToUser: "SC_KIO_050402_isAvailableToUser",
  items: [
    {
      id: "zdravotnickaPomocka",
      title: "ziadost.ziadostOpovolenieZdravPomocky.labelZdravotnickePomocky",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      options: [
        {
          id: "E_KIO_03_01",
          label: "Dioptrické okuliare",
        },
        {
          id: "E_KIO_03_02",
          label: "Ortopedické vložky",
        },
        {
          id: "E_KIO_03_03",
          label: "Ortopedické topánky",
        },
        {
          id: "E_KIO_03_04",
          label: "Barle",
        },
        {
          id: "E_KIO_03_05",
          label: "Dýchací prístroj",
        },
        {
          id: "E_KIO_03_06",
          label: "Diabetické pero",
        },
        {
          id: "E_KIO_03_07",
          label: "Kardio prístroj",
        },
        {
          id: "E_KIO_03_08",
          label: "Inhalačný sprej",
        },
        {
          id: "E_KIO_03_09",
          label: "Iné",
        },
      ],
    },
    {
      id: "dovodPreIne",
      title: "ziadostiVseobecne.ine",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "zdravotnickaPomocka",
          value: ["E_KIO_03_09"],
        },
      ],
    },
  ],
};
