import React from "react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { ZVJS_COLORS } from "../../theme/zvjs_theme";
import classes from "./ZvjsSelect.module.scss";
import { ZvjsHintModalFragment } from "./index";
import { useUITranslation } from "../../store/context/translation-context";

export interface ZvjsSelectItem {
  value: string | number;
  text: string;
  icon?: {
    src: string;
    alt: string;
  };
}

interface ZvjsSelectProps {
  id: string;
  items: ZvjsSelectItem[];
  text?: string;
  changeHandler: (event: SelectChangeEvent) => void;
  selectedValue: string;
  muiSelectSx?: SxProps;
  hintTitle?: string;
  hintText?: string;
  hintColor?: string;
  hintSize?: number;
  answerRequired?: boolean;
  error?: boolean;
  disabled?: boolean;
}

// Warning: Do not place select near to the viewport edge. It is known MUI issue: https://github.com/mui/material-ui/issues/35155
const ZvjsSelect: React.FC<ZvjsSelectProps> = ({
  items,
  text,
  changeHandler,
  selectedValue,
  id,
  muiSelectSx,
  hintTitle,
  hintText,
  hintColor,
  hintSize,
  answerRequired,
  error,
  disabled,
}) => {
  const { tui } = useUITranslation();

  return (
    <FormControl sx={{ textAlign: "start" }} fullWidth>
      <Stack direction={"row"} spacing={1}>
        <Typography variant={"body1"}>
          {text}
          {answerRequired && "*"}
        </Typography>
        {hintText && (
          <ZvjsHintModalFragment
            title={hintTitle === undefined ? tui("nápoveda") : hintTitle} //todo change translation
            text={hintText}
            color={hintColor}
            size={hintSize}
          />
        )}
      </Stack>
      <Select
        id={id}
        labelId={id}
        value={selectedValue}
        onChange={changeHandler}
        IconComponent={({ className }) => {
          return <KeyboardArrowDown className={className} />;
        }}
        error={error}
        disabled={disabled}
        sx={{
          ...{
            "&.MuiOutlinedInput-root": {
              "& fieldset": {
                border: `2px solid ${ZVJS_COLORS.BLACK}`,
              },
              "&.Mui-focused fieldset": {
                border: `2px solid ${ZVJS_COLORS.BLACK}`,
              },
            },
            height: 45,
          },
          ...muiSelectSx,
        }}
      >
        {items.map((item, index) => {
          return (
            <MenuItem
              key={index}
              value={item.value}
              sx={
                index !== 0
                  ? { borderTop: `1px solid ${ZVJS_COLORS.GREY_2}` }
                  : {}
              }
            >
              {item.icon ? (
                <>
                  <img
                    className={classes["menu-item-icon"]}
                    src={item.icon.src}
                    alt={item.icon.alt}
                  />
                  {item.text}
                </>
              ) : (
                item.text
              )}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default ZvjsSelect;
