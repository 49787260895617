import React, { useEffect, useState } from "react";

import { StringMap } from "../../store/context/envConfigContext";

interface ZvjsImageViewerProps {
  file: { url: string; contentType: string; httpHeaders?: StringMap };
}

const ZvjsImageViewer: React.FC<ZvjsImageViewerProps> = ({ file }) => {
  const [imageObjectUrl, setImageObjectUrl] = useState<string | undefined>();

  useEffect(() => {
    fetch(file.url, {
      headers: file.httpHeaders,
      method: "GET",
    }).then((response) => {
      response
        .blob()
        .then((blob) => setImageObjectUrl(window.URL.createObjectURL(blob)));
    });
  }, [file]);

  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <img src={imageObjectUrl} style={{ maxWidth: "100%", height: "auto" }} />
    </div>
  );
};

export default ZvjsImageViewer;
