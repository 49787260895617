import {
  ZvjsCustomQuestionTypes,
  ZvjsQuestionnaireTemplate,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050602",
  isAvailableToUser: "SC_KIO_050602_isAvailableToUser",
  basicInfo: [
    {
      text: "ziadost.ziadostOzaradenieSpecVzdelResocProgramu.zakladneInfo",
    },
  ],
  items: [
    {
      id: "SC_KIO_050602_QUES_01",
      type: ZvjsCustomQuestionTypes.SC_KIO_050602_PROGRAMS_GRID,
      isRequired: true,
      title:
        "ziadost.ziadostOzaradenieSpecVzdelResocProgramu.labelUrcenyResocProgram",
    },
  ],
};
