import {
  ZvjsItemTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050804",
  basicInfo: [
    {
      text: "ziadost.ziadostOposkytnutieDuchovnejSluzby.zakladneInfo",
    },
  ],
  items: [
    {
      type: ZvjsItemTypes.SECTION,
      title: "ziadost.ziadostOposkytnutieDuchovnejSluzby.labelDuchovneSluzby",
      items: [
        {
          id: "katolickaDuchovnaSluzba",
          title:
            "ziadost.ziadostOposkytnutieDuchovnejSluzby.labelKatolickaDuchovnaSluzba",
          type: ZvjsQuestionTypes.SINGLE_CHECKBOX,
        },
        {
          id: "ekumenickaPastoracnaSluzba",
          title:
            "ziadost.ziadostOposkytnutieDuchovnejSluzby.labelEkumenickaPastoracnaSluzba",
          type: ZvjsQuestionTypes.SINGLE_CHECKBOX,
        },
      ],
    },
  ],
};
