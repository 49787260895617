import { ZvjsSummaryTable, ZvjsTable } from "../../../../common";
import { ZvjsSummaryTableComponent } from "../../../../common/components/ZvjsSummaryTable";
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useUITranslation } from "../../../../store/context/translation-context";
import { ZakladneUdajeOTresteProps } from "../ZakladneUdajeOTreste";
import { capitalize } from "@mui/material/utils";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";

const UdajeOTresteTab: React.FC<ZakladneUdajeOTresteProps> = ({ data }) => {
  const { tui } = useUITranslation();

  const dataStyleVykonTrestu: TableStyle[] = [
    {
      align: "left",
    },
  ];

  const headerDataVykonTrestu: Array<CellData> = [
    {
      value: capitalize(tui("osobneUdaje.udajeOvazbe.paragraf")),
      ...dataStyleVykonTrestu[0],
    },
  ];

  const [tableDataVykonTrestu, setTableDataVykonTrestu] = useState<TableData>({
    label: capitalize(
      tui("osobneUdaje.vykonTrestuZaSpachanieTrCinu.vykonTrestu")
    ),
    header: headerDataVykonTrestu,
    body: [],
  });

  useEffect(() => {
    const bodyData: Array<RowData> = new Array<RowData>();

    bodyData.push({
      row: [
        {
          value: "< API? >",
          ...dataStyleVykonTrestu[0],
        },
      ],
    });

    setTableDataVykonTrestu({
      ...tableDataVykonTrestu,
      body: bodyData,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsSummaryTable
        header={tui(
          "osobneUdaje.vseobecneTrestnopravneUdaje.vseobecneTrestUdaje"
        )}
      >
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneTrestnopravneUdaje.zaciatokTrestu")}
          value={
            data.klientUni?.zakladneUdajeKlienta?.datumNastupu
              ? new Date(
                  data.klientUni.zakladneUdajeKlienta.datumNastupu
                ).zvjsToString()
              : ""
          }
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.napovedaNastupTrestu"
          )}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneTrestnopravneUdaje.terminPP")}
          value={
            data.klientUni?.zakladneUdajeKlienta?.datumPP
              ? new Date(
                  data.klientUni.zakladneUdajeKlienta.datumPP
                ).zvjsToString()
              : ""
          }
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui("osobneUdaje.vseobecneTrestnopravneUdaje.napovedaPP")}
        />
        <ZvjsSummaryTableComponent
          label={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.terminPPsTechnickouKontrolou"
          )}
          value={"< API? >"}
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.napovedaPPtechKontrola"
          )}
        />
        <ZvjsSummaryTableComponent
          label={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.terminDomaceVazenie"
          )}
          value={
            data.detailTDVData.data?.datumTDV
              ? new Date(data.detailTDVData.data?.datumTDV).zvjsToString()
              : ""
          }
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.napovedaDomaceVazenie"
          )}
        />
        <ZvjsSummaryTableComponent
          label={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.aktualnyStupenStrazenia"
          )}
          value={data.epvvvtKlient?.stupenStrazenia ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.diferenciacnaSkupina"
          )}
          value={data.epvvvtKlient?.diferenciacnaSkupina ?? ""}
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.napovedaAktualnaDifSkupina"
          )}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneTrestnopravneUdaje.dlzkaTrestu")}
          value={
            data.klientUni?.rozsireneUdajeKlienta?.dlzkaTrestu?.toString() ?? ""
          }
          isBorder={false}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui(
            "osobneUdaje.vseobecneTrestnopravneUdaje.napovedaInfoDlzkaTrestu"
          )}
        />
      </ZvjsSummaryTable>
      <ZvjsTable
        data={tableDataVykonTrestu}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
        hintTitle={tui("nápoveda").toString()}
        hintText={tui(
          "osobneUdaje.vykonTrestuZaSpachanieTrCinu.napovedaTrestnyCin"
        )}
      />
    </Stack>
  );
};
export default UdajeOTresteTab;
