import { State, ZvjsCustomQuestion } from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";

/**
 * Selectors
 */

const getVisitDateQuestionDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const { tuiz } = useUITranslation();

    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    let thisMonth = false;
    // TODO access userCalcData and check whether user has any request for this month available
    if (true) {
      thisMonth = true;
    }

    let nextMonth = false;
    // TODO access userCalcData and check whether user has any request for next month available
    if (true) {
      nextMonth = true;
    }

    return {
      id: question.id,
      title: tuiz(question.title),
      thisMonthRequestAvailable: thisMonth,
      nextMonthRequestAvailable: nextMonth,
    };
  });

export const allSelectors = {
  getVisitDateQuestionDisplayData,
};
