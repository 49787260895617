import {
  ZvjsConditionTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_051301",
  basicInfo: [
    {
      text: "ziadost.hlaseniePoruchy.zakladneInfo",
    },
  ],
  items: [
    {
      id: "miestoNedostatku",
      title: "ziadost.hlaseniePoruchy.labelMiestoNedostatku",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      hintText: "ziadost.hlaseniePoruchy.napovedaJednaMoznostZoZoznamu",
      options: [
        {
          id: "E_KIO_47_01",
          label: "Cela/izba, v ktorej som ubytovaný",
        },
        {
          id: "E_KIO_47_02",
          label: "Chodba oddielu, na ktorej som umiestnený",
        },
        {
          id: "E_KIO_47_03",
          label: "Priestory na vzdelávaciu, záujmovú a športovú činnosť",
        },
        {
          id: "E_KIO_47_04",
          label: "Priestor vychádzok",
        },
        {
          id: "E_KIO_47_05",
          label: "Priestory spoločných hygienických zariadení vrátane spŕch",
        },
        {
          id: "E_KIO_47_06",
          label: "Priestory pracoviska",
        },
        {
          id: "E_KIO_47_07",
          label: "Kuchynka",
        },
        {
          id: "E_KIO_47_08",
          label: "Iné priestory",
        },
      ],
    },
    {
      id: "dovodPreIneMiesto",
      title: "ziadostiVseobecne.ine",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "miestoNedostatku",
          value: ["E_KIO_47_08"],
        },
      ],
    },
    {
      id: "druhNedostatku",
      title: "ziadost.hlaseniePoruchy.labelDruhNedostatku",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      options: [
        {
          id: "E_KIO_48_01",
          label: "Poškodenie inventára - lôžko",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_01"],
            },
          ],
        },
        {
          id: "E_KIO_48_02",
          label: "Poškodenie inventára – stôl/stolička",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_01"],
            },
          ],
        },
        {
          id: "E_KIO_48_03",
          label: "Poškodenie inventára – skrinka",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_01"],
            },
          ],
        },
        {
          id: "E_KIO_48_04",
          label: "Porucha - elektrické osvetlenie",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_01"],
            },
          ],
        },
        {
          id: "E_KIO_48_05",
          label: "Porucha – umývadlo/WC",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_01"],
            },
          ],
        },
        {
          id: "E_KIO_48_06",
          label: "Poškodenie inventára - lôžko",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_01"],
            },
          ],
        },
        {
          id: "E_KIO_48_07",
          label: "Porucha – telefónne zariadenie",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_01"],
            },
          ],
        },
        {
          id: "E_KIO_48_08",
          label: "Iné poškodenie/porucha",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_01"],
            },
          ],
        },
        {
          id: "E_KIO_49_01",
          label: "Porucha – telefónne zariadenie",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_02"],
            },
          ],
        },
        {
          id: "E_KIO_49_02",
          label: "Iné poškodenie/porucha",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_02"],
            },
          ],
        },
        {
          id: "E_KIO_52_01",
          label: "Porucha – TV prijímač",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_03"],
            },
          ],
        },
        {
          id: "E_KIO_52_02",
          label: "Poškodenie inventára – stôl/stolička",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_03"],
            },
          ],
        },
        {
          id: "E_KIO_52_03",
          label: "Poškodenie inventára – športové zariadenie",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_03"],
            },
          ],
        },
        {
          id: "E_KIO_52_04",
          label: "Iné poškodenie/porucha",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_03"],
            },
          ],
        },
        {
          id: "E_KIO_51_01",
          label: "Porucha – telefónne zariadenie",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_04"],
            },
          ],
        },
        {
          id: "E_KIO_51_02",
          label: "Iné poškodenie/porucha",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_04"],
            },
          ],
        },
        {
          id: "E_KIO_53_01",
          label: "Porucha – umývadlo/WC",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_05"],
            },
          ],
        },
        {
          id: "E_KIO_53_02",
          label: "Porucha – sprcha",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_05"],
            },
          ],
        },
        {
          id: "E_KIO_53_03",
          label: "Iné poškodenie/porucha",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_05"],
            },
          ],
        },
        {
          id: "E_KIO_50_01",
          label: "Porucha – práčka",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_07"],
            },
          ],
        },
        {
          id: "E_KIO_50_02",
          label: "Porucha – sporák",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_07"],
            },
          ],
        },
        {
          id: "E_KIO_50_03",
          label: "Iné poškodenie/porucha",
          conditions: [
            {
              type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
              questionId: "miestoNedostatku",
              value: ["E_KIO_47_07"],
            },
          ],
        },
      ],
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "miestoNedostatku",
          value: [
            "E_KIO_47_01",
            "E_KIO_47_02",
            "E_KIO_47_03",
            "E_KIO_47_04",
            "E_KIO_47_05",
            "E_KIO_47_07",
          ],
        },
      ],
    },
    {
      id: "dovodPreInePoskodenie",
      title: "ziadostiVseobecne.ine",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "druhNedostatku",
          value: [
            "E_KIO_48_08",
            "E_KIO_49_02",
            "E_KIO_52_04",
            "E_KIO_51_02",
            "E_KIO_53_03",
            "E_KIO_50_03",
          ],
        },
      ],
    },
    {
      id: "blizsiPopisNedostatku",
      title: "ziadost.hlaseniePoruchy.labelPopisNedostatku",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: false,
      validations: ["MAX_ANSWER_LENGTH_500"],
    },
  ],
};
