import { FormControl, TextField } from "@mui/material";
import { ZVJS_COLORS } from "../../theme/zvjs_theme";
import React, { useEffect, useState } from "react";
//import DateRangeIcon from '@mui/icons-material/DateRange';

// currently supported DateSelect modes
export enum DateSelectMode {
  date = "date",
  month = "month",
}

interface ZvjsDateSelectProps {
  text: string;
  id: string;
  onChange: (selecteDate: string) => void;
  value: string;
  // min date which can be selected in date select
  minDate?: Date;
  // max date which can be selected in date select
  maxDate?: Date;
  // mode of date select
  mode?: DateSelectMode;
}

const ZvjsDateSelect: React.FC<ZvjsDateSelectProps> = ({
  text,
  id,
  onChange,
  value,
  minDate,
  maxDate,
  mode = DateSelectMode.date,
}) => {
  const [selectedDate, setSelectedDate] = useState("");
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
    onChange(event.target.value);
  };

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  const toDateRangeString = (date: Date) => {
    if (mode === DateSelectMode.month) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // add leading zero if needed
      return `${year}-${month}`;
    }
    // mode is "date"
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // add leading zero if needed
    const day = date.getDate().toString().padStart(2, "0"); // add leading zero if needed
    return `${year}-${month}-${day}`;
  };

  return (
    <FormControl sx={{ textAlign: "start" }} fullWidth>
      <div>{text}</div>
      <TextField
        id="date"
        type={mode}
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        variant="outlined"
        sx={{
          "&.MuiTextField-root": {
            "& fieldset": {
              border: `2px solid ${ZVJS_COLORS.BLACK}`,
            },
            "& input": {
              color: selectedDate ? ZVJS_COLORS.BLACK : "transparent",
              background: ZVJS_COLORS.WHITE,
            },
          },
        }}
        InputProps={{
          inputProps: {
            min: minDate ? toDateRangeString(minDate) : undefined,
            max: maxDate ? toDateRangeString(maxDate) : undefined,
          },
          //startAdornment: <DateRangeIcon />
        }}
        onChange={onChangeHandler}
        value={selectedDate}
      />
    </FormControl>
  );
};

export default ZvjsDateSelect;
