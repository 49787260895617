import { Grid, Typography } from "@mui/material";
import { ZVJS_COLORS } from "../../../theme/zvjs_theme";
import React, { useState } from "react";

export interface ZvjsChatBotSelectData {
  title: string;
  onClick: () => void;
}

interface ChatBotSelectProps {
  data: ZvjsChatBotSelectData[];
}

interface ChatBotSelectStoneProps {
  title: string;
  onClick: () => void;
  isSelected?: boolean;
  strongTitle?: boolean;
}

const ChatBotSelectStone: React.FC<ChatBotSelectStoneProps> = ({
  title,
  onClick,
  isSelected,
  strongTitle,
}) => {
  const [hover, setHover] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const handleOnClick = () => {
    onClick();
  };

  return (
    <Grid item xs={"auto"} sx={{ width: "fit-content !important" }}>
      <div
        style={{
          cursor: "pointer",
          backgroundColor: isSelected
            ? ZVJS_COLORS.BLUE
            : hover
            ? ZVJS_COLORS.BLUE
            : ZVJS_COLORS.WHITE,
          borderRadius: "3px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: `1px solid ${ZVJS_COLORS.BLUE}`,
        }}
        onClick={handleOnClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Typography
          variant={"body1"}
          style={{
            color: isSelected
              ? ZVJS_COLORS.WHITE
              : hover
              ? ZVJS_COLORS.WHITE
              : ZVJS_COLORS.BLACK,
            margin: "0.4rem",
            fontWeight: strongTitle ? 600 : "auto",
          }}
        >
          {title}
        </Typography>
      </div>
    </Grid>
  );
};

const STONES_DISPLAY_STEP = 3;

const ChatBotSelect: React.FC<ChatBotSelectProps> = ({ data }) => {
  const [selectedItem, setSelectedItem] = useState<number | undefined>(
    undefined
  );

  const [countOfDisplayedStones, setCountOfDisplayedStone] =
    useState<number>(STONES_DISPLAY_STEP);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <Grid container justifyContent={"center"} spacing={1}>
        {data
          .filter((data, index) => index < countOfDisplayedStones)
          .map((data, index) => (
            <ChatBotSelectStone
              key={index}
              title={data.title}
              onClick={() => {
                setSelectedItem(index);
                data.onClick();
              }}
              isSelected={selectedItem === index}
            />
          ))}
        {data.length > countOfDisplayedStones && (
          <ChatBotSelectStone
            // TODO add translation
            title={"Zobraziť ďalšie možnosti"}
            onClick={() =>
              setCountOfDisplayedStone(
                (prevState) => prevState + STONES_DISPLAY_STEP
              )
            }
            strongTitle
          />
        )}
      </Grid>
    </div>
  );
};

export default ChatBotSelect;
