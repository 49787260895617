import React, { useEffect, useState } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useNavigate } from "react-router-dom";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsSearchBar,
  ZvjsTable,
} from "../../../common";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router";
import { withLoader } from "../../../hoc/withLoader";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { ZvjsSearchBarVariant } from "../../../common/components/ZvjsSearchBar";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { PromiseElement } from "../../../utils/types";

const loadData = async () => {
  const { SHARE_INT_Post } = await API_Clients();

  const data = await Promise.all([
    SHARE_INT_Post("/api/interfaces/Epvvvt/ListAbsolvovaneTelefonickeHovory", {
      body: {
        klientId: {
          eq: localStorage.getItem("klientObjectId"),
        },
      },
    }),
  ]);
  return {
    telefonaty: data[0],
  };
};

export const TelefonovanieLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface TelefonovanieProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const Telefonovanie = (props: TelefonovanieProps) => {
  const loaderData = props.data;
  const { t } = useTranslation();
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Telephony_SC_KIO_0311.dateOfCall"
        )
      ),
      ...dataStyle[0],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Telephony_SC_KIO_0311.lengthOfCall"
        )
      ),
      ...dataStyle[1],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Telephony_SC_KIO_0311.priceOfCall"
        )
      ),
      ...dataStyle[2],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Telephony_SC_KIO_0311.calledPerson"
        )
      ),
      ...dataStyle[3],
    },
  ];

  const [tableData, setTableData] = useState<TableData>({
    header: headerData,
    body: [],
  });

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (loaderData.telefonaty.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      loaderData.telefonaty.data
        .sort(
          (p1, p2) =>
            new Date(p2.datumHovoru ?? new Date()).valueOf() -
            new Date(p1.datumHovoru ?? new Date()).valueOf()
        )
        .forEach((telefonat) => {
          bodyData.push({
            row: [
              {
                value:
                  new Date(telefonat.datumHovoru ?? new Date()).zvjsToString() +
                  " " +
                  new Date(
                    telefonat.datumHovoru ?? new Date()
                  ).toLocaleTimeString("sk-SK"),
                ...dataStyle[0],
              },
              {
                value: telefonat.dlzkaTrvania ?? "",
                ...dataStyle[1],
              },
              {
                value: telefonat.prevolanaSuma?.toFixed(2) ?? "", //telefonat.prevolanaSuma.toFixed(2),
                ...dataStyle[2],
              },
              {
                value: telefonat.volanaOsobaNazov ?? "",
                ...dataStyle[3],
              },
            ],
          });
        });
      setTableData({
        ...tableData,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  const mySubmitHandler = (dateFrom: Date, dateTo: Date) => {
    console.log(dateFrom);
    console.log(dateTo);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(
              tui("osobneUdaje.kategorieOsobnychUdajov.telefonovanie")
            )}
          </Typography>
        </Stack>
      </Grid>
      <Stack direction="column" spacing={2}>
        <ZvjsSearchBar
          title={t(
            "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Telephony_SC_KIO_0311.searchLabel"
          )}
          type={ZvjsSearchBarVariant.VARIANT_A_DpDp}
          onSubmit={mySubmitHandler}
        />

        <ZvjsTable
          data={tableData}
          height={360}
          variant={ZvjsTableVariant.NORMAL}
          // TODO add translation
          hintText={" "}
        />

        <Grid width="20rem">
          <ZvjsButton
            text={capitalize(
              t(
                "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Telephony_SC_KIO_0311.requestTelephony"
              )
            )}
            zvjsVariant="secondaryAction"
            onClick={() => {
              navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/13.00.01`);
            }}
            endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
            style={{
              lineHeight: "1",
              textAlign: "left",
              textTransform: "none",
            }}
          />
        </Grid>
      </Stack>
    </Grid>
  );
};

export default withLoader(Telefonovanie);
