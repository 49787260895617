import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../redux";
import questionnaireReducer from "../../../redux";
import {
  ActivitiesGrid,
  BanknotesGrid,
  DevicesRevisionControlGrid,
  DropdownQuestion,
  GroupQuestion,
  SC_KIO_051403_NotAllowedToSendRequestLabels,
  PlaceOfRelocationQuestion,
  PriorityQuestion,
  ProgramsGrid,
  RadioQuestion,
  RegisteredChildrenGrid,
  SingleCheckBoxQuestion,
  TextQuestion,
  TravelExpensesRadio,
  VisitDateQuestion,
  SC_KIO_051003_NotAllowedToSendRequestLabels,
  PersonalHygieneNeedsGrid,
  CivilianClothesGrid,
  DropdownQuestion_New,
  NestedDropdownQuestion,
  PlaceOfLocationQuestion,
} from "./index";
import {
  ZvjsCustomQuestionTypes,
  ZvjsItemTypes,
  ZvjsQuestionTypes,
} from "../../../redux/model";
import { AppDispatch } from "../../../../../store/redux";
import { Grid } from "@mui/material";

interface QuestionProps {
  location: number[];
}

const Question: React.FC<QuestionProps> = ({ location }) => {
  const dispatch = useDispatch<AppDispatch>();
  const questionInfo = useSelector(
    questionnaireRedux.selectors.getGeneralQuestionInfo(location)
  );
  const isDisplayed = useSelector(
    questionnaireRedux.selectors.getIsItemDisplayed(location)
  );
  const componentId = useSelector(
    questionnaireRedux.selectors.getQuestionToEdit
  );

  const view = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (view.current !== null) {
      if (componentId === questionInfo.id) {
        const scrollPosition =
          view.current.offsetTop -
          8 * parseFloat(getComputedStyle(document.documentElement).fontSize);
        window.scrollTo({ top: scrollPosition, behavior: "smooth" });
        dispatch(
          questionnaireReducer.sliceActions.setEditQuestionnaire({
            questionId: undefined,
          })
        );
        // view.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [dispatch, view, isDisplayed, componentId, questionInfo.id]);

  console.log(`QUESTION RERENDER ${location.join("-")}`);

  const buildQuestion = () => {
    const getQuestionComponent = (
      questionInfo: {
        type: ZvjsItemTypes | ZvjsQuestionTypes | ZvjsCustomQuestionTypes;
      },
      location: number[]
    ) => {
      switch (questionInfo.type) {
        case ZvjsQuestionTypes.DROPDOWN:
          return <DropdownQuestion location={location} />;
        case ZvjsQuestionTypes.DROPDOWN_NEW:
          return <DropdownQuestion_New location={location} />;
        case ZvjsQuestionTypes.NESTED_DROPDOWN:
          return <NestedDropdownQuestion location={location} />;
        case ZvjsQuestionTypes.TEXT:
          return <TextQuestion location={location} />;
        case ZvjsQuestionTypes.SINGLE_CHECKBOX:
          return <SingleCheckBoxQuestion location={location} />;
        case ZvjsQuestionTypes.RADIO:
          return <RadioQuestion location={location} />;
        case ZvjsCustomQuestionTypes.VISIT_DATE_PICKER:
          return <VisitDateQuestion location={location} />;
        case ZvjsCustomQuestionTypes.SC_KIO_050802_TRAVEL_EXPENSES_RADIO:
          return <TravelExpensesRadio location={location} />;
        case ZvjsCustomQuestionTypes.SC_KIO_051403_PLACE_OF_RELOCATION_QUESTION:
          return <PlaceOfRelocationQuestion location={location} />;
        case ZvjsCustomQuestionTypes.SC_KIO_051403_PRIORITY_QUESTION:
          return <PriorityQuestion location={location} />;
        case ZvjsCustomQuestionTypes.SC_KIO_051403_NOT_ALLOWED_TO_SEND_REQUEST_LABELS:
          return (
            // eslint-disable-next-line react/jsx-pascal-case
            <SC_KIO_051403_NotAllowedToSendRequestLabels location={location} />
          );
        case ZvjsCustomQuestionTypes.SC_KIO_051404_PLACE_OF_LOCATION_QUESTION:
          return <PlaceOfLocationQuestion location={location} />;
        case ZvjsCustomQuestionTypes.SC_KIO_050602_PROGRAMS_GRID:
          return <ProgramsGrid location={location} />;
        case ZvjsCustomQuestionTypes.SC_KIO_050601_ACTIVITIES_GRID:
          return <ActivitiesGrid location={location} />;
        case ZvjsCustomQuestionTypes.SC_KIO_050201_BANKNOTES_GRID:
          return <BanknotesGrid location={location} />;
        case ZvjsCustomQuestionTypes.SC_KIO_050604_GROUP_QUESTION:
          return <GroupQuestion location={location} />;
        case ZvjsCustomQuestionTypes.SC_KIO_050206_REGISTERED_CHILDREN_GRID:
          return <RegisteredChildrenGrid location={location} />;
        case ZvjsCustomQuestionTypes.SC_KIO_050501_DEVICES_REVISION_CONTROL_GRID:
          return <DevicesRevisionControlGrid location={location} />;
        case ZvjsCustomQuestionTypes.SC_KIO_051003_NOT_ALLOWED_TO_SEND_REQUEST_LABELS:
          return (
            // eslint-disable-next-line react/jsx-pascal-case
            <SC_KIO_051003_NotAllowedToSendRequestLabels location={location} />
          );
        case ZvjsCustomQuestionTypes.SC_KIO_051003_PERSONAL_HYGIENE_NEEDS_GRID:
          return <PersonalHygieneNeedsGrid location={location} />;
        case ZvjsCustomQuestionTypes.SC_KIO_051102_CIVILIAN_CLOTHES_GRID:
          return <CivilianClothesGrid location={location} />;
      }
      console.error(
        `Unsupported question type ${questionInfo.type} with location ${location}`
      );
      return <></>;
    };

    // if you are not in section limit your question width
    if (location.length === 1) {
      return (
        <Grid container>
          <Grid item md={8} xs={12}>
            {getQuestionComponent(questionInfo, location)}
          </Grid>
        </Grid>
      );
    } else {
      return getQuestionComponent(questionInfo, location);
    }
  };

  return isDisplayed ? <Grid ref={view}>{buildQuestion()}</Grid> : <></>;
};

export default Question;
