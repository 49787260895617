import React from "react";
import { Link } from "react-router-dom";

interface LinkTextInterface {
  href: string;
  children?: React.ReactNode;
}

const LinkText: React.FC<LinkTextInterface> = ({ href, ...rest }) => {
  return <Link to={href || ""}>{rest.children}</Link>;
};

export default LinkText;
