import React from "react";
import { Box } from "@mui/system";
import { ZVJS_COLORS } from "../../../theme/zvjs_theme";
import { Typography } from "@mui/material";

interface ChatBotMessageBotProps {
  message: string;
}

const ChatBotMessageUser: React.FC<ChatBotMessageBotProps> = ({ message }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <Box sx={{ backgroundColor: ZVJS_COLORS.BLUE, borderRadius: "3px" }}>
        <Typography variant={"body1"} style={{ color: ZVJS_COLORS.WHITE, margin: "0.4rem" }}>
          {message}
        </Typography>
      </Box>
    </div>
  );
};

export default ChatBotMessageUser;
