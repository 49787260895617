import {
  Calculate,
  LabelType,
  ZvjsItemTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050208",
  basicInfo: [
    {
      text: "ziadost.ziadostOvyplateniePPpremiestnenie.zakladneInfo",
    },
  ],
  dataCalculations: {
    AVAILABLE_ACCOUNT_AMOUNT: {
      calculateAt: Calculate.AT_INIT,
    },
    MINIMUM_SUBSISTENCE_AMOUNT: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  items: [
    {
      type: ZvjsItemTypes.SINGLE_VALUE_LABEL,
      // it is possible that label will use a different type of data
      text: "ziadost.ziadostOvyplateniePPpremiestnenie.labelMoznaSumaNaNakup",
      value: "AVAILABLE_ACCOUNT_AMOUNT",
      valueType: LabelType.EURO,
    },
    {
      id: "pozadovanaSuma",
      title: "ziadost.ziadostOvyplateniePPpremiestnenie.labelPozadovanaSuma",
      type: ZvjsQuestionTypes.TEXT,
      inputType: "number",
      isRequired: true,
      validations: ["SC_KIO_050208_VALIDATE_REQUESTED_AMOUNT"],
    },
  ],
};
