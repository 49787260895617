import React, { useEffect, useState } from "react";
import { PeniazeAPoplatkyProps } from "../PeniazeAPoplatky";
import { ZvjsSearchBar, ZvjsSummaryTable, ZvjsTable } from "../../../../common";
import { ZvjsSummaryTableComponent } from "../../../../common/components/ZvjsSummaryTable";
import { ZvjsSearchBarVariant } from "../../../../common/components/ZvjsSearchBar";
import { capitalize } from "@mui/material/utils";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";

const KreditABlokacieTab: React.FC<PeniazeAPoplatkyProps> = ({ data }) => {
  const { t } = useTranslation();
  const { tui } = useUITranslation();

  const dataStylePohybKreditBlokacie: TableStyle[] = [
    {
      align: "left",
      width: "26%",
    },
    {
      align: "left",
      width: "37%",
    },
    {
      align: "left",
      width: "37%",
    },
  ];

  const headerDataPohybKreditBlokacie: Array<CellData> = [
    {
      value: capitalize(tui("osobneUdaje.blokacie.datumPohybu")),
      ...dataStylePohybKreditBlokacie[0],
    },
    {
      value: capitalize(tui("osobneUdaje.kontoAkredit.druhPohybu")),
      ...dataStylePohybKreditBlokacie[1],
    },
    {
      value: capitalize(
        tui(
          // TODO: osobneUdaje.vyplatnePasky VS osobneUdaje.blokacie
          "osobneUdaje.blokacie.sumaPohybu"
        )
      ),
      ...dataStylePohybKreditBlokacie[2],
    },
  ];

  const [tableDataPohybKreditBlokacie, setTableDataPohybKreditBlokacie] =
    useState<TableData>({
      header: headerDataPohybKreditBlokacie,
      body: [],
    });

  useEffect(() => {
    if (data.pohybyKreditBlokacie.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      data.pohybyKreditBlokacie.data.records
        // .sort((p1, p2) =>
        //   p1.name > p2.name)
        ?.map((plohybKreditBlokacie) => {
          if (data.blokaciaTyp.data) {
            const typBlokacie =
              data.blokaciaTyp.data.records?.find(
                (i) => i.id === plohybKreditBlokacie.typBlokacieID
              )?.nazov ?? "<unknown>";
            return { ...plohybKreditBlokacie, typBlokacie: typBlokacie };
          }
          return { ...plohybKreditBlokacie, typBlokacie: "" };
        })
        .forEach((pohybKreditBlokacie) => {
          bodyData.push({
            row: [
              {
                value: pohybKreditBlokacie.datum
                  ? new Date(pohybKreditBlokacie.datum).zvjsToString()
                  : "",
                ...dataStylePohybKreditBlokacie[0],
              },
              {
                value: pohybKreditBlokacie.typBlokacie ?? "",
                ...dataStylePohybKreditBlokacie[1],
              },
              {
                value: pohybKreditBlokacie.suma?.toFixed(2) + " Eur",
                ...dataStylePohybKreditBlokacie[2],
              },
            ],
          });
        });
      setTableDataPohybKreditBlokacie({
        ...tableDataPohybKreditBlokacie,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const mySubmitHandlerCredit = (
    dateFrom: Date,
    dateTo: Date,
    comboboxValue: string
  ) => {
    console.log(dateFrom);
    console.log(dateTo);
    console.log(comboboxValue);
  };

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsSummaryTable
        header={[
          t(
            "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.MoneyAndFees_SC_KIO_0360.CreditAndBlocking.credit"
          ) as string,
          tui("osobneUdaje.blokacie.blokacie"),
        ]}
        hintText2={tui("osobneUdaje.blokacie.napovedaBlokacie")}
      >
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.kontoAkredit.stavKontaVreckove")}
          value={`${
            data.stavKontaAKreditu?.at(0)?.aktualnyKredit?.toFixed(2) ?? 0
          } EUR`}
          isBorder={true}
          hintText={tui(
            "osobneUdaje.kontoAkredit.napoveda.zostatokVyzivnePohladavky"
          )}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.blokacie.blokacieVreckove")}
          value={`${
            data.stavKontaAKreditu?.at(0)?.blokaciaSumaV?.toFixed(2) ?? 0
          } EUR`}
          isBorder={true}
        />
        <ZvjsSummaryTableComponent label={""} value={""} isBorder={true} />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.blokacie.blokacieOsobné")}
          value={`${
            data.stavKontaAKreditu?.at(0)?.blokaciaSumaO?.toFixed(2) ?? 0
          } EUR`}
          isBorder={true}
        />
        <ZvjsSummaryTableComponent label={""} value={""} isBorder={false} />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.blokacie.blokacieSpolu")}
          value={`${
            data.stavKontaAKreditu?.at(0)?.blokaciaSpolu?.toFixed(2) ?? 0
          } EUR`}
          isBorder={false}
        />
      </ZvjsSummaryTable>

      <ZvjsSearchBar
        title={t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.MoneyAndFees_SC_KIO_0360.Account.searchMovement"
        )}
        type={ZvjsSearchBarVariant.VARIANT_B_DpDpCo}
        onSubmit={mySubmitHandlerCredit}
        comboboxLabel={capitalize(tui("osobneUdaje.kontoAkredit.pohyb"))}
        items={[
          { value: 3, text: "Tri" },
          { value: 5, text: "Pat" },
          { value: 10, text: "Desat" },
        ]}
      />
      <ZvjsTable
        data={tableDataPohybKreditBlokacie}
        height={180}
        variant={ZvjsTableVariant.NORMAL}
        hintText={tui("osobneUdaje.blokacie.napovedaZrealizovanePohyby")}
      />
    </Stack>
  );
};

export default KreditABlokacieTab;
