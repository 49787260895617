export enum PersonalDataCategory_SC_KIO_0313 {
  BasicClientData = "BasicClientData",
  BasicPunishmentData = "BasicPunishmentData",
  BasicArrestData = "BasicArrestData",
  PlaceOfCustodyAndExecutionOfSentence = "PlaceOfCustodyAndExecutionOfSentence",
  DisciplinarySanctionsAndOtherDecisions = "DisciplinarySanctionsAndOtherDecisions",
  ResocializationAndLeisureActivities = "ResocializationAndLeisureActivities",
  MoneyAndFees = "MoneyAndFees",
  Letters = "Letters",
  Packets = "Packets",
  Visits = "Visits",
  Telephony = "Telephony",
  PersonalBelongings = "PersonalBelongings",
  Guidances = "Guidances",
}
