import React from "react";
import { Box, Stack } from "@mui/system";
import { ZVJS_COLORS } from "../../../theme/zvjs_theme";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import "./ChatBotTypingMessageBot.scss";

const ChatBotMessageUser: React.FC = () => {
  return (
    <Stack
      sx={{ width: "fit-content", display: "flex" }}
      alignItems={"center"}
      justifyContent={"flex-start"}
      spacing={1}
      direction={"row"}
    >
      <SmartToyIcon
        style={{ fill: ZVJS_COLORS.BLUE, height: "2rem", width: "2rem" }}
      />
      <Box
        sx={{
          backgroundColor: ZVJS_COLORS.WHITE,
          borderRadius: "3px",
          width: "100%",
          paddingLeft: 1,
          paddingRight: 1,
          height: "2rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="loading-dots"></div>
      </Box>
    </Stack>
  );
};

export default ChatBotMessageUser;
