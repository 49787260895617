import React, { useEffect, useState } from "react";
import { PeniazeAPoplatkyProps } from "../PeniazeAPoplatky";
import { ZvjsSearchBar, ZvjsSummaryTable, ZvjsTable } from "../../../../common";
import { ZvjsSummaryTableComponent } from "../../../../common/components/ZvjsSummaryTable";
import { capitalize } from "@mui/material/utils";
import { ZvjsSearchBarVariant } from "../../../../common/components/ZvjsSearchBar";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../../store/context/translation-context";

const KontoTab: React.FC<PeniazeAPoplatkyProps> = ({ data }) => {
  const { t } = useTranslation();
  const { tui } = useUITranslation();

  const dataStylePohybKonto: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
  ];
  const headerDataPohybKonto: Array<CellData> = [
    {
      value: capitalize(
        tui(
          // TODO: osobneUdaje.kontoAkredit VS osobneUdaje.blokacie
          "osobneUdaje.blokacie.datumPohybu"
        )
      ),
      ...dataStylePohybKonto[0],
    },
    {
      value: capitalize(
        tui(
          // TODO: osobneUdaje.kontoAkredit VS osobneUdaje.blokacie
          "osobneUdaje.blokacie.nazovPohybu"
        )
      ),
      ...dataStylePohybKonto[1],
    },
    {
      value: capitalize(
        tui(
          // TODO: osobneUdaje.kontoAkredit VS osobneUdaje.blokacie
          "osobneUdaje.blokacie.sumaPohybu"
        )
      ),
      ...dataStylePohybKonto[2],
    },
    {
      value: capitalize(
        tui(
          // TODO: osobneUdaje.kontoAkredit VS osobneUdaje.blokacie
          "osobneUdaje.blokacie.typKonta"
        )
      ),
      ...dataStylePohybKonto[3],
    },
    {
      value: capitalize(
        tui(
          // TODO: osobneUdaje.kontoAkredit VS osobneUdaje.blokacie
          "osobneUdaje.blokacie.poznamkaPohyb"
        )
      ),
      ...dataStylePohybKonto[4],
    },
  ];
  const [tableDataPohybKonto, setTableDataPohybKonto] = useState<TableData>({
    header: headerDataPohybKonto,
    body: [],
  });

  useEffect(() => {
    if (data.pohybyKonto.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      data.pohybyKonto.data.records
        // .sort((p1, p2) =>
        //   p1.name > p2.name)
        ?.forEach((pohybKonto) => {
          bodyData.push({
            row: [
              {
                value: pohybKonto.datumPohybuNaKonte
                  ? new Date(pohybKonto.datumPohybuNaKonte).zvjsToString()
                  : "",
                ...dataStylePohybKonto[0],
              },
              {
                value: pohybKonto.typPohybu ?? "",
                ...dataStylePohybKonto[1],
              },
              {
                value: pohybKonto.suma
                  ? pohybKonto.suma?.toFixed(2) + " EUR"
                  : "",
                ...dataStylePohybKonto[2],
              },
              {
                value: pohybKonto.typKonta ?? "",
                ...dataStylePohybKonto[3],
              },
              {
                value: pohybKonto.poznamka ?? "",
                ...dataStylePohybKonto[4],
              },
            ],
          });
        });
      setTableDataPohybKonto({
        ...tableDataPohybKonto,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const mySubmitHandlerAccount = (
    dateFrom: Date,
    dateTo: Date,
    dropDownValue: string
  ) => {
    console.log(dateFrom);
    console.log(dateTo);
    console.log(dropDownValue);
  };

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsSummaryTable
        header={
          t(
            "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.MoneyAndFees_SC_KIO_0360.Account.tabLabel"
          ) as string
        }
      >
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.kontoAkredit.stavKontaVreckove")}
          value={`${
            data.stavKontaAKreditu?.at(0)?.celkovyZostatokV?.toFixed(2) ?? 0
          } EUR`}
          isBorder={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.kontoAkredit.moznaSumaBufet")}
          value={`${
            data.stavKontaAKreditu?.at(0)?.moznaSumaNaNakup?.toFixed(2) ?? 0
          } EUR`}
          isBorder={true}
          hintText={tui("osobneUdaje.kontoAkredit.napovedaSumaBufet")}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.kontoAkredit.stavKontaOsobne")}
          value={`${
            data.stavKontaAKreditu?.at(0)?.celkovyZostatokO?.toFixed(2) ?? 0
          } EUR`}
          isBorder={true}
        />
        <ZvjsSummaryTableComponent
          label={t(
            "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.MoneyAndFees_SC_KIO_0360.Account.availableAccount"
          )}
          value={`${
            data.stavKontaAKreditu?.at(0)?.disponibilnyZostatok?.toFixed(2) ?? 0
          } EUR`}
          isBorder={true}
          hintText={tui("osobneUdaje.kontoAkredit.napovedaTelefonovanie")}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.kontoAkredit.stavKontaSpolu")}
          value={`${
            data.stavKontaAKreditu?.at(0)?.stavKontaSpolu?.toFixed(2) ?? 0
          } EUR`}
          isBorder={false}
          hintText={tui("osobneUdaje.kontoAkredit.napovedaCelkovaSuma")}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.kontoAkredit.sumaIneCerpanie")}
          value={`${
            data.stavKontaAKreditu?.at(0)?.moznaSumaNaCerpanie?.toFixed(2) ?? 0
          } EUR`}
          isBorder={false}
          hintText={tui("osobneUdaje.kontoAkredit.napovedaIneCerpanie")}
        >
          {/*TODO add request link here - but find correct request which is supposed to be here*/}
          {/*<Link to={""}>*/}
          {/*  {capitalize(*/}
          {/*    t(*/}
          {/*      "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.MoneyAndFees_SC_KIO_0360.Account.request"*/}
          {/*    )*/}
          {/*  )}*/}
          {/*</Link>*/}
        </ZvjsSummaryTableComponent>
      </ZvjsSummaryTable>

      <ZvjsSearchBar
        title={t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.MoneyAndFees_SC_KIO_0360.Account.searchMovement"
        )}
        type={ZvjsSearchBarVariant.VARIANT_B_DpDpCo}
        onSubmit={mySubmitHandlerAccount}
        comboboxLabel={capitalize(tui("osobneUdaje.kontoAkredit.pohyb"))}
        items={[
          { value: 3, text: "Tri" },
          { value: 5, text: "Pat" },
          { value: 10, text: "Desat" },
        ]}
      />
      <ZvjsTable
        data={tableDataPohybKonto}
        height={180}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default KontoTab;
