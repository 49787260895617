import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useUITranslation } from "../../store/context/translation-context";
import { capitalize } from "@mui/material/utils";
import { StringMap } from "../../store/context/envConfigContext";
import { Box } from "@mui/material";

interface ZvjsPDFViewerProps {
  file: { url: string; httpHeaders?: StringMap };
  openPage?: number;
}

const ZvjsPDFViewer: React.FC<ZvjsPDFViewerProps> = ({ file, openPage }) => {
  const [numPages, setNumPages] = useState(0);
  const { tui } = useUITranslation();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const getPdfContent = () => {
    if (openPage !== undefined && (numPages < openPage || openPage < 1)) {
      // if pdf viewer is supposed to display a page which does not exist in pdf, display error message
      return <p>{capitalize(tui("zadaná PDF stránka je mimo rozsah"))}</p>;
    } else {
      // otherwise display content of pdf (either whole pdf or selected page)
      if (openPage !== undefined) {
        return <Page loading={"Načítava sa súbor..."} pageNumber={openPage} />;
      } else {
        const pages = [];
        for (let i = 1; i <= numPages; i++) {
          pages.push(
            <Box sx={{ paddingBottom: 1 }} key={i}>
              <Page
                loading={i === 1 ? "Načítava sa súbor..." : ""}
                pageNumber={i}
              />
            </Box>
          );
        }
        return pages;
      }
    }
  };

  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <Document
        file={file} // https://www.npmjs.com/package/react-pdf
        options={{ workerSrc: "/pdf.worker.min.js" }} // https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
        loading={"Načítava sa súbor..."}
      >
        {numPages !== 0 ? getPdfContent() : <></>}
      </Document>
    </div>
  );
};

// Use React.memo to make sure pdf document is not loaded multiple times
// this behavior was occuring during page reload
export default React.memo(
  ZvjsPDFViewer,
  (prevProps, nextProps) =>
    prevProps.file.url === nextProps.file.url &&
    prevProps.openPage === nextProps.openPage
);
