import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../../../redux";
import { AppDispatch } from "../../../../../../../../store/redux";
import { Grid, Stack } from "@mui/material";
import { ZvjsAlert, ZvjsNestedDropDown } from "../../../../../../../components";
import {
  getAlertIconOfZvjsFlag,
  getSeverityOfZvjsFlag,
  NestedDropdownAnswerValueType,
  ZvjsLabelFlagTypes,
} from "../../../../../../redux/model";
import CustomQuestionProps from "../CustomQuestionProps";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";

const SC_KIO_051404_PlaceOfLocationQuestion: React.FC<CustomQuestionProps> = ({
  location,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { tuiz } = useUITranslation();

  console.log(
    `SC_KIO_051404 PLACE OF LOCATION QUESTION RERENDER ${location.join("-")}`
  );

  const questionData = useSelector(
    questionnaireRedux.selectors.getPlaceOfLocationQuestionDisplayData(
      location
    ),
    shallowEqual
  );

  const displayNoExpectedFreeCapacityWarning = useSelector(
    questionnaireRedux.selectors.displayNoExpectedFreeCapacityWarning404(
      location
    ),
    shallowEqual
  );

  const displayDifferentDegreeOfProtectionOrDifferentiationGroupWarning =
    useSelector(
      questionnaireRedux.selectors.displayDifferentDegreeOfProtectionOrDifferentiationGroupWarning404(
        location
      ),
      shallowEqual
    );

  const answerVal = useSelector(
    questionnaireRedux.selectors.getQuestionAnswerData(questionData.id)
  ) as undefined | NestedDropdownAnswerValueType[];

  const onChangeHandler = (
    checked: boolean,
    checkedValue: NestedDropdownAnswerValueType
  ) => {
    if (checked) {
      if (answerVal) {
        const newAnswersArray = Array.from(answerVal);
        newAnswersArray.push(checkedValue);
        dispatch(
          questionnaireRedux.actions.addNewAnswer(
            location,
            questionData.id,
            newAnswersArray
          )
        );
      } else {
        dispatch(
          questionnaireRedux.actions.addNewAnswer(location, questionData.id, [
            checkedValue,
          ])
        );
      }
    } else {
      if (answerVal) {
        const shouldRemoveAnswer = (
          oldAnswer: NestedDropdownAnswerValueType
        ) => {
          let shouldNotRemove = false;
          for (const key of Object.keys(oldAnswer)) {
            if (oldAnswer[key] !== checkedValue[key]) {
              shouldNotRemove = true;
            }
          }
          return shouldNotRemove;
        };

        let newAnswersArray = Array.from(answerVal);
        newAnswersArray = newAnswersArray.filter((oldAnswer) =>
          shouldRemoveAnswer(oldAnswer)
        );

        if (newAnswersArray.length > 0) {
          dispatch(
            questionnaireRedux.actions.addNewAnswer(
              location,
              questionData.id,
              newAnswersArray
            )
          );
        } else {
          dispatch(
            questionnaireRedux.sliceActions.removeAnswer({
              questionId: questionData.id,
            })
          );
        }
      }
    }
  };

  return (
    <Grid item xs>
      <Stack spacing={2}>
        <Grid item xs>
          <ZvjsNestedDropDown
            id={questionData.id}
            title={questionData.title}
            answerRequired={questionData.answerRequired}
            options={questionData.options}
            onChange={onChangeHandler}
            maxNumberSelect={2}
            selectedOptionsCount={questionData.selectedOptionsCount}
            renderValueText={questionData.selectedOptionsText}
          />
        </Grid>

        {displayDifferentDegreeOfProtectionOrDifferentiationGroupWarning && (
          <Grid item xs>
            <ZvjsAlert
              severity={getSeverityOfZvjsFlag(ZvjsLabelFlagTypes.WARNING)}
              iconMapping={getAlertIconOfZvjsFlag(ZvjsLabelFlagTypes.WARNING)}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: tuiz(
                    "ziadost.ziadostOumiestnenie.napovedaNedostatocnyStupenStrazenia"
                  ),
                }}
              />
            </ZvjsAlert>
          </Grid>
        )}

        {displayNoExpectedFreeCapacityWarning && (
          <Grid item xs>
            <ZvjsAlert
              severity={getSeverityOfZvjsFlag(ZvjsLabelFlagTypes.WARNING)}
              iconMapping={getAlertIconOfZvjsFlag(ZvjsLabelFlagTypes.WARNING)}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: tuiz(
                    "ziadost.ziadostOumiestnenie.napovedaUbytovaciaPlocha"
                  ),
                }}
              />
            </ZvjsAlert>
          </Grid>
        )}
      </Stack>
    </Grid>
  );
};

export default SC_KIO_051404_PlaceOfLocationQuestion;
