import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsItemTypes,
  ZvjsLabelFlagTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050302",
  basicInfo: [
    {
      text: "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.zakladneInfo",
    },
  ],
  dataCalculations: {
    CAN_ACCEPT_PACKAGE_WITH_PERSONAL_ITEMS: {
      calculateAt: Calculate.AT_INIT,
    },
    CAN_ACCEPT_PACKAGE_WITH_CLOTHES_AND_FOOTWEAR: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  items: [
    {
      id: "SC_KIO_050302_QUES_01",
      title: "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.labelDruhBalika",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      options: [
        {
          id: "E_KIO_04_01",
          label: "Balík s vecami osobnej potreby",
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "CAN_ACCEPT_PACKAGE_WITH_PERSONAL_ITEMS",
              conditionMetWhenValueIs: true,
            },
          ],
        },
        {
          id: "E_KIO_04_02",
          label: "Balík s vhodným odevom a obuvou",
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "CAN_ACCEPT_PACKAGE_WITH_CLOTHES_AND_FOOTWEAR",
              conditionMetWhenValueIs: true,
            },
          ],
        },
        {
          id: "E_KIO_04_03",
          label:
            "Balík obsahujúci študijné materiály, literatúru a veci na záujmovú a športovú činnosť",
        },
      ],
    },
    {
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.napovedaObmenaOdevu",
      flag: ZvjsLabelFlagTypes.WARNING,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050302_QUES_01",
          value: ["E_KIO_04_02"],
        },
      ],
    },
    // TODO considering how counter CisDruhBalika looks like this might be standard dropdown and not nested one (there are no level in counter) - decision also depends on in what form is szoo expecting the answer for this question
    // {
    //   id: "SC_KIO_050302_QUES_2",
    //   title: "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.labelPozadovaneVeci",
    //   type: ZvjsQuestionTypes.NESTED_DROPDOWN,
    //   isRequired: true,
    //   conditions: [
    //     {
    //       type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
    //       questionId: "SC_KIO_050302_QUES_01",
    //       hasValue: true,
    //     },
    //   ],
    //   options: [
    //     {
    //       id: "E_KIO_04_01_01",
    //       label: "iné veci osobnej potreby",
    //       conditions: [
    //         {
    //           type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
    //           questionId: "SC_KIO_050302_QUES_01",
    //           value: ["E_KIO_04_01"],
    //         },
    //       ],
    //     },
    //     {
    //       id: "E_KIO_04_02_01",
    //       label: "tričko",
    //       conditions: [
    //         {
    //           type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
    //           questionId: "SC_KIO_050302_QUES_01",
    //           value: ["E_KIO_04_02"],
    //         },
    //       ],
    //     },
    //     {
    //       id: "E_KIO_04_02_02",
    //       label: "topánky",
    //       conditions: [
    //         {
    //           type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
    //           questionId: "SC_KIO_050302_QUES_01",
    //           value: ["E_KIO_04_02"],
    //         },
    //       ],
    //     },
    //     {
    //       id: "E_KIO_04_02_03",
    //       label: "spodky",
    //       conditions: [
    //         {
    //           type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
    //           questionId: "SC_KIO_050302_QUES_01",
    //           value: ["E_KIO_04_02"],
    //         },
    //       ],
    //     },
    //     {
    //       id: "E_KIO_04_02_04",
    //       label: "zimná bunda, čiapka",
    //       conditions: [
    //         {
    //           type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
    //           questionId: "SC_KIO_050302_QUES_01",
    //           value: ["E_KIO_04_02"],
    //         },
    //       ],
    //     },
    //     {
    //       id: "E_KIO_04_03_01",
    //       label: "študijné materiály",
    //       conditions: [
    //         {
    //           type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
    //           questionId: "SC_KIO_050302_QUES_01",
    //           value: ["E_KIO_04_03"],
    //         },
    //       ],
    //     },
    //     {
    //       id: "E_KIO_04_03_02",
    //       label: "literatúra",
    //       conditions: [
    //         {
    //           type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
    //           questionId: "SC_KIO_050302_QUES_01",
    //           value: ["E_KIO_04_03"],
    //         },
    //       ],
    //     },
    //     {
    //       id: "E_KIO_04_03_03",
    //       label: "veci na záujmovú činnosť",
    //       conditions: [
    //         {
    //           type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
    //           questionId: "SC_KIO_050302_QUES_01",
    //           value: ["E_KIO_04_03"],
    //         },
    //       ],
    //     },
    //     {
    //       id: "E_KIO_04_03_04",
    //       label: "veci na športovú činnosť",
    //       conditions: [
    //         {
    //           type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
    //           questionId: "SC_KIO_050302_QUES_01",
    //           value: ["E_KIO_04_03"],
    //         },
    //       ],
    //       children: [
    //         {
    //           id: "E_KIO_04_03_04_01",
    //           label: "športový odev",
    //         },
    //         {
    //           id: "E_KIO_04_03_04_02",
    //           label: "športové pomôcky",
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      id: "SC_KIO_050302_QUES_3",
      title: "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.labelIneVeci",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050302_QUES_2",
          value: ["E_KIO_04_01_01"],
        },
      ],
    },
    {
      id: "SC_KIO_050302_QUES_4",
      title:
        "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.labelLiterarneDielo",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      hintText:
        "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.napovedaObsahBalikaKnihy",
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050302_QUES_2",
          value: ["E_KIO_04_03_02"],
        },
      ],
    },
    {
      id: "SC_KIO_050302_QUES_4",
      title:
        "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.labelZaujmovaCinnost",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      hintText:
        "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.napovedaObsahBalikaPocty",
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050302_QUES_2",
          value: ["E_KIO_04_03_03"],
        },
      ],
    },
    {
      id: "SC_KIO_050302_QUES_4",
      title:
        "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.labelSportovaPomocka",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050302_QUES_2",
          value: ["E_KIO_04_03_04_02"],
        },
      ],
    },
    {
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.napovedaObsahBalikaIneVeci",
      flag: ZvjsLabelFlagTypes.INFO,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050302_QUES_01",
          value: ["E_KIO_04_01"],
        },
      ],
    },
    {
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.napovedaBalikMimoEU",
      flag: ZvjsLabelFlagTypes.WARNING,
    },
    {
      id: "SC_KIO_050302_QUES_05",
      title:
        "ziadost.ziadostOvydaniePovoleniaNaPrijatieBalika.labelOdoslaniePO",
      type: ZvjsQuestionTypes.SINGLE_CHECKBOX,
    },
    {
      type: ZvjsItemTypes.SECTION,
      title: "Údaje právnickej osoby, ktorá má zaslať balík",
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050302_QUES_05",
          hasValue: true,
        },
      ],
      items: [
        {
          type: ZvjsItemTypes.LABEL,
          text: "Tu bude sekcia pre pravnicku osobu.",
          flag: ZvjsLabelFlagTypes.INFO,
        },
      ],
    },
  ],
};
