import { useEffect, useMemo, useState } from "react";
import { Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ReactComponent as DetailIcon } from "../../../assets/icons/Other/detail.svg";
import { useNavigate } from "react-router-dom";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router";
import { withLoader } from "../../../hoc/withLoader";
import {
  GeneralInformation_SC_KIO_0490,
  Dashboard_SC_KIO_0200,
  Noticeboard_SC_KIO_0400,
  ZvjsTable,
} from "../../../common";
import useFileApi from "../../../store/context/useFileApi";
import {
  ZvjsAudioPlayer,
  ZvjsButton,
  ZvjsImageViewer,
  ZvjsPDFViewer,
} from "../../../common/components";
import { FileApiCallHeaders, targetUiApi } from "../../../utils/targetApi";
import { Module } from "../../../utils/module";
import {
  WebDavFileType,
  WebDavItemType,
  WebDavList,
  webDavGetInfo,
} from "../../../utils/webDav";
import {
  AudioFileOutlined,
  FolderOutlined,
  InsertDriveFileOutlined,
  VideoFileOutlined,
} from "@mui/icons-material";
import ZvjsTextViewer from "../../../common/components/ZvjsTextViewer";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";

const INTRANET_PATH = "intranet/Kiosk";

export const PrehliadacSuborovLoader = async (
  section: string,
  filePath: string
) => {
  const { getDirectory } = useFileApi();

  const baseIntranetPath =
    section === Noticeboard_SC_KIO_0400.ConstitutionalOrder
      ? `${INTRANET_PATH}/UstavnyPoriadok`
      : section === Noticeboard_SC_KIO_0400.CulturalAndEducationalActivities
      ? `${INTRANET_PATH}/KulturnoOsvetoveAktivity`
      : section === Noticeboard_SC_KIO_0400.PacketsAndLetters
      ? `${INTRANET_PATH}/BalikyAListy`
      : section === Noticeboard_SC_KIO_0400.Telephony
      ? `${INTRANET_PATH}/Telefonovanie`
      : section === Noticeboard_SC_KIO_0400.DailyPressAndMagazines
      ? `${INTRANET_PATH}/DennaTlacACasopisy`
      : section === Noticeboard_SC_KIO_0400.ProtectionOfRights
      ? `${INTRANET_PATH}/OchranaPrav`
      : section === GeneralInformation_SC_KIO_0490.AudioRecordings
      ? `${INTRANET_PATH}/Audionahravky`
      : section === GeneralInformation_SC_KIO_0490.InformationalBrochures
      ? `${INTRANET_PATH}/InformacneBrozury`
      : section === GeneralInformation_SC_KIO_0490.InternalRegulationsZVJS
      ? `${INTRANET_PATH}/InternePredpisyZVJS`
      : `${INTRANET_PATH}/IneSubory`;

  const webDavXMLText = await getDirectory(baseIntranetPath + filePath);

  return defer({
    data: {
      section: section,
      baseIntranetPath: baseIntranetPath,
      filePath: filePath,
      webDavXMLText: webDavXMLText,
    },
  });
};

interface PrehliadacSuborovProps {
  data:
    | {
        section: string;
        baseIntranetPath: string;
        filePath: string;
        webDavXMLText: string | undefined;
      }
    | undefined;
}

const PrehliadacSuborov = (props: PrehliadacSuborovProps) => {
  const items = props.data;
  const { t, i18n } = useTranslation();
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const isSupportedContentType = (contentType: string): boolean => {
    return (
      contentType.startsWith("audio/") ||
      contentType.startsWith("image/") ||
      contentType === "text/plain" ||
      contentType === "application/pdf"
    );
  };

  const webDavInfo: WebDavList = useMemo(() => {
    if (items?.webDavXMLText) {
      const document = new DOMParser().parseFromString(
        items.webDavXMLText,
        "application/xml"
      );
      if (document) {
        return webDavGetInfo(document);
      }
    }
    return [];
  }, [items?.webDavXMLText]);

  const dataStyle: TableStyle[] = useMemo(
    () => [
      {
        align: "left",
      },
      {
        align: "right",
      },
    ],
    []
  );

  const headerData: Array<CellData> = [
    {
      value: capitalize(
        //t("spec.section.ProtectionOfRights_SC_KIO_0480.documentName")
        "Názov"
      ),
      ...dataStyle[0],
    },
    {
      value: capitalize(
        t("spec.section.ProtectionOfRights_SC_KIO_0480.action")
      ),
      ...dataStyle[1],
    },
  ];

  const [tableData, setTableData] = useState<TableData>({
    header: headerData,
    body: [],
  });

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  const getNavigationLink = (item: WebDavItemType): string => {
    const separator = props.data?.baseIntranetPath.replace("intranet", "");
    const idx = item.name.indexOf(separator!);
    const extractedPath = item.name.substring(idx + separator!.length);

    return (
      "/auth/" +
      Dashboard_SC_KIO_0200.NoticeBoard +
      "/" +
      Noticeboard_SC_KIO_0400.GeneralInformation +
      "/" +
      props.data?.section +
      "/" +
      encodeURIComponent(extractedPath)
    );
  };

  useEffect(() => {
    if (webDavInfo.length > 1) {
      const bodyData: Array<RowData> = new Array<RowData>();

      webDavInfo
        .slice(1)
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((item) => {
          bodyData.push({
            row: [
              {
                value: (
                  <Stack
                    direction={"row"}
                    spacing={theme.spacing(1)}
                    alignItems="center"
                  >
                    {item.type === WebDavFileType.directory ? (
                      <FolderOutlined />
                    ) : item.contentType.startsWith("audio") ? (
                      <AudioFileOutlined />
                    ) : item.contentType.startsWith("video") ? (
                      <VideoFileOutlined />
                    ) : (
                      <InsertDriveFileOutlined />
                    )}
                    <Typography>
                      {decodeURIComponent(
                        item.name.replace(
                          decodeURIComponent(webDavInfo[0].name),
                          ""
                        )
                      )}
                    </Typography>
                  </Stack>
                ),
                ...dataStyle[0],
              },
              {
                value: (
                  <ZvjsButton
                    text={capitalize(
                      t("spec.section.ProtectionOfRights_SC_KIO_0480.detail")
                    )}
                    zvjsVariant="secondaryAction"
                    startIcon={
                      <DetailIcon
                        style={{ fill: "white", width: 28, height: 28 }}
                      />
                    }
                    onClick={() => {
                      navigate(getNavigationLink(item));
                    }}
                    disabled={!isSupportedContentType(item.contentType)}
                  />
                ),
                ...dataStyle[1],
              },
            ],
          });
        });

      setTableData((previousState) => ({
        ...previousState,
        body: bodyData,
      }));
    }
  }, [webDavInfo, dataStyle, navigate, t, i18n.language]);

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Stack>
            <Typography variant={"h1"}>
              {(props.data?.section ===
                Noticeboard_SC_KIO_0400.ConstitutionalOrder ||
                props.data?.section ===
                  Noticeboard_SC_KIO_0400.CulturalAndEducationalActivities ||
                props.data?.section ===
                  Noticeboard_SC_KIO_0400.PacketsAndLetters ||
                props.data?.section === Noticeboard_SC_KIO_0400.Telephony ||
                props.data?.section ===
                  Noticeboard_SC_KIO_0400.DailyPressAndMagazines ||
                props.data?.section ===
                  Noticeboard_SC_KIO_0400.ProtectionOfRights) &&
                capitalize(
                  t(
                    `spec.section.Noticeboard_SC_KIO_0400.${props.data?.section}`
                  )
                )}
              {(props.data?.section ===
                GeneralInformation_SC_KIO_0490.AudioRecordings ||
                props.data?.section ===
                  GeneralInformation_SC_KIO_0490.InformationalBrochures ||
                props.data?.section ===
                  GeneralInformation_SC_KIO_0490.InternalRegulationsZVJS ||
                props.data?.section ===
                  GeneralInformation_SC_KIO_0490.OtherFiles) &&
                capitalize(
                  t(
                    `spec.section.GeneralInformation_SC_KIO_0490.${props.data?.section}`
                  )
                )}
            </Typography>
            {props.data?.filePath.length !== 1 && (
              <Typography variant="h4">
                {props.data?.filePath.substring(1, props.data.filePath.length)}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Grid>

      {(webDavInfo.length > 1 ||
        (webDavInfo.length === 1 &&
          webDavInfo[0].type === WebDavFileType.directory)) && (
        <ZvjsTable
          data={tableData}
          // TODO refactor this in more clever way
          height={"calc(100vh - 450px)"}
          variant={ZvjsTableVariant.NORMAL}
        />
      )}
      {webDavInfo.length === 1 &&
        webDavInfo[0].contentType.startsWith("audio/") && (
          <ZvjsAudioPlayer
            file={{
              contentType: webDavInfo[0].contentType ?? "",
              url: targetUiApi(
                `/${Module.INTERNAL}/api/v1/File/${props.data?.baseIntranetPath}${props.data?.filePath}`
              ),
              httpHeaders: FileApiCallHeaders,
            }}
          />
        )}
      {webDavInfo.length === 1 &&
        webDavInfo[0].contentType.startsWith("image/") && (
          <ZvjsImageViewer
            file={{
              contentType: webDavInfo[0].contentType ?? "",
              url: targetUiApi(
                `/${Module.INTERNAL}/api/v1/File/${props.data?.baseIntranetPath}${props.data?.filePath}`
              ),
              httpHeaders: FileApiCallHeaders,
            }}
          />
        )}
      {webDavInfo.length === 1 &&
        webDavInfo[0].contentType === "text/plain" && (
          <ZvjsTextViewer
            file={{
              contentType: webDavInfo[0].contentType ?? "",
              url: targetUiApi(
                `/${Module.INTERNAL}/api/v1/File/${props.data?.baseIntranetPath}${props.data?.filePath}`
              ),
              httpHeaders: FileApiCallHeaders,
            }}
          />
        )}
      {webDavInfo.length === 1 &&
        webDavInfo[0].contentType === "application/pdf" && (
          <ZvjsPDFViewer
            file={{
              url: targetUiApi(
                `/${Module.INTERNAL}/api/v1/File/${props.data?.baseIntranetPath}${props.data?.filePath}`
              ),
              httpHeaders: FileApiCallHeaders,
            }}
          />
        )}
    </Grid>
  );
};

export default withLoader(PrehliadacSuborov);
