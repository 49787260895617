import React from "react";
import {
  Box,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  InputProps,
  Stack,
  useTheme,
} from "@mui/material";
import { ZVJS_COLORS } from "../../theme/zvjs_theme";
import { useUITranslation } from "../../store/context/translation-context";
import { ZvjsHintModalFragment } from "./ZvjsModal";
import { ZvjsAlert } from "./index";
import {
  getSeverityOfValidationFlag,
  ZvjsValidationFlagTypes,
} from "../request/redux/model";
import { ChangeHandler, RefCallBack } from "react-hook-form";

interface ZvjsInputProps extends InputProps {
  label: string;
  hintText?: string;
  hintColor?: string;
  hintSize?: number;
  answerRequired?: boolean;
  error?: boolean;
  validationErrorMessage?: string;
  validationFlagType?: ZvjsValidationFlagTypes;
  textField?: boolean;
  // use to register react-hook-form form field
  register?: {
    onBlur: ChangeHandler;
    ref: RefCallBack;
    min?: string | number;
    onChange: ChangeHandler;
    max?: string | number;
    minLength?: number;
    name: string;
    pattern?: string;
    disabled?: boolean;
    maxLength?: number;
    required?: boolean;
  };
}

const ZvjsTextField: React.FC<ZvjsInputProps> = ({
  label,
  id,
  hintText,
  hintColor,
  hintSize,
  answerRequired,
  error,
  validationErrorMessage,
  validationFlagType = ZvjsValidationFlagTypes.ERROR,
  textField,
  register,
  ...rest
}) => {
  const theme = useTheme();
  const { tui } = useUITranslation();

  return (
    <Box>
      <FormControl fullWidth variant="standard">
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <InputLabel
            shrink
            htmlFor={id}
            sx={{
              // disable transform of input label (otherwise it cause font size change)
              transform: "none",
              color: ZVJS_COLORS.BLACK,
              position: "relative",
              textWrap: "wrap",
            }}
          >
            {label}
            {answerRequired && "*"}
          </InputLabel>
          {hintText && (
            <ZvjsHintModalFragment
              title={tui("nápoveda")}
              text={hintText}
              color={hintColor}
              size={hintSize}
            />
          )}
        </Stack>
        <InputBase
          sx={{
            "label + &": {
              marginTop: "24px",
            },
            "& .MuiInputBase-input": {
              borderRadius: "5px",
              padding: "0 12px 0 12px",
              height: "41px",
              background: ZVJS_COLORS.WHITE,
              border: `2px solid ${
                error ? theme.palette.error.main : ZVJS_COLORS.BLACK
              }`,
              "&:focus": {
                borderColor: theme.palette.primary.main,
              },
            },
          }}
          name={id}
          id={id}
          {...rest}
          {...register}
        />
      </FormControl>
      {error === true && validationErrorMessage && validationFlagType && (
        <Grid item xs>
          <ZvjsAlert
            severity={getSeverityOfValidationFlag(validationFlagType)}
            style={{ marginTop: "1rem" }}
          >
            {validationErrorMessage}
          </ZvjsAlert>
        </Grid>
      )}
    </Box>
  );
};

export default ZvjsTextField;
