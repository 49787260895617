import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";
import { useNavigate } from "react-router-dom";
import { ZvjsSummaryTable } from "../../../common";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ZvjsSummaryTableComponent } from "../../../common/components/ZvjsSummaryTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router";
import { withLoader } from "../../../hoc/withLoader";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { PromiseElement } from "../../../utils/types";

export const loadData = async (id: string) => {
  const { SHARE_INT_Post } = await API_Clients();

  const data = await Promise.all([
    SHARE_INT_Post("/api/interfaces/Epvvvt/ListPoucenia", {
      body: {
        poucenieId: {
          eq: Number(id),
        },
      },
    }),
  ]);

  return { poucenia: data[0] };
};

export const PoucenieDetailLoader = async (id: string) => {
  return defer({
    data: loadData(id),
  });
};

interface PoucenieDetailProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const PoucenieDetail = (props: PoucenieDetailProps) => {
  const loaderData = props.data;
  const { t } = useTranslation();
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(
                t(
                  "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Guidances_SC_KIO_01000.guidanceDetail"
                )
              )}
            </Typography>
          </Stack>
        </Grid>

        <Stack direction="column" mt={3} spacing={2}>
          <ZvjsSummaryTable>
            <ZvjsSummaryTableComponent
              label={t(
                "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Guidances_SC_KIO_01000.guidanceDate"
              )}
              value={
                loaderData.poucenia.data?.at(0)?.datumPouceniaKlienta
                  ? new Date(
                      loaderData.poucenia.data?.at(0)?.datumPouceniaKlienta ??
                        new Date()
                    ).zvjsToString()
                  : ""
              }
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={t(
                "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Guidances_SC_KIO_01000.guidanceType"
              )}
              value={loaderData.poucenia.data?.at(0)?.poucenieFormaNazov ?? ""}
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={t(
                "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Guidances_SC_KIO_01000.guidanceContent"
              )}
              value={loaderData.poucenia.data?.at(0)?.poucenieText ?? ""}
              isBorder={true}
              fullWidth={true}
            />
          </ZvjsSummaryTable>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default withLoader(PoucenieDetail);
