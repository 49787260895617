import { ZvjsNumpad } from "../../common";
import { LoginStepProps } from "../Login";

const LoginStep2: React.FC<LoginStepProps> = ({ onLoginStepData }) => {
  const onCheck = async (pin: string) => {
    onLoginStepData(2, pin);
  };

  return <ZvjsNumpad pinLength={4} onCheck={onCheck} />;
};

export default LoginStep2;
