import React from "react";
import { ZvjsSummaryTable } from "../../../../common";
import { ZvjsSummaryTableComponent } from "../../../../common/components/ZvjsSummaryTable";
import { Stack } from "@mui/material";
import { useUITranslation } from "../../../../store/context/translation-context";
import { ZakladneUdajeKlientaProps } from "../ZakladneUdajeKlienta";

const PrechodnyPobytTab: React.FC<ZakladneUdajeKlientaProps> = ({ data }) => {
  const { tui } = useUITranslation();

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsSummaryTable
        header={tui("osobneUdaje.AdresaPrechodnehoBydliska.adresa")}
      >
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.uKoho")}
          value={data.prechodnePobyty?.uKoho ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.ulica")}
          value={data.prechodnePobyty?.ulica ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.cisloSupisne")}
          value={data.prechodnePobyty?.supisneCislo ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.cisloOrientacne")}
          value={data.prechodnePobyty?.orientacneCislo ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.obec")}
          value={data.prechodnePobyty?.obecNazov ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.psc")}
          value={data.prechodnePobyty?.psc ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.okres")}
          value={data.prechodnePobyty?.okresNazov ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.kraj")}
          value={data.prechodnePobyty?.krajNazov ?? ""}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.AdresaPrechodnehoBydliska.stat")}
          value={data.prechodnePobyty?.statNazov ?? ""}
          isBorder={false}
          fullWidth={true}
        />
      </ZvjsSummaryTable>
    </Stack>
  );
};

export default PrechodnyPobytTab;
