import { ZakladneUdajeOTresteProps } from "../ZakladneUdajeOTreste";
import React, { useEffect, useState } from "react";
import { ZvjsButton, ZvjsTable } from "../../../../common";
import { Stack } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DetailIcon } from "../../../../assets/icons/Other/detail.svg";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";

const TrestyAVazbyTab: React.FC<ZakladneUdajeOTresteProps> = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dataStyleTrest: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "center",
      width: 90,
    },
    {
      align: "center",
      width: 90,
    },
    {
      align: "center",
      width: 90,
    },
    {
      align: "right",
      width: 150,
    },
  ];

  const headerDataTrest: Array<CellData> = [
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.PunishmentsAndDetentions.fileReference"
        )
      ),
      ...dataStyleTrest[0],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.PunishmentsAndDetentions.years"
        )
      ),
      ...dataStyleTrest[1],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.PunishmentsAndDetentions.months"
        )
      ),
      ...dataStyleTrest[2],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.PunishmentsAndDetentions.days"
        )
      ),
      ...dataStyleTrest[3],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.PunishmentsAndDetentions.action"
        )
      ),
      ...dataStyleTrest[4],
    },
  ];

  const [tableDataTrest, setTableDataTrest] = useState<TableData>({
    label: capitalize(
      t(
        "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.PunishmentsAndDetentions.punishmentsList"
      )
    ),
    header: headerDataTrest,
    body: [],
  });

  const dataStyleVazba: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "center",
      width: 90,
    },
    {
      align: "center",
      width: 90,
    },
    {
      align: "center",
      width: 90,
    },
    {
      align: "right",
      width: 150,
    },
  ];

  const headerDataVazba: Array<CellData> = [
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.PunishmentsAndDetentions.fileReference"
        )
      ),
      ...dataStyleVazba[0],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.PunishmentsAndDetentions.years"
        )
      ),
      ...dataStyleVazba[1],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.PunishmentsAndDetentions.months"
        )
      ),
      ...dataStyleVazba[2],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.PunishmentsAndDetentions.days"
        )
      ),
      ...dataStyleVazba[3],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.PunishmentsAndDetentions.action"
        )
      ),
      ...dataStyleVazba[4],
    },
  ];

  const [tableDataDetention, setTableDataDetention] = useState<TableData>({
    label: capitalize(
      t(
        "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.PunishmentsAndDetentions.detentionsList"
      )
    ),
    header: headerDataVazba,
    body: [],
  });

  useEffect(() => {
    if (data.trestyAVazby.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      data.trestyAVazby.data.records
        ?.filter((x) => x.isVazba === false)
        // .sort((p1, p2) =>
        //   p1.name > p2.name)
        .forEach((trest) => {
          bodyData.push({
            row: [
              {
                value: trest.spisovaZnacka ?? "",
                ...dataStyleTrest[0],
              },
              {
                value: trest.roky ?? "",
                ...dataStyleTrest[1],
              },
              {
                value: trest.mesiace ?? "",
                ...dataStyleTrest[2],
              },
              {
                value: trest.dni ?? "",
                ...dataStyleTrest[3],
              },
              {
                value: (
                  <ZvjsButton
                    text={capitalize(
                      t(
                        "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.PunishmentsAndDetentions.detail"
                      )
                    )}
                    zvjsVariant="secondaryAction"
                    startIcon={
                      <DetailIcon
                        style={{ fill: "white", width: 28, height: 28 }}
                      />
                    }
                    onClick={() => {
                      navigate(`Punishment/${trest.id}`);
                    }}
                  />
                ),
                ...dataStyleTrest[4],
              },
            ],
          });
        });
      setTableDataTrest({
        ...tableDataTrest,
        body: bodyData,
      });
    }

    if (data.trestyAVazby.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      data.trestyAVazby.data.records
        ?.filter((x) => x.isVazba === true)
        // .sort((p1, p2) =>
        //   p1.name > p2.name)
        .forEach((vazba) => {
          bodyData.push({
            row: [
              {
                value: vazba.spisovaZnacka ?? "",
                ...dataStyleVazba[0],
              },
              {
                value: vazba.roky ?? "",
                ...dataStyleVazba[1],
              },
              {
                value: vazba.mesiace ?? "",
                ...dataStyleVazba[2],
              },
              {
                value: vazba.dni ?? "",
                ...dataStyleVazba[3],
              },
              {
                value: (
                  <ZvjsButton
                    text={capitalize(
                      t(
                        "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.PunishmentsAndDetentions.detail"
                      )
                    )}
                    zvjsVariant="secondaryAction"
                    startIcon={
                      <DetailIcon
                        style={{ fill: "white", width: 28, height: 28 }}
                      />
                    }
                    onClick={() => {
                      navigate(`Detention/${vazba.id}`);
                    }}
                  />
                ),
                ...dataStyleVazba[4],
              },
            ],
          });
        });
      setTableDataDetention({
        ...tableDataDetention,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Stack direction="column" mt={1} spacing={3}>
      <ZvjsTable
        data={tableDataTrest}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
      <ZvjsTable
        data={tableDataDetention}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default TrestyAVazbyTab;
