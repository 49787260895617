import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { ZVJS_COLORS } from "../theme/zvjs_theme";
import { getUuid } from "../utils/helpers";
import TableCell from "@mui/material/TableCell";
import AppActivityGuard from "../utils/AppActivityGuard";
import { ZvjsPullToRefresh } from "../common";
import { API_Clients } from "../store/context/dataApi/Data";

const SynchronizationStatusPage = () => {
  const theme = useTheme();

  // TODO
  const syncIsRunning = false;

  // TODO
  // const syncStatusText = "Údaje z tabletu sa aktualizujú.";
  // const syncStatusText =
  //   "Pre tablet ešte nebola definovaná ubytovacia jednotka v Manažmente procesov.";
  // const syncStatusText =
  //   "Aktualizácia tabletu bola ukončená. Aktualizovaná ubytovacia jednotka:";

  // TODO
  const selectedCell = {
    objekt: "Ilava",
    sektor: 5,
    blok: 3,
    typUbytovacejJednotky: "X",
    cislo: 10,
  };

  const isCellSelected = () => {
    // TODO
    return true;
  };

  const getDeviceUuid = () => {
    let tabletUUID = localStorage.getItem("tabletUUID");
    if (!tabletUUID) {
      tabletUUID = getUuid();
      localStorage.setItem("tabletUUID", tabletUUID);
    }

    return tabletUUID;
  };

  // TODO
  const showStartSynchroAgainButton = true;

  const invokeSynchronization = async () => {
    const { EOO_Post, ZVJS_MP_Post } = await API_Clients();

    const tabletData = await ZVJS_MP_Post("/administracia-tabletu", {
      body: {
        datumPripojenia: new Date().toISOString(),
        kioskTabletId: getDeviceUuid(),
      },
    });

    console.debug("Tablet data:");
    console.debug(tabletData.data);

    if (tabletData.data?.ustavId === undefined) {
      const errorText =
        "Pre tablet ešte nebol definovaný ústav v Manažmente procesov.";
      setSyncStatusText(errorText);
      console.error(errorText);
      return;
    }

    if (tabletData.data?.ustavId === null) {
      const errorText =
        "Pre tablet ešte nebola definovaná ubytovacia jednotka/y v Manažmente procesov.";
      setSyncStatusText(errorText);
      console.error(errorText);
      return;
    }

    console.info(
      `Synchronizacia: ustavId[${
        tabletData.data?.ustavId
      }], ubytovaciaJednotkaList[${tabletData.data?.ubytovaciaJednotkaList?.join(
        ","
      )}]`
    );

    const resultArray = tabletData.data?.ubytovaciaJednotkaList
      ? await Promise.all(
          tabletData.data?.ubytovaciaJednotkaList?.map((ubytovaciaJednotka) =>
            EOO_Post("/api/Klient/List", {
              body: {
                filters: [
                  {
                    ustavID: tabletData.data.ustavId,
                    ubytovaciPriestorId: ubytovaciaJednotka,
                  },
                ],
              },
            })
          )
        )
      : [];

    const klientIds: number[] = resultArray
      .flat(1)
      .map((result) => result.data?.records)
      .flat(1)
      .map((record) => record?.id)
      .filter((id): id is number => !!id);

    console.log(klientIds);
    alert(klientIds.join(","));

    navigator.serviceWorker.controller?.postMessage({
      type: "SYNCHRONIZE_DATA",
      klientIds: klientIds, //[1, 2, 6],
      ustavZvjsId: tabletData.data?.ustavId, // 9
    });
  };

  const [syncStatusText, setSyncStatusText] = useState("-");

  const handleMessage = (event: MessageEvent) => {
    if (event.data && event.data.type === "SYNCHRONIZATION_STATUS") {
      setSyncStatusText(event.data.message);
    }
  };

  const channel = new BroadcastChannel("synchronization_status");
  channel.onmessage = handleMessage;

  useEffect(() => {
    // start synchronization on a synchronization status page load
    invokeSynchronization();
  }, []);

  return (
    <AppActivityGuard>
      <ZvjsPullToRefresh>
        <Grid container bgcolor={ZVJS_COLORS.GREY}>
          <Grid item xs={12} mt={theme.spacing(3)}>
            <Grid container justifyContent={"center"}>
              <Grid item sx={{ maxWidth: "50%" }}>
                <Paper
                  elevation={1}
                  sx={{ padding: theme.spacing(1), textAlign: "center" }}
                >
                  <Typography variant={"h4"}>Administrácia</Typography>
                  <Typography variant={"body2"}>{getDeviceUuid()}</Typography>
                  <Divider
                    sx={{
                      marginTop: theme.spacing(1),
                      marginBottom: syncIsRunning ? 0 : theme.spacing(1),
                    }}
                  />
                  {syncIsRunning && (
                    <LinearProgress
                      sx={{ height: 2, marginBottom: theme.spacing(1) }}
                    />
                  )}
                  <Typography variant={"body1"}>{syncStatusText}</Typography>
                  <Table
                    size={"small"}
                    sx={{
                      marginRight: theme.spacing(3),
                      marginLeft: theme.spacing(3),
                      width: "calc(100% - 48px)",
                    }}
                  >
                    {isCellSelected() && (
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {"Objekt"}
                          </TableCell>
                          <TableCell align="right">
                            {selectedCell.objekt}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {"Sektor"}
                          </TableCell>
                          <TableCell align="right">
                            {selectedCell.sektor}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {"Blok"}
                          </TableCell>
                          <TableCell align="right">
                            {selectedCell.blok}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {"Typ ubytovacej jednotky"}
                          </TableCell>
                          <TableCell align="right">
                            {selectedCell.typUbytovacejJednotky}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {"Číslo"}
                          </TableCell>
                          <TableCell align="right">
                            {selectedCell.cislo}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                  {showStartSynchroAgainButton && (
                    <button
                      onClick={() => {
                        invokeSynchronization();
                      }}
                      style={{
                        backgroundColor: ZVJS_COLORS.BLUE,
                        color: ZVJS_COLORS.WHITE,
                        border: "none",
                        padding: "10px 20px",
                        borderRadius: "5px",
                      }}
                    >
                      Spustiť aktualizáciu znova
                    </button>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ZvjsPullToRefresh>
    </AppActivityGuard>
  );
};

export default SynchronizationStatusPage;
