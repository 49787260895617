import {
  Calculate,
  LabelType,
  ZvjsItemTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050207",
  basicInfo: [
    {
      text: "ziadost.ziadostOvyplateniePPmimoriadneVolno.zakladneInfo",
    },
  ],
  isAvailableToUser: "SC_KIO_050207_isAvailableToUser",
  dataCalculations: {
    AVAILABLE_ACCOUNT_AMOUNT: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  items: [
    {
      type: ZvjsItemTypes.SINGLE_VALUE_LABEL,
      text: "ziadost.ziadostOvyplateniePPmimoriadneVolno.labelDisponibilnyStavKonta",
      value: "AVAILABLE_ACCOUNT_AMOUNT",
      valueType: LabelType.EURO,
    },
    {
      id: "pozadovanaSumaNaVyplatenie",
      title: "ziadost.ziadostOvyplateniePPmimoriadneVolno.labelPozadovanaSuma",
      type: ZvjsQuestionTypes.TEXT,
      inputType: "number",
      isRequired: true,
      validations: ["SC_KIO_050207_VALIDATE_REQUESTED_AMOUNT"],
    },
  ],
};
