import { paths as cis_v1_paths } from "../../../api/types/cis_v1";
import { paths as edpoo_v1_paths } from "../../../api/types/edpoo_v1";
import { paths as eoo_v1_paths } from "../../../api/types/eoo_v1";
import { paths as epvvvt_v1_paths } from "../../../api/types/epvvvt_v1";
import { paths as ezoo_v1_paths } from "../../../api/types/ezoo_v1";
import { paths as feoo_v1_paths } from "../../../api/types/feoo_v1";
import { paths as hasura_openapi_v1_paths } from "../../../api/types/hasura-openapi_v1";
import { paths as rezervacia_knih_openapi_v1_paths } from "../../../api/types/rezervacia-knih-openapi_v1";
import { paths as share_int_v1_paths } from "../../../api/types/share-int_v1";
import { paths as sidkoo_v1_paths } from "../../../api/types/sidkoo_v1";
import { paths as szoo_v1_paths } from "../../../api/types/szoo_v1";
import { paths as zvjs_mp_app_api_openapi_v1_paths } from "../../../api/types/zvjs-mp-app-api.openapi_v1";

import { Module } from "../../../utils/module";
import { BaseApiCallHeaders } from "../../../utils/targetApi";

import { dbPromise } from "../../../services/database";
import { KEY_SVC_URL } from "../envConfigContext";

import createClient from "openapi-fetch";
import { PromiseElement } from "../../../utils/types";

const host = async (): Promise<string | undefined> => {
  const database = await dbPromise;
  return database.get("EnvironmentVariables", KEY_SVC_URL);
};

export const API_Clients = async () => {
  const { GET: CIS_Get, POST: CIS_Post } = createClient<cis_v1_paths>({
    baseUrl: await host(),
    headers: { ...BaseApiCallHeaders, "X-Zvjs-Module": Module.CIS },
  });

  const { GET: EDPOO_Get, POST: EDPOO_Post } = createClient<edpoo_v1_paths>({
    baseUrl: await host(),
    headers: { ...BaseApiCallHeaders, "X-Zvjs-Module": Module.EDPOO },
  });

  const { GET: EOO_Get, POST: EOO_Post } = createClient<eoo_v1_paths>({
    baseUrl: await host(),
    headers: { ...BaseApiCallHeaders, "X-Zvjs-Module": Module.EOO },
  });

  const { GET: EPVVVT_Get, POST: EPVVVT_Post } = createClient<epvvvt_v1_paths>({
    baseUrl: await host(),
    headers: { ...BaseApiCallHeaders, "X-Zvjs-Module": Module.EPVVVT },
  });

  const { GET: EZOO_Get, POST: EZOO_Post } = createClient<ezoo_v1_paths>({
    baseUrl: await host(),
    headers: { ...BaseApiCallHeaders, "X-Zvjs-Module": Module.EZOO },
  });

  const { GET: FEOO_Get, POST: FEOO_Post } = createClient<feoo_v1_paths>({
    baseUrl: await host(),
    headers: { ...BaseApiCallHeaders, "X-Zvjs-Module": Module.FEOO },
  });

  const { POST: HASURA_Post } = createClient<hasura_openapi_v1_paths>({
    baseUrl: await host(),
    headers: { ...BaseApiCallHeaders, "X-Zvjs-Module": Module.HASURA },
  });

  const { GET: REZERVACIA_KNIH_Get, POST: REZERVACIA_KNIH_Post } =
    createClient<rezervacia_knih_openapi_v1_paths>({
      baseUrl: await host(),
      headers: {
        ...BaseApiCallHeaders,
        "X-Zvjs-Module": Module.REZERVACIA_KNIH,
      },
    });

  const { GET: SHARE_INT_Get, POST: SHARE_INT_Post } =
    createClient<share_int_v1_paths>({
      baseUrl: await host(),
      headers: { ...BaseApiCallHeaders, "X-Zvjs-Module": Module.SHARE_INT },
    });

  const { GET: SIDKOO_Get, POST: SIDKOO_Post } = createClient<sidkoo_v1_paths>({
    baseUrl: await host(),
    headers: { ...BaseApiCallHeaders, "X-Zvjs-Module": Module.SIDKOO },
  });

  const { GET: SZOO_Get, POST: SZOO_Post } = createClient<szoo_v1_paths>({
    baseUrl: await host(),
    headers: { ...BaseApiCallHeaders, "X-Zvjs-Module": Module.SZOO },
  });

  const { GET: SZOO_Local_Get, POST: SZOO_Local_Post } =
    createClient<szoo_v1_paths>({
      baseUrl: await host(),
      headers: {
        ...BaseApiCallHeaders,
        "X-Zvjs-Module": Module.SZOO,
        "X-Zvjs-Szoo": "local",
      },
    });

  const { GET: ZVJS_MP_Get, POST: ZVJS_MP_Post } =
    createClient<zvjs_mp_app_api_openapi_v1_paths>({
      baseUrl: await host(),
      headers: {
        ...BaseApiCallHeaders,
        "X-Zvjs-Module": Module.ZVJS_MP,
      },
    });

  return {
    CIS_Get,
    CIS_Post,
    EDPOO_Get,
    EDPOO_Post,
    EOO_Get,
    EOO_Post,
    EPVVVT_Get,
    EPVVVT_Post,
    EZOO_Get,
    EZOO_Post,
    FEOO_Get,
    FEOO_Post,
    HASURA_Post,
    REZERVACIA_KNIH_Get,
    REZERVACIA_KNIH_Post,
    SHARE_INT_Get,
    SHARE_INT_Post,
    SIDKOO_Get,
    SIDKOO_Post,
    SZOO_Get,
    SZOO_Post,
    SZOO_Local_Get,
    SZOO_Local_Post,
    ZVJS_MP_Get,
    ZVJS_MP_Post,
  };
};

/**
 * Helper medhod to retrieve APIs result types
 * DON'T CALL THIS METHOD
 */
const internalDataResultTypes = async () => {
  const {
    CIS_Get,
    CIS_Post,
    EDPOO_Get,
    EDPOO_Post,
    EOO_Get,
    EOO_Post,
    EPVVVT_Get,
    EPVVVT_Post,
    EZOO_Get,
    EZOO_Post,
    FEOO_Get,
    FEOO_Post,
    SHARE_INT_Get,
    SHARE_INT_Post,
    SIDKOO_Get,
    SIDKOO_Post,
    SZOO_Get,
    SZOO_Post,
    SZOO_Local_Get,
    SZOO_Local_Post,
    ZVJS_MP_Get,
    ZVJS_MP_Post,
  } = await API_Clients();

  return {
    // EDPOO
    "/edpoo/api/Klient/Get": await EDPOO_Post("/api/Klient/Get"),
    "/edpoo/api/OsobneVeci/ListElektrospotrebic": await EDPOO_Post(
      "/api/OsobneVeci/ListElektrospotrebic"
    ),
    "/edpoo/api/OsobneVeci/ListOsobnaVec": await EDPOO_Post(
      "/api/OsobneVeci/ListOsobnaVec"
    ),
    "/edpoo/api/Stravovanie/List": await EDPOO_Post("/api/Stravovanie/List"),

    // EOO
    "/eoo/api/Ciselnik/DruhAdresy": await EOO_Get("/api/Ciselnik/DruhAdresy"),
    "/eoo/api/DalsieOsobneUdaje/ListPobytKlienta": await EOO_Post(
      "/api/DalsieOsobneUdaje/ListPobytKlienta"
    ),
    "/eoo/api/DalsieOsobneUdaje/VazbyATrestyKlienta": await EOO_Post(
      "/api/DalsieOsobneUdaje/VazbyATrestyKlienta"
    ),
    "/eoo/api/FyzickaOsoba/DetailData": await EOO_Get(
      "/api/FyzickaOsoba/DetailData"
    ),
    "/eoo/api/FyzickaOsoba/ListAdresaOsoba": await EOO_Post(
      "/api/FyzickaOsoba/ListAdresaOsoba"
    ),
    "/eoo/api/Klient/DetailData": await EOO_Get("/api/Klient/DetailData"),
    "/eoo/api/Klient/DetailZakladneUdajeKlientaData": await EOO_Get(
      "/api/Klient/DetailZakladneUdajeKlientaData"
    ),
    "/eoo/api/Klient/ListTrestneKonanieData": await EOO_Post(
      "/api/Klient/ListTrestneKonanieData"
    ),
    "/eoo/api/Klient/ListNepravoplatnyTrestOosData": await EOO_Post(
      "/api/Klient/ListNepravoplatnyTrestOosData"
    ),
    "/eoo/api/OchranneOpatrenie/ListOchranneOpatrenieData": await EOO_Post(
      "/api/OchranneOpatrenie/ListOchranneOpatrenieData"
    ),
    "/eoo/api/PravniZastupcoviaKlienta/PravnyZastupcaKlientaList":
      await EOO_Post("/api/PravniZastupcoviaKlienta/PravnyZastupcaKlientaList"),
    "/eoo/api/Rozhodnutie/ListRozhodnutieData": await EOO_Post(
      "/api/Rozhodnutie/ListRozhodnutieData"
    ),
    "/eoo/api/VazbyATresty/DetailTDVData": await EOO_Post(
      "/api/VazbyATresty/DetailTDVData"
    ),
    "/eoo/api/VazbyATresty/DetailTrestData": await EOO_Post(
      "/api/VazbyATresty/DetailTrestData"
    ),
    "/eoo/api/VazbyATresty/DetailVazbaData": await EOO_Post(
      "/api/VazbyATresty/DetailVazbaData"
    ),
    "/eoo/api/VazbyATresty/ListDovodovyParagrafVazbyData": await EOO_Post(
      "/api/VazbyATresty/ListDovodovyParagrafVazbyData"
    ),
    "/eoo/api/VazbyATresty/ListTrestData": await EOO_Post(
      "/api/VazbyATresty/ListTrestData"
    ),
    "/eoo/api/VazbyATresty/ListVazbaAleboTrestKlientaData": await EOO_Post(
      "/api/VazbyATresty/ListVazbaAleboTrestKlientaData"
    ),

    // EPVVVT
    "/epvvvt/api/interfaces/Ezoo/ListPracovneZaradenia": await EPVVVT_Post(
      "/api/interfaces/Ezoo/ListPracovneZaradenia"
    ),
    "/epvvvt/api/Navsteva/visits/completed-visits": await EPVVVT_Get(
      "/api/Navsteva/visits/completed-visits"
    ),
    "/epvvvt/api/programzaobchadzania/pz-ciel-klienta": await EPVVVT_Get(
      "/api/programzaobchadzania/pz-ciel-klienta"
    ),
    "/epvvvt/api/programzaobchadzania/pz-uloha-klienta": await EPVVVT_Get(
      "/api/programzaobchadzania/pz-uloha-klienta"
    ),
    "/epvvvt/api/stitkovnicaklientov/ListVsetciKlienti": await EPVVVT_Post(
      "/api/stitkovnicaklientov/ListVsetciKlienti"
    ),

    // FEOO
    "/feoo/api/integracie/Kiosk/GetZivotneMinimum": await FEOO_Get(
      "/api/integracie/Kiosk/GetZivotneMinimum"
    ),
    "/feoo/api/integracie/Kiosk/ListStavKontaAKreditu": await FEOO_Post(
      "/api/integracie/Kiosk/ListStavKontaAKreditu"
    ),
    "/feoo/api/Klient/Get": await FEOO_Get("/api/Klient/Get"),
    "/feoo/api/KlientUni/Get": await FEOO_Get("/api/KlientUni/Get"),
    "/feoo/api/Pohladavka/List": await FEOO_Post("/api/Pohladavka/List"),
    "/feoo/api/Pohladavka/ListSplatkyPohladavky": await FEOO_Post(
      "/api/Pohladavka/ListSplatkyPohladavky"
    ),
    "/feoo/api/VyzivovaciaPovinnost/List": await FEOO_Post(
      "/api/VyzivovaciaPovinnost/List"
    ),
    "/feoo/api/VyzivovaciaPovinnost/PrehladSplatokVyzivovacichPovinnosti":
      await FEOO_Post(
        "/api/VyzivovaciaPovinnost/PrehladSplatokVyzivovacichPovinnosti"
      ),

    // SHARE_INT
    "/share_int/api/interfaces/Epvvvt/ListAbsolvovaneTelefonickeHovory":
      await SHARE_INT_Post(
        "/api/interfaces/Epvvvt/ListAbsolvovaneTelefonickeHovory"
      ),
    "/share_int/api/interfaces/Epvvvt/ListPoucenia": await SHARE_INT_Post(
      "/api/interfaces/Epvvvt/ListPoucenia"
    ),
    "/share_int/api/interfaces/Epvvvt/ListSocialnaDiagnostikaOdsudeneho":
      await SHARE_INT_Post(
        "/api/interfaces/Epvvvt/ListSocialnaDiagnostikaOdsudeneho"
      ),
    "/share_int/api/interfaces/Epvvvt/ListZoznamAbsolvovanychProgramovAAktivit":
      await SHARE_INT_Post(
        "/api/interfaces/Epvvvt/ListZoznamAbsolvovanychProgramovAAktivit"
      ),
    "/share_int/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitKruzky":
      await SHARE_INT_Post(
        "/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitKruzky"
      ),
    "/share_int/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitOstatne":
      await SHARE_INT_Post(
        "/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitOstatne"
      ),
    "/share_int/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVychovnoOsvetovychAktivit":
      await SHARE_INT_Post(
        "/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVychovnoOsvetovychAktivit"
      ),
    "/share_int/api/interfaces/Epvvvt/ListZoznamOdoslanejKorenspondencie":
      await SHARE_INT_Post(
        "/api/interfaces/Epvvvt/ListZoznamOdoslanejKorenspondencie"
      ),
    "/share_int/api/interfaces/Epvvvt/ListZoznamPrijatejKorenspondencie":
      await SHARE_INT_Post(
        "/api/interfaces/Epvvvt/ListZoznamPrijatejKorenspondencie"
      ),
    "/share_int/api/interfaces/Epvvvt/ListZoznamPrijatychBalikovKlienta":
      await SHARE_INT_Post(
        "/api/interfaces/Epvvvt/ListZoznamPrijatychBalikovKlienta"
      ),
    "/share_int/api/interfaces/Er/ListZoznamDisciplinarnychOdmienKlienta":
      await SHARE_INT_Post(
        "/api/interfaces/Er/ListZoznamDisciplinarnychOdmienKlienta"
      ),
    "/share_int/api/interfaces/Er/ListZoznamDisciplinarnychTrestovKlienta":
      await SHARE_INT_Post(
        "/api/interfaces/Er/ListZoznamDisciplinarnychTrestovKlienta"
      ),
    "/share_int/api/interfaces/Er/ListZoznamInychRozhodnutiOKlientovi":
      await SHARE_INT_Post(
        "/api/interfaces/Er/ListZoznamInychRozhodnutiOKlientovi"
      ),

    // SIDKOO
    "/sidkoo/api/IdKarta/ListIdKarta": await SIDKOO_Post(
      "/api/IdKarta/ListIdKarta"
    ),

    // SZOO
    "/szoo/api/Ziadosti/Create": await SZOO_Post("/api/Ziadosti/Create"),
    "/szoo/api/Ziadosti/Get": await SZOO_Get("/api/Ziadosti/Get"),
    "/szoo/api/Ziadosti/GetOdpovedPreKlienta": await SZOO_Get(
      "/api/Ziadosti/GetOdpovedPreKlienta"
    ),
    "/szoo/api/Ziadosti/List": await SZOO_Post("/api/Ziadosti/List"),
    "/szoo/api/Ziadosti/SpatVzatie": await SZOO_Post(
      "/api/Ziadosti/SpatVzatie"
    ),
    // ZVJS_MP
    "/zvjs_mp/administracia-tabletu": await ZVJS_MP_Post(
      "/administracia-tabletu"
    ),
  };
};

export type DataResultType<
  T extends keyof PromiseElement<ReturnType<typeof internalDataResultTypes>>
> = PromiseElement<ReturnType<typeof internalDataResultTypes>>[T];

export const DataArray: Array<
  keyof PromiseElement<ReturnType<typeof internalDataResultTypes>>
> = [
  "/edpoo/api/Klient/Get",
  "/edpoo/api/OsobneVeci/ListElektrospotrebic",
  "/edpoo/api/OsobneVeci/ListOsobnaVec",
  "/edpoo/api/Stravovanie/List",
  "/eoo/api/Ciselnik/DruhAdresy",
  "/eoo/api/DalsieOsobneUdaje/ListPobytKlienta",
  "/eoo/api/DalsieOsobneUdaje/VazbyATrestyKlienta",
  "/eoo/api/FyzickaOsoba/DetailData",
  "/eoo/api/FyzickaOsoba/ListAdresaOsoba",
  "/eoo/api/Klient/DetailData",
  "/eoo/api/Klient/DetailZakladneUdajeKlientaData",
  "/eoo/api/Klient/ListTrestneKonanieData",
  "/eoo/api/Klient/ListNepravoplatnyTrestOosData",
  "/eoo/api/OchranneOpatrenie/ListOchranneOpatrenieData",
  "/eoo/api/PravniZastupcoviaKlienta/PravnyZastupcaKlientaList",
  "/eoo/api/Rozhodnutie/ListRozhodnutieData",
  "/eoo/api/VazbyATresty/DetailTDVData",
  "/eoo/api/VazbyATresty/DetailTrestData",
  "/eoo/api/VazbyATresty/DetailVazbaData",
  "/eoo/api/VazbyATresty/ListDovodovyParagrafVazbyData",
  "/eoo/api/VazbyATresty/ListTrestData",
  "/eoo/api/VazbyATresty/ListVazbaAleboTrestKlientaData",
  "/epvvvt/api/interfaces/Ezoo/ListPracovneZaradenia",
  "/epvvvt/api/Navsteva/visits/completed-visits",
  "/epvvvt/api/programzaobchadzania/pz-ciel-klienta",
  "/epvvvt/api/programzaobchadzania/pz-uloha-klienta",
  "/epvvvt/api/stitkovnicaklientov/ListVsetciKlienti",
  "/feoo/api/integracie/Kiosk/GetZivotneMinimum",
  "/feoo/api/integracie/Kiosk/ListStavKontaAKreditu",
  "/feoo/api/Klient/Get",
  "/feoo/api/KlientUni/Get",
  "/feoo/api/Pohladavka/List",
  "/feoo/api/Pohladavka/ListSplatkyPohladavky",
  "/feoo/api/VyzivovaciaPovinnost/List",
  "/feoo/api/VyzivovaciaPovinnost/PrehladSplatokVyzivovacichPovinnosti",
  "/share_int/api/interfaces/Epvvvt/ListAbsolvovaneTelefonickeHovory",
  "/share_int/api/interfaces/Epvvvt/ListPoucenia",
  "/share_int/api/interfaces/Epvvvt/ListSocialnaDiagnostikaOdsudeneho",
  "/share_int/api/interfaces/Epvvvt/ListZoznamAbsolvovanychProgramovAAktivit",
  "/share_int/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitKruzky",
  "/share_int/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVolnocasovychAktivitOstatne",
  "/share_int/api/interfaces/Epvvvt/ListZoznamAbsolvovanychVychovnoOsvetovychAktivit",
  "/share_int/api/interfaces/Epvvvt/ListZoznamOdoslanejKorenspondencie",
  "/share_int/api/interfaces/Epvvvt/ListZoznamPrijatejKorenspondencie",
  "/share_int/api/interfaces/Epvvvt/ListZoznamPrijatychBalikovKlienta",
  "/share_int/api/interfaces/Er/ListZoznamDisciplinarnychOdmienKlienta",
  "/share_int/api/interfaces/Er/ListZoznamDisciplinarnychTrestovKlienta",
  "/share_int/api/interfaces/Er/ListZoznamInychRozhodnutiOKlientovi",
  "/sidkoo/api/IdKarta/ListIdKarta",
  "/szoo/api/Ziadosti/Create",
  "/szoo/api/Ziadosti/Get",
  "/szoo/api/Ziadosti/GetOdpovedPreKlienta",
  "/szoo/api/Ziadosti/List",
  "/szoo/api/Ziadosti/SpatVzatie",
  "/zvjs_mp/administracia-tabletu",
];
