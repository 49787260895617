import React from "react";
import { Alert, AlertColor, AlertTitle } from "@mui/material";

interface ZvjsAlertProps {
  severity?: AlertColor;
  title?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  iconMapping?: Partial<Record<AlertColor, React.ReactNode>>;
}

const ZvjsAlert: React.FC<ZvjsAlertProps> = ({
  severity,
  title,
  children,
  style,
  iconMapping,
}) => {
  return (
    <Alert iconMapping={iconMapping} severity={severity} style={style}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </Alert>
  );
};

export default ZvjsAlert;
