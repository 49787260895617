import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { ZvjsButton, ZvjsSearchBar, ZvjsTable } from "../../../common";
import { useSnackbar } from "../../../store/context/snackbar-context";
import { ZvjsSearchBarVariant } from "../../../common/components/ZvjsSearchBar";
import { Stack } from "@mui/system";
import { ReactComponent as DetailIcon } from "../../../assets/icons/Other/detail.svg";
import {
  Params,
  useActionData,
  useNavigate,
  useNavigation,
  useSubmit,
} from "react-router-dom";
import { Requests_SC_KIO_0481 } from "../../../common/specs/sections/Requests_SC_KIO_0481";
import { ActionResult, ActionResultTypes } from "../../../router/Router";
import {
  getTranslationByLanguageId,
  isEmptyString,
  isSubmitting,
} from "../../../utils/helpers";
import { ZvjsSelectItem } from "../../../common/components/ZvjsSelect";
import { paths as szoo_v1_paths } from "../../../api/types/szoo_v1";
import { getCiselnikJazyk } from "../../../locales/i18n";
import i18n from "i18next";
import { useUITranslation } from "../../../store/context/translation-context";
import { ZVJS_COLORS } from "../../../theme/zvjs_theme";
import { SubmitTarget } from "react-router-dom/dist/dom";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { RequestsLoadedDataType } from "./Requests";
import { API_Clients } from "../../../store/context/dataApi/Data";

export async function RequestsTableActions({
  params,
  request,
}: {
  params: Params;
  request: Request;
}): Promise<ActionResult> {
  const { SZOO_Post } = await API_Clients();

  const body =
    (await request.json()) as szoo_v1_paths["/api/Ziadosti/List"]["post"]["requestBody"];

  const response = await SZOO_Post("/api/Ziadosti/List", {
    body: body?.content["application/json"],
  });

  if (response.error) {
    // requests load failed
    return { type: ActionResultTypes.ERROR };
  }

  const userRequests: szoo_v1_paths["/api/Ziadosti/List"]["post"]["responses"]["200"]["content"]["application/json"] =
    response.data;

  // TODO replace with sorting via BE call
  userRequests.records?.sort(
    (r1, r2) =>
      new Date(r2.datumPodania ?? "").getTime() -
      new Date(r1.datumPodania ?? "").getTime()
  );

  return { type: ActionResultTypes.SUCCESS, data: userRequests };
}

interface UserRequestsListProps {
  requestStates: RequestsLoadedDataType["data"]["requestStates"];
  initialUserRequests: RequestsLoadedDataType["data"]["initialUserRequests"];
  requestTypes: RequestsLoadedDataType["data"]["requestTypes"];
}

const UserRequestsList = (props: UserRequestsListProps) => {
  const { requestStates, initialUserRequests, requestTypes } = props;
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const submit = useSubmit();
  const submitResult = useActionData() as ActionResult | undefined;
  const { getFallbackJazyk } = useUITranslation();
  const navigation = useNavigation();

  useEffect(() => {
    if (
      submitResult?.type === ActionResultTypes.ERROR &&
      !isSubmitting(navigation)
    ) {
      // TODO replace with translation
      openSnackbar(
        "Vaše žiadosti sa nepodarilo načítať. Skúste neskôr.",
        "error"
      );
    }
  }, [openSnackbar, submitResult?.type, navigation]);

  const onSubmit = (dateFrom: Date, dateTo: Date, stav: string) => {
    submit(
      {
        content: {
          "application/json": {
            filters: [
              {
                datumPodaniaZiadostiOd: !isNaN(dateFrom.getTime())
                  ? dateFrom.toISOString()
                  : null,
                datumPodaniaZiadostiDo: !isNaN(dateTo.getTime())
                  ? dateTo.toISOString()
                  : null,
                stavZiadosti: {
                  id: !isEmptyString(stav) ? stav : null,
                },
                klientId: localStorage.getItem("klientObjectId") ?? "",
              },
            ],
          },
          "text/json": {
            filters: [
              {
                datumPodaniaZiadostiOd: !isNaN(dateFrom.getTime())
                  ? dateFrom.toISOString()
                  : null,
                datumPodaniaZiadostiDo: !isNaN(dateTo.getTime())
                  ? dateTo.toISOString()
                  : null,
                stavZiadosti: {
                  id: !isEmptyString(stav) ? stav : null,
                },
                klientId: localStorage.getItem("klientObjectId") ?? "",
              },
            ],
          },
          "application/*+json": {
            filters: [
              {
                datumPodaniaZiadostiOd: !isNaN(dateFrom.getTime())
                  ? dateFrom.toISOString()
                  : null,
                datumPodaniaZiadostiDo: !isNaN(dateTo.getTime())
                  ? dateTo.toISOString()
                  : null,
                stavZiadosti: {
                  id: !isEmptyString(stav) ? stav : null,
                },
                klientId: localStorage.getItem("klientObjectId") ?? "",
              },
            ],
          },
        },
      } as szoo_v1_paths["/api/Ziadosti/List"]["post"]["requestBody"] as SubmitTarget,
      {
        method: "POST",
        encType: "application/json",
      }
    );
  };

  const getSearchBarSelectItems = (): ZvjsSelectItem[] => {
    // TODO add translations once they are present in CisStavZiadosti counter
    return (
      requestStates.data.records?.map((value): ZvjsSelectItem => {
        return {
          value: value.kod ?? "",
          text: value.nazov ?? "",
        };
      }) ?? []
    );
  };

  const tableStyle: TableStyle[] = [
    { align: "left", width: 130 },
    { align: "left" },
    { align: "left" },
    { align: "right" },
  ];

  //todo add correct translation
  const header: CellData[] = [
    {
      value: "Dátum podania",
      ...tableStyle[0],
    },
    {
      value: "Názov žiadosti",
      ...tableStyle[1],
    },
    {
      value: "Stav",
      ...tableStyle[2],
    },
    {
      value: "Akcia",
      ...tableStyle[3],
    },
  ];

  /**
   * @deprecated get request state color from counter once it is implemented
   */
  const getRequestStateColor = (requestState?: string | null): string => {
    if (requestState !== undefined && requestState !== null) {
      if (requestState.includes("Zaevidovaná")) {
        return ZVJS_COLORS.BLUE;
      }
      if (requestState.includes("Schválená")) {
        return ZVJS_COLORS.GREEN;
      }
      if (requestState.includes("Zamietnutá")) {
        return ZVJS_COLORS.RED;
      }
      if (requestState.includes("Späťvzatá")) {
        return ZVJS_COLORS.PURPLE;
      }
    }
    return ZVJS_COLORS.BLUE;
  };

  const getTableBody = (): RowData[] => {
    const getUserRequestsToDisplay =
      (): szoo_v1_paths["/api/Ziadosti/List"]["post"]["responses"]["200"]["content"]["application/json"] => {
        if (submitResult?.type === ActionResultTypes.ERROR) {
          // if a filtered user requests fetch finished with error, display empty table
          return { records: [] };
        }
        if (submitResult?.type === ActionResultTypes.SUCCESS) {
          // if filtered user requests fetch loaded new user requests, display them in table
          return submitResult.data;
        }
        // otherwise, display initially loaded requests
        return initialUserRequests.data;
      };

    const userRequests = getUserRequestsToDisplay();
    return (
      userRequests.records?.map((request) => {
        return {
          row: [
            {
              value: new Date(request.datumPodania ?? "").zvjsToString(),
              ...tableStyle[0],
            },
            {
              value: request.typZiadostiId?.id
                ? getTranslationByLanguageId(
                    requestTypes.data.records ?? [],
                    getCiselnikJazyk(i18n.language),
                    getFallbackJazyk(),
                    request.typZiadostiId?.id,
                    "nazov"
                  )
                : "",
              ...tableStyle[1],
            },
            // You might use in third column following component:
            // {
            //   value: <ZvjsRequestFlag requestFlag={"Schválená"} />,
            //   ...tableStyle[2],
            // },
            {
              value: (
                <div
                  style={{
                    backgroundColor: getRequestStateColor(request.stavZiadosti),
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    width: "7rem",
                    borderRadius: "0.2rem",
                  }}
                >
                  <Typography
                    fontSize={"20"}
                    color={ZVJS_COLORS.WHITE}
                    textAlign={"center"}
                  >
                    {request.stavZiadosti}
                  </Typography>
                </div>
              ),
              ...tableStyle[2],
            },
            {
              value: (
                <ZvjsButton
                  text={"Detail"} // todo add correct translation
                  zvjsVariant="secondaryAction"
                  startIcon={
                    <DetailIcon
                      style={{ fill: "white", width: 28, height: 28 }}
                    />
                  }
                  onClick={() => {
                    navigate(
                      `${Requests_SC_KIO_0481.Detail}/${request.ziadostId}`
                    );
                  }}
                />
              ),
              ...tableStyle[3],
            },
          ],
        };
      }) ?? []
    );
  };

  const data: TableData = {
    header: header,
    body: getTableBody(),
  };

  return (
    <Grid pt={3}>
      <Stack mb={3}>
        <ZvjsSearchBar
          type={ZvjsSearchBarVariant.VARIANT_B_DpDpCo}
          onSubmit={onSubmit}
          title={"Vyhľadať v žiadostiach"}
          comboboxLabel={"Stav"}
          items={getSearchBarSelectItems()}
        />
        <ZvjsTable variant={ZvjsTableVariant.NORMAL} data={data} height={500} />
      </Stack>
    </Grid>
  );
};

export default UserRequestsList;
