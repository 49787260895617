import React, { useEffect, useState } from "react";
import { ZakladneUdajeKlientaProps } from "../ZakladneUdajeKlienta";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsTable,
} from "../../../../common";
import { Grid, Stack } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";

const PotravinoveIntolerancieTab: React.FC<ZakladneUdajeKlientaProps> = ({
  data,
}) => {
  const { t } = useTranslation();
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const dataStylePotravinoveIntolerancie: TableStyle[] = [
    {
      align: "left",
    },
  ];

  const headerDataPotravinoveIntolerancie: Array<CellData> = [
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicClientData_SC_KIO_0300.Intolerances.name"
        )
      ),
      ...dataStylePotravinoveIntolerancie[0],
    },
  ];

  const [
    tableDataPotravinoveIntolerancie,
    setTableDataPotravinoveIntolerancie,
  ] = useState<TableData>({
    label: capitalize(
      tui("osobneUdaje.poskytovanaSluzba.evidovanPotravinoveIntolerancie")
    ),
    header: headerDataPotravinoveIntolerancie,
    body: [],
  });

  useEffect(() => {
    if (data.stravovanie.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      data.stravovanie.data.records
        // .sort((p1, p2) =>
        //   p1.name > p2.name)
        ?.forEach((strava) => {
          bodyData.push({
            row: [
              {
                value: strava.intolerancia ?? "",
                ...dataStylePotravinoveIntolerancie[0],
              },
            ],
          });
        });
      setTableDataPotravinoveIntolerancie({
        ...tableDataPotravinoveIntolerancie,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Stack direction="column" mt={1} spacing={1}>
      <ZvjsTable
        data={tableDataPotravinoveIntolerancie}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
        hintTitle={tui("nápoveda").toString()}
        hintText={tui(
          "osobneUdaje.potravinoveIntolerancie.napovedaZdravotnyStav"
        )}
      />
      <Grid width="20rem">
        <ZvjsButton
          text={capitalize(
            tui("osobneUdaje.potravinoveIntolerancie.zdravotnaStarostlivost")
          )}
          zvjsVariant="secondaryAction"
          endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
          onClick={() => {
            navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/15.00.01`);
          }}
          style={{
            lineHeight: "1",
            textTransform: "none",
            textAlign: "left",
          }}
        />
      </Grid>
    </Stack>
  );
};

export default PotravinoveIntolerancieTab;
