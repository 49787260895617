import { UserCalcData } from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectUserCalcData } from "../../../../../../redux/selectors";

/**
 * Selectors
 */

const displayClientHasEnoughMoneyOnAccount = () =>
  createSelector(
    selectUserCalcData,
    (state: UserCalcData): boolean | undefined => {
      let estimatedPriceTotalSum = 0;
      for (const item of state[
        "SC_KIO_051003_CLIENT_PERSONAL_HYGIENE_NEEDS_LIST"
      ]) {
        estimatedPriceTotalSum += item.estimatedPrice;
      }

      if (state["AVAILABLE_ACCOUNT_AMOUNT"] >= estimatedPriceTotalSum) {
        return true;
      }

      return false;
    }
  );

const getClientClientAvailableBalance = () =>
  createSelector(selectUserCalcData, (state: UserCalcData): number => {
    return state["AVAILABLE_ACCOUNT_AMOUNT"];
  });

const displayClientShoppedLastCalendarMonth = () =>
  createSelector(
    selectUserCalcData,
    (state: UserCalcData): boolean | undefined => {
      return state["SHOPPED_LAST_CALENDAR_MONTH"];
    }
  );

export const allSelectors = {
  displayClientHasEnoughMoneyOnAccount,
  getClientClientAvailableBalance,
  displayClientShoppedLastCalendarMonth,
};
