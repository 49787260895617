import {
  SC_KIO_050101_ZiadostONavstevu,
  SC_KIO_050102_ZiadostOMimoriadnuNavstevuZDovoduDisciplinarnejOdmeny,
  SC_KIO_050201_ZiadostOVymenuCudzozemskychPlatidiel,
  SC_KIO_050202_ZiadostOVydanieZaevidovanychCeninCennychPapierovAI,
  SC_KIO_050203_ZiadostODobrovolnuUhraduVyzivnehoAleboPohladavky,
  SC_KIO_050204_ZiadostOPoukazaniePenaznychProstriedkovVProspechOsobAleboSubjektov,
  SC_KIO_050205_ZiadostOZaevidovaniePPNaVreckoveKontoOdsudenehoUmiestnenehoVOO,
  SC_KIO_050206_ZiadostOVykonanieZrazokZCPOOdsudenehoNaUhraduVyzivnehoNezaopatrenychDeti,
  SC_KIO_050207_ZiadostOVyplateniePPPriUdeleniMVOU,
  SC_KIO_050208_ZiadostOVyplateniePPZKontaOdsudenehoPremiestnenehoDoOO,
  SC_KIO_050209_ZiadostODalsiePouItieVratenejPlatbyZDobrovolnejUhradyVyzivnehoAleboPohladavky,
  SC_KIO_050301_ZiadostOOdoslanieKorespondencieNaNakladyUstavu,
  SC_KIO_050302_ZiadostOVydaniePovoleniaNaPrijatieBalika,
  SC_KIO_050401_ZiadostOPoskytnutieAmbulantnejZdravotnejStarostlivosti,
  SC_KIO_050402_ZiadostOPovolenieZdravotnejPomocky,
  SC_KIO_050501_ZiadostOPouzivanieVlastnehoElektrospotrebicaAAleboJehoTechnickuKontrolu,
  SC_KIO_050502_ZiadostOVydanieVeciUlozenychVUschoveAleboVeciVUzivani,
  SC_KIO_050601_ZiadostOUcastNaHromadnejKukturnoOsvetovejASportovejAktivite,
  SC_KIO_050602_ZiadostOZaradenieDoSpecializovanehoResocializacnehoAVychovnoVzdelavaciehoProgramu,
  SC_KIO_050603_ZiadostOPrijatieDoVzdelavaciehoProgramuAleboRekvalifikacnehoKurzu,
  SC_KIO_050604_ZiadostOZaradenieDoKruzku,
  SC_KIO_050701_ZiadostOTelefonovanie,
  SC_KIO_050801_ZiadostOPohovorSoSocialnymPracovnikomSCielomZabezpeceniaPrislusnejSocialnejSluzby,
  SC_KIO_050802_ZiadostOPoskytnutiePomociPriPrepusteniOdevStravaCestovnyListokAPod,
  SC_KIO_050803_ZiadostOPoskytnutiePsychologickejSluzby,
  SC_KIO_050804_ZiadostOPoskytnutieDuchovnejAPastoracnejSluzby,
  SC_KIO_050901_ZiadostOPohovorSRiaditelomUstavuAleboPoskytnutieInformacieInymPracovnikomZboru,
  SC_KIO_051001_ZiadostOCastejsieSprchovanie,
  SC_KIO_051002_ZiadostOOstrihanie,
  SC_KIO_051003_ZiadostOPoskytnutiePotriebOsobnejHygieny,
  SC_KIO_051004_ZiadostONakupSortimentuVUstavnejPredajniVObmedzenomRozsahu,
  SC_KIO_051102_ZiadostOPouzivanieVlastnehoOdevuBielizneObuvi,
  SC_KIO_051104_ZiadostOObmenuOdevuBielizneAObuviUstavnychVystrojnychSucasti,
  SC_KIO_051201_ZiadostOZmenuPoskytovanehoDruhuStravy,
  SC_KIO_051301_HlaseniePoruchVObjekteVykonuVazbyAVykonuTrestu,
  SC_KIO_051401_ZiadostOUmiestnenieVOddieleSoStandardnymRezimom,
  SC_KIO_051402_ZiadostOUmiestnenieVRamciAktualnehoUstavu,
  SC_KIO_051403_ZiadostOdsudenehoOPremiestnenie,
  SC_KIO_051404_ZiadostOdsudenehoOUmiestnenie,
  SC_KIO_051501_UniverzalnaZiadost,
} from "./index";
import { ZvjsQuestionnaireTemplate } from "../redux/model";

export enum RequestSzooCode {
  "04.00.01" = "04.00.01",
  "04.00.02" = "04.00.02",
  "09.04.01" = "09.04.01",
  "09.03.01" = "09.03.01",
  "09.01.02" = "09.01.02",
  "09.06.01" = "09.06.01",
  "09.05.02" = "09.05.02",
  "09.01.01" = "09.01.01",
  "09.07.01" = "09.07.01",
  "09.05.01" = "09.05.01",
  "01.02.01" = "01.02.01",
  "01.01.01" = "01.01.01",
  "15.00.01" = "15.00.01",
  "15.00.02" = "15.00.02",
  "08.01.01" = "08.01.01",
  "08.00.01" = "08.00.01",
  "11.04.01" = "11.04.01",
  "11.02.01" = "11.02.01",
  "11.01.01" = "11.01.01",
  "11.03.01" = "11.03.01",
  "13.00.01" = "13.00.01",
  "10.02.01" = "10.02.01",
  "10.03.01" = "10.03.01",
  "10.01.01" = "10.01.01",
  "10.04.01" = "10.04.01",
  "06.00.01" = "06.00.01",
  "07.02.01" = "07.02.01",
  "07.03.01" = "07.03.01",
  "07.01.01" = "07.01.01",
  "07.01.02" = "07.01.02",
  "05.00.01" = "05.00.01",
  "05.00.02" = "05.00.02",
  "12.00.01" = "12.00.01",
  "02.00.01" = "02.00.01",
  "03.00.03" = "03.00.03",
  "03.00.02" = "03.00.02",
  "03.00.04" = "03.00.04",
  "03.00.01" = "03.00.01",
  "14.00.01" = "14.00.01",
}

export enum RequestInternalCode {
  SC_KIO_050101 = "SC_KIO_050101",
  SC_KIO_050102 = "SC_KIO_050102",
  SC_KIO_050201 = "SC_KIO_050201",
  SC_KIO_050202 = "SC_KIO_050202",
  SC_KIO_050203 = "SC_KIO_050203",
  SC_KIO_050204 = "SC_KIO_050204",
  SC_KIO_050205 = "SC_KIO_050205",
  SC_KIO_050206 = "SC_KIO_050206",
  SC_KIO_050207 = "SC_KIO_050207",
  SC_KIO_050208 = "SC_KIO_050208",
  SC_KIO_050209 = "SC_KIO_050209",
  SC_KIO_050301 = "SC_KIO_050301",
  SC_KIO_050302 = "SC_KIO_050302",
  SC_KIO_050401 = "SC_KIO_050401",
  SC_KIO_050402 = "SC_KIO_050402",
  SC_KIO_050501 = "SC_KIO_050501",
  SC_KIO_050502 = "SC_KIO_050502",
  SC_KIO_050601 = "SC_KIO_050601",
  SC_KIO_050602 = "SC_KIO_050602",
  SC_KIO_050603 = "SC_KIO_050603",
  SC_KIO_050604 = "SC_KIO_050604",
  SC_KIO_050701 = "SC_KIO_050701",
  SC_KIO_050801 = "SC_KIO_050801",
  SC_KIO_050802 = "SC_KIO_050802",
  SC_KIO_050803 = "SC_KIO_050803",
  SC_KIO_050804 = "SC_KIO_050804",
  SC_KIO_050901 = "SC_KIO_050901",
  SC_KIO_051001 = "SC_KIO_051001",
  SC_KIO_051002 = "SC_KIO_051002",
  SC_KIO_051003 = "SC_KIO_051003",
  SC_KIO_051004 = "SC_KIO_051004",
  SC_KIO_051102 = "SC_KIO_051102",
  SC_KIO_051104 = "SC_KIO_051104",
  SC_KIO_051201 = "SC_KIO_051201",
  SC_KIO_051301 = "SC_KIO_051301",
  SC_KIO_051401 = "SC_KIO_051401",
  SC_KIO_051402 = "SC_KIO_051402",
  SC_KIO_051403 = "SC_KIO_051403",
  SC_KIO_051404 = "SC_KIO_051404",
  SC_KIO_051501 = "SC_KIO_051501",
}

const requestTemplates: {
  [key in RequestSzooCode]: ZvjsQuestionnaireTemplate;
} = {
  "04.00.01": SC_KIO_050101_ZiadostONavstevu,
  "04.00.02":
    SC_KIO_050102_ZiadostOMimoriadnuNavstevuZDovoduDisciplinarnejOdmeny,
  "09.04.01": SC_KIO_050201_ZiadostOVymenuCudzozemskychPlatidiel,
  "09.03.01": SC_KIO_050202_ZiadostOVydanieZaevidovanychCeninCennychPapierovAI,
  "09.01.02": SC_KIO_050203_ZiadostODobrovolnuUhraduVyzivnehoAleboPohladavky,
  "09.06.01":
    SC_KIO_050204_ZiadostOPoukazaniePenaznychProstriedkovVProspechOsobAleboSubjektov,
  "09.05.02":
    SC_KIO_050205_ZiadostOZaevidovaniePPNaVreckoveKontoOdsudenehoUmiestnenehoVOO,
  "09.01.01":
    SC_KIO_050206_ZiadostOVykonanieZrazokZCPOOdsudenehoNaUhraduVyzivnehoNezaopatrenychDeti,
  "09.07.01": SC_KIO_050207_ZiadostOVyplateniePPPriUdeleniMVOU,
  "09.05.01":
    SC_KIO_050208_ZiadostOVyplateniePPZKontaOdsudenehoPremiestnenehoDoOO,
  // TODO verify if this request is 09.00.01
  // SC_KIO_050209:
  //   SC_KIO_050209_ZiadostODalsiePouItieVratenejPlatbyZDobrovolnejUhradyVyzivnehoAleboPohladavky,
  "01.02.01": SC_KIO_050301_ZiadostOOdoslanieKorespondencieNaNakladyUstavu,
  "01.01.01": SC_KIO_050302_ZiadostOVydaniePovoleniaNaPrijatieBalika,
  "15.00.01":
    SC_KIO_050401_ZiadostOPoskytnutieAmbulantnejZdravotnejStarostlivosti,
  "15.00.02": SC_KIO_050402_ZiadostOPovolenieZdravotnejPomocky,
  "08.01.01":
    SC_KIO_050501_ZiadostOPouzivanieVlastnehoElektrospotrebicaAAleboJehoTechnickuKontrolu,
  "08.00.01":
    SC_KIO_050502_ZiadostOVydanieVeciUlozenychVUschoveAleboVeciVUzivani,
  "11.04.01":
    SC_KIO_050601_ZiadostOUcastNaHromadnejKukturnoOsvetovejASportovejAktivite,
  "11.02.01":
    SC_KIO_050602_ZiadostOZaradenieDoSpecializovanehoResocializacnehoAVychovnoVzdelavaciehoProgramu,
  "11.01.01":
    SC_KIO_050603_ZiadostOPrijatieDoVzdelavaciehoProgramuAleboRekvalifikacnehoKurzu,
  "11.03.01": SC_KIO_050604_ZiadostOZaradenieDoKruzku,
  "13.00.01": SC_KIO_050701_ZiadostOTelefonovanie,
  "10.02.01":
    SC_KIO_050801_ZiadostOPohovorSoSocialnymPracovnikomSCielomZabezpeceniaPrislusnejSocialnejSluzby,
  "10.03.01":
    SC_KIO_050802_ZiadostOPoskytnutiePomociPriPrepusteniOdevStravaCestovnyListokAPod,
  "10.01.01": SC_KIO_050803_ZiadostOPoskytnutiePsychologickejSluzby,
  "10.04.01": SC_KIO_050804_ZiadostOPoskytnutieDuchovnejAPastoracnejSluzby,
  "06.00.01":
    SC_KIO_050901_ZiadostOPohovorSRiaditelomUstavuAleboPoskytnutieInformacieInymPracovnikomZboru,
  "07.02.01": SC_KIO_051001_ZiadostOCastejsieSprchovanie,
  "07.03.01": SC_KIO_051002_ZiadostOOstrihanie,
  "07.01.01": SC_KIO_051003_ZiadostOPoskytnutiePotriebOsobnejHygieny,
  "07.01.02":
    SC_KIO_051004_ZiadostONakupSortimentuVUstavnejPredajniVObmedzenomRozsahu,
  "05.00.01": SC_KIO_051102_ZiadostOPouzivanieVlastnehoOdevuBielizneObuvi,
  "05.00.02":
    SC_KIO_051104_ZiadostOObmenuOdevuBielizneAObuviUstavnychVystrojnychSucasti,
  "12.00.01": SC_KIO_051201_ZiadostOZmenuPoskytovanehoDruhuStravy,
  "02.00.01": SC_KIO_051301_HlaseniePoruchVObjekteVykonuVazbyAVykonuTrestu,
  "03.00.03": SC_KIO_051401_ZiadostOUmiestnenieVOddieleSoStandardnymRezimom,
  "03.00.02": SC_KIO_051402_ZiadostOUmiestnenieVRamciAktualnehoUstavu,
  "03.00.04": SC_KIO_051403_ZiadostOdsudenehoOPremiestnenie,
  "03.00.01": SC_KIO_051404_ZiadostOdsudenehoOUmiestnenie,
  "14.00.01": SC_KIO_051501_UniverzalnaZiadost,
};

export default requestTemplates;
