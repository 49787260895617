import {
  State,
  UserCalcData,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";

const AVAILABLE_GROUPS = "GROUPS";
export const GroupQuestionConstants = {
  AVAILABLE_GROUPS,
};

/**
 * OnInit
 */

export interface GroupType {
  id: string;
  name: string;
}

export const SC_KIO_050604_GroupQuestion_onInit = async (
  calcData: UserCalcData
): Promise<void> => {
  // TODO replace with actual fetch - filter out groups which client cannot apply for
  const listOfGroups: GroupType[] = [
    {
      id: "001",
      name: "Krúžok pre rehabilitáciu a resocializáciu",
    },
    {
      id: "002",
      name: "Literárny krúžok: Slová slobody",
    },
    {
      id: "003",
      name: "Krúžok umenia za mrežami",
    },
    {
      id: "004",
      name: "Krúžok vzdelávania na diaľku",
    },
    {
      id: "005",
      name: "Krúžok meditácie a duševného zdravia",
    },
    {
      id: "006",
      name: "Športový krúžok pre väzňov",
    },
    {
      id: "007",
      name: "Krúžok hudobnej terapie",
    },
    {
      id: "008",
      name: "Krúžok remesiel a pracovných zručností",
    },
    {
      id: "009",
      name: "Krúžok rodičovstva vo väzení",
    },
    {
      id: "010",
      name: "Krúžok občianskej vzdelanosti a právnych znalostí",
    },
  ];

  calcData[GroupQuestionConstants.AVAILABLE_GROUPS] = listOfGroups;
};

/**
 * Selectors
 */

const getGroupQuestionDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      answerRequired: question.isRequired,
      options: (
        state.userCalcData[
          GroupQuestionConstants.AVAILABLE_GROUPS
        ] as GroupType[]
      ).map((item) => {
        return { value: item.id, text: item.name };
      }),
    };
  });

export const allSelectors = {
  getGroupQuestionDisplayData,
};
