import { Box, Paper, Stack, Typography } from "@mui/material";
import { ReactComponent as Hint } from "../../../assets/icons/Other/hint.svg";
import { ZVJS_COLORS } from "../../../theme/zvjs_theme";
import { capitalize } from "@mui/material/utils";
import { ZvjsButton } from "../index";
import React from "react";

interface ZvjsHintModalBodyProps {
  title: string;
  text: string;
  color?: string;
  size?: number;
  onClose?: () => void;
}

const ZvjsHintModalBody: React.FC<ZvjsHintModalBodyProps> = ({
  title,
  text,
  size,
  color,
  onClose,
}) => {
  return (
    <Paper
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: "1rem",
        borderRadius: "1rem",
        boxShadow: 24,
        "&:focus": {
          outline: "none",
        },
      }}
    >
      <Stack spacing={1}>
        <Box
          style={{
            backgroundColor: "rgba(0,112,60,0.3)",
            minWidth: "30rem",
            borderRadius: "0.5rem",
            border: `0.1rem solid ${ZVJS_COLORS.GREEN}`,
            padding: "0.5rem",
          }}
        >
          <Stack direction="row" spacing={2} justifyContent={"center"}>
            <Hint fill={ZVJS_COLORS.GREEN} />
            <Typography
              variant={"h4"}
              fontWeight={700}
              color={ZVJS_COLORS.GREEN}
            >
              {capitalize(title)}
            </Typography>
          </Stack>
        </Box>
        {
          //todo some hints have link inside
        }
        <Typography
          id="transition-modal-description"
          sx={{ mt: 2 }}
          paddingLeft={1}
          paddingRight={1}
        >
          {capitalize(text)}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ZvjsButton
            zvjsVariant={"secondaryAction"}
            onClick={onClose}
            text={"OK"}
            sx={{ height: "3rem" }}
          />
        </Box>
      </Stack>
    </Paper>
  );
};

export default ZvjsHintModalBody;