import React, { useEffect, useState } from "react";

import { StringMap } from "../../store/context/envConfigContext";
import { Paper, Typography } from "@mui/material";

interface ZvjsTextViewerProps {
  file: { url: string; contentType: string; httpHeaders?: StringMap };
}

const ZvjsTextViewer: React.FC<ZvjsTextViewerProps> = ({ file }) => {
  const [text, setText] = useState<string | undefined>();

  useEffect(() => {
    fetch(file.url, {
      headers: file.httpHeaders,
      method: "GET",
    }).then((response) => {
      response.text().then((txt) => setText(txt));
    });
  }, [file]);

  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <Paper sx={{ width: "100%" }}>
        <Typography
          variant={"body1"}
          minHeight={"50vh"}
          style={{ overflow: "auto", whiteSpace: "pre-wrap" }}
        >
          {text}
        </Typography>
      </Paper>
    </div>
  );
};

export default ZvjsTextViewer;
