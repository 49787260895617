import React from "react";
import { capitalize } from "@mui/material/utils";
import { useUITranslation } from "../../../store/context/translation-context";
import {
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { ZvjsTable } from "../../../common";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

/**
 * TODO reimplement so offline versions of webpages are loaded
 */
const BezpecnyInternetTablet = () => {
  const navigate = useNavigate();
  const { tui } = useUITranslation();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  const Links = [
    "www.slov-lex.sk",
    "www.nrsr.sk",
    "www.vlada.gov.sk",
    "www.prezident.sk",
    "www.justice.gov.sk",
    "www.ustavnysud.sk",
    "www.nsud.sk",
    "www.genpro.gov.sk",
    "www.vop.gov.sk",
    "www.coe.int/sk",
    "www.centrumpravnejpomoci.sk",
  ];

  // TODO use proper KEY
  const Name = [
    "Slov-Lex: Právny a informačný portál",
    "Národná rada Slovenskej republiky",
    "Úrad vlády Slovenskej republiky",
    "Kancelária prezidenta SR",
    "Ministerstvo spravodlivosti Slovenskej republiky",
    "ÚSTAVNÝ SÚD Slovenskej republiky",
    "NAJVYŠŠÍ SÚD SLOVENSKEJ REPUBLIKY",
    "Generálna prokuratúra Slovenskej republiky",
    "The Office of The Public Defender of Rights",
    "Council of Europe",
    "Centrum právnej pomoci",
  ];

  const dataStyleBezpecnyInternet: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "right",
    },
  ];

  const tableBezpecnyInternet: TableData = {
    header: [
      {
        value: capitalize(
          tui(
            "Názov stránky" // TODO: use proper KEY
          )
        ),
        ...dataStyleBezpecnyInternet[0],
      },
      {
        value: capitalize(
          tui(
            "Link" // TODO: use proper KEY
          )
        ),
        ...dataStyleBezpecnyInternet[1],
      },
    ],
    body: new Array<RowData>(),
  };

  for (let i = 0; i < Links.length; i++) {
    tableBezpecnyInternet.body.push({
      row: [
        {
          value: capitalize(Name[i]),
          ...dataStyleBezpecnyInternet[0],
        },
        {
          value: (
            <a
              // component="button"
              target={"_blank"}
              href={"https://" + Links[i]}
              // onClick={() => {
              //   const searchParams = new URLSearchParams(location.search);
              //   searchParams.set("page", Links[i]);
              //   navigate(
              //     "/auth/SafeInternet/openedPage?" + searchParams.toString(),
              //     {
              //       // replace current path (so back button redirects you directly to previous page and not to the previous tab)
              //       replace: true,
              //     }
              //   );
              // }}
            >
              {"Link"} {/*TODO: use proper KEY */}
            </a>
          ),
          ...dataStyleBezpecnyInternet[1],
        },
      ],
    });
  }

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} width={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(tui("dashboard.bezpecnyInternet"))}
            </Typography>
          </Stack>
        </Grid>
        <Grid item mt={4}>
          <ZvjsTable
            data={tableBezpecnyInternet}
            variant={ZvjsTableVariant.NORMAL}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BezpecnyInternetTablet;
