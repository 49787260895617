import React from "react";
import { AuthContextProvider } from "./auth-context";
import { OnlineStatusProvider } from "./onlineStatus-context";
import { TranslationProvider } from "./translation-context";
import { ModalContextProvider } from "./modal-context";
import { SnackbarContextProvider } from "./snackbar-context";
import { AuditLogsProvider } from "./auditLogs-context";
import ZvjsSnackbar from "../../common/components/ZvjsSnackbar";

interface AppContextProviderProps {
  children: React.ReactNode;
}

const AppContextProvider: React.FC<AppContextProviderProps> = (props) => {
  return (
    <OnlineStatusProvider>
      <AuthContextProvider>
        <ModalContextProvider>
          <SnackbarContextProvider>
            <AuditLogsProvider>
              <TranslationProvider>{props.children}</TranslationProvider>
              <ZvjsSnackbar />
            </AuditLogsProvider>
          </SnackbarContextProvider>
        </ModalContextProvider>
      </AuthContextProvider>
    </OnlineStatusProvider>
  );
};

export default AppContextProvider;
