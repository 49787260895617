import { useEffect, useState } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useNavigate } from "react-router-dom";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router";
import { withLoader } from "../../../hoc/withLoader";
import { ZvjsTable } from "../../../common";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { PromiseElement } from "../../../utils/types";

const loadData = async () => {
  const { EOO_Post } = await API_Clients();

  const data = await Promise.all([
    EOO_Post("/api/DalsieOsobneUdaje/ListPobytKlienta", {
      body: {
        filters: [
          {
            id: Number.parseInt(localStorage.getItem("klientId") ?? "") ?? 1,
            ustavZvjsId:
              Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
          },
        ],
      },
    }),
  ]);

  return {
    pobytyVUstavoch: data[0],
  };
};

export const MiestoVykonuVazbyAVykonuTrestuLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface MiestoVykonuVazbyAVykonuTrestuProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const MiestoVykonuVazbyAVykonuTrestu = (
  props: MiestoVykonuVazbyAVykonuTrestuProps
) => {
  const loaderData = props.data;
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const dataStyle: TableStyle[] = [
    {
      align: "left",
      width: "calc(100% / 4)",
    },
    {
      align: "left",
      width: "calc(100% / 4)",
    },
    {
      align: "left",
      width: "calc(100% / 4)",
    },
    {
      align: "left",
      width: "calc(100% / 4)",
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(tui("osobneUdaje.pobytyVustavoch.ustav")),
      ...dataStyle[0],
    },
    {
      value: capitalize(tui("osobneUdaje.pobytyVustavoch.datumOd")),
      ...dataStyle[1],
    },
    {
      value: capitalize(tui("osobneUdaje.pobytyVustavoch.casOdchodu")),
      ...dataStyle[2],
    },
    {
      value: capitalize(tui("osobneUdaje.pobytyVustavoch.datumDo")),
      ...dataStyle[3],
    },
  ];

  const [tableData, setTableData] = useState<TableData>({
    label: capitalize(tui("osobneUdaje.pobytyVustavoch.pobytyVustavoch")),
    header: headerData,
    body: [],
  });

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (loaderData.pobytyVUstavoch.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      loaderData.pobytyVUstavoch.data.records
        ?.sort(
          (p1, p2) =>
            new Date(p2.datumNastupu ?? new Date()).valueOf() -
            new Date(p1.datumNastupu ?? new Date()).valueOf()
        )
        .forEach((miestoVykonuVazbyAVykonuTrestu) => {
          bodyData.push({
            row: [
              {
                value:
                  miestoVykonuVazbyAVykonuTrestu.ustavPrepusteniaSkratka ?? "",
                ...dataStyle[0],
              },
              {
                value: new Date(
                  miestoVykonuVazbyAVykonuTrestu.datumNastupu ?? new Date()
                ).zvjsToString(),
                ...dataStyle[1],
              },
              {
                value: miestoVykonuVazbyAVykonuTrestu.datumPrepustenia
                  ? new Date(
                      miestoVykonuVazbyAVykonuTrestu.datumPrepustenia
                    ).toLocaleTimeString()
                  : "",
                ...dataStyle[2],
              },
              {
                value: miestoVykonuVazbyAVykonuTrestu.datumPrepustenia
                  ? new Date(
                      miestoVykonuVazbyAVykonuTrestu.datumPrepustenia
                    ).zvjsToString()
                  : "",
                ...dataStyle[3],
              },
            ],
          });
        });
      setTableData({
        ...tableData,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderData]);

  // const mySubmitHandler = (
  //   dateFrom: Date,
  //   dateTo: Date,
  //   dropDownValue: string
  // ) => {
  //   console.log(dateFrom);
  //   console.log(dateTo);
  // };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(
              tui(
                "osobneUdaje.kategorieOsobnychUdajov.miestoVykonuVazbyVykonuTrestu"
              )
            )}
          </Typography>
        </Stack>
      </Grid>

      <ZvjsTable
        data={tableData}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Grid>
  );
};

export default withLoader(MiestoVykonuVazbyAVykonuTrestu);
