import { ZVJS_COLORS } from "../../../theme/zvjs_theme";
import { ReactComponent as Hint } from "../../../assets/icons/Other/hint.svg";
import { Grid } from "@mui/material";
import React from "react";
import { useModal } from "../../../store/context/modal-context";
import { ZvjsHintModalBody } from "../index";

export interface HintProps {
  color?: string;
  size?: number;
}

interface ZvjsHintModalFragmentProps extends HintProps {
  title: string;
  text: string;
}

const ZvjsHintModalFragment: React.FC<ZvjsHintModalFragmentProps> = ({
  title,
  text,
  size,
  color = ZVJS_COLORS.BLUE_2,
}) => {
  const { openModal, closeModal } = useModal();
  const handleOpen = () => {
    openModal(
      <ZvjsHintModalBody
        title={title}
        text={text}
        onClose={closeModal}
        color={color}
        size={size}
      />
    );
  };

  return (
    <Grid item display={"flex"} alignItems={"center"} justifyContent={"center"}>
      {size !== undefined ? (
        <Hint
          onClick={handleOpen}
          fill={color ?? ZVJS_COLORS.BLACK}
          height={size}
          width={size}
        />
      ) : (
        <Hint onClick={handleOpen} fill={color ?? ZVJS_COLORS.BLACK} />
      )}
    </Grid>
  );
};
export default ZvjsHintModalFragment;
