import React from "react";
import {
  RequestPagesZmenaUdajovZiadost,
  ZmenaUdajovZiadostData,
} from "./ZmenaUdajovZiadost";
import { ZvjsButton, ZvjsSummaryTable } from "../../../../common";
import { capitalize } from "@mui/material/utils";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import {
  ZvjsSummaryTableRequestComponent,
  ZvjsSummaryTableRequestHeader,
} from "../../../../common/components/ZvjsSummaryTable";
import { useUITranslation } from "../../../../store/context/translation-context";

interface ZvjsOsobneUdajeSummaryProps {
  setCurrentPage: React.Dispatch<
    React.SetStateAction<RequestPagesZmenaUdajovZiadost>
  >;
  data: ZmenaUdajovZiadostData;
}

const Summary: React.FC<ZvjsOsobneUdajeSummaryProps> = ({
  setCurrentPage,
  data,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { tuiz } = useUITranslation();

  const onContiune = () => {
    navigate("#confirmation", { replace: true });
    setCurrentPage(RequestPagesZmenaUdajovZiadost.CONFIRMATION);
  };

  return (
    <Stack spacing={5}>
      <ZvjsSummaryTable>
        <ZvjsSummaryTableRequestHeader
          label={tuiz("ziadostiVseobecne.osobneUdaje")}
        />
        <ZvjsSummaryTableRequestComponent
          label={tuiz("ziadostiVseobecne.menoPriezvisko")}
          value={data.name as string}
          isBorder={true}
          textAlign={"left"}
        />
        <ZvjsSummaryTableRequestComponent
          label={tuiz("ziadostiVseobecne.datumNarodenia")}
          value={data.date as string}
          isBorder={true}
          textAlign={"left"}
        />
        <ZvjsSummaryTableRequestHeader
          label={tuiz("ziadostiVseobecne.udajeZiadosti")}
        />
        {/*TODO get name of request from request counter*/}
        <ZvjsSummaryTableRequestComponent
          label={"Žiadosť"}
          value={"Univerzálna žiadosť"}
          isBorder={true}
          textAlign={"left"}
        />
        <ZvjsSummaryTableRequestComponent
          label={tuiz("ziadost.vseobecnaZiadost.labelNazovAleboPredmet")}
          value={data.nameRequest as string}
          isBorder={true}
          textAlign={"left"}
        />
        <ZvjsSummaryTableRequestComponent
          label={"Obsah žiadosti"}
          value={data.data as string}
          isBorder={false}
          textAlign={"left"}
          longText={true}
          applyJsFromat={true}
        />
      </ZvjsSummaryTable>
      <div>
        <Typography fontSize={28} fontWeight={700}>
          {tuiz("ziadostiVseobecne.potvrdenieUdajov")}
        </Typography>
        <Typography>{tuiz("notifikacie.zavaznePotvrdenie")}</Typography>
      </div>
      <Stack direction={"row"} spacing={2}>
        <ZvjsButton
          onClick={() => onContiune()}
          zvjsVariant={"secondaryAction"}
          text={capitalize(t("spec.section.Request.Buttons.SubmitRequest"))}
          endIcon={<ChevronRight style={{ height: 45, width: 45 }} />}
        />
        <ZvjsButton
          zvjsVariant={"cancelAction"}
          text={capitalize(t("spec.section.Request.Buttons.CancelRequest"))}
          startIcon={<ClearIcon style={{ height: 45, width: 45 }} />}
          onClick={() =>
            navigate("/auth/MyData/BasicClientData", { replace: true })
          }
        />
      </Stack>
    </Stack>
  );
};

export default Summary;
