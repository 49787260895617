import React from "react";
import { Grid, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { ZvjsButton } from "../../../components";
import { useUITranslation } from "../../../../store/context/translation-context";
import { RequestPages } from "../../Request";
import { useSelector } from "react-redux";
import questionnaireRedux from "../../redux";
import { useNavigate } from "react-router-dom";

interface IntroductionProps {
  setCurrentPage: React.Dispatch<React.SetStateAction<RequestPages>>;
}

const Introduction: React.FC<IntroductionProps> = ({ setCurrentPage }) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const introductionData =
    useSelector(questionnaireRedux.selectors.getIntroductionDisplayData()) ||
    [];
  const hasQuestionnaireAnyItems = useSelector(
    questionnaireRedux.selectors.getHasQuestionnaireAnyItems
  );

  console.log("INTRODUCTION RERENDER");

  const onStartRequestClickHandler = () => {
    if (hasQuestionnaireAnyItems) {
      setCurrentPage((prevState) => prevState + 1);
      navigate("#questionnaire");
    } else {
      // if there are no items in questionnaire then skip questionnaire generator page and display request summary
      setCurrentPage((prevState) => prevState + 2);
      navigate("#summary");
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant={"h4"}>
          {capitalize(tui("informácie k tejto žiadosti"))}
        </Typography>
      </Grid>
      {introductionData.map((info, index) => (
        <Grid item key={index} xs={12}>
          <Typography
            variant={"h4"}
            fontWeight={400}
            fontStyle={"italic"}
            key={index}
          >
            {info}
          </Typography>
        </Grid>
      ))}
      <Grid item xs={12}>
        <ZvjsButton
          zvjsVariant={"secondaryAction"}
          text={capitalize(tui("začať teraz"))}
          endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
          onClick={onStartRequestClickHandler}
        />
      </Grid>
    </Grid>
  );
};

export default Introduction;
