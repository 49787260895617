import React from "react";
import { Stack } from "@mui/system";
import { Typography, useTheme } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ZvjsButton, ZvjsTextField } from "../../index";
import { useModal } from "../../../../store/context/modal-context";
import { useSnackbar } from "../../../../store/context/snackbar-context";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { capitalize } from "@mui/material/utils";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dashboard_SC_KIO_0200 } from "../../../specs";
import { ZakladneUdajeKlientaProps } from "../../../../pages/auth/MojeUdajePages/ZakladneUdajeKlienta";

//https://www.react-hook-form.com/get-started/#SchemaValidation
//https://www.cluemediator.com/how-to-add-a-react-select-dropdown-with-react-hook-form-in-react
// todo add enums for controlers
// todo finish back button and "the pain of the back button"

const ZvjsOsobneUdajeFormular: React.FC<ZakladneUdajeKlientaProps> = ({
  data,
}) => {
  const { closeModal } = useModal();
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const schema = yup
    .object({
      // toto je pre dropdown
      // maritalStatus: yup.number().nullable().required("Vyberte rodinný stav"), // todo add translation
      // highestEducation: yup.number().nullable().required("Vyberte najvyššie dosiahnuté vzdelanie"), // todo add translation
      // pensionReceived: yup.number().nullable().required("Vyberte poberaný dôchodok"), // todo add translation
      // typeOfDiet: yup.number().nullable().required("Vyberte poberaný druh stravy"), // todo add translation
      maritalStatus: yup
        .string()
        .required("Napíšte rodinný stav")
        .default(
          data.socialnaDiagnostikaOdsudeneho?.data?.at(0)?.rodinnyStavNazov ??
            ""
        ), // todo add translation
      highestEducation: yup
        .string()
        .required("Napíšte najvyššie dosiahnuté vzdelanie")
        .default(
          data.socialnaDiagnostikaOdsudeneho.data?.at(0)?.vzdelanieNazov ?? ""
        ), // todo add translation
      pensionReceived: yup
        .string()
        .required("Napíšte poberaný dôchodok")
        .default(
          data.feooKlient.data?.data?.zakladneUdajeKlienta?.dochodca
            ? "áno"
            : "nie"
        ), // todo add translation
      typeOfDiet: yup
        .string()
        .required("Napíšte poberaný druh stravy")
        .default(
          data.klientUni.data?.data?.rozsireneUdajeKlienta?.druhStravnejDavky ??
            ""
        ), // todo add translation
    })
    .required();
  type FormData = yup.InferType<typeof schema>;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: schema.cast(undefined),
    resolver: yupResolver(schema),
  });
  const onSubmit = (formData: FormData) => {
    // function that check if something has changed using only if
    const checkIfSomethingHasChanged = (): boolean => {
      let somethingHasChanged = false;
      if (
        formData.maritalStatus !==
        data.socialnaDiagnostikaOdsudeneho?.data?.at(0)?.rodinnyStavNazov
      ) {
        somethingHasChanged = true;
      } else if (
        formData.highestEducation !==
        data.socialnaDiagnostikaOdsudeneho.data?.at(0)?.vzdelanieNazov
      ) {
        somethingHasChanged = true;
      } else if (
        formData.pensionReceived !==
        (data.feooKlient.data?.data?.zakladneUdajeKlienta?.dochodca
          ? "áno"
          : "nie")
      ) {
        somethingHasChanged = true;
      } else if (
        formData.typeOfDiet !==
        data.klientUni.data?.data?.rozsireneUdajeKlienta?.druhStravnejDavky
      ) {
        somethingHasChanged = true;
      } else {
        somethingHasChanged = false;
      }
      return somethingHasChanged;
    };

    if (checkIfSomethingHasChanged()) {
      // todo navigate to universal request
      console.log("zmena stranky na universal request");
      // navigate("/auth/Requests/universalRequest/personalData", { state: { data } })
      let stringData = "";
      if (
        formData.maritalStatus !==
        data.socialnaDiagnostikaOdsudeneho?.data?.at(0)?.rodinnyStavNazov
      ) {
        stringData +=
          "Rodinný stav: \nPôvodná hodnota: " +
          data.socialnaDiagnostikaOdsudeneho?.data?.at(0)?.rodinnyStavNazov +
          "\nNova hodnota: " +
          formData.maritalStatus +
          "\n\n";
      }
      if (
        formData.highestEducation !==
        data.socialnaDiagnostikaOdsudeneho.data?.at(0)?.vzdelanieNazov
      ) {
        stringData +=
          "Najvyššie dosiahnuté vzdelanie: \nPôvodná hodnota: " +
          data.socialnaDiagnostikaOdsudeneho.data?.at(0)?.vzdelanieNazov +
          "\n Nova hodnota: " +
          formData.highestEducation +
          "\n\n";
      }
      if (
        formData.pensionReceived !==
        (data.feooKlient.data?.data?.zakladneUdajeKlienta?.dochodca
          ? "áno"
          : "nie")
      ) {
        stringData +=
          "Poberaný dôchodok: \nPôvodná hodnota: " +
          (data.feooKlient.data?.data?.zakladneUdajeKlienta?.dochodca
            ? "áno"
            : "nie") +
          "\nNova hodnota: " +
          formData.pensionReceived +
          "\n\n";
      }
      if (
        formData.typeOfDiet !==
        data.klientUni.data?.data?.rozsireneUdajeKlienta?.druhStravnejDavky
      ) {
        stringData +=
          "Poskytovaný druh stravy: \nPôvodná hodnota: " +
          data.klientUni.data?.data?.rozsireneUdajeKlienta?.druhStravnejDavky +
          "\nNova hodnota: " +
          formData.typeOfDiet;
      }

      navigate(
        `/auth/${Dashboard_SC_KIO_0200.Requests}/universalRequest/personalData`,
        {
          state: {
            nameRequest: "Zmena osobných údajov",
            data: stringData,
          },
        }
      );
    } else {
      openSnackbar("Neboli vykonané žiadne zmeny", "error");
    }
    closeModal();
  };

  // this function is because to trigger validate we need have same function in onBlur
  const dummyFunction = () => {
    return;
  };

  // useEffect(() => {
  //   const mapErrors = async () => {
  //     console.log("som v useeffecte");
  //     const errorsArray = Object.entries(errors);
  //     for (const error of errorsArray) {
  //       const [key, value] = error;
  //       if (value?.message) {
  //         console.log(" som v ife");
  //         openSnackbar(value.message, "error");
  //       }
  //     }
  //   };
  //   const promise = mapErrors();
  //   promise.then();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [errors]);

  return (
    <Stack width={"70vw"} maxHeight={"80vh"} overflow={"auto"}>
      {/* todo add translation */}
      <Typography variant={"h2"} mb={2}>
        Žiadosť o zmenu údajov
      </Typography>{" "}
      <form
        style={{ width: "20 rem" }}
        onSubmit={handleSubmit(onSubmit)}
        // TODO think about how to make live validation work - low priority
        // onBlur={handleSubmit(dummyFunction)}
      >
        <Stack spacing={1}>
          {/* todo add translation */}
          <ZvjsTextField
            label={"Meno"}
            value={data.klientUni.data?.data?.zakladneUdajeKlienta?.meno}
            disabled
          />
          {/* todo add translation */}
          <ZvjsTextField
            label={"Priezvisko"}
            value={data.klientUni.data?.data?.zakladneUdajeKlienta?.priezvisko}
            disabled
          />
          {/* todo add translation */}
          <ZvjsTextField
            label={"Datum narodenia"}
            value={
              data.klientUni.data?.data?.zakladneUdajeKlienta?.datumNarodenia
                ? new Date(
                    data.klientUni.data?.data?.zakladneUdajeKlienta?.datumNarodenia
                  ).zvjsToString()
                : ""
            }
            disabled
          />
          {/* todo add translation */}
          <ZvjsTextField
            label={"Miesto narodenia"}
            value={data.eoo_fyzickaOsoba_DetailData.data?.data?.miestoNarodenia}
            disabled
          />
          {/* todo add translation */}
          <ZvjsTextField
            label={"Rodinný stav"}
            register={{ ...register("maritalStatus") }}
            error={errors.maritalStatus !== undefined}
            validationErrorMessage={errors.maritalStatus?.message}
          />
          {/* todo add translation */}
          <ZvjsTextField
            label={"Najvyššie dosiahnuté vzdelanie"}
            register={{ ...register("highestEducation") }}
            error={errors.highestEducation !== undefined}
            validationErrorMessage={errors.highestEducation?.message}
          />
          {/* todo add translation */}
          <ZvjsTextField
            label={"Poberaný dôchodok"}
            register={{ ...register("pensionReceived") }}
            error={errors.pensionReceived !== undefined}
            validationErrorMessage={errors.pensionReceived?.message}
          />
          {/* todo add translation */}
          <ZvjsTextField
            label={"Poskytovaný druh stravy"}
            register={{ ...register("typeOfDiet") }}
            error={errors.typeOfDiet !== undefined}
            validationErrorMessage={errors.typeOfDiet?.message}
          />
        </Stack>
        <Stack direction={"row"} spacing={2} mt={3} justifyContent={"center"}>
          <ZvjsButton
            type={"submit"}
            zvjsVariant={"secondaryAction"}
            disabled={Object.keys(errors).length > 0}
            text={capitalize(t("spec.section.Request.Buttons.ContinueRequest"))}
            endIcon={<ChevronRight style={{ height: 45, width: 45 }} />}
          />
          <ZvjsButton
            zvjsVariant={"cancelAction"}
            text={capitalize(t("spec.section.Request.Buttons.CancelRequest"))}
            startIcon={<ClearIcon style={{ height: 45, width: 45 }} />}
            onClick={closeModal}
          />
        </Stack>
      </form>
    </Stack>
  );
};
export default ZvjsOsobneUdajeFormular;
