import { ZvjsIframe } from "../../../common";
import { useLocation, useNavigate } from "react-router-dom";
import AppActivityGuard from "../../../utils/AppActivityGuard";

const BezpecnyInternetOpenPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const openUrl = new URLSearchParams(location.search).get("page") || "";
  return (
    <AppActivityGuard>
      <ZvjsIframe
        iframeTitle={"title"}
        url={`https://${openUrl}`}
        onClose={() => navigate("/auth/SafeInternet", { replace: true })}
      />
    </AppActivityGuard>
  );
};

export default BezpecnyInternetOpenPage;
