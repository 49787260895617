export enum WebDavFileType {
  directory,
  file,
}

export type WebDavItemType = {
  name: string;
  type: WebDavFileType;
  contentType: string;
};

export type WebDavList = WebDavItemType[];

/**
 * @description get information from the WebDAV document
 * @param webDavDocument retrieved via DOMParser().parseFromString(...)
 * @returns structured information about WebDAV document
 */
export const webDavGetInfo = (webDavDocument: Document): WebDavList => {
  const result: WebDavList = [];

  const responses = webDavDocument.getElementsByTagName("d:response");
  for (let i = 0; i < responses.length; i++) {
    const name: string =
      responses[i].getElementsByTagName("d:href")[0].textContent ?? "";

    const type: WebDavFileType =
      responses[i].getElementsByTagName("d:collection").length > 0
        ? WebDavFileType.directory
        : WebDavFileType.file;

    const contentType: string =
      responses[i].getElementsByTagName("d:getcontenttype").length > 0
        ? responses[i].getElementsByTagName("d:getcontenttype")[0]
            .textContent ?? ""
        : "";

    result.push({
      name: name,
      type: type,
      contentType: contentType,
    });
  }

  return result;
};

/**
 * @description check if the WebDAV document describes file
 * @param webDavDocument retrieved via DOMParser().parseFromString(...)
 * @returns true if the WebDAV document is describing file
 */
export const webDavIsFile = (webDavDocument: Document): boolean => {
  const info = webDavGetInfo(webDavDocument);
  return info.length === 1 && info[0].type === WebDavFileType.file;
};

/**
 * @description check if the WebDAV document describes directory
 * @param webDavDocument retrieved via DOMParser().parseFromString(...)
 * @returns true if the WebDAV document is describing directory
 */
export const webDavIsDirectory = (webDavDocument: Document): boolean => {
  return !webDavIsFile(webDavDocument);
};

/**
 * @description get ContentType
 * @param webDavDocument retrieved via DOMParser().parseFromString(...)
 * @returns content type in case of file or empty string otherwise
 */
export const webDavFileContentType = (webDavDocument: Document): string => {
  const info = webDavGetInfo(webDavDocument);
  return info.length === 1 && info[0].type === WebDavFileType.file
    ? info[0].contentType
    : "";
};
