import React, {
  createContext,
  useContext,
  useEffect,
  useSyncExternalStore,
} from "react";
import { isElectron } from "../../utils/helpers";

type OnlineStatusContextType = {
  isOnline: boolean;
};

const OnlineStatusContext = createContext<OnlineStatusContextType | null>(null);

export const useOnlineStatus = () =>
  useContext(OnlineStatusContext) as OnlineStatusContextType;

type Props = {
  children: React.ReactNode;
};

let isInitial = true;

export const OnlineStatusProvider: React.FC<Props> = ({ children }) => {
  function getSnapshot() {
    return navigator.onLine;
  }

  function subscribe(callback: () => void) {
    window.addEventListener("online", callback);
    window.addEventListener("offline", callback);
    return () => {
      window.removeEventListener("online", callback);
      window.removeEventListener("offline", callback);
    };
  }

  const isOnline = useSyncExternalStore(subscribe, getSnapshot);

  useEffect(() => {
    // reload page when you are using electron and connectivity changed
    // used to avoid electron buggy behaviour when switching between offline and online
    if (isElectron()) {
      if (isInitial) {
        isInitial = false;
        return;
      }

      window.location.reload();
    }
  }, [isOnline]);

  return (
    <OnlineStatusContext.Provider
      value={{
        isOnline,
      }}
    >
      {children}
    </OnlineStatusContext.Provider>
  );
};
