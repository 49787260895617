import { AppBar, Box, Stack, Toolbar, Typography } from "@mui/material";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ZvjsButton, ZvjsHintModalBody } from "../common";
import { useUITranslation } from "../store/context/translation-context";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as ZVJSIcon } from "../assets/icons/Other/zvjs.svg";
import { ReactComponent as PersonalHelpIcon } from "../assets/icons/Other/personal_help.svg";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ZVJS_COLORS } from "../theme/zvjs_theme";
import { useLocation, useNavigate } from "react-router-dom";
import { useModal } from "../store/context/modal-context";
import AuthContext from "../store/context/auth-context";

export type FooterRef = {
  getHeight: () => number;
};

interface FooterProps {
  zoomIn: () => void;
}

// eslint-disable-next-line no-empty-pattern
const Footer = forwardRef<FooterRef, FooterProps>((props, ref) => {
  const appbarRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    getHeight: () => {
      if (appbarRef.current) {
        return appbarRef.current.clientHeight;
      }
      return 0;
    },
  }));

  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [helpPageNumber, setHelpPageNumber] = useState(1);
  const [showOsobnaPomoc, setShowOsobnaPomoc] = useState(true);
  const { isLoggedIn } = useContext(AuthContext);

  const { openModal, closeModal } = useModal();

  useImperativeHandle(ref, () => ({
    getHeight: () => {
      if (appbarRef.current) {
        return appbarRef.current.clientHeight;
      }
      return 0;
    },
  }));

  useEffect(() => {
    // for now works only for this pages
    if (location.pathname.includes("Requests")) {
      setHelpPageNumber(1);
    } else if (location.pathname.includes("examples")) {
      setHelpPageNumber(2);
    }
    if (!isLoggedIn) {
      setShowOsobnaPomoc(true);
    } else {
      setShowOsobnaPomoc(false);
    }
  }, [isLoggedIn, location]);

  return (
    <AppBar
      ref={appbarRef}
      position="fixed"
      color="primary"
      sx={{
        top: "auto",
        bottom: 0,
        borderTop: 1,
        borderBlockColor: ZVJS_COLORS.BLUE_2,
      }}
    >
      <Toolbar color="default">
        <Stack width="100%" direction="row" padding={2} spacing={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <ZVJSIcon />
            <Typography variant="h5">{tui("zvjs")}</Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" spacing={2} alignItems="center">
            <ZvjsButton
              text={capitalize(tui("tlacidla.zvacsitPismo"))}
              startIcon={
                <AddCircleOutlineIcon style={{ height: 33, width: 33 }} />
              }
              zvjsVariant={"primaryAction"}
              onClick={() => {
                console.debug("footer_increase_font_size");
                //
                props.zoomIn();
              }}
              style={{ textTransform: "none", lineHeight: "1" }}
            />
            {showOsobnaPomoc && (
              <ZvjsButton
                text={capitalize(tui("osobná pomoc"))}
                startIcon={
                  <PersonalHelpIcon
                    style={{ fill: ZVJS_COLORS.WHITE, height: 28, width: 28 }}
                  />
                }
                zvjsVariant={"primaryAction"}
                onClick={() => {
                  // console.debug("footer_personal_help");
                  // navigate(`help/${helpPageNumber}`);
                  openModal(
                    <ZvjsHintModalBody
                      title={tui("nápoveda")}
                      text={
                        "V prípade problémov s prihlásením sa obráťte na odborný personál Vášho ústavu."
                      }
                      onClose={closeModal}
                      color={ZVJS_COLORS.BLUE_2}
                    />
                  );
                }}
                style={{ textTransform: "none", lineHeight: "1" }}
              />
            )}
            <ZvjsButton
              text={capitalize(tui("príručka"))}
              startIcon={<InsertDriveFile style={{ height: 32, width: 32 }} />}
              zvjsVariant={"primaryAction"}
              onClick={() => {
                console.debug("footer_manual");
                navigate(`help/${helpPageNumber}`);
              }}
              style={{
                textTransform: "none",
                lineHeight: "1",
                minWidth: "9rem",
              }}
            />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
});

export default Footer;
