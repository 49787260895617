import {
  State,
  UserCalcData,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";

const AVAILABLE_ACTIVITIES = "ACTIVITIES";
export const ActivitiesGridConstants = {
  AVAILABLE_ACTIVITIES,
};

/**
 * OnInit
 */

export interface ActivityType {
  id: number;
  name: string;
}

export const SC_KIO_050601_ActivitiesGrid_onInit = async (
  calcData: UserCalcData
): Promise<void> => {
  // TODO replace with actual fetch - filter out activities which client cannot attend
  const listOfActivities: ActivityType[] = [
    {
      id: 1,
      name: "Večer kultúry a divadla",
    },
    {
      id: 2,
      name: "Turnaj vo stolnom tenise",
    },
    {
      id: 3,
      name: "Hudobný festival väzenských talentov",
    },
    {
      id: 4,
      name: "Literárna čítanka a diskusia",
    },
    {
      id: 5,
      name: "Športový deň so súťažami",
    },
    {
      id: 6,
      name: "Výtvarná výstava a dielne",
    },
  ];

  calcData[ActivitiesGridConstants.AVAILABLE_ACTIVITIES] = listOfActivities;
};

/**
 * Selectors
 */

const getActivitiesGridDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      availableActivities: state.userCalcData[
        ActivitiesGridConstants.AVAILABLE_ACTIVITIES
      ] as ActivityType[],
    };
  });

export const allSelectors = {
  getActivitiesGridDisplayData,
};
