import { Box, Stack } from "@mui/system";
import { ZVJS_COLORS } from "../../../theme/zvjs_theme";
import { Grid, InputBase, Typography } from "@mui/material";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { ReactComponent as Decline } from "../../../assets/icons/Other/decline.svg";
import { ReactComponent as Search } from "../../../assets/icons/Other/search.svg";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { ZvjsButton } from "../index";
import ChatBotMessageUser from "./ChatBotMessageUser";
import ChatBotMessageBot from "./ChatBotMessageBot";
import ChatBotSelect, { ZvjsChatBotSelectData } from "./ChatBotSelect";
import { useNavigate } from "react-router-dom";
import ChatBotTypingMessageBot from "./ChatBotTypingMessageBot";
import { ChatBotRoutes } from "./ChatBotRouting";
import { API_Clients } from "../../../store/context/dataApi/Data";

export enum ZvjsChatBotMessageType {
  BOT_MESSAGE,
  USER_MESSAGE,
  SELECT,
}

interface ZvjsChatBotDataInterface {
  messageType: ZvjsChatBotMessageType;
}

interface ZvjsChatBotMessage extends ZvjsChatBotDataInterface {
  messageType:
    | ZvjsChatBotMessageType.BOT_MESSAGE
    | ZvjsChatBotMessageType.USER_MESSAGE;
  message: string;
}

interface ZvjsChatBotSelect extends ZvjsChatBotDataInterface {
  messageType: ZvjsChatBotMessageType.SELECT;
  data: ZvjsChatBotSelectData[];
}

export type ZvjsChatBotData = ZvjsChatBotMessage | ZvjsChatBotSelect;

interface ChatBotWindowProps {
  setOpenchat: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChatBotWindow: React.FC<ChatBotWindowProps> = ({ setOpenchat }) => {
  const navigate = useNavigate();
  const bottomRef = useRef<HTMLDivElement>(null);

  const [messages, setMessages] = useState<ZvjsChatBotData[]>([
    {
      messageType: ZvjsChatBotMessageType.BOT_MESSAGE,
      message:
        // TODO replace with translation
        "Dobrý deň. Volám sa Kio a som virtuálny pomocník a pomôžem Vám s hľadaním.",
    },
    {
      messageType: ZvjsChatBotMessageType.BOT_MESSAGE,
      message:
        // TODO replace with translation
        'Do poľa "Čo hľadáte?" zapíšte stručne čo potrebujete vyriešiť a stlačte modré tlačidlo vpravo.',
    },
  ]);
  const [inputValue, setInputValue] = useState<string>("");
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    // scroll to bottom every time message changes
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    // check if string contains non-white characters
    if (!isFetching && inputValue.trim().length > 0) {
      setMessages((prevState) => [
        ...prevState,
        {
          messageType: ZvjsChatBotMessageType.USER_MESSAGE,
          message: inputValue.trim(),
        },
      ]);
      setInputValue("");
      setIsFetching(true);

      API_Clients()
        .then((clients) => {
          clients
            .HASURA_Post("/api/rest/menu/search", {
              body: {
                keywords: inputValue.trim(),
              },
            })
            .then((data) => {
              const items: ZvjsChatBotSelectData[] = [];

              if (
                data.data?.menuSearch !== undefined &&
                data.data.menuSearch.length > 0
              ) {
                setMessages((prevState) => [
                  ...prevState,
                  {
                    messageType: ZvjsChatBotMessageType.BOT_MESSAGE,
                    // TODO replace with translation
                    message: "Našiel som pre Vás tieto možnosti:",
                  },
                ]);
              } else {
                setMessages((prevState) => [
                  ...prevState,
                  {
                    messageType: ZvjsChatBotMessageType.BOT_MESSAGE,
                    message:
                      // TODO replace with translation
                      "Pre zadaný výraz som nič nenašiel. Skúste dopyt formulať inak.",
                  },
                ]);
              }

              data.data?.menuSearch?.forEach((item) => {
                const route = ChatBotRoutes[item.name?.trim() ?? ""];
                // if you found route, use it
                if (route !== undefined) {
                  items.push({
                    title: item.name?.trim() ?? "",
                    onClick: () => {
                      console.debug(`Click [${item.name?.trim()}]`);
                      navigate(route);
                    },
                  });
                }
              });

              setMessages((prevState) => [
                ...prevState,
                {
                  messageType: ZvjsChatBotMessageType.SELECT,
                  data: items,
                },
              ]);
            })
            .catch((error) => {
              console.error(`Error: ${error}`);
              setMessages((prevState) => [
                ...prevState,
                {
                  messageType: ZvjsChatBotMessageType.BOT_MESSAGE,
                  // TODO replace with translation
                  message: "Hľadanie výrazu sa nepodarilo. Skúste neskôr.",
                },
              ]);
            })
            .finally(() => setIsFetching(false));
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          position: "fixed",
          bottom: "16rem",
          right: "2rem",
          width: "29rem",
          height: "33rem",
          border: "0px",
          backgroundColor: ZVJS_COLORS.GREY,
          borderRadius: "1rem",
          boxShadow: "0px 0px 17px 1px rgba(0,0,0,0.75)",
          WebkitBoxShadow: "0px 0px 17px 1px rgba(0,0,0,0.75)",
          MozBoxShadow: "0px 0px 17px 1px rgba(0,0,0,0.75)",
        }}
      >
        <Grid
          container
          direction={"column"}
          height={"100%"}
          justifyContent={"space-between"}
          flexWrap={"nowrap"}
        >
          <Grid
            item
            style={{
              height: "4rem",
              backgroundColor: ZVJS_COLORS.BLUE,
              borderTopRightRadius: "1rem",
              borderTopLeftRadius: "1rem",
            }}
          >
            <Grid
              container
              direction="row"
              alignItems={"center"}
              height={"100%"}
            >
              <Grid item xs={6}>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems={"flex-start"}
                  justifyContent={"center"}
                >
                  <SmartToyIcon
                    style={{ fontSize: "2rem", color: ZVJS_COLORS.WHITE }}
                  />
                  <Typography
                    variant="body1"
                    style={{ color: ZVJS_COLORS.WHITE }}
                  >
                    {/*TODO replace with translation*/}
                    Virtuálny pomocník
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                alignItems={"center"}
                display={"flex"}
                justifyContent={"flex-end"}
              >
                <Decline
                  style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    fill: "white",
                    marginRight: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenchat(false)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid height={"100%"} item overflow={"auto"}>
            <Stack direction={"column"} spacing={1.5} margin={2}>
              {messages.map((data, index) =>
                data.messageType === ZvjsChatBotMessageType.BOT_MESSAGE ? (
                  <ChatBotMessageBot key={index} message={data.message} />
                ) : data.messageType === ZvjsChatBotMessageType.SELECT ? (
                  <ChatBotSelect key={index} data={data.data} />
                ) : (
                  <ChatBotMessageUser key={index} message={data.message} />
                )
              )}
              {isFetching && <ChatBotTypingMessageBot />}
              {/*div used just for the scroll to the bottom purpose*/}
              <div ref={bottomRef}></div>
            </Stack>
          </Grid>
          <Grid
            item
            style={{
              height: "5rem",
              backgroundColor: ZVJS_COLORS.GREY_2,
              borderBottomLeftRadius: "1rem",
              borderBottomRightRadius: "1rem",
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems={"center"}
              justifyContent={"center"}
              height={"100%"}
              marginLeft={"0.5rem"}
              marginRight={"0.5rem"}
            >
              <div
                style={{
                  backgroundColor: ZVJS_COLORS.WHITE,
                  borderRadius: "3px",
                  width: "100%",
                  height: "2.5rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <InputBase
                  style={{
                    backgroundColor: ZVJS_COLORS.WHITE,
                    width: "99%",
                  }}
                  placeholder="Čo hľadáte?" //todo add correct translation
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
              <ZvjsButton
                zvjsVariant={"primaryAction"}
                style={{ height: "2.5rem" }}
                disabled={isFetching}
                type={"submit"}
              >
                <Search fill={ZVJS_COLORS.WHITE} />
              </ZvjsButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default ChatBotWindow;
