import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsLabelFlagTypes,
  ZvjsItemTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
  LabelType,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050204",
  basicInfo: [
    {
      text: "Obvinený alebo odsúdený môže, okrem peňažných prostriedkov blokovaných na konte, poukázať peňažné prostriedky blízkej osobe, na úhradu nákladov, ktoré by obvinenému/ odsúdenému spôsobili ujmu, na úhradu nákladov za sprístupnenie informácií či úhradu súdnych a správnych poplatkov. Ak sú peňažné prostriedky zasielané prostredníctvom Slovenskej pošty, tak poštovné znáša obvinený a odsúdený.",
    },
  ],
  dataCalculations: {
    AVAILABLE_ACCOUNT_AMOUNT: {
      calculateAt: Calculate.AT_INIT,
    },
    POSSIBLE_WITHDRAWAL_AMOUNT: {
      calculateAt: Calculate.AT_INIT,
    },
    CAN_TRANSFER_FUNDS_TO_CLOSE_PERSON: {
      calculateAt: Calculate.AT_INIT,
    },
    CAN_TRANSFER_FUNDS_TO_OTHER_PERSON_OR_HARM_CAUSED: {
      calculateAt: Calculate.AT_INIT,
    },
    CAN_TRANSFER_FUNDS_TO_CERTAIN_PERSON: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  items: [
    {
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.napovedaZnizenieKreditu",
      flag: ZvjsLabelFlagTypes.INFO,
    },
    {
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.napovedaZiadnyVplyvnVyskuKreditu",
      flag: ZvjsLabelFlagTypes.INFO,
    },
    {
      id: "SC_KIO_050204_QUES_01",
      title:
        "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelOdoslatKomu",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      options: [
        {
          id: "E_KIO_24_01",
          label: "Blízkej osobe",
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "CAN_TRANSFER_FUNDS_TO_CLOSE_PERSON",
              conditionMetWhenValueIs: true,
            },
          ],
        },
        {
          id: "E_KIO_24_02",
          label:
            "Orgánu verejnej moci, ktorý klientovi sprístupnil informáciu s následným rozhodnutím o úhrade nákladov",
        },
        {
          id: "E_KIO_24_03",
          label:
            "Inej osobe, voči ktorej má klient povinnosť peňažnej úhrady a jej neuhradením by spôsobilo klientovi ujmu",
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "CAN_TRANSFER_FUNDS_TO_OTHER_PERSON_OR_HARM_CAUSED",
              conditionMetWhenValueIs: true,
            },
          ],
        },
        {
          id: "E_KIO_24_04",
          label: "Súdu alebo inému správnemu orgánu",
        },
        {
          id: "E_KIO_24_05",
          label: "Určenej osobe",
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "CAN_TRANSFER_FUNDS_TO_CERTAIN_PERSON",
              conditionMetWhenValueIs: true,
            },
          ],
        },
      ],
    },
    {
      id: "SC_KIO_050204_QUES_02",
      title:
        "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelSposobOdoslania",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      options: [
        {
          id: "E_KIO_23_01",
          label: "Na účet v banke",
        },
        {
          id: "E_KIO_23_02",
          label: "Poštová poukážka",
        },
      ],
    },
    {
      id: "SC_KIO_050204_QUES_03",
      title: "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelSuma",
      type: ZvjsQuestionTypes.TEXT,
      inputType: "number",
      isRequired: true,
    },
    {
      id: "SC_KIO_050204_QUES_04",
      title:
        "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelVariabilnySymbol",
      type: ZvjsQuestionTypes.TEXT,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050204_QUES_01",
          value: ["E_KIO_24_01"],
        },
      ],
    },
    {
      id: "SC_KIO_050204_QUES_04",
      title:
        "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelVariabilnySymbol",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050204_QUES_01",
          value: ["E_KIO_24_02", "E_KIO_24_03", "E_KIO_24_04", "E_KIO_24_05"],
        },
      ],
    },
    {
      type: ZvjsItemTypes.SECTION,
      title: "Blízkej osobe",
      items: [
        {
          type: ZvjsItemTypes.SINGLE_VALUE_LABEL,
          text: "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelMoznaSumaNaCerpanie",
          value: "POSSIBLE_WITHDRAWAL_AMOUNT",
          valueType: LabelType.EURO,
        },
      ],
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050204_QUES_01",
          value: ["E_KIO_24_01"],
        },
      ],
    },
    {
      type: ZvjsItemTypes.SECTION,
      title: "Inej osobe alebo určenej osobe",
      items: [
        {
          type: ZvjsItemTypes.SINGLE_VALUE_LABEL,
          text: "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelMoznaSumaNaCerpanie",
          value: "POSSIBLE_WITHDRAWAL_AMOUNT",
          valueType: LabelType.EURO,
        },
        {
          id: "SC_KIO_050204_QUES_05",
          title:
            "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelAkeNakladyUhradit",
          type: ZvjsQuestionTypes.TEXT,
          isRequired: true,
        },
        {
          type: ZvjsItemTypes.SECTION,
          title:
            "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelKomuPoslat",
          items: [
            {
              id: "SC_KIO_050204_QUES_06",
              title:
                "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelMenoPriezviskoPravnickaOsoba",
              type: ZvjsQuestionTypes.TEXT,
              isRequired: true,
            },
            {
              id: "SC_KIO_050204_QUES_07",
              title:
                "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelVyhladatPOpodlaICO",
              type: ZvjsQuestionTypes.TEXT,
              isRequired: true,
            },
            {
              type: ZvjsItemTypes.SECTION,
              title: "Adresa",
              items: [
                {
                  id: "SC_KIO_050204_QUES_08",
                  title: "Štát",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
                {
                  id: "SC_KIO_050204_QUES_09",
                  title: "Kraj",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
                {
                  id: "SC_KIO_050204_QUES_10",
                  title: "Okres",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
                {
                  id: "SC_KIO_050204_QUES_11",
                  title: "Obec",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
                {
                  id: "SC_KIO_050204_QUES_12",
                  title: "PSČ",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
                {
                  id: "SC_KIO_050204_QUES_13",
                  title: "Súpisné číslo",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
                {
                  id: "SC_KIO_050204_QUES_14",
                  title: "Orientačné číslo",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
                {
                  id: "SC_KIO_050204_QUES_15",
                  title: "Ulica",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
              ],
            },
            {
              type: ZvjsItemTypes.SECTION,
              title: "Bankový účet vedený v SR",
              items: [
                {
                  id: "SC_KIO_050204_QUES_16",
                  title: "ziadostiVseobecne.IBAN",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
              ],
            },
          ],
        },
      ],
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050204_QUES_01",
          value: ["E_KIO_24_03", "E_KIO_24_05"],
        },
      ],
    },
    {
      type: ZvjsItemTypes.SECTION,
      title: "Na úhradu nákladov za sprístupnenie informácií",
      items: [
        {
          type: ZvjsItemTypes.SINGLE_VALUE_LABEL,
          text: "ziadostiVseobecne.disponibilnaSuma",
          value: "AVAILABLE_ACCOUNT_AMOUNT",
          valueType: LabelType.EURO,
        },
      ],
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050204_QUES_01",
          value: ["E_KIO_24_02"],
        },
      ],
    },
    {
      type: ZvjsItemTypes.SECTION,
      title: "Súdu alebo inému správnemu orgánu",
      items: [
        {
          type: ZvjsItemTypes.SINGLE_VALUE_LABEL,
          text: "ziadostiVseobecne.disponibilnaSuma",
          value: "AVAILABLE_ACCOUNT_AMOUNT",
          valueType: LabelType.EURO,
        },
        {
          type: ZvjsItemTypes.SECTION,
          title:
            "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelKomuPoslat",
          items: [
            {
              id: "SC_KIO_050204_QUES_TODO_01",
              title:
                "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelNazovSudu",
              type: ZvjsQuestionTypes.TEXT,
              isRequired: true,
            },
            {
              id: "SC_KIO_050204_QUES_TODO_02",
              title:
                "ziadost.ziadostOpoukazaniePPosobamAleboSubjektom.labelVyhladatPOpodlaICO",
              type: ZvjsQuestionTypes.TEXT,
              isRequired: true,
            },
            // {
            //   id: "XY",
            //   type: ZvjsCustomQuestionTypes.TABLE,
            //   title: "Bankový účet vedený v SR",
            // },
            {
              type: ZvjsItemTypes.SECTION,
              title: "Bankový účet vedený v SR",
              items: [
                {
                  id: "SC_KIO_050204_QUES_TODO_03",
                  title: "ziadostiVseobecne.IBAN",
                  type: ZvjsQuestionTypes.TEXT,
                  isRequired: true,
                },
              ],
            },
          ],
        },
      ],
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050204_QUES_01",
          value: ["E_KIO_24_04"],
        },
      ],
    },
  ],
};
