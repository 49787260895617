import {
  State,
  UserCalcData,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";

const AVAILABLE_PROGRAMS = "PROGRAMS";
export const ProgramsGridConstants = {
  AVAILABLE_PROGRAMS,
};

/**
 * OnInit
 */

export interface ProgramType {
  id: string;
  name: string;
  content?: string;
}

export const SC_KIO_050602_ProgramsGrid_onInit = async (
  calcData: UserCalcData
): Promise<void> => {
  // TODO replace with actual fetch - filter out programs which client cannot attend or he has already applied for
  const listOfPrograms: ProgramType[] = [
    {
      id: "001",
      name: "Finančná gramotnosť",
      content:
        "Cieľ: Rozvoj základných finančných schopností účastníkov, plánovanie rozpočtu, správa úverov a investičná gramotnosť, s dôrazom na zodpovednú finančnú budúcnosť.",
    },
    {
      id: "002",
      name: "Násilie v partnerských vzťahoch",
      content:
        "Cieľ: Prevencia a riešenie násilia v partnerských vzťahoch. Poskytuje nástroje na identifikáciu, prevenciu a zvládanie násilia v intímnych vzťahoch.",
    },
    {
      id: "003",
      name: "Radikalizácia a extrémizmus",
      content:
        "Cieľ: Prevencia radikalizácie a rehabilitácia jednotlivcov, ktorí sa mohli zapojiť do extrémistických aktivít. Pomáha porozumieť faktorom vedúcim k radikalizácii a poskytuje alternatívy k extrémistickým ideológiám.",
    },
  ];

  calcData[ProgramsGridConstants.AVAILABLE_PROGRAMS] = listOfPrograms;
};

/**
 * Selectors
 */

const getProgramsGridDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      availablePrograms: state.userCalcData[
        ProgramsGridConstants.AVAILABLE_PROGRAMS
      ] as ProgramType[],
    };
  });

export const allSelectors = {
  getProgramsGridDisplayData,
};
