import {
  Calculate,
  LabelType,
  ZvjsItemTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050203",
  basicInfo: [
    {
      text: "ziadost.ziadostOdobrovlnuUhraduVyzivneho.zakladneInfo",
    },
    {
      text: "ziadost.ziadostOdobrovlnuUhraduVyzivneho.zakladneInfoMinimum",
    },
  ],
  dataCalculations: {
    AVAILABLE_ACCOUNT_AMOUNT: {
      calculateAt: Calculate.AT_INIT,
    },
    TOTAL_UNPAID_CHILD_SUPPORT_OBLIGATIONS_AND_CLAIMS_AMOUNT: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  items: [
    {
      type: ZvjsItemTypes.SINGLE_VALUE_LABEL,
      text: "Výška disponibilného konta:",
      value: "AVAILABLE_ACCOUNT_AMOUNT",
      valueType: LabelType.EURO,
    },
    {
      type: ZvjsItemTypes.SINGLE_VALUE_LABEL,
      text: "ziadost.ziadostOdobrovlnuUhraduVyzivneho.labelCelkovaSumaNesplatenychPohladavok",
      value: "TOTAL_UNPAID_CHILD_SUPPORT_OBLIGATIONS_AND_CLAIMS_AMOUNT",
      valueType: LabelType.EURO,
    },
    {
      id: "pohladavkyNaZaplatenie",
      title: "Ktoré výživné alebo pohľadávky chcem uhradiť",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      options: [
        {
          id: "E_KIO_23_01",
          label: "Konkrétne výživné",
        },
        {
          id: "E_KIO_23_02",
          label: "Konkrétne iné evidované pohľadávky",
        },
        {
          id: "E_KIO_23_03",
          label: "Je mi to jedno",
        },
      ],
    },
  ],
};
