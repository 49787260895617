import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { capitalize } from "@mui/material/utils";
import { useNavigate } from "react-router-dom";
import { ZvjsSummaryTable, ZvjsTable } from "../../../common";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ZvjsSummaryTableComponent } from "../../../common/components/ZvjsSummaryTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router";
import { withLoader } from "../../../hoc/withLoader";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { PromiseElement } from "../../../utils/types";
import { API_Clients } from "../../../store/context/dataApi/Data";

const loadData = async () => {
  const { EDPOO_Post, EOO_Post, EPVVVT_Post } = await API_Clients();

  const edpooKlient = await EDPOO_Post("/api/Klient/Get", {
    body: {
      klientId: Number.parseInt(localStorage.getItem("klientId") ?? "") ?? 1,
      ustavZvjsId:
        Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
    },
  });

  const data = await Promise.all([
    EOO_Post("/api/VazbyATresty/ListDovodovyParagrafVazbyData", {
      body: {
        filters: [
          {
            id: Number.parseInt(localStorage.getItem("klientId") ?? "") ?? 1,
            ustavZvjsId:
              Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
          },
        ],
      },
    }),
    EPVVVT_Post("/api/stitkovnicaklientov/ListVsetciKlienti", {
      body: {
        ustavId:
          Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
      },
    }),
  ]);

  return {
    edpooKlient: edpooKlient,
    obvinenia: data[0],
    epvvvtKlient: data[1].data
      ?.filter(
        (klient) =>
          klient.klientObjectId === localStorage.getItem("klientObjectId")
      )
      .at(0),
  };
};
export const ZakladneUdajeOVazbeLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface ZakladneUdajeOVazbeProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const ZakladneUdajeOVazbe = (props: ZakladneUdajeOVazbeProps) => {
  const loaderData = props.data;
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  const dataStyleObvinenie: TableStyle[] = [
    {
      align: "left",
    },
  ];

  const headerDataObvinenie: Array<CellData> = [
    {
      value: capitalize(tui("osobneUdaje.udajeOvazbe.paragraf")),
      ...dataStyleObvinenie[0],
    },
  ];

  const [tableDataObvinenie, setTableDataObvinenie] = useState<TableData>({
    label: capitalize(tui("osobneUdaje.obvinenieZaSpachanieTrCinu.obvinenie")),
    header: headerDataObvinenie,
    body: [],
  });

  useEffect(() => {
    const bodyData: Array<RowData> = new Array<RowData>();
    loaderData.obvinenia.data?.records
      // .sort((p1, p2) =>
      //   p1.name > p2.name)
      ?.forEach((obvinenie) => {
        bodyData.push({
          row: [
            {
              value: obvinenie.paragrafLabel ?? "",
              ...dataStyleObvinenie[0],
            },
          ],
        });
      });
    setTableDataObvinenie({
      ...tableDataObvinenie,
      body: bodyData,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderData]);

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(tui("osobneUdaje.kategorie.zakladneUdajeVazba"))}
            </Typography>
          </Stack>
        </Grid>

        <Stack direction="column" mt={3} spacing={2}>
          <ZvjsSummaryTable header={tui("osobneUdaje.udajeOvazbe.udajeVaza")}>
            <ZvjsSummaryTableComponent
              label={tui("osobneUdaje.udajeOvazbe.zaciatokVazby")}
              value={
                loaderData.edpooKlient.data?.data?.datumNastupu
                  ? new Date(
                      loaderData.edpooKlient.data.data.datumNastupu
                    ).zvjsToString()
                  : ""
              }
              isBorder={true}
              fullWidth={true}
              hintText={tui("osobneUdaje.udajeOvazbe.napovedaNastupVazby")}
            />
            <ZvjsSummaryTableComponent
              label={tui("osobneUdaje.udajeOvazbe.dovodVazby")}
              value={loaderData.epvvvtKlient?.dovodVazby ?? ""}
              isBorder={true}
              fullWidth={true}
              hintText={tui(
                "osobneUdaje.udajeOvazbe.napovedaOznacenieTrestCinu"
              )}
            />
          </ZvjsSummaryTable>
          <ZvjsTable
            data={tableDataObvinenie}
            height={360}
            variant={ZvjsTableVariant.NORMAL}
            hintText={tui(
              "osobneUdaje.obvinenieZaSpachanieTrCinu.napovedaObvinenie"
            )}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default withLoader(ZakladneUdajeOVazbe);
