import React, { useEffect, useState } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useNavigate } from "react-router-dom";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsSearchBar,
  ZvjsTable,
} from "../../../common";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { ZvjsSearchBarVariant } from "../../../common/components/ZvjsSearchBar";
import { withLoader } from "../../../hoc/withLoader";
import { MAX_NUMBER_OF_ITEMS } from "../../../store/context/dataApi/CIS_Ciselnik";
import { PromiseElement } from "../../../utils/types";
import { API_Clients } from "../../../store/context/dataApi/Data";

const loadData = async () => {
  const { CIS_Post, EDPOO_Post } = await API_Clients();

  const data = await Promise.all([
    // CIS_Post("/api/StavOsobnejVeci/List", {
    //   body: {
    //     filters: [{ aktualny: true, platny: true }],
    //     paging: {
    //       currentPage: 1,
    //       recordsPerPage: MAX_NUMBER_OF_ITEMS,
    //     },
    //     sorting: [{}],
    //   },
    // }),
    EDPOO_Post("/api/OsobneVeci/ListOsobnaVec", {
      body: {
        filters: [
          {
            id: Number.parseInt(localStorage.getItem("klientId") ?? ""),
            ustavZvjsId: Number.parseInt(
              localStorage.getItem("klientUstavId") ?? ""
            ),
          },
        ],
      },
    }),
  ]);
  return {
    // stavOsobnejVeci: data[0],
    osobneVeci: data[0],
  };
};

export const OsobneVeciLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface OsobneVeciProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const OsobneVeci = (props: OsobneVeciProps) => {
  const loaderData = props.data;
  const { t, i18n } = useTranslation();
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.PersonalBelongings_SC_KIO_0312.nameOfThings"
        )
      ),
      ...dataStyle[0],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.PersonalBelongings_SC_KIO_0312.stateOfThings"
        )
      ),
      ...dataStyle[1],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.PersonalBelongings_SC_KIO_0312.allowedThings"
        )
      ),
      ...dataStyle[2],
    },
  ];

  const [tableData, setTableData] = useState<TableData>({
    header: headerData,
    body: [],
  });

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (loaderData.osobneVeci.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      loaderData.osobneVeci.data.records
        ?.sort((p1, p2) => (p1.popis ?? "")?.localeCompare(p2.popis ?? ""))
        // .map((osobnaVec) => {
        //   if (loaderData.stavOsobnejVeci) {
        //     const stavOsobnejVeci =
        //       loaderData.stavOsobnejVeci.data?.records?.find(
        //         (i) => i.kod === osobnaVec.stavVeciId
        //       )?.stav ?? "<unknown>";
        //     return { ...osobnaVec, stav: stavOsobnejVeci };
        //   }
        //   return osobnaVec;
        // })
        .forEach((osobnaVec) => {
          bodyData.push({
            row: [
              {
                value: osobnaVec.popis ?? "",
                ...dataStyle[0],
              },
              {
                value: osobnaVec.stavVeciNazov ?? "",
                ...dataStyle[1],
              },
              {
                value: osobnaVec.nepovolena ? "𐄂" : "✓",
                ...dataStyle[2],
              },
            ],
          });
        });
      setTableData({
        ...tableData,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderData.osobneVeci, i18n.language]);

  const mySubmitHandler = (dropDownValue: string, isChecked: boolean) => {
    console.log(dropDownValue);
    console.log(isChecked);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant={"h1"} mb={3}>
              {capitalize(
                tui("osobneUdaje.kategorieOsobnychUdajov.osobneVeci")
              )}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Stack direction="column" spacing={2}>
        <ZvjsSearchBar
          title={t(
            "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.PersonalBelongings_SC_KIO_0312.searchLabel"
          )}
          type={ZvjsSearchBarVariant.VARIANT_D_CoCb}
          checkBoxLabel={capitalize(
            t(
              "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.PersonalBelongings_SC_KIO_0312.checkBoxLabel"
            )
          )}
          comboboxLabel={capitalize(
            t(
              "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.PersonalBelongings_SC_KIO_0312.selectLabel"
            )
          )}
          items={[
            { value: 3, text: "Tri" },
            { value: 5, text: "Pat" },
            { value: 10, text: "Desat" },
          ]}
          onSubmit={mySubmitHandler}
        />

        <ZvjsTable
          data={tableData}
          height={360}
          variant={ZvjsTableVariant.NORMAL}
          // TODO add translation
          hintText={" "}
        />

        <Grid container spacing={1} width={"100%"}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ZvjsButton
              text={capitalize(
                t(
                  "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.PersonalBelongings_SC_KIO_0312.requestIssue"
                )
              )}
              zvjsVariant="secondaryAction"
              onClick={() => {
                navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/08.00.01`);
              }}
              endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
              style={{
                lineHeight: "1",
                textAlign: "left",
                textTransform: "none",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ZvjsButton
              text={capitalize(
                t(
                  "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.PersonalBelongings_SC_KIO_0312.requestUse"
                )
              )}
              zvjsVariant="secondaryAction"
              onClick={() => {
                navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/08.01.01`);
              }}
              endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
              style={{
                lineHeight: "1",
                textAlign: "left",
                textTransform: "none",
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  );
};

export default withLoader(OsobneVeci);
