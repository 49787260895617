import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ZVJS_COLORS } from "../../theme/zvjs_theme";
import { useUITranslation } from "../../store/context/translation-context";
import { ZvjsHintModalFragment } from "./ZvjsModal";
import { ZvjsAlert } from "./index";
import {
  getSeverityOfValidationFlag,
  ZvjsValidationFlagTypes,
} from "../request/redux/model";

export interface ZvjsRadioItem {
  value: string | number;
  text: string;
}

interface ZvjsRadioGroupProps {
  id: string;
  label?: string;
  items: ZvjsRadioItem[];
  hintText?: string;
  hintColor?: string;
  hintSize?: number;
  answerRequired?: boolean;
  error?: boolean;
  validationErrorMessage?: string;
  validationFlatType?: ZvjsValidationFlagTypes;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  selectedValue: string | number;
}

const ZvjsRadioGroup: React.FC<ZvjsRadioGroupProps> = ({
  label,
  id,
  items,
  hintText,
  hintColor,
  hintSize,
  answerRequired,
  error,
  validationErrorMessage,
  validationFlatType,
  onChange,
  selectedValue,
}) => {
  const theme = useTheme();
  const { tui } = useUITranslation();

  return (
    <>
      <FormControl>
        {label && (
          <FormLabel id={id} sx={{ color: ZVJS_COLORS.BLACK }}>
            <Stack direction={"row"} spacing={1}>
              <Typography variant={"body1"}>
                {label}
                {answerRequired && "*"}
              </Typography>
              {hintText && (
                <ZvjsHintModalFragment
                  title={tui("nápoveda")}
                  text={hintText}
                  color={hintColor}
                  size={hintSize}
                />
              )}
            </Stack>
          </FormLabel>
        )}
        <RadioGroup value={selectedValue} onChange={onChange}>
          {items.map((item, index) => (
            <FormControlLabel
              key={`${index}_${item.value}`}
              value={item.value}
              control={<Radio />}
              label={item.text}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {error === true && validationErrorMessage && validationFlatType && (
        <Grid item xs>
          <ZvjsAlert
            severity={getSeverityOfValidationFlag(validationFlatType)}
            style={{ marginTop: "1rem" }}
          >
            {validationErrorMessage}
          </ZvjsAlert>
        </Grid>
      )}
    </>
  );
};

export default ZvjsRadioGroup;
