import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { ZvjsButton, ZvjsSummaryTable } from "../../../components";
import { useTranslation } from "react-i18next";
import { RequestPages } from "../../Request";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../redux";
import questionnaireReducer from "../../redux";
import Link from "@mui/material/Link";
import {
  Params,
  useActionData,
  useNavigate,
  useNavigation,
  useSubmit,
} from "react-router-dom";
import { ActionResult, ActionResultTypes } from "../../../../router/Router";
import { AppDispatch } from "../../../../store/redux";
import { isSubmitting } from "../../../../utils/helpers";
import {
  ZvjsSummaryRequestSection,
  ZvjsSummaryTableRequestComponent,
  ZvjsSummaryTableRequestHeader,
} from "../../../components/ZvjsSummaryTable";
import { ZvjsSummaryItemType } from "../../redux/selectors/summary";
import { useUITranslation } from "../../../../store/context/translation-context";
import { useSnackbar } from "../../../../store/context/snackbar-context";
import { API_Clients } from "../../../../store/context/dataApi/Data";

interface SummaryProps {
  setCurrentPage: React.Dispatch<React.SetStateAction<RequestPages>>;
}

const supportedSave: { [key: string]: true } = {
  // umiestnenie
  "03.00.01": true,
  // umiestnenie v ramci aktuálneho ústavu
  "03.00.02": true,
  // umiestnenie v oddiele so štandardným režimom
  "03.00.03": true,
  // premiestnenie
  "03.00.04": true,
  // pohovor s riaditelom alebo pracovnikom zboru
  "06.00.01": true,
  // potreby osobnej hygieny
  "07.01.01": true,
  // TODO uncomment after szoo api is updated (kiosk's part is ready)
  // nakup sortimentu v ustavnej predajni v obmedzenom rozsahu
  // "07.01.02": true,
  // ostrihanie
  "07.03.01": true,
  // elektrospotrebic
  "08.01.01": true,
  // zaevidovanie pp na vreckove konto odsudeneho v oo
  "09.05.02": true,
  // poskytnutie psychologickej sluzby
  "10.01.01": true,
  // TODO uncomment after save is working to szoo (kiosk's part is ready)
  // // poskytnutie pomoci pri prepusteni (odev, strava, cestovne)
  // "10.03.01": true,
  // pastoracna sluzba
  "10.04.01": true,
  // ucast na hromadnej kulturno-osvetovej a sportovej aktivite
  "11.04.01": true,
  // univerzalna
  "14.00.01": true,
  // poskytnutie ambulantnej starostlivosti
  "15.00.01": true,
};

export async function RequestActions({
  params,
  request,
}: {
  params: Params;
  request: Request;
}): Promise<ActionResult> {
  const { SZOO_Post } = await API_Clients();

  const submitBodies: SummarySubmitData[] = await request.json();

  console.debug(submitBodies);

  let error = false;
  if (
    !submitBodies ||
    !Array.isArray(submitBodies) ||
    submitBodies.some((body) => !supportedSave[body.typZiadosti])
  ) {
    // if submit is not supported, display success page (should be removed in future)
    return { type: ActionResultTypes.SUCCESS };
  }

  for (const submitBody of submitBodies) {
    const response = await SZOO_Post("/api/Ziadosti/Create", {
      body: submitBody,
    });

    if (response.error || !response.data.isCreated) {
      error = true;
    }
  }

  if (error) {
    return { type: ActionResultTypes.ERROR };
  } else {
    return { type: ActionResultTypes.SUCCESS };
  }
}

export interface SummarySubmitData {
  typZiadosti: string;
  datumPodania: string;
  idZiadosti: string;
  klientId: string;
  zdroj: string;
  ustavId: number;
  // you do not know question ids and values in advance
  [key: string]: unknown;
}

const Summary: React.FC<SummaryProps> = ({ setCurrentPage }) => {
  const { t } = useTranslation();
  const { tuiz } = useUITranslation();
  const navigate = useNavigate();
  const submit = useSubmit();
  const { openSnackbar } = useSnackbar();
  const navigation = useNavigation();
  const submitResult = useActionData() as ActionResult | undefined;
  const dispatch = useDispatch<AppDispatch>();

  const summaryData = useSelector(
    questionnaireRedux.selectors.getSummaryData()
  );
  const summarySubmitData = useSelector(
    questionnaireRedux.selectors.getSummarySubmitData()
  );
  const hasQuestionnaireAnyItems = useSelector(
    questionnaireRedux.selectors.getHasQuestionnaireAnyItems
  );

  console.log("SUMMARY RERENDER");

  useEffect(() => {
    // if submit succeeded redirect user to a new step
    if (submitResult?.type === ActionResultTypes.SUCCESS) {
      setCurrentPage((prevState) => prevState + 1);
      navigate("#confirmation");
    }

    if (submitResult?.type === ActionResultTypes.ERROR) {
      openSnackbar(tuiz("notifikacie.nepodariloSaOdoslat"), "error");
    }
  }, [navigate, openSnackbar, setCurrentPage, submitResult, tuiz]);

  const onSubmitRequestClickHandler = () => {
    if (summarySubmitData) {
      submit(JSON.parse(JSON.stringify(summarySubmitData)), {
        method: "POST",
        encType: "application/json",
      });
    }
  };
  const onLinkClickHandler = (id: string) => {
    setCurrentPage(RequestPages.QUESTIONNAIRE);
    navigate("#questionnaire");
    dispatch(
      questionnaireReducer.sliceActions.setEditQuestionnaire({
        questionId: id,
      })
    );
  };

  return (
    <Stack spacing={5}>
      <ZvjsSummaryTable>
        <ZvjsSummaryTableRequestHeader
          label={tuiz("ziadostiVseobecne.osobneUdaje")}
        />
        <ZvjsSummaryTableRequestComponent
          label={tuiz("ziadostiVseobecne.menoPriezvisko")}
          value={summaryData.userPersonalData.fullName as string}
          isBorder={true}
          textAlign={"left"}
        />
        <ZvjsSummaryTableRequestComponent
          label={tuiz("ziadostiVseobecne.datumNarodenia")}
          value={summaryData.userPersonalData.birthDate as string}
          isBorder={true}
          textAlign={"left"}
        />
        <ZvjsSummaryTableRequestHeader
          label={tuiz("ziadostiVseobecne.udajeZiadosti")}
        />
        <ZvjsSummaryTableRequestComponent
          label={"Žiadosť"}
          value={summaryData.request.title as string}
          isBorder={true}
          textAlign={"left"}
        />
        {summaryData.request.items.map((item, index) => {
          if (item.type === ZvjsSummaryItemType.ROW) {
            return (
              <ZvjsSummaryTableRequestComponent
                key={index}
                label={item.label ?? ""}
                value={item.answerText as string}
                isBorder={index !== summaryData.request.items.length - 1}
              >
                <Link
                  component="button"
                  onClick={() => onLinkClickHandler(item.id)}
                >
                  {"Zmeň"}
                </Link>
              </ZvjsSummaryTableRequestComponent>
            );
          }
          if (item.type === ZvjsSummaryItemType.SECTION) {
            return (
              <ZvjsSummaryRequestSection
                key={index}
                label={item.label}
                onEditClick={
                  item?.id !== undefined
                    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      () => onLinkClickHandler(item.id!)
                    : undefined
                }
                isBorder={index !== summaryData.request.items.length - 1}
              >
                {item.items.map((sectionItem, sectionIndex) => (
                  <ZvjsSummaryTableRequestComponent
                    key={sectionIndex}
                    label={sectionItem.label ?? ""}
                    value={sectionItem.answerText as string}
                    isBorder={false}
                    dense
                  />
                ))}
              </ZvjsSummaryRequestSection>
            );
          }
          console.warn("Unhandled summary item type");
          return <></>;
        })}
      </ZvjsSummaryTable>

      <div>
        <Typography fontSize={28} fontWeight={700}>
          {tuiz("ziadostiVseobecne.potvrdenieUdajov")}
        </Typography>
        <Typography>{tuiz("notifikacie.zavaznePotvrdenie")}</Typography>
      </div>

      <Stack direction="row" spacing={2}>
        <ZvjsButton
          disabled={isSubmitting(navigation)}
          zvjsVariant={"secondaryAction"}
          text={capitalize(t("spec.section.Request.Buttons.SubmitRequest"))}
          endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
          onClick={onSubmitRequestClickHandler}
        />
        <ZvjsButton
          disabled={isSubmitting(navigation)}
          zvjsVariant={"cancelAction"}
          text={capitalize(t("spec.section.Request.Buttons.CancelRequest"))}
          startIcon={<ClearIcon style={{ height: 42, width: 42 }} />}
          onClick={() => navigate(hasQuestionnaireAnyItems ? -3 : -2)}
        />
      </Stack>
    </Stack>
  );
};

export default Summary;
