import {
  ZvjsConditionTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050803",
  basicInfo: [
    {
      text: "ziadost.ziadostOposkytnutiePsychSluzby.zakladneInfo",
    },
  ],
  counters: [
    "/api/CisOblastiPsychickychProblemov/List",
    "/api/CisPsychickyStav/List",
    "/api/CisStrach/List",
    "/api/CisFyzickePrejavyProblemov/List",
  ],
  items: [
    {
      id: "oblastProblemov",
      title: "ziadost.ziadostOposkytnutiePsychSluzby.labelOblastiProblemov",
      type: ZvjsQuestionTypes.NESTED_DROPDOWN,
      isRequired: true,
      hintText: "ziadost.ziadostOposkytnutiePsychSluzby.napovedaVseobecneInfo",
      options: {
        answerId: "oblastProblemov",
        counterKey: "/api/CisOblastiPsychickychProblemov/List",
        textKey: "nazov",
        children: [
          {
            id: "1",
          },
          {
            id: "2",
          },
          {
            id: "3",
          },
          {
            id: "4",
            options: {
              answerId: "psychickyStav",
              counterKey: "/api/CisPsychickyStav/List",
              textKey: "nazov",
              children: [
                {
                  id: "HLASY",
                },
                {
                  id: "STRACH",
                  options: {
                    answerId: "strach",
                    counterKey: "/api/CisStrach/List",
                    textKey: "nazov",
                    children: [
                      {
                        id: "NECHUT",
                      },
                      {
                        id: "ZOSOB",
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            id: "5",
          },
        ],
      },
    },
    {
      id: "dovodPreIneProblemy",
      title: "ziadost.ziadostOposkytnutiePsychSluzby.labelIneProblemy",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      hintText:
        "ziadost.ziadostOposkytnutiePsychSluzby.napovedaPsychologickaSluzba",
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "oblastProblemov",
          value: [{ oblastProblemov: "5" }],
        },
      ],
    },
    {
      id: "fyzickePrejavyProblemov",
      title:
        "ziadost.ziadostOposkytnutiePsychSluzby.labelFyzickePrejavyProblemov",
      type: ZvjsQuestionTypes.NESTED_DROPDOWN,
      options: {
        answerId: "fyzickePrejavyProblemov",
        counterKey: "/api/CisFyzickePrejavyProblemov/List",
        textKey: "nazov",
        children: [
          {
            id: "1",
          },
          {
            id: "2",
          },
          {
            id: "3",
          },
          {
            id: "4",
          },
          {
            id: "5",
          },
          {
            id: "6",
          },
          {
            id: "7",
          },
        ],
      },
    },
    {
      id: "dovodPreIneFyzickeProblemy",
      title: "ziadost.ziadostOposkytnutiePsychSluzby.labelIneProblemy",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "fyzickePrejavyProblemov",
          value: [{ fyzickePrejavyProblemov: "7" }],
        },
      ],
    },
  ],
};
