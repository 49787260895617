import React from "react";
import { useSelector } from "react-redux";
import { Grid, Stack } from "@mui/material";
import questionnaireRedux from "../../redux";
import { ZvjsCustomQuestionTypes, ZvjsQuestionTypes } from "../../redux/model";
import { GeneralItem, Question } from "./items";
import QuestionnaireControlBtns from "./QuestionnaireControlBtns";
import { RequestPages } from "../../Request";

interface QuestionnaireProps {
  setCurrentPage: React.Dispatch<React.SetStateAction<RequestPages>>;
}

const Questionnaire: React.FC<QuestionnaireProps> = ({ setCurrentPage }) => {
  const rootItems =
    useSelector(questionnaireRedux.selectors.getRootItems) || [];

  const questionnaireItems = [];
  for (let i = 0; i < rootItems.length; i++) {
    if (
      rootItems[i] in ZvjsQuestionTypes ||
      rootItems[i] in ZvjsCustomQuestionTypes
    ) {
      questionnaireItems.push(<Question key={i} location={[i]} />);
    } else {
      questionnaireItems.push(<GeneralItem key={i} location={[i]} />);
    }
  }

  console.log("QUESTIONNIARE RERENDER");

  return (
    <Stack spacing={2}>
      {questionnaireItems}
      <Grid item sx={{ pt: 1 }}>
        <QuestionnaireControlBtns setCurrentPage={setCurrentPage} />
      </Grid>
    </Stack>
  );
};

export default Questionnaire;
