import React, { Fragment, useEffect } from "react";
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Params,
  useActionData,
  useNavigate,
  useNavigation,
  useSubmit,
} from "react-router-dom";
import {
  ZvjsSummaryRequestSection,
  ZvjsSummaryTableRequestComponent,
  ZvjsSummaryTableRequestHeader,
} from "../../../common/components/ZvjsSummaryTable";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsSummaryTable,
} from "../../../common";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ReactComponent as KeyboardDoubleArrowLeftIcon } from "../../../assets/icons/Other/keyboard_double_arrow_left.svg";
import { useUITranslation } from "../../../store/context/translation-context";
import { LoaderError } from "../../../router/LoaderError";
import { defer } from "react-router";
import { MAX_NUMBER_OF_ITEMS } from "../../../store/context/dataApi/CIS_Ciselnik";
import { withLoader } from "../../../hoc/withLoader";
import { paths as szoo_v1_paths } from "../../../api/types/szoo_v1";
import {
  getTranslationByLanguageId,
  isEmptyString,
  isSubmitting,
} from "../../../utils/helpers";
import { getCiselnikJazyk } from "../../../locales/i18n";
import i18n from "i18next";
import { ZVJS_COLORS } from "../../../theme/zvjs_theme";
import { useModal } from "../../../store/context/modal-context";
import { ActionResult, ActionResultTypes } from "../../../router/Router";
import { useSnackbar } from "../../../store/context/snackbar-context";
import { findItem } from "../../../common/request/redux/slice";
import { requestTemplates } from "../../../common/request/requestTemplates";
import {
  LabelType,
  RequestUserPersonalData,
  ZvjsDropdownQuestion,
  ZvjsSection,
  ZvjsTextQuestion,
} from "../../../common/request/redux/model";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { PromiseElement } from "../../../utils/types";

export async function RequestDetailActions({
  params,
  request,
}: {
  params: Params;
  request: Request;
}): Promise<ActionResult> {
  const body: { requestId: number | undefined } = await request.json();

  const { SZOO_Post } = await API_Clients();

  if (body.requestId !== undefined) {
    const result = await SZOO_Post("/api/Ziadosti/SpatVzatie", {
      params: {
        query: {
          id: body.requestId,
        },
      },
    });

    if (
      result.data?.data?.stavZiadostiId === 15 || // CisStavZiadosti "Späťvzatá"
      result.data?.data?.stavZiadostiId === 16 // CisStavZiadosti "Späťvzatá na spracovanie"
    ) {
      return { type: ActionResultTypes.SUCCESS };
    }
  }

  return { type: ActionResultTypes.ERROR };
}

const LoadData = async (requestId?: string) => {
  if (requestId === undefined || isNaN(Number(requestId))) {
    // if requestID is not provided or not a number, display error page
    throw new LoaderError();
  }

  const { CIS_Post, EOO_Get, SZOO_Get } = await API_Clients();

  const data = await Promise.all([
    CIS_Post("/api/CisTypZiadosti/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
    CIS_Post("/api/CisStavZiadosti/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
    SZOO_Get("/api/Ziadosti/Get", {
      params: {
        query: {
          id: Number(requestId),
        },
      },
    }),
    EOO_Get("/api/Klient/DetailZakladneUdajeKlientaData", {
      params: {
        query: {
          Id: Number.parseInt(localStorage.getItem("klientId") ?? "") ?? 1,
          UstavZvjsId:
            Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
        },
      },
    }),
    SZOO_Get("/api/Ziadosti/GetOdpovedPreKlienta", {
      params: {
        query: {
          id: Number(requestId),
        },
      },
    }),
    CIS_Post("/api/CisOdpovedPreKlienta/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
  ]);

  if (
    data[0].error ||
    data[1].error ||
    data[2].error ||
    data[3].error ||
    data[4].error ||
    data[5].error
  ) {
    // if counters and request detail etc. were not fetched show error page
    throw new LoaderError();
  }

  const userPersonalData = {
    fullName: `${data[3].data.data?.meno} ${data[3].data.data?.priezvisko}`,
    birthDate: data[3].data.data?.datumNarodenia
      ? new Date(data[3].data.data.datumNarodenia).zvjsToString()
      : "",
  };

  const requestDetailBody: szoo_v1_paths["/api/Ziadosti/Get"]["get"]["responses"]["200"]["content"]["application/json"] =
    data[2].data;

  if (requestDetailBody.data === undefined) {
    // if request detail was not found, display error page
    throw new LoaderError();
  }

  const requestDetailResponseForClientBody: szoo_v1_paths["/api/Ziadosti/GetOdpovedPreKlienta"]["get"]["responses"]["200"]["content"]["application/json"] =
    await data[4].data;

  if (requestDetailResponseForClientBody.records === undefined) {
    // if request detail was not found, display error page
    throw new LoaderError();
  }

  const requestType = {
    ...data[0],
    requests: data[0].data.records?.filter(
      (request) => request.id === requestDetailBody.data?.typZiadostiId
    ),
  };

  // additional counter fetch for the 07.01.01/SC_KIO_051003 request
  let personalHygieneNeedsCounter = undefined;
  if (requestDetailBody.data?.typZiadostiId === 14) {
    personalHygieneNeedsCounter = await CIS_Post("/api/DalsiePotrebyOh/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    });

    if (personalHygieneNeedsCounter.error) {
      throw new LoaderError();
    }
  }

  return {
    requestTypeCounter: requestType,
    requestStatesCounter: data[1],
    requestDetail: requestDetailBody,
    userPersonalData: userPersonalData,
    requestDetailResponseForClient: requestDetailResponseForClientBody,
    requestResponseForClientCounter: data[5],
    personalHygieneNeedsCounter: personalHygieneNeedsCounter,
  };
};

export const RequestDetailLoader = async ({
  request,
  params,
}: {
  request: Request;
  params: Params;
}) => {
  return defer({
    data: LoadData(params.requestID),
  });
};

export interface RequestDetailProps {
  data: PromiseElement<ReturnType<typeof LoadData>>;
}

const RequestDetail = (props: RequestDetailProps) => {
  const {
    requestTypeCounter,
    requestStatesCounter,
    requestDetail,
    userPersonalData,
    requestDetailResponseForClient,
    requestResponseForClientCounter,
    personalHygieneNeedsCounter,
  } = props.data;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tui, tuiz, getFallbackJazyk } = useUITranslation();
  const { openModal, closeModal } = useModal();
  const submit = useSubmit();
  const navigation = useNavigation();
  const submitResult = useActionData() as ActionResult | undefined;
  const { openSnackbar } = useSnackbar();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (submitResult?.type === ActionResultTypes.ERROR) {
      openSnackbar(
        "Žiadosť sa nepodarilo zrušiť. Skúste neskôr.",
        ActionResultTypes.ERROR
      );
    }
    if (submitResult?.type === ActionResultTypes.SUCCESS) {
      openSnackbar("Žiadosť bola úspešne zrušená", ActionResultTypes.SUCCESS);
      navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}?tab=1`);
    }
  }, [navigate, openSnackbar, submitResult]);

  /**
   * @deprecated get request state color from counter once it is implemented
   */
  const getRequestStateColor = (requestStateId: number | undefined): string => {
    if (requestStateId !== undefined) {
      const requestState = getRequestStateNameFromCode(requestStateId);

      if (requestState !== undefined && requestState !== null) {
        if (requestState.includes("Zaevidovaná")) {
          return ZVJS_COLORS.BLUE;
        }
        if (requestState.includes("Schválená")) {
          return ZVJS_COLORS.GREEN;
        }
        if (requestState.includes("Zamietnutá")) {
          return ZVJS_COLORS.RED;
        }
        if (requestState.includes("Späťvzatá")) {
          return ZVJS_COLORS.PURPLE;
        }
      }
    }

    return ZVJS_COLORS.BLUE;
  };

  const getRequestStateNameFromCode = (code: number | undefined) => {
    for (const state of requestStatesCounter.data?.records ?? []) {
      if (state.kod === code?.toString()) {
        return state.nazov;
      }
    }
    return undefined;
  };

  const onConfirmRequestCancellation = () => {
    closeModal();
    submit(JSON.parse(JSON.stringify({ requestId: requestDetail.data?.id })), {
      method: "POST",
      encType: "application/json",
    });
  };

  const getRequestClientResponseTextFromCode = (code: number | undefined) => {
    // TODO add transnlations once available in counter
    for (const responseForClientItem of requestResponseForClientCounter.data
      ?.records ?? []) {
      if (responseForClientItem.kod === code?.toString()) {
        return responseForClientItem.text ?? "";
      }
    }
    console.error(
      `Request response with code ${code} not found in request response counter`
    );
    return "";
  };

  /**
   * @deprecated Refactor the way how the body of requests is being displayed
   */
  const getRequestBody = () => {
    const toReturn = [];
    if (requestDetail.data?.typZiadostiId === 45) {
      if (
        requestDetail.data?.specifickeUdaje !== undefined &&
        requestDetail.data?.specifickeUdaje !== null
      ) {
        const requestDetailBody = JSON.parse(
          requestDetail.data.specifickeUdaje
        );

        toReturn.push(
          <ZvjsSummaryTableRequestComponent
            key={"1"}
            label={tuiz(
              (findItem(requestTemplates["14.00.01"], [0]) as ZvjsTextQuestion)
                .title
            )}
            value={requestDetailBody.PredmetZiadosti}
            isBorder={true}
            textAlign={"left"}
          />
        );

        toReturn.push(
          <ZvjsSummaryTableRequestComponent
            key={"2"}
            label={tuiz(
              (findItem(requestTemplates["14.00.01"], [1]) as ZvjsTextQuestion)
                .title
            )}
            value={requestDetailBody.ObsahZiadosti}
            isBorder={false}
            textAlign={"left"}
          />
        );
      }
    }

    if (requestDetail.data?.typZiadostiId === 19) {
      if (
        requestDetail.data?.specifickeUdaje !== undefined &&
        requestDetail.data?.specifickeUdaje !== null
      ) {
        const requestDetailBody = JSON.parse(
          requestDetail.data.specifickeUdaje
        );

        const getElectricDeviceTypeText = (electricDeviceId: number) => {
          switch (electricDeviceId) {
            case 1:
              return "Elektrický holiaci strojček";
            case 2:
              return "Strojček na strihanie vlasov";
            case 3:
              return "Ponorný varič / rýchlovarná kanvica";
            case 4:
              return "Rádioprijímač";
            case 5:
              return "Televízny prijímač";
            case 6:
              return "Iný elektrický spotrebič";
            default:
              return "";
          }
        };

        if (requestDetailBody.DovodPodaniaZiadostiId === 1) {
          toReturn.push(
            <ZvjsSummaryTableRequestComponent
              key={"1"}
              label={tuiz(
                (
                  findItem(requestTemplates["08.01.01"], [
                    0,
                  ]) as ZvjsDropdownQuestion
                ).title
              )}
              value={"Chcem začať používať elektrický spotrebič"}
              isBorder={true}
              textAlign={"left"}
            />
          );

          toReturn.push(
            <ZvjsSummaryTableRequestComponent
              key={"2"}
              label={tuiz(
                "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.labelDruhElektrickehoSpotrebica"
              )}
              value={getElectricDeviceTypeText(
                requestDetailBody.DruhElektrickehoSpotrebicaId
              )}
              isBorder={true}
              textAlign={"left"}
            />
          );

          if (
            !isEmptyString(requestDetailBody.NazovInehoElektrickehoSpotrebica)
          ) {
            toReturn.push(
              <ZvjsSummaryTableRequestComponent
                key={"3"}
                label={tuiz(
                  "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.labelInyElektrickySpotrebic"
                )}
                value={requestDetailBody.NazovInehoElektrickehoSpotrebica}
                isBorder={true}
                textAlign={"left"}
              />
            );
          }

          const getPowerSupplyText = () => {
            switch (requestDetailBody.ZdrojNapajaniaId) {
              case 1:
                return "Elektrický spotrebič je funkčný iba počas pripojenia na elektrickú sieť";
              case 2:
                return "Elektrický spotrebič využíva batériové napájanie";
              default:
                return "";
            }
          };

          toReturn.push(
            <ZvjsSummaryTableRequestComponent
              key={"4"}
              label={tuiz(
                "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.labelZdrojNapajania"
              )}
              value={getPowerSupplyText()}
              isBorder={false}
              textAlign={"left"}
            />
          );
        }

        if (requestDetailBody.DovodPodaniaZiadostiId === 2) {
          toReturn.push(
            <ZvjsSummaryTableRequestComponent
              key={"1"}
              label={tuiz(
                (
                  findItem(requestTemplates["08.01.01"], [
                    0,
                  ]) as ZvjsDropdownQuestion
                ).title
              )}
              value={
                "Potrebujem vykonať opakovanú revíznu kontrolu už používaného spotrebiča"
              }
              isBorder={true}
              textAlign={"left"}
            />
          );

          toReturn.push(
            <ZvjsSummaryRequestSection
              key={"2"}
              label={tuiz(
                (findItem(requestTemplates["08.01.01"], [2]) as ZvjsSection)
                  .title ?? ""
              )}
              isBorder={false}
            >
              <ZvjsSummaryTableRequestComponent
                key={"1"}
                label={tuiz(
                  "ziadost.ziadostOpouzivanieVlastnehoElSpotrebica.labelDruhElektrickehoSpotrebica"
                )}
                value={getElectricDeviceTypeText(
                  requestDetailBody.DruhElektrickehoSpotrebicaId
                )}
                isBorder={false}
                textAlign={"left"}
              />
            </ZvjsSummaryRequestSection>
          );
        }
      }
    }

    if (requestDetail.data?.typZiadostiId === 14) {
      if (
        requestDetail.data?.specifickeUdaje !== undefined &&
        requestDetail.data?.specifickeUdaje !== null &&
        personalHygieneNeedsCounter?.data?.records !== undefined &&
        personalHygieneNeedsCounter?.data?.records !== null
      ) {
        const requestDetailBody = JSON.parse(
          requestDetail.data.specifickeUdaje
        );

        const getPersonalNeedNameFromCode = (code: string) => {
          // TODO add translations once available in counter
          for (const item of personalHygieneNeedsCounter.data?.records ?? []) {
            if (item.kod === code) {
              return item.nazov;
            }
          }
          throw new Error(
            `Personal hygiene need with code ${code} not found in counter`
          );
        };

        toReturn.push(
          <ZvjsSummaryRequestSection
            key={"1"}
            label={tuiz("Požadované potreby osobnej hygieny")}
            isBorder={true}
          >
            {requestDetailBody.PotrebyOsobnejHygieny.flatMap(
              (
                item: { PredpokladanaCenaPolozky: number; DruhDPOH: number },
                index: number
              ) => {
                return (
                  <Fragment key={index}>
                    <ZvjsSummaryTableRequestComponent
                      label={tuiz(
                        "ziadost.ziadostOposkytnutiePotriebOsHygieny.labelNazovPolozky"
                      )}
                      value={
                        getPersonalNeedNameFromCode(item.DruhDPOH.toString()) ??
                        ""
                      }
                      isBorder={false}
                      textAlign={"left"}
                    />
                    <ZvjsSummaryTableRequestComponent
                      label={tuiz(
                        "ziadost.ziadostOposkytnutiePotriebOsHygieny.labelPredpokladanaCenaPolozky"
                      )}
                      value={`${item?.PredpokladanaCenaPolozky} ${LabelType.EURO}`}
                      isBorder={false}
                      textAlign={"left"}
                    />
                  </Fragment>
                );
              }
            )}
          </ZvjsSummaryRequestSection>
        );
        toReturn.push(
          <ZvjsSummaryRequestSection
            key={"2"}
            label={tuiz(
              "ziadost.ziadostOposkytnutiePotriebOsHygieny.labelPredpokladanaCenaSpolu"
            )}
            isBorder={false}
          >
            <ZvjsSummaryTableRequestComponent
              key={"1"}
              label={tuiz(
                "ziadost.ziadostOposkytnutiePotriebOsHygieny.labelSuma"
              )}
              value={`${requestDetailBody.Suma} ${LabelType.EURO}` ?? ""}
              isBorder={false}
              textAlign={"left"}
            />
          </ZvjsSummaryRequestSection>
        );
      }
    }

    return toReturn;
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid item mb={3}>
        <Stack direction="row" spacing={2} mb={3} alignItems="flex-start">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant="h1" mb={3}>
            {capitalize(t("Detail žiadosti"))}
          </Typography>
        </Stack>
        <Typography variant="h3">
          {requestDetail.data?.typZiadostiId
            ? getTranslationByLanguageId(
                requestTypeCounter.data?.records ?? [],
                getCiselnikJazyk(i18n.language),
                getFallbackJazyk(),
                requestDetail.data?.typZiadostiId,
                "nazov"
              )
            : ""}
        </Typography>
      </Grid>
      <Stack spacing={5}>
        <Stack spacing={5}>
          <ZvjsSummaryTable>
            <ZvjsSummaryTableRequestHeader
              label={tuiz("ziadostiVseobecne.osobneUdaje")}
            />
            <ZvjsSummaryTableRequestComponent
              label={tuiz("ziadostiVseobecne.menoPriezvisko")}
              value={userPersonalData.fullName as string}
              isBorder={true}
              textAlign={"left"}
            />
            <ZvjsSummaryTableRequestComponent
              label={tuiz("ziadostiVseobecne.datumNarodenia")}
              value={userPersonalData.birthDate as string}
              isBorder={true}
              textAlign={"left"}
            />
            <ZvjsSummaryTableRequestHeader
              // TODO add translation
              label={tuiz("Odpovede pre klienta")}
            />
            <ZvjsSummaryTableRequestComponent
              label={"Stav žiadosti"}
              value={
                <div
                  style={{
                    backgroundColor: getRequestStateColor(
                      requestDetail.data?.stavZiadostiId
                    ),
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    width: "fit-content",
                    borderRadius: "0.2rem",
                  }}
                >
                  <Typography
                    fontSize={"20"}
                    color={ZVJS_COLORS.WHITE}
                    textAlign={"center"}
                    paddingLeft={1}
                    paddingRight={1}
                  >
                    {getRequestStateNameFromCode(
                      requestDetail.data?.stavZiadostiId
                    ) ?? ""}
                  </Typography>
                </div>
              }
              isBorder={true}
              textAlign={"left"}
            />
            {requestDetailResponseForClient.records?.map(
              (requestResponse, index) => {
                return (
                  <ZvjsSummaryTableRequestComponent
                    key={index}
                    label={new Date(requestResponse.datum ?? "").zvjsToString()}
                    value={getRequestClientResponseTextFromCode(
                      requestResponse.odpovedId
                    )}
                    isBorder={true}
                    textAlign={"left"}
                    longText
                  />
                );
              }
            )}
            <ZvjsSummaryTableRequestHeader
              label={tuiz("ziadostiVseobecne.udajeZiadosti")}
            />
            <ZvjsSummaryTableRequestComponent
              label={"Žiadosť"}
              value={
                requestDetail.data?.typZiadostiId
                  ? getTranslationByLanguageId(
                      requestTypeCounter.data?.records ?? [],
                      getCiselnikJazyk(i18n.language),
                      getFallbackJazyk(),
                      requestDetail.data?.typZiadostiId,
                      "nazov"
                    )
                  : ""
              }
              isBorder={true}
              textAlign={"left"}
            />
            {getRequestBody()}
          </ZvjsSummaryTable>
        </Stack>
        {requestDetail.data?.jeMozneSpatvzatie && (
          <Stack direction="row" spacing={2}>
            <ZvjsButton
              zvjsVariant={"secondaryAction"}
              text={capitalize(t("Zrušiť žiadosť"))}
              startIcon={<KeyboardDoubleArrowLeftIcon style={{ height: 24 }} />}
              disabled={isSubmitting(navigation)}
              onClick={() =>
                openModal(
                  <CancelRequestModalBody
                    onCancel={closeModal}
                    onConfirm={onConfirmRequestCancellation}
                  />
                )
              }
            />
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default withLoader(RequestDetail);

interface CancelRequestModalBodyProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const CancelRequestModalBody: React.FC<CancelRequestModalBodyProps> = (
  props: CancelRequestModalBodyProps
) => {
  const { onCancel, onConfirm } = props;

  return (
    <Paper
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: "1rem",
        borderRadius: "1rem",
        boxShadow: 24,
        "&:focus": {
          outline: "none",
        },
      }}
    >
      <Stack spacing={1}>
        <Box
          style={{
            backgroundColor: "rgba(0,112,60,0.3)",
            minWidth: "30rem",
            borderRadius: "0.5rem",
            border: `0.1rem solid ${ZVJS_COLORS.GREEN}`,
            padding: "0.5rem",
          }}
        >
          <Stack direction="row" spacing={2} justifyContent={"center"}>
            {/*<Hint fill={ZVJS_COLORS.GREEN} />*/}
            <Typography
              variant={"h4"}
              fontWeight={700}
              color={ZVJS_COLORS.GREEN}
            >
              {/*TODO add translation*/}
              {capitalize("Zrušiť žiadosť")}
            </Typography>
          </Stack>
        </Box>
        <Typography
          id="transition-modal-description"
          sx={{ mt: 2 }}
          paddingLeft={1}
          paddingRight={1}
        >
          {/*TODO add translation*/}
          {capitalize("Naozaj si želáte zrušiť žiadosť? ")}
        </Typography>
        <Stack
          sx={{ display: "flex", justifyContent: "center" }}
          spacing={1}
          direction={"row"}
        >
          <ZvjsButton
            zvjsVariant={"secondaryAction"}
            onClick={onConfirm}
            // TODO add translation
            text={"Áno"}
            sx={{ height: "3rem" }}
          />
          <ZvjsButton
            zvjsVariant={"cancelAction"}
            onClick={onCancel}
            // TODO add translation
            text={"Nie"}
            sx={{ height: "3rem" }}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};
