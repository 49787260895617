import { useEffect, useState } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ReactComponent as ReserveIcon } from "../../../assets/icons/Other/reserve.svg";
import { useNavigate } from "react-router-dom";
import { ZvjsButton, ZvjsSearchBar, ZvjsTable } from "../../../common";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router";
import { withLoader } from "../../../hoc/withLoader";
import { ZvjsSearchBarVariant } from "../../../common/components/ZvjsSearchBar";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { PromiseElement } from "../../../utils/types";
import useFileApi from "../../../store/context/useFileApi";

// Typ zadefinovany podla struktury JSON suboru v Intranete
type KniznicaKatalog = {
  ustav: {
    nazov: string;
    ico: string;
    dic: string;
    korespondencnaAdresa: {
      ulica: string;
      orientacneCislo: string;
      supisneCislo: string;
      psc: string;
      obec: string;
    };
    adresaSidla: {
      ulica: string;
      orientacneCislo: string;
      supisneCislo: string;
      psc: string;
      obec: string;
    };
    identifikaciaVSp: string;
    kodUstavu: string;
    eschranka: string;
    directoryName: string;
  };
  tituly: [
    {
      zaner: string;
      typSignatury: string;
      signaturneCislo: number;
      kusSignatury: number;
      autor: string;
      nazov: string;
      podnazov: string;
      vydavatelstvo: string;
      miestoVydania: string;
      rokVydania: string;
      jazyk: string;
      tematickeZaradenie: string;
      datumZapisu: string;
      datumVyradenia: string | null;
    }
  ];
};

const loadData = async () => {
  const { REZERVACIA_KNIH_Post, CIS_Post } = await API_Clients();

  const cisUstav = await CIS_Post("/api/CisUstavZvjs/List", {
    body: {
      filters: [
        {
          aktualny: true,
          kodKontaktneUdajeUstavuId: Number(
            localStorage.getItem("klientUstavId")
          ),
        },
      ],
    },
  });

  const ustavKod = cisUstav.data?.records?.at(0)?.kod?.trim();
  const ustavSkratka = cisUstav.data?.records?.at(0)?.skratka?.trim();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getBlob } = useFileApi();

  const katalogTitulovFile = await getBlob(
    `intranet/NAVIS_priecinky/KIOSK/${ustavKod}_${ustavSkratka}/Kniznica/Katalog_titulov_kniznice/Katalog_titulov_kniznice.json`
  );

  const katalogTitulovString = await katalogTitulovFile?.text();
  const katalogTitulov: KniznicaKatalog = JSON.parse(
    katalogTitulovString ?? ""
  );

  const rezervacie = await REZERVACIA_KNIH_Post("/rezervacia/list", {
    body: {
      klientId: localStorage.getItem("klientId") ?? "",
      ustavId:
        Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
    },
  });

  return { katalogTitulov: katalogTitulov, rezervacie: rezervacie };
};

export const KniznicaKatalogTitulovLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface KniznicaKatalogTitulovProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const KniznicaKatalogTitulov = (props: KniznicaKatalogTitulovProps) => {
  const loaderData = props.data;
  const { t, i18n } = useTranslation();
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "right",
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(t("spec.section.Library_SC_KIO_0460.title")),
      ...dataStyle[0],
    },
    {
      value: capitalize(t("spec.section.Library_SC_KIO_0460.author")),
      ...dataStyle[1],
    },
    {
      value: capitalize(t("spec.section.Library_SC_KIO_0460.genre")),
      ...dataStyle[2],
    },
    {
      value: capitalize(t("spec.section.Library_SC_KIO_0460.language")),
      ...dataStyle[3],
    },
    {
      value: capitalize(t("spec.section.Library_SC_KIO_0460.action")),
      ...dataStyle[4],
    },
  ];

  const [tableData, setTableData] = useState<TableData>({
    header: headerData,
    body: [],
  });

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    const bodyData: Array<RowData> = new Array<RowData>();
    loaderData.katalogTitulov.tituly.forEach((titul) => {
      bodyData.push({
        row: [
          {
            value: `${titul.nazov} ${
              titul.podnazov ? ` - ${titul.podnazov}` : ""
            }`,
            ...dataStyle[0],
          },
          {
            value: titul.autor,
            ...dataStyle[1],
          },
          {
            value: titul.zaner,
            ...dataStyle[2],
          },
          {
            value: titul.jazyk,
            ...dataStyle[3],
          },
          {
            value: (
              <ZvjsButton
                text={capitalize(t("spec.section.Library_SC_KIO_0460.reserve"))}
                zvjsVariant="secondaryAction"
                startIcon={<ReserveIcon style={{ fill: "white" }} />}
                onClick={() => {
                  navigate(`${titul.signaturneCislo}`);
                }}
              />
            ),
            ...dataStyle[4],
          },
        ],
      });
    });
    setTableData({
      ...tableData,
      body: bodyData,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderData, i18n.language]);

  const mySubmitHandler = (
    comboboxValue1: string,
    comboboxValue2: string,
    comboboxValue3: string
  ) => {
    console.log(comboboxValue1);
    console.log(comboboxValue2);
    console.log(comboboxValue3);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(t("spec.section.Library_SC_KIO_0460.Catalog"))}
          </Typography>
        </Stack>
      </Grid>

      <ZvjsSearchBar
        title={t("spec.section.Library_SC_KIO_0460.searchInCatalog")}
        type={ZvjsSearchBarVariant.VARIANT_F_CoCoCo}
        onSubmit={mySubmitHandler}
        comboboxLabel={capitalize(t("spec.section.Library_SC_KIO_0460.genre"))}
        items={loaderData.katalogTitulov.tituly
          .map((titul) => titul.zaner)
          .filter((item, index, arr) => arr.indexOf(item) === index)
          .map((item) => {
            return { value: item, text: item };
          })}
        comboboxLabel2="Pohyb"
        items2={[
          { value: 3, text: "Tri" },
          { value: 5, text: "Pat" },
          { value: 10, text: "Desat" },
        ]}
        comboboxLabel3="Pohyb"
        items3={[
          { value: 3, text: "Tri" },
          { value: 5, text: "Pat" },
          { value: 10, text: "Desat" },
        ]}
      />

      <ZvjsTable
        data={tableData}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Grid>
  );
};

export default withLoader(KniznicaKatalogTitulov);
