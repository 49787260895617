import { useEffect, useState } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ReactComponent as DetailIcon } from "../../../assets/icons/Other/detail.svg";
import { useNavigate } from "react-router-dom";
import { ZvjsButton, ZvjsTable } from "../../../common";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router";
import { withLoader } from "../../../hoc/withLoader";
import { PromiseElement } from "../../../utils/types";
import { API_Clients } from "../../../store/context/dataApi/Data";

export const loadData = async () => {
  const { SHARE_INT_Post } = await API_Clients();

  const data = await Promise.all([
    SHARE_INT_Post("/api/interfaces/Epvvvt/ListPoucenia", {
      body: {
        klientId: {
          eq: localStorage.getItem("klientObjectId"),
        },
      },
    }),
  ]);

  return { poucenia: data[0] };
};

export const PouceniaLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface PouceniaProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const Poucenia = (props: PouceniaProps) => {
  const loaderData = props.data;
  const { t, i18n } = useTranslation();
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "right",
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Guidances_SC_KIO_01000.guidanceName"
        )
      ),
      ...dataStyle[0],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Guidances_SC_KIO_01000.action"
        )
      ),
      ...dataStyle[1],
    },
  ];

  const [tableData, setTableData] = useState<TableData>({
    header: headerData,
    body: [],
  });

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (loaderData.poucenia.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      loaderData.poucenia.data
        .sort(
          (p1, p2) =>
            new Date(p2.datumPouceniaKlienta ?? new Date()).valueOf() -
            new Date(p1.datumPouceniaKlienta ?? new Date()).valueOf()
        )
        .forEach((poucenie) => {
          bodyData.push({
            row: [
              {
                value: poucenie.poucenieFormaNazov ?? "",
                ...dataStyle[0],
              },
              {
                value: (
                  <ZvjsButton
                    text={capitalize(
                      t(
                        "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Guidances_SC_KIO_01000.detail"
                      )
                    )}
                    zvjsVariant="secondaryAction"
                    startIcon={
                      <DetailIcon
                        style={{ fill: "white", width: 28, height: 28 }}
                      />
                    }
                    onClick={() => {
                      navigate(`${poucenie.poucenieId}`);
                    }}
                  />
                ),
                ...dataStyle[1],
              },
            ],
          });
        });
      setTableData({
        ...tableData,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderData.poucenia, i18n.language]);

  // const mySubmitHandler = (
  //   dateFrom: Date,
  //   dateTo: Date,
  //   dropDownValue: string
  // ) => {
  //   console.log(dateFrom);
  //   console.log(dateTo);
  // };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(tui("poucenia.poucenia"))}
          </Typography>
        </Stack>
      </Grid>

      <ZvjsTable
        data={tableData}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Grid>
  );
};

export default withLoader(Poucenia);
