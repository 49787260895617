import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useUITranslation } from "../../../store/context/translation-context";
import { capitalize } from "@mui/material/utils";
import { useNavigate } from "react-router-dom";
import { ZvjsTabs } from "../../../common";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { defer } from "react-router";
import { withLoader } from "../../../hoc/withLoader";
import {
  KontoTab,
  PohladavkyTab,
  KreditABlokacieTab,
  VyplatnePaskyTab,
  ZiadostiTab,
} from "./PeniazeAPoplatkyTabs";
import {
  CiselnikListResultType,
  MAX_NUMBER_OF_ITEMS,
} from "../../../store/context/dataApi/CIS_Ciselnik";
import { requestTemplates } from "../../../common/request/requestTemplates";
import { LoaderError } from "../../../router/LoaderError";
import { PromiseElement } from "../../../utils/types";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { RequestSzooCode } from "../../../common/request/requestTemplates/requestTemplates";

const MONEY_AND_FEES_REQUEST_CATEGORY_CODE = "5";
const getPeniazeAPoplatkyZiadostiCounter = (
  subRequestsCounter?: CiselnikListResultType<"/api/CisPodkategoriaZiadosti/List">,
  requestsCounter?: CiselnikListResultType<"/api/CisTypZiadosti/List">
): CiselnikListResultType<"/api/CisTypZiadosti/List">["data"] => {
  // if any of the counters is undefined (not fetched), display error
  if (subRequestsCounter === undefined || requestsCounter === undefined) {
    throw new LoaderError();
  }

  // filter out all subCategories that belong to the specified category
  const moneyAndFeesSubCategories =
    subRequestsCounter?.data?.records?.filter(
      (subCategory) =>
        subCategory.kategoriaId?.toString() ===
        MONEY_AND_FEES_REQUEST_CATEGORY_CODE
    ) || [];

  const selectedRequestsCounter = {
    records: requestsCounter?.data?.records?.filter(
      (request) =>
        // show only supported requests
        request.kod !== undefined &&
        request.kod !== null &&
        requestTemplates[request.kod as `${RequestSzooCode}`] !== undefined &&
        // show requests that belong to the selected subCategories
        moneyAndFeesSubCategories.some(
          (subCategory) =>
            subCategory.kod === request.podKategoriaId?.toString()
        )
    ),
  };

  return selectedRequestsCounter;
};
const loadData = async () => {
  const { CIS_Post, FEOO_Get, FEOO_Post } = await API_Clients();

  const feooKlient = await FEOO_Get("/api/Klient/Get", {
    params: {
      query: {
        id: localStorage.getItem("klientObjectId") ?? undefined,
      },
    },
  });

  const data = await Promise.all([
    FEOO_Post("/api/PohybyNaKonte/List", {
      body: {
        filters: [
          {
            zakladnyFilter: {
              zakladneCislo:
                feooKlient.data?.data?.zakladneUdajeKlienta?.zakladneCislo,
            },
          },
        ],
      },
    }),
    FEOO_Post("/api/Blokacia/List", {
      body: {
        filters: [
          {
            zakladnyFilterRequest: {
              klientFeooID:
                feooKlient.data?.data?.zakladneUdajeKlienta?.klientFeooID,
            },
          },
        ],
      },
    }),
    FEOO_Post("/api/integracie/Kiosk/ListVyplatnaPaska", {
      body: {
        idKlienta: localStorage.getItem("klientObjectId"),
      },
    }),
    FEOO_Post("/api/integracie/Kiosk/ListStavKontaAKreditu", {
      body: {
        idKlienta: localStorage.getItem("klientObjectId"),
      },
    }),
    CIS_Post("/api/CisPodkategoriaZiadosti/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
    CIS_Post("/api/CisTypZiadosti/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 0,
          recordsPerPage: 99999,
        },
        sorting: [{}],
      },
    }),
    FEOO_Post("/api/Codelist/ListBlokaciaTyp", {
      body: {
        filters: [],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    }),
  ]);

  return {
    feooKlient: feooKlient,
    pohybyKonto: data[0],
    pohybyKreditBlokacie: data[1],
    vyplatnePasky: data[2],
    stavKontaAKreditu: data[3].data,
    peniazeAPoplatkyZiadostiCounter: getPeniazeAPoplatkyZiadostiCounter(
      data[4],
      data[5]
    ),
    blokaciaTyp: data[5],
  };
};

export const PeniazeAPoplatkyLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface PeniazeAPoplatkyProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const PeniazeAPoplatky = (props: PeniazeAPoplatkyProps) => {
  const loaderData = props.data;
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  const [mainLabel, setMainLabel] = useState<string>(
    "osobneUdaje.kategorieOsobnychUdajov.peniazePoplatky"
  );

  const onChangeHandler = (value: number) => {
    switch (value) {
      case 0:
        setMainLabel("osobneUdaje.kategorieOsobnychUdajov.peniazePoplatky");
        break;
      case 1:
        setMainLabel("Kredit a blokácie");
        break;
      case 2:
        setMainLabel("osobneUdaje.pohladavky.pohladavky");
        break;
      case 3:
        setMainLabel("osobneUdaje.vyplatnePasky.vyplatnePasky");
        break;
      case 4:
        setMainLabel("tlacidla.ziadosti");
        break;
    }
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(tui(mainLabel))}
            </Typography>
          </Stack>
        </Grid>
        <Grid item mt={4}>
          <ZvjsTabs
            onChange={onChangeHandler}
            data={[
              {
                label: tui("osobneUdaje.kontoAkredit.konto"),
                element: <KontoTab data={loaderData} />,
              },
              {
                label: tui("Kredit a blokácie"),
                element: <KreditABlokacieTab data={loaderData} />,
              },
              {
                label: tui("osobneUdaje.pohladavky.pohladavky"),
                element: <PohladavkyTab data={loaderData} />,
              },
              {
                label: tui("osobneUdaje.vyplatnePasky.vyplatnePasky"),
                element: <VyplatnePaskyTab data={loaderData} />,
              },
              {
                label: tui("tlacidla.ziadosti"),
                element: <ZiadostiTab data={loaderData} />,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withLoader(PeniazeAPoplatky);
