import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsItemTypes,
  ZvjsLabelFlagTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050301",
  basicInfo: [
    {
      text: "ziadost.ziadostOodoslanieKorespondencie.zakladneInfo",
    },
  ],
  dataCalculations: {
    // AVAILABLE_ACCOUNT_AMOUNT: {
    //   calculateAt: Calculate.AT_INIT,
    // },
    // SHOPPED_LAST_CALENDAR_MONTH: {
    //   calculateAt: Calculate.AT_INIT,
    // },
    SC_KIO_050301_CAN_REQUEST_PRIVATE_CORRESPONDENCE: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  counters: ["/api/CisDovodNaOdoslanieKorespondencie/List"],
  items: [
    {
      id: "dovodOdoslaniaKorespondencie",
      title:
        "ziadost.ziadostOodoslanieKorespondencie.labelDovodOdoslaniaKorespondencie",
      type: ZvjsQuestionTypes.NESTED_DROPDOWN,
      isRequired: true,
      options: {
        answerId: "dovodOdoslaniaKorespondencie",
        counterKey: "/api/CisDovodNaOdoslanieKorespondencie/List",
        textKey: "dovod",
        children: [
          {
            id: "LIST",
            conditions: [
              {
                type: ZvjsConditionTypes.FUNCTION,
                conFunc: "SC_KIO_050301_CAN_REQUEST_PRIVATE_CORRESPONDENCE",
                conditionMetWhenValueIs: true,
              },
            ],
          },
          {
            id: "URADNA",
          },
        ],
      },
    },
    {
      id: "SC_KIO_050301_QUES_02",
      title:
        "ziadost.ziadostOodoslanieKorespondencie.labelPocetSukromnejKorešpondencie",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050301_QUES_01",
          value: ["COUNTER_1"],
        },
      ],
      options: [
        {
          id: "E_KIO_18_01",
          label: "1",
        },
        {
          id: "E_KIO_18_02",
          label: "2",
        },
      ],
    },
    {
      text: "ziadost.ziadostOodoslanieKorespondencie.napovedaTrestneKonanie",
      type: ZvjsItemTypes.LABEL,
      flag: ZvjsLabelFlagTypes.INFO,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "dovodOdoslaniaKorespondencie",
          value: [{ dovodOdoslaniaKorespondencie: "URADNA" }],
        },
      ],
    },
  ],
};
