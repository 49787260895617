import { useEffect, useState } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ReactComponent as DetailIcon } from "../../../../../assets/icons/Other/detail.svg";
import { useNavigate } from "react-router-dom";
import { ZvjsButton, ZvjsTable } from "../../../../../common";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../../../store/context/translation-context";
import { defer } from "react-router";
import { withLoader } from "../../../../../hoc/withLoader";
import { API_Clients } from "../../../../../store/context/dataApi/Data";
import { PromiseElement } from "../../../../../utils/types";

const loadData = async () => {
  const { FEOO_Get, FEOO_Post } = await API_Clients();

  const feooKlient = await FEOO_Get("/api/Klient/Get", {
    params: {
      query: {
        id: localStorage.getItem("klientObjectId") ?? undefined,
      },
    },
  });

  const data = await Promise.all([
    FEOO_Post("/api/VyzivovaciaPovinnost/List", {
      body: {
        filters: [
          {
            zakladnyFilter: {
              zakladneCislo:
                feooKlient.data?.data?.zakladneUdajeKlienta?.zakladneCislo,
            },
          },
        ],
      },
    }),
  ]);

  return {
    vyzivovaciePovinnosti: data[0],
    klientId: localStorage.getItem("klientId"),
  };
};

export const PohladavkyVyzivovacichPovinnostiLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface PohladavkyVyzivovacichPovinnostiProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const PohladavkyVyzivovacichPovinnosti = (
  props: PohladavkyVyzivovacichPovinnostiProps
) => {
  const loaderData = props.data;
  const { t, i18n } = useTranslation();
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "right",
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(tui("osobneUdaje.vseobecneOsobneUdaje.meno")),
      ...dataStyle[0],
    },
    {
      value: capitalize(tui("osobneUdaje.vseobecneOsobneUdaje.priezvisko")),
      ...dataStyle[1],
    },
    {
      value: capitalize(tui("osobneUdaje.rozpisSplatky.sposobZaevidovania")),
      ...dataStyle[2],
    },
    {
      value: capitalize(
        tui("osobneUdaje.vyzivovaciePovinnosti.sumaBeznehoVyzivneho")
      ),
      ...dataStyle[3],
    },
    {
      value: capitalize(
        tui("osobneUdaje.vyzivovaciePovinnosti.sumaDlznehoVyzivneho")
      ),
      ...dataStyle[4],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Alimonys_SC_KIO_03604.action"
        )
      ),
      ...dataStyle[5],
    },
  ];

  const [tableData, setTableData] = useState<TableData>({
    header: headerData,
    body: [],
  });

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (loaderData.vyzivovaciePovinnosti.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      loaderData.vyzivovaciePovinnosti.data.records?.forEach(
        (vyzivovaciaPovinnost) => {
          bodyData.push({
            row: [
              {
                value:
                  vyzivovaciaPovinnost.vyzivovanaOsoba?.split(" ").at(0) ?? "",
                ...dataStyle[0],
              },
              {
                value:
                  vyzivovaciaPovinnost.vyzivovanaOsoba?.split(" ").at(1) ?? "",
                ...dataStyle[1],
              },
              {
                value: vyzivovaciaPovinnost.typZaevidovania ?? "",
                ...dataStyle[2],
              },
              {
                value: vyzivovaciaPovinnost.mesacneVyzivne
                  ? vyzivovaciaPovinnost.mesacneVyzivne.toFixed(2) + " Eur"
                  : "",
                ...dataStyle[3],
              },
              {
                value: vyzivovaciaPovinnost.zaostaleVyzivne
                  ? vyzivovaciaPovinnost.zaostaleVyzivne.toFixed(2) + " Eur"
                  : "",
                ...dataStyle[4],
              },
              {
                value: (
                  <ZvjsButton
                    text={capitalize(
                      t(
                        "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Alimonys_SC_KIO_03604.paymentSchedule"
                      )
                    )}
                    zvjsVariant="secondaryAction"
                    startIcon={
                      <DetailIcon
                        style={{ fill: "white", width: 28, height: 28 }}
                      />
                    }
                    onClick={() => {
                      navigate(
                        `${vyzivovaciaPovinnost.vyzivovaciaPovinnostID}`
                      );
                    }}
                    style={{
                      lineHeight: "1",
                      textTransform: "none",
                      textAlign: "left",
                    }}
                  />
                ),
                ...dataStyle[5],
              },
            ],
          });
        }
      );
      setTableData({
        ...tableData,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderData, i18n.language]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const mySubmitHandler = (
    dateFrom: Date,
    dateTo: Date,
    dropDownValue: string
  ) => {
    console.log(dateFrom);
    console.log(dateTo);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(
              t(
                "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.Alimonys_SC_KIO_03604.title"
              )
            )}
          </Typography>
        </Stack>
      </Grid>

      <ZvjsTable
        data={tableData}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Grid>
  );
};

export default withLoader(PohladavkyVyzivovacichPovinnosti);
