import React from "react";
import { PeniazeAPoplatkyProps } from "../PeniazeAPoplatky";
import { capitalize } from "@mui/material/utils";
import {
  CellData,
  RowData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  getTranslationByLanguageCode,
  getUniqueCodesFromCiselnik,
} from "../../../../utils/helpers";
import { getCiselnikJazyk } from "../../../../locales/i18n";
import i18n from "i18next";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsTable,
} from "../../../../common";
import { ZVJS_COLORS } from "../../../../theme/zvjs_theme";
import { ReactComponent as KeyboardArrowRightIcon } from "../../../../assets/icons/Other/keyboard_arrow_right.svg";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";

const ZiadostiTab: React.FC<PeniazeAPoplatkyProps> = ({ data }) => {
  const { tui, getFallbackJazyk } = useUITranslation();
  const navigate = useNavigate();

  const dataStyleRequestsTable: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "right",
      width: 250,
    },
  ];

  const headerDataRequestsInCategory: Array<CellData> = [
    {
      value: capitalize(
        tui(
          "Názov žiadosti" // TODO: use proper KEY
        )
      ),
      ...dataStyleRequestsTable[0],
    },
    {
      value: capitalize(
        tui(
          "Akcia" // TODO: use proper KEY
        )
      ),
      ...dataStyleRequestsTable[1],
    },
  ];

  const getTableBody = () => {
    const counterCodes = getUniqueCodesFromCiselnik(
      data.peniazeAPoplatkyZiadostiCounter?.records ?? []
    );

    const requests = counterCodes.map((code) => {
      return {
        requestCode: code,
        title: getTranslationByLanguageCode(
          data.peniazeAPoplatkyZiadostiCounter?.records ?? [],
          getCiselnikJazyk(i18n.language),
          getFallbackJazyk(),
          code,
          "nazov"
        ),
      };
    });

    const bodyData: Array<RowData> = new Array<RowData>();
    requests.forEach((request) => {
      bodyData.push({
        row: [
          {
            value: request.title,
            ...dataStyleRequestsTable[0],
          },
          {
            value: (
              <ZvjsButton
                // TODO: add translation
                text={capitalize("vybrať žiadosť")}
                zvjsVariant="secondaryAction"
                endIcon={
                  <KeyboardArrowRightIcon
                    style={{ fill: ZVJS_COLORS.WHITE, width: 28, height: 28 }}
                  />
                }
                onClick={() => {
                  navigate(
                    `/auth/${Dashboard_SC_KIO_0200.Requests}/${request.requestCode}`
                  );
                }}
              />
            ),
            ...dataStyleRequestsTable[1],
          },
        ],
      });
    });

    return bodyData;
  };

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsTable
        data={{
          header: headerDataRequestsInCategory,
          body: getTableBody(),
        }}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default ZiadostiTab;
