import React, { useEffect, useState } from "react";
import { ZakladneUdajeOTresteProps } from "../ZakladneUdajeOTreste";
import { ZvjsButton, ZvjsTable } from "../../../../common";
import { Stack } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DetailIcon } from "../../../../assets/icons/Other/detail.svg";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";

const RozhodnutiaTab: React.FC<ZakladneUdajeOTresteProps> = ({ data }) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const dataStyleRozhodnutia: TableStyle[] = [
    {
      align: "left",
      width: "calc(100% / 4)",
    },
    {
      align: "center",
      width: "calc(100% / 4)",
    },
    {
      align: "center",
      width: "calc(100% / 4)",
    },
    {
      align: "right",
      width: "calc(100% / 4)",
    },
  ];

  const headerDataRozhodnutia: Array<CellData> = [
    {
      value: capitalize(tui("názov")),
      ...dataStyleRozhodnutia[0],
    },
    {
      value: capitalize(tui("spisová značka")),
      ...dataStyleRozhodnutia[1],
    },
    {
      value: capitalize(tui("aktuálny")),
      ...dataStyleRozhodnutia[2],
    },
    {
      value: capitalize(tui("akcia")),
      ...dataStyleRozhodnutia[3],
    },
  ];

  const [tableDataRozhodnutie, setTableDataRozhodnutie] = useState<TableData>({
    label: capitalize(tui("zoznam rozhodnutí OČTK a súdov")),
    header: headerDataRozhodnutia,
    body: [],
  });

  useEffect(
    () => {
      if (data.rozhodnutia) {
        const bodyData: Array<RowData> = new Array<RowData>();
        data.rozhodnutia.data?.records?.forEach((rozhodnutie) => {
          // .sort((p1, p2) =>
          //   p1.name > p2.name)
          // .forEach((rozhodnutie) => {
          bodyData.push({
            row: [
              {
                value: rozhodnutie.typRozhodnutiaSuduAleboOctkNazov ?? "",
                ...dataStyleRozhodnutia[0],
              },
              {
                value: rozhodnutie.spisovaZnacka ?? "",
                ...dataStyleRozhodnutia[1],
              },
              {
                // TODO: co znamena stlpec "Aktualny", aku property tam dat?
                value: (!rozhodnutie.zrusene ? tui("áno") : tui("nie")) ?? "",
                ...dataStyleRozhodnutia[2],
              },
              {
                value: (
                  <ZvjsButton
                    text={capitalize(tui("detail"))}
                    zvjsVariant="secondaryAction"
                    startIcon={
                      <DetailIcon
                        style={{ fill: "white", width: 28, height: 28 }}
                      />
                    }
                    onClick={() => {
                      navigate(`Verdict/${rozhodnutie.id}`);
                    }}
                  />
                ),
                ...dataStyleRozhodnutia[3],
              },
            ],
          });
        });
        setTableDataRozhodnutie({
          ...tableDataRozhodnutie,
          body: bodyData,
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <Stack direction="column" mt={1} spacing={3}>
      <ZvjsTable
        data={tableDataRozhodnutie}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};
export default RozhodnutiaTab;
