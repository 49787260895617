import React, { useEffect, useState } from "react";
import { ResocializacneAVolnocasoveAktivityProps } from "../ResocializacneAVolnocasoveAktivity";
import { Stack, capitalize } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ZvjsTable } from "../../../../common";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";

const AbsolvovaneAktivityTab: React.FC<
  ResocializacneAVolnocasoveAktivityProps
> = ({ data }) => {
  const { t } = useTranslation();

  const dataStyleAbsolvovaneProgramyAAktivity: TableStyle[] = [
    {
      align: "left",
      width: "calc(100% / 3)",
    },
    {
      align: "right",
      width: "calc(100% / 3)",
    },
    {
      align: "right",
      width: "calc(100% / 3)",
    },
  ];

  const dataStyleAbsolvovaneVolnocasoveAktivityKruzky: TableStyle[] = [
    {
      align: "left",
      width: "calc(100% / 3)",
    },
    {
      align: "right",
      width: "calc(100% / 3)",
    },
    {
      align: "right",
      width: "calc(100% / 3)",
    },
  ];

  const dataStyleAbsolvovaneVolnocasoveAktivityOstatne: TableStyle[] = [
    {
      align: "left",
      width: "calc(100% / 3)",
    },
    {
      align: "right",
      width: "calc(100% / 3)",
    },
    {
      align: "right",
      width: "calc(100% / 3)",
    },
  ];

  const dataStyleAbsolvovaneVychovnoOsvetoveAktivity: TableStyle[] = [
    {
      align: "left",
      width: "calc(100% / 3)",
    },
    {
      align: "right",
      width: "calc(100% / 3)",
    },
    {
      align: "right",
      width: "calc(100% / 3)",
    },
  ];

  const headerDataAbsolvovaneProgramyAAktivity: Array<CellData> = [
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.CompletedActivities.programPlace"
        )
      ),
      ...dataStyleAbsolvovaneProgramyAAktivity[0],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.CompletedActivities.programStart"
        )
      ),
      ...dataStyleAbsolvovaneProgramyAAktivity[1],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.CompletedActivities.programEnd"
        )
      ),
      ...dataStyleAbsolvovaneProgramyAAktivity[2],
    },
  ];

  const headerDataAbsolvovaneVolnocasoveAktivityKruzky: Array<CellData> = [
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.CompletedActivities.classType"
        )
      ),
      ...dataStyleAbsolvovaneVolnocasoveAktivityKruzky[0],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.CompletedActivities.classStartDate"
        )
      ),
      ...dataStyleAbsolvovaneVolnocasoveAktivityKruzky[1],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.CompletedActivities.classEndDate"
        )
      ),
      ...dataStyleAbsolvovaneVolnocasoveAktivityKruzky[2],
    },
  ];

  const headerDataAbsolvovaneVolnocasoveAktivityOstatne: Array<CellData> = [
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.CompletedActivities.otherActivityKind"
        )
      ),
      ...dataStyleAbsolvovaneVolnocasoveAktivityOstatne[0],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.CompletedActivities.otherActivityType"
        )
      ),
      ...dataStyleAbsolvovaneVolnocasoveAktivityOstatne[1],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.CompletedActivities.otherActivityDate"
        )
      ),
      ...dataStyleAbsolvovaneVolnocasoveAktivityOstatne[2],
    },
  ];

  const headerDataAbsolvovaneVychovnoOsvetoveAktivity: Array<CellData> = [
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.CompletedActivities.educationaPlace"
        )
      ),
      ...dataStyleAbsolvovaneVychovnoOsvetoveAktivity[0],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.CompletedActivities.educationalFocus"
        )
      ),
      ...dataStyleAbsolvovaneVychovnoOsvetoveAktivity[1],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.CompletedActivities.educationalDate"
        )
      ),
      ...dataStyleAbsolvovaneVychovnoOsvetoveAktivity[2],
    },
  ];

  const [
    tableDataAbsolvovaneProgramyAAktivity,
    setTableDataAbsolvovaneProgramyAAktivity,
  ] = useState<TableData>({
    label: capitalize(
      t(
        "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.CompletedActivities.tableCompletedProgramsAndActivities"
      )
    ),
    header: headerDataAbsolvovaneProgramyAAktivity,
    body: [],
  });

  const [
    tableDataAbsolvovaneVolnocasoveAktivityKruzky,
    setTableDataAbsolvovaneVolnocasoveAktivityKruzky,
  ] = useState<TableData>({
    label: capitalize(
      t(
        "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.CompletedActivities.tableCompletedLeisureActivitiesClasses"
      )
    ),
    header: headerDataAbsolvovaneVolnocasoveAktivityKruzky,
    body: [],
  });

  const [
    tableDataAbsolvovaneVolnocasoveAktivityOstatne,
    setTableDataAbsolvovaneVolnocasoveAktivityOstatne,
  ] = useState<TableData>({
    label: capitalize(
      t(
        "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.CompletedActivities.tableCompletedLeisureActivitiesOther"
      )
    ),
    header: headerDataAbsolvovaneVolnocasoveAktivityOstatne,
    body: [],
  });

  const [
    tableDataAbsolvovaneVychovnoOsvetoveAktivity,
    setTableDataAbsolvovaneVychovnoOsvetoveAktivity,
  ] = useState<TableData>({
    label: capitalize(
      t(
        "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.CompletedActivities.tableCompletedEducationalActivities"
      )
    ),
    header: headerDataAbsolvovaneVychovnoOsvetoveAktivity,
    body: [],
  });

  useEffect(() => {
    if (data.absolvovaneProgramyAAktivity.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      data.absolvovaneProgramyAAktivity.data
        .sort(
          (a, b) =>
            new Date(b.terminZaciatkuBehuProgramu ?? new Date()).valueOf() -
            new Date(a.terminZaciatkuBehuProgramu ?? new Date()).valueOf()
        )
        .forEach((item) => {
          bodyData.push({
            row: [
              {
                value: item.miestoRealizacieVUstave ?? "",
                ...dataStyleAbsolvovaneProgramyAAktivity[0],
              },
              {
                value: new Date(
                  item.terminZaciatkuBehuProgramu ?? new Date()
                ).zvjsToString(),
                ...dataStyleAbsolvovaneProgramyAAktivity[1],
              },
              {
                value: new Date(
                  item.terminUkonceniaBehuProgramu ?? new Date()
                ).zvjsToString(),
                ...dataStyleAbsolvovaneProgramyAAktivity[2],
              },
            ],
          });
        });
      setTableDataAbsolvovaneProgramyAAktivity({
        ...tableDataAbsolvovaneProgramyAAktivity,
        body: bodyData,
      });
    }

    if (data.absolvovaneVolnocasoveAktivityKruzky.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      data.absolvovaneVolnocasoveAktivityKruzky.data
        .sort(
          (a, b) =>
            new Date(b.behTerminZaciatku ?? new Date()).valueOf() -
            new Date(a.behTerminZaciatku ?? new Date()).valueOf()
        )
        .forEach((item) => {
          bodyData.push({
            row: [
              {
                value: item.typKruzkuNazov ?? "",
                ...dataStyleAbsolvovaneVolnocasoveAktivityKruzky[0],
              },
              {
                value: new Date(
                  item.behTerminZaciatku ?? new Date()
                ).zvjsToString(),
                ...dataStyleAbsolvovaneVolnocasoveAktivityKruzky[1],
              },
              {
                value: new Date(
                  item.behTerminUkoncenia ?? new Date()
                ).zvjsToString(),
                ...dataStyleAbsolvovaneVolnocasoveAktivityKruzky[2],
              },
            ],
          });
        });
      setTableDataAbsolvovaneVolnocasoveAktivityKruzky({
        ...tableDataAbsolvovaneVolnocasoveAktivityKruzky,
        body: bodyData,
      });
    }

    if (data.absolvovaneVolnocasoveAktivityOstatne.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      data.absolvovaneVolnocasoveAktivityOstatne.data
        .sort(
          (a, b) =>
            new Date(b.datumKonania ?? new Date()).valueOf() -
            new Date(a.datumKonania ?? new Date()).valueOf()
        )
        .forEach((item) => {
          bodyData.push({
            row: [
              {
                value: item.druhVolnocasovejAktivityNazov ?? "",
                ...dataStyleAbsolvovaneVolnocasoveAktivityOstatne[0],
              },
              {
                value: item.typVolnocasovejAktivityNazov ?? "",
                ...dataStyleAbsolvovaneVolnocasoveAktivityOstatne[1],
              },
              {
                value: new Date(item.datumKonania ?? new Date()).zvjsToString(),
                ...dataStyleAbsolvovaneVolnocasoveAktivityOstatne[2],
              },
            ],
          });
        });
      setTableDataAbsolvovaneVolnocasoveAktivityOstatne({
        ...tableDataAbsolvovaneVolnocasoveAktivityOstatne,
        body: bodyData,
      });
    }

    if (data.absolvovaneVychovnoOsvetoveAktivity.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      data.absolvovaneVychovnoOsvetoveAktivity.data
        .sort(
          (a, b) =>
            new Date(b.datumKonania ?? new Date()).valueOf() -
            new Date(a.datumKonania ?? new Date()).valueOf()
        )
        .forEach((item) => {
          bodyData.push({
            row: [
              {
                value: item.miestoRealizacie ?? "",
                ...dataStyleAbsolvovaneVychovnoOsvetoveAktivity[0],
              },
              {
                value: item.tematickeZameranieNazov ?? "",
                ...dataStyleAbsolvovaneVychovnoOsvetoveAktivity[1],
              },
              {
                value: new Date(item.datumKonania ?? new Date()).zvjsToString(),
                ...dataStyleAbsolvovaneVychovnoOsvetoveAktivity[2],
              },
            ],
          });
        });
      setTableDataAbsolvovaneVychovnoOsvetoveAktivity({
        ...tableDataAbsolvovaneVychovnoOsvetoveAktivity,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Stack direction="column" mt={1} spacing={3}>
      <ZvjsTable
        data={tableDataAbsolvovaneProgramyAAktivity}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
      <ZvjsTable
        data={tableDataAbsolvovaneVolnocasoveAktivityKruzky}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
      <ZvjsTable
        data={tableDataAbsolvovaneVolnocasoveAktivityOstatne}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
      <ZvjsTable
        data={tableDataAbsolvovaneVychovnoOsvetoveAktivity}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default AbsolvovaneAktivityTab;
