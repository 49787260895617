import React from "react";
import { ZakladneUdajeKlientaProps } from "../ZakladneUdajeKlienta";
import {
  Dashboard_SC_KIO_0200,
  ZvjsButton,
  ZvjsOsobneUdajeFormular,
  ZvjsSummaryTable,
} from "../../../../common";
import { ZvjsSummaryTableComponent } from "../../../../common/components/ZvjsSummaryTable";
import { Grid, Stack } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { useUITranslation } from "../../../../store/context/translation-context";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../../store/context/modal-context";

const OsobneUdajeTab: React.FC<ZakladneUdajeKlientaProps> = ({ data }) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();
  const { openModal } = useModal();

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsSummaryTable
        header={
          tui("osobneUdaje.vseobecneOsobneUdaje.vseobecneOsobneUdaje") as string
        }
      >
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneOsobneUdaje.meno")}
          value={`${
            data.eoo_klient_DetailZakladneUdajeKlientaData?.data?.data?.meno ??
            ""
          } ${
            data.eoo_klient_DetailZakladneUdajeKlientaData?.data?.data
              ?.druheMeno ?? ""
          }`}
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneOsobneUdaje.priezvisko")}
          value={
            data.eoo_klient_DetailZakladneUdajeKlientaData?.data?.data
              ?.priezvisko ?? ""
          }
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneOsobneUdaje.datumNarodenia")}
          value={
            data.eoo_klient_DetailZakladneUdajeKlientaData?.data?.data
              ?.datumNarodenia
              ? new Date(
                  data.eoo_klient_DetailZakladneUdajeKlientaData?.data?.data?.datumNarodenia
                ).zvjsToString()
              : ""
          }
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneOsobneUdaje.miestoNarodenia")}
          value={
            data.eoo_fyzickaOsoba_DetailData?.data?.data?.miestoNarodenia ?? ""
          }
          isBorder={true}
          fullWidth={true}
        />
        <ZvjsSummaryTableComponent
          label={tui("Rodinný stav")}
          value={
            data.socialnaDiagnostikaOdsudeneho?.data?.at(0)?.rodinnyStavNazov ??
            ""
          }
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui("osobneUdaje.vseobecne.napovedaPravdivyUaj")}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneOsobneUdaje.najvyssieVzdelanie")}
          value={
            data.socialnaDiagnostikaOdsudeneho?.data?.at(0)?.vzdelanieNazov ??
            ""
          }
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui("osobneUdaje.vseobecneOsobneUdaje.napovedaVzdelanie")}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneOsobneUdaje.poberanyDochodok")}
          value={
            (data.feooKlient?.data?.data?.zakladneUdajeKlienta?.dochodca
              ? tui("áno")
              : tui("nie")) +
            (data.feooKlient.data?.data?.rozsireneUdajeKlienta?.typDochodku1
              ? ` ${data.feooKlient.data?.data?.rozsireneUdajeKlienta?.typDochodku1}`
              : "") +
            (data.feooKlient.data?.data?.rozsireneUdajeKlienta?.typDochodku2
              ? ` ${data.feooKlient.data?.data?.rozsireneUdajeKlienta?.typDochodku2}`
              : "") +
            (data.feooKlient.data?.data?.rozsireneUdajeKlienta?.typDochodku3
              ? ` ${data.feooKlient.data?.data?.rozsireneUdajeKlienta?.typDochodku3}`
              : "")
          }
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui("osobneUdaje.vseobecneOsobneUdaje.napovedaDochodok")}
        />
        <ZvjsSummaryTableComponent
          label={tui("osobneUdaje.vseobecneOsobneUdaje.poskytovanyDruhStravy")}
          value={
            data.klientUni.data?.data?.rozsireneUdajeKlienta
              ?.druhStravnejDavky ?? ""
          }
          isBorder={true}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui("osobneUdaje.vseobecneOsobneUdaje.napovedaDruhStravy")}
        />
        <ZvjsSummaryTableComponent
          label={tui("fajčiar")}
          value={
            data.socialnaDiagnostikaOdsudeneho?.data?.at(0)?.fajciar
              ? tui("áno")
              : tui("nie")
          }
          isBorder={false}
          fullWidth={true}
          hintTitle={tui("nápoveda").toString()}
          hintText={tui("osobneUdaje.vseobecne.napovedaPravdivyUaj")}
        />
      </ZvjsSummaryTable>
      <Grid container spacing={1} width={"100%"} justifyContent={"flex-start"}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <ZvjsButton
            text={capitalize(
              tui("osobneUdaje.AdresaPrechodnehoBydliska.ziadostOzmenuUdaja")
            )}
            zvjsVariant="secondaryAction"
            endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
            onClick={() => {
              openModal(<ZvjsOsobneUdajeFormular data={data} />);
            }}
            style={{
              lineHeight: "1",
              textTransform: "none",
              textAlign: "left",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <ZvjsButton
            text={capitalize(
              tui("osobneUdaje.AdresaPrechodnehoBydliska.zmenaStravnejDavky")
            )}
            zvjsVariant="secondaryAction"
            endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
            onClick={() => {
              navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/12.00.01`);
            }}
            style={{
              lineHeight: "1",
              textTransform: "none",
              textAlign: "left",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <ZvjsButton
            text={capitalize(
              tui("osobneUdaje.AdresaPrechodnehoBydliska.pohovorSocPracovnik")
            )}
            zvjsVariant="secondaryAction"
            endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
            onClick={() => {
              navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/10.02.01`);
            }}
            style={{
              lineHeight: "1",
              textTransform: "none",
              textAlign: "left",
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default OsobneUdajeTab;
