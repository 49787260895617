import React from "react";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import questionnaireRedux from "../../../../redux";
import {
  ZvjsCustomQuestionTypes,
  ZvjsQuestionTypes,
} from "../../../../redux/model";
import { GeneralItem, Question } from "../index";
import { ZvjsSectionUI } from "../../../../../components";

interface SectionProps {
  location: number[];
}

const Section: React.FC<SectionProps> = ({ location }) => {
  const sectionItems =
    useSelector(questionnaireRedux.selectors.getSectionItems(location)) || [];

  const sectionTitles =
    useSelector(questionnaireRedux.selectors.getSectionTitles(location)) || [];

  console.log(`SECTION RERENDER ${location.join("-")}`);

  const itemsToDisplay = [];
  for (let i = 0; i < sectionItems.length; i++) {
    if (
      sectionItems[i] in ZvjsQuestionTypes ||
      sectionItems[i] in ZvjsCustomQuestionTypes
    ) {
      itemsToDisplay.push(<Question key={i} location={location.concat(i)} />);
    } else {
      itemsToDisplay.push(
        <GeneralItem key={i} location={location.concat(i)} />
      );
    }
  }

  return (
    <ZvjsSectionUI sectionTitles={sectionTitles}>
      <Stack spacing={2}>{itemsToDisplay}</Stack>
    </ZvjsSectionUI>
  );
};

export default Section;
