import { useEffect, useState } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useNavigate } from "react-router-dom";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router";
import { withLoader } from "../../../hoc/withLoader";
import { Noticeboard_SC_KIO_0400 } from "../../../common/specs/sections/Noticeboard_SC_KIO_0400";
import { ZvjsTable } from "../../../common";

export const StravovanieLoader = async () => {
  return defer({
    data: [
      {
        name: "Jedálny lístok",
        dateFrom: new Date("2023-11-06"),
        dateTo: new Date("2023-11-12"),
      },
      {
        name: "Jedálny lístok",
        dateFrom: new Date("2023-11-13"),
        dateTo: new Date("2023-11-19"),
      },
      {
        name: "Jedálny lístok",
        dateFrom: new Date("2023-11-20"),
        dateTo: new Date("2023-11-26"),
      },
    ],
  });
};

interface StravovanieProps {
  data: { name: string; dateFrom: Date; dateTo: Date }[] | undefined;
}

const Stravovanie = (props: StravovanieProps) => {
  const data = props.data;
  const { t } = useTranslation();
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "left",
    },
    {
      align: "left",
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: "Dátum od",
      ...dataStyle[0],
    },
    {
      value: "Dátum do",
      ...dataStyle[1],
    },
    {
      value: "Názov",
      ...dataStyle[2],
    },
  ];

  const [tableData, setTableData] = useState<TableData>({
    header: headerData,
    body: [],
  });

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      data
        .sort(
          (p1, p2) =>
            new Date(p2.dateFrom).valueOf() - new Date(p1.dateFrom).valueOf()
        )
        .forEach((jedalnyListok) => {
          bodyData.push({
            row: [
              {
                value: new Date(jedalnyListok.dateFrom).zvjsToString(),
                ...dataStyle[0],
              },
              {
                value: new Date(jedalnyListok.dateTo).zvjsToString(),
                ...dataStyle[1],
              },
              {
                value: jedalnyListok.name,
                ...dataStyle[2],
              },
            ],
          });
        });
      setTableData({
        ...tableData,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // const mySubmitHandler = (
  //   dateFrom: Date,
  //   dateTo: Date,
  //   dropDownValue: string
  // ) => {
  //   console.log(dateFrom);
  //   console.log(dateTo);
  // };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(
              t(
                `spec.section.Noticeboard_SC_KIO_0400.${Noticeboard_SC_KIO_0400.Meal}`
              )
            )}
          </Typography>
        </Stack>
      </Grid>

      <ZvjsTable
        data={tableData}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Grid>
  );
};

export default withLoader(Stravovanie);
