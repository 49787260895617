import {
  ZvjsCustomQuestionTypes,
  ZvjsQuestionnaireTemplate,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050206",
  basicInfo: [
    {
      text: "ziadost.ziadostOvykonanieZrazokZcpo.zakladneInfo",
    },
  ],
  isAvailableToUser: "SC_KIO_050206_isAvailableToUser",
  items: [
    {
      id: "SC_KIO_050206_QUES_01",
      type: ZvjsCustomQuestionTypes.SC_KIO_050206_REGISTERED_CHILDREN_GRID,
      isRequired: true,
      title: "Evidované dieťa",
    },
  ],
};
