import {
  FileApiCallHeaders,
  FileDirectoryApiCallHeaders,
  targetApi,
} from "../../utils/targetApi";
import { Module } from "../../utils/module";

const useFileApi = () => {
  const template = async (api: string): Promise<Blob | undefined> => {
    try {
      console.debug(`API[${await targetApi(api)}]`);

      const response = await fetch(await targetApi(api), {
        headers: FileApiCallHeaders,
        method: "GET",
      });

      if (!response.ok) {
        throw new Error(`API[${await targetApi(api)}] fetch failed`);
      }

      const blob = await response.blob();
      if (blob) {
        console.debug(`Blob: type[${blob.type}] size[${blob.size}]`);
        return blob;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(`Error: ${error}`);
    }
  };

  const getBlob = (filename: string): Promise<Blob | undefined> => {
    return template(`/${Module.INTERNAL}/api/v1/File/${filename}`);
  };

  const getDirectory = async (
    filepath: string
  ): Promise<string | undefined> => {
    try {
      const path = `/${Module.INTERNAL}/api/v1/File/${filepath}`;
      console.debug(`API[${targetApi(path)}]`);

      const response = await fetch(await targetApi(path), {
        headers: FileDirectoryApiCallHeaders,
        method: "PROPFIND",
      });

      const text = await response.text();
      if (text) {
        return text;
      }
    } catch (error: any) {
      console.error(`Error: ${error}`);
    }
  };

  return {
    getBlob,
    getDirectory,
  };
};

export default useFileApi;
