import {
  UserCalcData,
  ZvjsAnswerValueType,
  ZvjsValidationError,
  ZvjsValidationFlagTypes,
} from "../../../../redux/model";
import { ValidationType } from "../validations";
import { ElectricDevice } from "../calculations/reusable";

const SC_KIO_050207_validate_requested_amount = ({
  newAnswer,
  userCalcData,
}: {
  newAnswer: ZvjsAnswerValueType;
  userCalcData: UserCalcData;
}): ZvjsValidationError | undefined => {
  const availableAccountAmount = userCalcData["AVAILABLE_ACCOUNT_AMOUNT"];

  if (availableAccountAmount !== undefined) {
    if (Number(newAnswer) > availableAccountAmount) {
      return {
        type: ZvjsValidationFlagTypes.ERROR,
        message:
          "Vyska ziadanej sumy nesmie presiahnut vysku stavu disponibilneho konta",
      };
    }
  }

  return undefined;
};

const SC_KIO_050208_validate_requested_amount = ({
  newAnswer,
  userCalcData,
}: {
  newAnswer: ZvjsAnswerValueType;
  userCalcData: UserCalcData;
}): ZvjsValidationError | undefined => {
  const availableAccountAmount = userCalcData["AVAILABLE_ACCOUNT_AMOUNT"];

  if (availableAccountAmount !== undefined) {
    if (Number(newAnswer) > availableAccountAmount) {
      return {
        type: ZvjsValidationFlagTypes.ERROR,
        message:
          "Vyska ziadanej sumy nesmie presiahnut vysku moznej sumy na nákup",
      };
    }
  }

  return undefined;
};

/**
 * helper function (this is not validation on its own) which checks whether user is already using device
 * @param requestedDeviceTypeCode type of new electric device which user wants to start using
 * @param userDevices list of user devices
 */
export const SC_KIO_05050_does_user_already_use_device = (
  requestedDeviceTypeCode: string,
  userDevices: ElectricDevice[]
) => {
  if (Array.isArray(userDevices) && requestedDeviceTypeCode !== undefined) {
    for (const userDevice of userDevices) {
      if (userDevice.typeCode === requestedDeviceTypeCode) {
        return true;
      }
    }
  }

  return false;
};

const custom: ValidationType = {
  SC_KIO_050207_VALIDATE_REQUESTED_AMOUNT:
    SC_KIO_050207_validate_requested_amount,
  SC_KIO_050208_VALIDATE_REQUESTED_AMOUNT:
    SC_KIO_050208_validate_requested_amount,
};

export default custom;
