import React, { Fragment, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import { capitalize } from "@mui/material";
import { ZVJS_COLORS } from "../../theme/zvjs_theme";
import { useLocation, useNavigate } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

export interface TabData {
  label: string;
  element: React.ReactNode;
}

interface ZvjsTabsProps {
  data: TabData[];
  onChange: (value: number) => unknown;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...rest
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...rest}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </div>
  );
};

/**
 * When using ZVJSTabs you need to define shouldRevalidate function in Router.tsx for your current route,
 * otherwise loader function will be executed between each tab switch (which is not necessarily bad, it just depends on your use case)
 */
const ZvjsTabs: React.FC<ZvjsTabsProps> = ({ data, ...rest }) => {
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [tabLoadedFromPath, setTabLoadedFromPath] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("tab", newValue.toString());
    navigate(
      { search: searchParams.toString() },
      {
        // replace current path (so back button redirects you directly to previous page and not to the previous tab)
        replace: true,
      }
    );
    rest.onChange(newValue);
  };

  useEffect(() => {
    // try to read tab from url
    const searchParams = new URLSearchParams(location.search);
    const tabValue = searchParams.get("tab");
    setValue(parseInt(tabValue || "0"));
    setTabLoadedFromPath(true);
  }, [location.search]);

  // do not display default tab before you try to read tab from url - to avoid flickering of tabs when returning from some page
  if (!tabLoadedFromPath) {
    return <></>;
  } else {
    return (
      <Fragment>
        <Box sx={{ borderBottom: 3, borderColor: ZVJS_COLORS.GREY_2 }}>
          <Tabs
            variant="scrollable"
            scrollButtons={false}
            value={value}
            onChange={handleChange}
            sx={{
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            {data.map((data, index) => (
              <Tab
                key={"tab" + index}
                label={capitalize(data.label)}
                style={{
                  background:
                    value === index ? ZVJS_COLORS.BLUE : ZVJS_COLORS.WHITE,
                  marginRight: 8,
                  borderTopRightRadius: 5,
                  borderTopLeftRadius: 5,
                  fontWeight: 700,
                  fontSize: 22,
                  color:
                    value === index ? ZVJS_COLORS.WHITE : ZVJS_COLORS.BLACK,
                  textTransform: "none",
                }}
              />
            ))}
          </Tabs>
        </Box>
        {data.map((data, index) => (
          <TabPanel key={"tabPanel" + index} value={value} index={index}>
            {data.element}
          </TabPanel>
        ))}
      </Fragment>
    );
  }
};

export default ZvjsTabs;
