import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useUITranslation } from "../../../store/context/translation-context";
import { capitalize } from "@mui/material/utils";
import { useNavigate } from "react-router-dom";
import { ZvjsTabs } from "../../../common";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { defer } from "react-router";
import { withLoader } from "../../../hoc/withLoader";
import {
  PotravinoveIntolerancieTab,
  VyzivovaciePovinnostiTab,
  TrvalyPobytTab,
  OsobneUdajeTab,
  PrechodnyPobytTab,
} from "./ZakladneUdajeKlientaTabs";

import { PromiseElement } from "../../../utils/types";
import { API_Clients } from "../../../store/context/dataApi/Data";
import {
  CisDruhAdresy_Prechodny_Pobyt_Kod,
  CisDruhAdresy_Trvaly_Pobyt_Kod,
} from "../../../common/specs/countersValues";

const loadData = async () => {
  const { EDPOO_Post, EOO_Get, EOO_Post, FEOO_Get, FEOO_Post, SHARE_INT_Post } =
    await API_Clients();

  const eoo_klient_DetailZakladneUdajeKlientaData = await EOO_Get(
    "/api/Klient/DetailZakladneUdajeKlientaData",
    {
      params: {
        query: {
          Id: Number.parseInt(localStorage.getItem("klientId") ?? "") ?? 1,
          UstavZvjsId:
            Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
        },
      },
    }
  );

  const eoo_fyzickaOsoba_DetailData = await EOO_Get(
    "/api/FyzickaOsoba/DetailData",
    {
      params: {
        query: {
          request:
            eoo_klient_DetailZakladneUdajeKlientaData.data?.data
              ?.fyzickaOsobaId,
        },
      },
    }
  );

  const feooKlient = await FEOO_Get("/api/Klient/Get", {
    params: {
      query: {
        id: localStorage.getItem("klientObjectId") ?? undefined,
      },
    },
  });

  const data = await Promise.all([
    FEOO_Get("/api/KlientUni/Get", {
      params: {
        query: {
          id: localStorage.getItem("klientObjectId") ?? undefined,
        },
      },
    }), // 0
    EOO_Post("/api/FyzickaOsoba/ListAdresaOsoba", {
      body: {
        filters: [eoo_fyzickaOsoba_DetailData.data?.data?.fyzickaOsobaId ?? 1],
      },
    }), //1
    EDPOO_Post("/api/Stravovanie/List", {
      body: {
        filters: [
          {
            zakladneCislo:
              eoo_klient_DetailZakladneUdajeKlientaData.data?.data
                ?.zakladneCislo,
          },
        ],
      },
    }), //2
    FEOO_Post("/api/VyzivovaciaPovinnost/List", {
      body: {
        filters: [
          {
            zakladnyFilter: {
              zakladneCislo:
                feooKlient.data?.data?.zakladneUdajeKlienta?.zakladneCislo,
            },
          },
        ],
      },
    }), //3
    EOO_Get("/api/Ciselnik/DruhAdresy"), // 4
    SHARE_INT_Post("/api/interfaces/Epvvvt/ListSocialnaDiagnostikaOdsudeneho", {
      body: {
        klientId: {
          eq: localStorage.getItem("klientObjectId"),
        },
      },
    }), // 5
  ]);

  return {
    klientUni: data[0],
    trvalePobyty: data[1].data?.records
      ?.filter((x) =>
        data[4].data
          ?.filter((x) => x.kod === CisDruhAdresy_Trvaly_Pobyt_Kod)
          .map((x) => x.id)
          .includes(x.druhAdresyId)
      )
      .at(0),
    prechodnePobyty: data[1].data?.records
      ?.filter((x) =>
        data[4].data
          ?.filter((x) => x.kod === CisDruhAdresy_Prechodny_Pobyt_Kod)
          .map((x) => x.id)
          .includes(x.druhAdresyId)
      )
      .at(0),
    stravovanie: data[2],
    vyzivovaciePovinnosti: data[3],
    eoo_klient_DetailZakladneUdajeKlientaData:
      eoo_klient_DetailZakladneUdajeKlientaData,
    eoo_fyzickaOsoba_DetailData: eoo_fyzickaOsoba_DetailData,
    feooKlient: feooKlient,
    socialnaDiagnostikaOdsudeneho: data[5],
    klientId: localStorage.getItem("klientId"),
  };
};

export const ZakladneUdajeKlientaLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface ZakladneUdajeKlientaProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const ZakladneUdajeKlienta = (props: ZakladneUdajeKlientaProps) => {
  const loaderData = props.data;
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(
                tui("osobneUdaje.kategorieOsobnychUdajov.zakladneUdajeKlienta")
              )}
            </Typography>
          </Stack>
        </Grid>
        <Grid item mt={4}>
          <ZvjsTabs
            onChange={() => {
              return;
            }}
            data={[
              {
                label: tui(
                  "osobneUdaje.kategorieOsobnychUdajov.osobneUdaje"
                ).toLowerCase(),
                element: <OsobneUdajeTab data={loaderData} />,
              },
              {
                label: tui("osobneUdaje.poskytovanaSluzba.trvalyPobyt"),
                element: <TrvalyPobytTab data={loaderData} />,
              },
              {
                label: tui("Prechodný pobyt"),
                element: <PrechodnyPobytTab data={loaderData} />,
              },
              {
                label: tui(
                  "osobneUdaje.potravinoveIntolerancie.potravinoveIntolerancie"
                ),
                element: <PotravinoveIntolerancieTab data={loaderData} />,
              },
              {
                label: tui("Vyživovacia povinnosť"),
                element: <VyzivovaciePovinnostiTab data={loaderData} />,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withLoader(ZakladneUdajeKlienta);
