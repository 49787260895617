import React, { useState } from "react";

import { redirect } from "react-router-dom";
import { isElectron } from "../../utils/helpers";
import { API_Clients } from "./dataApi/Data";

interface AuthContextProps {
  isLoggedIn: boolean;
  cisloKarty: number | undefined | null;
  klientId: number | undefined | null;
  klientObjectId: string | undefined | null;
  klientUstavId: number | undefined | null;
  setCisloKarty: (cisloKarty: number | undefined) => void;
  setKlientId: (klientId: number | undefined) => void;
  setKlientObjectId: (klientObjectId: string | undefined) => void;
  setKlientUstavId: (klientUstavId: number | undefined) => void;
  onLogout: () => void;
  onLogin: () => void;
}

const AuthContext = React.createContext<AuthContextProps>({
  isLoggedIn: false,
  cisloKarty: undefined,
  klientId: undefined,
  klientObjectId: undefined,
  klientUstavId: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCisloKarty: (cisloKarty: number | undefined) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setKlientId: (klientId: number | undefined) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setKlientObjectId: (klientObjectId: string | undefined) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setKlientUstavId: (klientUstavId: number | undefined) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onLogout: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onLogin: () => {},
});

interface AuthContextProviderProps {
  children: React.ReactNode;
}

export const AuthContextProvider: React.FC<AuthContextProviderProps> = (
  props
) => {
  const userCisloKarty = Number(localStorage.getItem("cisloKarty"));
  const userKlientId = Number(localStorage.getItem("klientId"));
  const userKlientObjectId = localStorage.getItem("klientObjectId");
  const userKlientUstavtId = Number(localStorage.getItem("klientUstavId"));

  const [isLoggedIn, setIsLoggedIn] = useState(
    userCisloKarty !== null &&
      userKlientId !== null &&
      userKlientObjectId !== null &&
      userKlientUstavtId !== null
  );

  const [cisloKarty, setCisloKarty] = useState<number | undefined | null>(
    userCisloKarty
  );

  const [klientId, setKlientId] = useState<number | undefined | null>(
    userKlientId
  );

  const [klientObjectId, setKlientObjectId] = useState<
    string | undefined | null
  >(userKlientObjectId);

  const [klientUstavId, setKlientUstavId] = useState<number | undefined | null>(
    userKlientUstavtId
  );

  const setCisloKartyInternal = (cisloKarty: number | undefined) => {
    console.debug(`setCisloKartyInternal cisloKarty[${cisloKarty}]`);
    setCisloKarty(cisloKarty);
  };

  const setKlientIdInternal = (klientId: number | undefined) => {
    console.debug(`setKlientIdInternal klientId[${klientId}]`);
    setKlientId(klientId);
  };

  const setKlientObjectIdInternal = (klientObjectId: string | undefined) => {
    console.debug(
      `setKlientObjectIdInternal klientObjectId[${klientObjectId}]`
    );
    setKlientObjectId(klientObjectId);
  };

  const setKlientUstavIdInternal = (klientUstavId: number | undefined) => {
    console.debug(`setKlientUstavIdInternal klientUstavId[${klientUstavId}]`);
    setKlientUstavId(klientUstavId);
  };

  const loginHandler = () => {
    if (cisloKarty) {
      API_Clients().then((clients) =>
        clients
          .SIDKOO_Post("/api/IdKarta/ListIdKarta")
          .then((loginData) => {
            localStorage.setItem("cisloKarty", cisloKarty.toString());

            const klientId = loginData?.data?.records?.find(
              (item) => item.cisloKarty === cisloKarty
            )?.klientId;

            localStorage.setItem("klientId", klientId?.toString() ?? "");

            const klientObjectId = loginData?.data?.records?.find(
              (item) => item.cisloKarty === cisloKarty
            )?.klientObjectId;

            localStorage.setItem("klientObjectId", klientObjectId ?? "");

            const klientUstavId = loginData?.data?.records?.find(
              (item) => item.cisloKarty === cisloKarty
            )?.ustavZvjsId;

            localStorage.setItem(
              "klientUstavId",
              klientUstavId?.toString() ?? ""
            );

            setKlientId(klientId);
            setKlientObjectId(klientObjectId);
            setKlientUstavId(klientUstavId);

            if (!klientId || !klientObjectId || !klientUstavId) {
              console.error(
                `Mandatory value NULL klientId[${klientId}] klientObjectId[${klientObjectId}] klientUstavId[${klientUstavId}]`
              );
              return;
            }

            setCisloKarty(cisloKarty);
            setIsLoggedIn(true);
          })
          .catch((error) => {
            console.error(`SIDKOO API ListIdKarta failed error[${error}]`);
          })
      );
    }
  };

  const logoutHandler = () => {
    localStorage.removeItem("cisloKarty");
    localStorage.removeItem("klientId");
    localStorage.removeItem("klientObjectId");
    localStorage.removeItem("klientUstavId");

    setIsLoggedIn(false);

    setCisloKarty(undefined);
    setKlientId(undefined);
    setKlientObjectId(undefined);
    setKlientUstavId(undefined);

    // Do not close all other windows when a user is logged out.
    // When a client closes a safe internet window, he will need to log into the kiosk app again.
    // if (isElectron()) {
    //   const { ipcRenderer } = window.require("electron");
    //   ipcRenderer.send("logout");
    // }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        cisloKarty,
        setCisloKarty: setCisloKartyInternal,
        klientId,
        setKlientId: setKlientIdInternal,
        klientObjectId,
        setKlientObjectId: setKlientObjectIdInternal,
        klientUstavId,
        setKlientUstavId: setKlientUstavIdInternal,
        onLogout: logoutHandler,
        onLogin: loginHandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

const isLoggedIn = () => {
  const userCisloKarty = localStorage.getItem("cisloKarty");
  const userKlientId = localStorage.getItem("klientId");
  const userKlientObjectId = localStorage.getItem("klientObjectId");
  const userKlientUstavtId = localStorage.getItem("klientUstavId");

  return (
    userCisloKarty !== null &&
    userKlientId !== null &&
    userKlientObjectId !== null &&
    userKlientUstavtId !== null
  );
};

export function CheckIsLoggedInGuardLoader() {
  if (!isLoggedIn()) {
    return redirect("/");
  }

  return null;
}

export function CheckIsLoggedOutGuardLoader() {
  if (isLoggedIn()) {
    return redirect("/auth");
  }
  return null;
}
