import React from "react";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";
import { ZvjsPDFViewer } from "../common";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { FileApiCallHeaders, targetUiApi } from "../utils/targetApi";
import { ReactComponent as KeyboardArrowLeftIcon } from "../assets/icons/Other/keyboard_arrow_left.svg";
import { useUITranslation } from "../store/context/translation-context";
import { Module } from "../utils/module";

const HelpPage = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { tui } = useUITranslation();
  const params = useParams();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };
  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={"26px"}>
            {capitalize(tui("osobná pomoc"))}
          </Typography>
        </Stack>
      </Grid>
      <ZvjsPDFViewer
        file={{
          url: targetUiApi(
            `/${Module.INTERNAL}/api/v1/File/intranet/Kiosk/Manual/${i18n.language}.pdf`
          ),
          httpHeaders: FileApiCallHeaders,
        }}
        openPage={+(params?.pageID ?? 0)}
      />
    </Grid>
  );
};
export default HelpPage;
