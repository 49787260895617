import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsCustomQuestionTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050101",
  basicInfo: [
    {
      text: "ziadost.ziadostOnavstevu.zakladneInfoObvineny",
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "IS_USER_ACCUSED",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      text: "ziadost.ziadostOnavstevu.zakladneInfoOdsudeny",
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "IS_USER_SENTENCED",
          conditionMetWhenValueIs: true,
        },
      ],
    },
  ],
  dataCalculations: {
    IS_USER_ACCUSED: {
      calculateAt: Calculate.AT_INIT,
    },
    IS_USER_SENTENCED: {
      calculateAt: Calculate.AT_INIT,
    },
    VISIT_REQUESTS_LIST: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050101_DISPLAY_2_HOURS_VISIT_OPTION: {
      calculateAt: Calculate.LIVE,
    },
  },
  items: [
    {
      id: "SC_KIO_050101_QUES_01",
      type: ZvjsCustomQuestionTypes.VISIT_DATE_PICKER,
      title: "ziadost.ziadostOnavstevu.labelMesiac",
    },
    {
      id: "SC_KIO_050101_QUES_02",
      title: "ziadost.ziadostOnavstevu.FormaNavstevy",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      options: [
        {
          id: "E_KIO_11_01",
          label: "Fyzická návšteva",
        },
        {
          id: "E_KIO_11_02",
          label: "Videonávšteva",
        },
        {
          id: "E_KIO_11_03",
          label: "Videonávšteva s maloletými deťmi",
        },
      ],
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050101_QUES_01",
          hasValue: true,
        },
      ],
    },
    {
      id: "SC_KIO_050101_QUES_03",
      title: "Trvanie návštevy",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      options: [
        {
          id: "E_KIO_12_01",
          label: "2 hodiny",
          conditions: [
            {
              type: ZvjsConditionTypes.FUNCTION,
              conFunc: "SC_KIO_050101_DISPLAY_2_HOURS_VISIT_OPTION",
              conditionMetWhenValueIs: true,
            },
          ],
        },
        {
          id: "E_KIO_12_02",
          label: "1 hodina",
        },
      ],
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050101_QUES_02",
          hasValue: true,
        },
      ],
    },
    {
      id: "SC_KIO_050101_QUES_04",
      title: "Spôsob odoslania pozvánky na návštevu",
      type: ZvjsQuestionTypes.DROPDOWN,
      isRequired: true,
      options: [
        {
          id: "E_KIO_13_01",
          label: "Poštou",
        },
        {
          id: "E_KIO_13_02",
          label: "Mailom",
        },
      ],
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050101_QUES_03",
          hasValue: true,
        },
      ],
    },
    {
      id: "SC_KIO_050101_QUES_05",
      title: "ziadost.ziadostOnavstevu.labelMail",
      type: ZvjsQuestionTypes.TEXT,
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.PREVIOUS_ANSWERS,
          questionId: "SC_KIO_050101_QUES_04",
          value: ["E_KIO_13_02"],
        },
      ],
    },
  ],
};
