import React from "react";
import { capitalize } from "@mui/material/utils";
import { useUITranslation } from "../../../store/context/translation-context";
import {
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { ZvjsTable } from "../../../common";
import { withLoader } from "../../../hoc/withLoader";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { Params, useNavigate } from "react-router-dom";
import { defer } from "react-router";
import { PromiseElement } from "../../../utils/types";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { LoaderError } from "../../../router/LoaderError";

const LoadData = async () => {
  const { HASURA_Post } = await API_Clients();

  const response = await HASURA_Post("/api/rest/bezpecnyinternet", {
    body: { where: { stav: { _eq: "PLATNY" } }, order_by: [{ url: "asc" }] },
  });

  if (response.error || !response.response.ok) {
    throw new LoaderError();
  }

  return response.data;
};

export const BezpecnyInternetKioskLoader = async ({
  request,
  params,
}: {
  request: Request;
  params: Params;
}) => {
  return defer({
    data: LoadData(),
  });
};

interface BezpecnyInternetKioskProps {
  data: PromiseElement<ReturnType<typeof LoadData>>;
}

const BezpecnyInternetKiosk = (props: BezpecnyInternetKioskProps) => {
  const { mpBezpecnyInternet } = props.data;
  const navigate = useNavigate();
  const { tui } = useUITranslation();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  const dataStyleBezpecnyInternet: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "right",
    },
  ];

  const tableBezpecnyInternet: TableData = {
    header: [
      {
        value: capitalize(
          tui(
            "Názov stránky" // TODO: use proper KEY
          )
        ),
        ...dataStyleBezpecnyInternet[0],
      },
      {
        value: capitalize(
          tui(
            "Link" // TODO: use proper KEY
          )
        ),
        ...dataStyleBezpecnyInternet[1],
      },
    ],
    body: new Array<RowData>(),
  };

  if (mpBezpecnyInternet) {
    for (const bezpecnyInternetPage of mpBezpecnyInternet) {
      if (bezpecnyInternetPage.url) {
        const url = bezpecnyInternetPage.url.startsWith("www")
          ? "https://" + bezpecnyInternetPage.url
          : bezpecnyInternetPage.url;

        tableBezpecnyInternet.body.push({
          row: [
            {
              value: url,
              ...dataStyleBezpecnyInternet[0],
            },
            {
              value: (
                <a
                  // component="button"
                  target={"_blank"}
                  href={url}
                  // onClick={() => {
                  //   const searchParams = new URLSearchParams(location.search);
                  //   searchParams.set("page", Links[i]);
                  //   navigate(
                  //     "/auth/SafeInternet/openedPage?" + searchParams.toString(),
                  //     {
                  //       // replace current path (so back button redirects you directly to previous page and not to the previous tab)
                  //       replace: true,
                  //     }
                  //   );
                  // }}
                >
                  {"Link"} {/*TODO: use proper KEY */}
                </a>
              ),
              ...dataStyleBezpecnyInternet[1],
            },
          ],
        });
      }
    }
  }

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} width={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(tui("dashboard.bezpecnyInternet"))}
            </Typography>
          </Stack>
        </Grid>
        <Grid item mt={4}>
          <ZvjsTable
            data={tableBezpecnyInternet}
            variant={ZvjsTableVariant.NORMAL}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withLoader(BezpecnyInternetKiosk);
