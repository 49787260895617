import { UserCalcData } from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectUserCalcData } from "../../../../../../redux/selectors";

const PERMANENT_RESIDENCE_IN_SLOVAKIA = "PERMANENT_RESIDENCE_IN_SLOVAKIA";
const TEMPORARY_RESIDENCE_IN_SLOVAKIA = "TEMPORARY_RESIDENCE_IN_SLOVAKIA";
const RESIDENCE_IN_SLOVAKIA = "RESIDENCE_IN_SLOVAKIA";
const RESIDENCE_NOT_IN_SLOVAKIA = "RESIDENCE_NOT_IN_SLOVAKIA";
export const TravelExpensesRadioQuestionConstants = {
  PERMANENT_RESIDENCE_IN_SLOVAKIA,
  TEMPORARY_RESIDENCE_IN_SLOVAKIA,
  RESIDENCE_IN_SLOVAKIA,
  RESIDENCE_NOT_IN_SLOVAKIA,
};

/**
 * OnInit
 */

export const SC_KIO_050802_TravelExpensesRadioQuestion_onInit = async (
  calcData: UserCalcData
): Promise<void> => {
  // TODO replace with actual logic
  calcData[PERMANENT_RESIDENCE_IN_SLOVAKIA] = true;
  calcData[TEMPORARY_RESIDENCE_IN_SLOVAKIA] = true;
  calcData[RESIDENCE_IN_SLOVAKIA] =
    calcData[PERMANENT_RESIDENCE_IN_SLOVAKIA] ||
    calcData[TEMPORARY_RESIDENCE_IN_SLOVAKIA];
  calcData[RESIDENCE_NOT_IN_SLOVAKIA] = true;
};

/**
 * Selectors
 */

const getTravelExpensesRadioQuestionConditions = () =>
  createSelector(
    selectUserCalcData,
    (state: UserCalcData): { [key: string]: boolean } => {
      return {
        PERMANENT_RESIDENCE_IN_SLOVAKIA: state[PERMANENT_RESIDENCE_IN_SLOVAKIA],
        TEMPORARY_RESIDENCE_IN_SLOVAKIA: state[TEMPORARY_RESIDENCE_IN_SLOVAKIA],
        RESIDENCE_IN_SLOVAKIA: state[RESIDENCE_IN_SLOVAKIA],
        RESIDENCE_NOT_IN_SLOVAKIA: state[RESIDENCE_NOT_IN_SLOVAKIA],
      };
    }
  );

export const allSelectors = {
  getTravelExpensesRadioQuestionConditions,
};
