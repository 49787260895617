import { ZvjsQuestionnaireTemplate, ZvjsQuestionTypes } from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050202",
  basicInfo: [
    {
      text: "ziadost.ziadostOvydanieCeniny.zakladneInfo",
    },
  ],
  counters: ["/api/CisSposobOdovzdaniaCeniny/List"],
  items: [
    {
      id: "SC_KIO_050202_QUES_01",
      title: "ziadost.ziadostOvydanieCeniny.labelSposobOdovzdania",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      options: {
        counterKey: "/api/CisSposobOdovzdaniaCeniny/List",
        textKey: "sposob_odovzdania",
        optValues: [{ id: "NAVST" }, { id: "ADVOK" }],
      },
    },
  ],
};
