import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";
import { useNavigate } from "react-router-dom";
import { ZvjsSummaryTable } from "../../../common";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ZvjsSummaryTableComponent } from "../../../common/components/ZvjsSummaryTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router";
import { withLoader } from "../../../hoc/withLoader";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { PromiseElement } from "../../../utils/types";

const loadData = async (id: string) => {
  const { EOO_Post } = await API_Clients();

  const data = await Promise.all([
    EOO_Post("/api/Rozhodnutie/ListRozhodnutieData", {
      body: {
        filters: [
          {
            klientId:
              Number.parseInt(localStorage.getItem("klientId") ?? "") ?? 1,
          },
        ],
      },
    }),
  ]);

  return {
    rozhodnutia: data[0],
    rozhodnutieId: id,
  };
};

export const RozhodnutieDetailLoader = async (id: string) => {
  return defer({
    data: loadData(id),
  });
};

export interface RozhodnutieDetailProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const RozhodnutieDetail = (props: RozhodnutieDetailProps) => {
  const loaderData = props.data;
  const { t } = useTranslation();
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(
                t(
                  "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.Verdicts.verdictDetail"
                )
              )}
            </Typography>
          </Stack>
        </Grid>

        <Stack direction="column" mt={3} spacing={2}>
          <ZvjsSummaryTable>
            <ZvjsSummaryTableComponent
              label={t(
                "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.Verdicts.title"
              )}
              value={
                loaderData.rozhodnutia.data?.records
                  ?.filter(
                    (rozhodnutie) =>
                      rozhodnutie.id === Number(loaderData.rozhodnutieId)
                  )
                  .at(0)?.typRozhodnutiaSuduAleboOctkNazov ?? ""
              }
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={t(
                "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.Verdicts.fileReference"
              )}
              value={
                loaderData.rozhodnutia.data?.records
                  ?.filter(
                    (rozhodnutie) =>
                      rozhodnutie.id === Number(loaderData.rozhodnutieId)
                  )
                  .at(0)?.spisovaZnacka ?? ""
              }
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={t(
                "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.Verdicts.actual"
              )}
              value={
                !loaderData.rozhodnutia.data?.records
                  ?.filter(
                    (rozhodnutie) =>
                      rozhodnutie.id === Number(loaderData.rozhodnutieId)
                  )
                  .at(0)?.zrusene
                  ? tui("áno")
                  : tui("nie") ?? ""
              }
              //value={(!rozhodnutie?.zrusene ? tui("áno") : tui("nie")) ?? ""}
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={t("<ics>")}
              value={
                loaderData.rozhodnutia.data?.records
                  ?.filter(
                    (rozhodnutie) =>
                      rozhodnutie.id === Number(loaderData.rozhodnutieId)
                  )
                  .at(0)?.ics ?? ""
              }
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={t("<poznamka>")}
              value={
                loaderData.rozhodnutia.data?.records
                  ?.filter(
                    (rozhodnutie) =>
                      rozhodnutie.id === Number(loaderData.rozhodnutieId)
                  )
                  .at(0)?.poznamka ?? ""
              }
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={t("<odosielatel>")}
              value={
                loaderData.rozhodnutia.data?.records
                  ?.filter(
                    (rozhodnutie) =>
                      rozhodnutie.id === Number(loaderData.rozhodnutieId)
                  )
                  .at(0)?.odosielatel ?? ""
              }
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={t("<Suvisiaca Vazba>")}
              value={
                loaderData.rozhodnutia.data?.records
                  ?.filter(
                    (rozhodnutie) =>
                      rozhodnutie.id === Number(loaderData.rozhodnutieId)
                  )
                  .at(0)?.suvisiacaVazbaNazov ?? ""
              }
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={t("<Suvisiaci Trest>")}
              value={
                loaderData.rozhodnutia.data?.records
                  ?.filter(
                    (rozhodnutie) =>
                      rozhodnutie.id === Number(loaderData.rozhodnutieId)
                  )
                  .at(0)?.suvisiaciTrestNazov ?? ""
              }
              isBorder={true}
              fullWidth={true}
            />
          </ZvjsSummaryTable>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default withLoader(RozhodnutieDetail);
