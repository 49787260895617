import { ZVJS_COLORS } from "../../../theme/zvjs_theme";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { Stack } from "@mui/system";
import { Typography, useTheme } from "@mui/material";
import { useState } from "react";
import ChatBotWindow from "./ChatBotWindow";

const ZvjsChatBot = () => {
  const theme = useTheme();
  const [openChat, setOpenchat] = useState<boolean>(false);

  const handleClick = () => {
    setOpenchat(!openChat);
    console.log("click");
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "8rem",
        right: "2rem",
        width: "7rem",
        height: "7rem",
        border: "0px",
        backgroundColor: theme.palette.primary.main,
        zIndex: 9999,
        borderRadius: "50%",
      }}
    >
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        marginTop={2}
        onClick={handleClick}
      >
        <SmartToyIcon style={{ fontSize: "3rem", color: ZVJS_COLORS.WHITE }} />
        {/*todo add correct translation*/}
        <Typography variant={"body1"} style={{ color: ZVJS_COLORS.WHITE }}>
          Pomoc
        </Typography>
      </Stack>
      {openChat && <ChatBotWindow setOpenchat={setOpenchat} />}
    </div>
  );
};

export default ZvjsChatBot;
