import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsCustomQuestionTypes,
  ZvjsItemTypes,
  ZvjsLabelFlagTypes,
  ZvjsQuestionnaireTemplate,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_051003",
  basicInfo: [
    {
      text: "ziadost.ziadostOposkytnutiePotriebOsHygieny.zakladneInfo",
    },
  ],
  dataCalculations: {
    AVAILABLE_ACCOUNT_AMOUNT: {
      calculateAt: Calculate.AT_INIT,
    },
    SHOPPED_LAST_CALENDAR_MONTH: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_051003_CLIENT_PERSONAL_HYGIENE_NEEDS_LIST: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_051003_IS_ALLOWED_TO_REQUEST_PERSONAL_HYGIENE_ITEM: {
      calculateAt: Calculate.LIVE,
    },
  },
  items: [
    {
      type: ZvjsItemTypes.LABEL,
      flag: ZvjsLabelFlagTypes.HINT,
      text: "ziadost.ziadostOposkytnutiePotriebOsHygieny.napovedaUhradaPenaznychProstriedkov",
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051003_IS_ALLOWED_TO_REQUEST_PERSONAL_HYGIENE_ITEM",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      id: "SC_KIO_051003_NOT_ALLOWED_TO_SEND_REQUEST_LABELS",
      type: ZvjsCustomQuestionTypes.SC_KIO_051003_NOT_ALLOWED_TO_SEND_REQUEST_LABELS,
      title: "",
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051003_IS_ALLOWED_TO_REQUEST_PERSONAL_HYGIENE_ITEM",
          conditionMetWhenValueIs: false,
        },
      ],
    },
    {
      id: "potrebyOsobnejHygieny",
      type: ZvjsCustomQuestionTypes.SC_KIO_051003_PERSONAL_HYGIENE_NEEDS_GRID,
      title:
        "ziadost.ziadostOposkytnutiePotriebOsHygieny.labelPotrebyOsobnejHygieny",
      isRequired: true,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051003_IS_ALLOWED_TO_REQUEST_PERSONAL_HYGIENE_ITEM",
          conditionMetWhenValueIs: true,
        },
      ],
    },
  ],
};
