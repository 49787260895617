import React from "react";
import { Box, Stack } from "@mui/system";
import { ZVJS_COLORS } from "../../../theme/zvjs_theme";
import { Typography } from "@mui/material";
import SmartToyIcon from "@mui/icons-material/SmartToy";

interface ChatBotMessageBotProps {
  message: string;
}

const ChatBotMessageUser: React.FC<ChatBotMessageBotProps> = ({ message }) => {
  return (
    <Stack
      style={{
        width: "100%",
        display: "flex",
      }}
      alignItems={"center"}
      justifyContent={"flex-start"}
      spacing={1}
      direction={"row"}
    >
      <SmartToyIcon
        style={{ fill: ZVJS_COLORS.BLUE, height: "2rem", width: "2rem" }}
      />
      <Box
        sx={{
          backgroundColor: ZVJS_COLORS.WHITE,
          borderRadius: "3px",
          width: "100%",
        }}
      >
        <Typography
          variant={"body1"}
          style={{ color: ZVJS_COLORS.BLACK, margin: "0.4rem", width: "100%" }}
        >
          {message}
        </Typography>
      </Box>
    </Stack>
  );
};

export default ChatBotMessageUser;
