import { UserCalcData, ZvjsAnswers } from "../../../../redux/model";
import { SC_KIO_05050_does_user_already_use_device } from "../validations/custom";
import { CisDruhElektrospotrebica_Iny_Elektricky_Spotrebic_Kod } from "../../../../../specs/countersValues";

// group one includes women with kids, youngsters etc.
const SC_KIO_051102_is_user_sentenced_and_group_one = () => {
  // TODO replace with actual logic
  return true;
};

const SC_KIO_051102_is_user_sentenced_and_group_other = () => {
  // TODO replace with actual logic
  return true;
};

const SC_KIO_050501_display_user_already_use_device_warning = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  if (
    answers["druhElektrickehoSpotrebica"] !==
      CisDruhElektrospotrebica_Iny_Elektricky_Spotrebic_Kod &&
    SC_KIO_05050_does_user_already_use_device(
      answers["druhElektrickehoSpotrebica"] as string,
      userCalcData["ALL_USER_ELECTRIC_DEVICES_LIST"]
    )
  ) {
    return true;
  }

  return false;
};

const SC_KIO_050101_display_2_hours_visit_option = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  // TODO replace with actual logic
  // check what type of visit user has selected and check if 2 hour for personal visit is still available for user or he has only 1 hour left
  return false;
};

const SC_KIO_051201_should_display_ramadan_question = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  // TODO replace with real values (instead of strings)
  if (
    userCalcData["CURRENT_DIET_TYPE"] === "Strava s vylúčením mäsa" ||
    userCalcData["CURRENT_DIET_TYPE"] === "Strava s vylúčením bravčového mäsa"
  ) {
    return true;
  }

  return false;
};

const SC_KIO_050802_food_already_requested = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  // TODO replace with actual logic
  return false;
};

const SC_KIO_050802_food_not_yet_requested = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return !userCalcData["SC_KIO_050802_FOOD_ALREADY_REQUESTED"];
};

const SC_KIO_050802_clothing_already_requested = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  // TODO replace with actual logic
  return false;
};

const SC_KIO_050802_clothing_not_yet_requested = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return !userCalcData["SC_KIO_050802_CLOTHING_ALREADY_REQUESTED"];
};

const SC_KIO_050802_travel_expenses_already_requested = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  // TODO replace with actual logic
  return false;
};

const SC_KIO_050802_travel_expenses_not_yet_requested = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return !userCalcData["SC_KIO_050802_TRAVEL_EXPENSES_ALREADY_REQUESTED"];
};

const SC_KIO_050603_is_user_illiterate_or_without_primary_schooling = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  // TODO replace with actual logic
  return true;
};

const SC_KIO_050603_is_user_not_illiterate_or_without_primary_schooling = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return !userCalcData[
    "SC_KIO_050603_IS_USER_ILLITERATE_OR_WITHOUT_PRIMARY_SCHOOLING"
  ];
};

const SC_KIO_050301_can_request_private_correspondence = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  // TODO replace with actual logic
  return true;
};

const SC_KIO_051403_should_show_priority_question = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  // if exactly 2 institutions are selected in the first question display priority question
  if (
    Array.isArray(answers["miestoPozadovanehoPremiestnenia"]) &&
    answers["miestoPozadovanehoPremiestnenia"].length === 2
  ) {
    return true;
  }
  return false;
};

const SC_KIO_051403_is_client_in_nt_group = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  // TODO replace with actual logic
  return false;
};

const SC_KIO_051403_is_client_not_in_nt_group = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return !userCalcData["SC_KIO_051403_IS_CLIENT_IN_NT_GROUP"];
};

const SC_KIO_051403_client_has_refused_request_in_past_6_months = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  // TODO replace with actual logic
  return false;
};

const SC_KIO_051403_client_has_not_refused_request_in_past_6_months = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return !userCalcData[
    "SC_KIO_051403_CLIENT_HAS_REFUSED_REQUEST_IN_PAST_6_MONTHS"
  ];
};

const SC_KIO_051403_is_client_not_allowed_to_send_request = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  return (
    userCalcData["SC_KIO_051403_IS_CLIENT_IN_NT_GROUP"] === true ||
    userCalcData[
      "SC_KIO_051403_CLIENT_HAS_REFUSED_REQUEST_IN_PAST_6_MONTHS"
    ] === true
  );
};

const SC_KIO_051003_client_available_balance = () => {
  // TODO replace with actual logic
  return 30;
};

const SC_KIO_051003_client_personal_hygiene_needs_list =
  (): PersonalHygieneItem[] => {
    // TODO replace with actual logic
    // check if person is fe/male and filter out not related items from counter
    // merge counter with last issued date

    return [
      {
        typeCode: "1",
        // TODO replace with value from counter (pass only typeId here and determine translation from counter)
        typeName: "Hrebeň na vlasy",
        consumptionStandardInMonths: 1,
        estimatedPrice: 10,
        dateOfLastIssue: "2023-10-25T00:00:00.000Z",
      },
      {
        typeCode: "3",
        // TODO replace with value from counter (pass only typeId here and determine translation from counter)
        typeName: "Zubná kefka",
        consumptionStandardInMonths: 1,
        estimatedPrice: 15,
        dateOfLastIssue: "2023-09-25T00:00:00.000Z",
      },
      {
        typeCode: "5",
        // TODO replace with value from counter (pass only typeId here and determine translation from counter)
        typeName: "Zubná pasta",
        consumptionStandardInMonths: 2,
        estimatedPrice: 1500,
        dateOfLastIssue: "2023-09-25T00:00:00.000Z",
      },
      // {
      //   typeCode: "8",
      //   // TODO replace with value from counter (pass only typeId here and determine translation from counter)
      //   typeName: "Šampón na vlasy",
      //   consumptionStandardInMonths: 3,
      //   estimatedPrice: 30,
      // },
    ];
  };

const SC_KIO_051003_is_allowed_to_request_personal_hygiene_item = ({
  userCalcData,
  answers,
}: {
  userCalcData: UserCalcData;
  answers: ZvjsAnswers;
}) => {
  if (userCalcData["SHOPPED_LAST_CALENDAR_MONTH"] === true) {
    return false;
  }

  let estimatedPriceTotalSum = 0;
  for (const item of userCalcData[
    "SC_KIO_051003_CLIENT_PERSONAL_HYGIENE_NEEDS_LIST"
  ]) {
    estimatedPriceTotalSum += item.estimatedPrice;
  }

  if (userCalcData["AVAILABLE_ACCOUNT_AMOUNT"] >= estimatedPriceTotalSum) {
    return false;
  }

  return true;
};

const SC_KIO_051102_civilian_clothes_list = (): CivilianClothes[] => {
  // TODO replace with actual logic
  // either return plain array or there will be 2 grid return object which will hold two arrays (one for each grid)
  // keep in mind all needed checks (gender...)

  return [
    {
      civilianClothesType: "2",
      civilianClothesName: "košele",
      countOfCurrentlyRegisteredItems: 1,
      maxCountOfRegisteredItems: 3,
      estimatedWashingCostPerItem: 10,
      clientMethodOfEnsuringHygienicSafety: "RUCNE",
    },
    {
      civilianClothesType: "3",
      civilianClothesName: "tričká bez kapucne alebo maskáčového vzoru",
      countOfCurrentlyRegisteredItems: 1,
      maxCountOfRegisteredItems: 3,
      estimatedWashingCostPerItem: 10,
      clientMethodOfEnsuringHygienicSafety: "PRACOV",
    },
    {
      civilianClothesType: "4",
      civilianClothesName: "ponožky",
      countOfCurrentlyRegisteredItems: 0,
      maxCountOfRegisteredItems: 7,
      estimatedWashingCostPerItem: 2,
      clientMethodOfEnsuringHygienicSafety: "OBMENA",
    },
  ];
};

const reusable: CalculationType = {
  SC_KIO_051102_IS_USER_FROM_SENTENCED_AND_GROUP_ONE:
    SC_KIO_051102_is_user_sentenced_and_group_one,
  SC_KIO_051102_IS_USER_FROM_SENTENCED_AND_GROUP_OTHER:
    SC_KIO_051102_is_user_sentenced_and_group_other,
  SC_KIO_050501_DISPLAY_USER_ALREADY_USE_DEVICE_WARNING:
    SC_KIO_050501_display_user_already_use_device_warning,
  SC_KIO_050101_DISPLAY_2_HOURS_VISIT_OPTION:
    SC_KIO_050101_display_2_hours_visit_option,
  SC_KIO_051201_SHOULD_DISPLAY_RAMADAN_QUESTION:
    SC_KIO_051201_should_display_ramadan_question,
  SC_KIO_050802_FOOD_ALREADY_REQUESTED: SC_KIO_050802_food_already_requested,
  SC_KIO_050802_FOOD_NOT_YET_REQUESTED: SC_KIO_050802_food_not_yet_requested,
  SC_KIO_050802_CLOTHING_ALREADY_REQUESTED:
    SC_KIO_050802_clothing_already_requested,
  SC_KIO_050802_CLOTHING_NOT_YET_REQUESTED:
    SC_KIO_050802_clothing_not_yet_requested,
  SC_KIO_050802_TRAVEL_EXPENSES_ALREADY_REQUESTED:
    SC_KIO_050802_travel_expenses_already_requested,
  SC_KIO_050802_TRAVEL_EXPENSES_NOT_YET_REQUESTED:
    SC_KIO_050802_travel_expenses_not_yet_requested,
  SC_KIO_050603_IS_USER_ILLITERATE_OR_WITHOUT_PRIMARY_SCHOOLING:
    SC_KIO_050603_is_user_illiterate_or_without_primary_schooling,
  // SC_KIO_050603_IS_USER_NOT_ILLITERATE_OR_WITHOUT_PRIMARY_SCHOOLING:
  //   SC_KIO_050603_is_user_not_illiterate_or_without_primary_schooling,
  SC_KIO_050301_CAN_REQUEST_PRIVATE_CORRESPONDENCE:
    SC_KIO_050301_can_request_private_correspondence,
  SC_KIO_051403_SHOULD_SHOW_PRIORITY_QUESTION:
    SC_KIO_051403_should_show_priority_question,
  SC_KIO_051403_IS_CLIENT_IN_NT_GROUP: SC_KIO_051403_is_client_in_nt_group,
  SC_KIO_051403_IS_CLIENT_NOT_IN_NT_GROUP:
    SC_KIO_051403_is_client_not_in_nt_group,
  SC_KIO_051403_CLIENT_HAS_REFUSED_REQUEST_IN_PAST_6_MONTHS:
    SC_KIO_051403_client_has_refused_request_in_past_6_months,
  SC_KIO_051403_CLIENT_HAS_NOT_REFUSED_REQUEST_IN_PAST_6_MONTH:
    SC_KIO_051403_client_has_not_refused_request_in_past_6_months,
  SC_KIO_051403_IS_CLIENT_NOT_ALLOWED_TO_SEND_REQUEST:
    SC_KIO_051403_is_client_not_allowed_to_send_request,
  SC_KIO_051003_CLIENT_AVAILABLE_BALANCE:
    SC_KIO_051003_client_available_balance,
  SC_KIO_051003_CLIENT_PERSONAL_HYGIENE_NEEDS_LIST:
    SC_KIO_051003_client_personal_hygiene_needs_list,
  SC_KIO_051003_IS_ALLOWED_TO_REQUEST_PERSONAL_HYGIENE_ITEM:
    SC_KIO_051003_is_allowed_to_request_personal_hygiene_item,
  SC_KIO_051102_CIVILIAN_CLOTHES_LIST: SC_KIO_051102_civilian_clothes_list,
};

type CalculationType = {
  [key: string]: ({
    userCalcData,
    answers,
  }: {
    userCalcData: UserCalcData;
    answers: ZvjsAnswers;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => number | boolean | string | any;
};

export default reusable;

/**
 * Return models
 */

export interface CivilianClothes {
  civilianClothesType: string;
  civilianClothesName: string;
  countOfCurrentlyRegisteredItems?: number;
  maxCountOfRegisteredItems: number;
  estimatedWashingCostPerItem: number;
  clientMethodOfEnsuringHygienicSafety?: string;
}

export interface PersonalHygieneItem {
  typeCode: string;
  // TODO replace with value from counter (pass only typeId here and determine translation from counter)
  typeName: string;
  consumptionStandardInMonths: number;
  estimatedPrice: number;
  dateOfLastIssue?: string;
}
