import React, { useEffect, useState } from "react";

import { StringMap } from "../../store/context/envConfigContext";

interface ZvjsAudioPlayerProps {
  file: { url: string; contentType: string; httpHeaders?: StringMap };
}

const ZvjsAudioPlayer: React.FC<ZvjsAudioPlayerProps> = ({ file }) => {
  const [audioObjectUrl, setAudioObjectUrl] = useState<string | undefined>();

  useEffect(() => {
    fetch(file.url, {
      headers: file.httpHeaders,
      method: "GET",
    }).then((response) => {
      response
        .blob()
        .then((blob) => setAudioObjectUrl(window.URL.createObjectURL(blob)));
    });
  }, [file]);

  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <audio controls src={audioObjectUrl} />
    </div>
  );
};

export default ZvjsAudioPlayer;
