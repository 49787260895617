import React from "react";
import { isKiosk } from "../store/context/envConfigContext";
import { useOnlineStatus } from "../store/context/onlineStatus-context";
import { SynchronizationStatusPage } from "../pages";

interface AppConnectivityGuardProps {
  children: React.ReactElement;
}

const AppConnectivityGuard: React.FC<AppConnectivityGuardProps> = ({
  children,
}) => {
  const { isOnline } = useOnlineStatus();

  if (isKiosk()) {
    // if you are running app in kiosk mode always display kiosk app
    return children;
  } else {
    if (isOnline) {
      // if tablet is online display synchronization status page
      return <SynchronizationStatusPage />;
    } else {
      // if tablet is offline display kiosk app
      return children;
    }
  }
};

export default AppConnectivityGuard;
