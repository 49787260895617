import React from "react";
import { Grid } from "@mui/material";
import { shallowEqual, useSelector } from "react-redux";
import questionnaireRedux from "../../../../redux";
import {
  getAlertIconOfZvjsFlag,
  getSeverityOfZvjsFlag,
} from "../../../../redux/model";
import { ZvjsAlert } from "../../../../../components";
import { useUITranslation } from "../../../../../../store/context/translation-context";

interface LabelProps {
  location: number[];
}

const Label: React.FC<LabelProps> = ({ location }) => {
  const { tuiz } = useUITranslation();
  const labelData = useSelector(
    questionnaireRedux.selectors.getLabelData(location),
    shallowEqual
  );

  console.log(`LABEL RERENDER ${location.join("-")}`);

  if (labelData.flag !== undefined) {
    return (
      <Grid item xs>
        <ZvjsAlert
          severity={getSeverityOfZvjsFlag(labelData.flag)}
          iconMapping={getAlertIconOfZvjsFlag(labelData.flag)}
        >
          <div dangerouslySetInnerHTML={{ __html: tuiz(labelData.text) }} />
        </ZvjsAlert>
      </Grid>
    );
  } else {
    return (
      <Grid item xs>
        <div dangerouslySetInnerHTML={{ __html: tuiz(labelData.text) }} />
      </Grid>
    );
  }
};

export default Label;
