import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsCustomQuestionTypes,
  ZvjsItemTypes,
  ZvjsLabelFlagTypes,
  ZvjsQuestionnaireTemplate,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_051102",
  basicInfo: [
    {
      text: "ziadost.ziadostOpouzivanieVlastnehoOdevu.zakladneInfoObvineny",
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "IS_USER_ACCUSED",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      text: "ziadost.ziadostOpouzivanieVlastnehoOdevu.zakladneInfoOdsudeniVOmatkyMladistvi",
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051102_IS_USER_FROM_SENTENCED_AND_GROUP_ONE",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      text: "ziadost.ziadostOpouzivanieVlastnehoOdevu.zakladneInfoOdsudeny",
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "SC_KIO_051102_IS_USER_FROM_SENTENCED_AND_GROUP_OTHER",
          conditionMetWhenValueIs: true,
        },
      ],
    },
  ],
  dataCalculations: {
    IS_USER_ACCUSED: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_051102_IS_USER_FROM_SENTENCED_AND_GROUP_ONE: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_051102_IS_USER_FROM_SENTENCED_AND_GROUP_OTHER: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_051102_CIVILIAN_CLOTHES_LIST: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  items: [
    {
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOpouzivanieVlastnehoOdevu.napovedaMnozstvoKusov",
      flag: ZvjsLabelFlagTypes.HINT,
    },
    {
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOpouzivanieVlastnehoOdevu.napovedaNepouzivanieVeci",
      flag: ZvjsLabelFlagTypes.HINT,
    },
    {
      type: ZvjsItemTypes.LABEL,
      text: "ziadost.ziadostOpouzivanieVlastnehoOdevu.napovedaPranieVustavnejPracovni",
      flag: ZvjsLabelFlagTypes.HINT,
    },
    {
      id: "polozky",
      type: ZvjsCustomQuestionTypes.SC_KIO_051102_CIVILIAN_CLOTHES_GRID,
      title:
        "ziadost.ziadostOpouzivanieVlastnehoOdevu.labelDruhAmnozstvoOdevuObuvi",
      isRequired: true,
    },
  ],
};
