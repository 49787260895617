import { RequestInternalCode } from "./requestTemplates";
import { SummarySubmitData } from "../requestPages/summary/Summary";
import { NestedDropdownAnswerValueType, State } from "../redux/model";
import { getBaseRequestSubmitData } from "../redux/selectors/summary";
import { questionsIteratorWithLocFromDisplayedSections } from "../redux/slice";
import { isItemDisplayed } from "../redux/selectors";
import { isEmptyArray, isEmptyString } from "../../../utils/helpers";
import { CisOblastZdravotnychProblemov_Bolest_Kod } from "../../specs/countersValues";

const SC_KIO_050401_customSubmit = (state: State): SummarySubmitData[] => {
  const iterator = questionsIteratorWithLocFromDisplayedSections(
    state.questionnaire,
    state
  );

  const toSubmit: { [key: string]: unknown } = {};

  for (const question of iterator) {
    if (isItemDisplayed(question.location, state)) {
      if (question.value.id === "oblastZdravotnychProblemov") {
        const answer = state.answers[question.value.id] as
          | NestedDropdownAnswerValueType[]
          | undefined;

        if (answer) {
          const modifiedAnswerForm = [];
          const bolestiCodes: string[] = [];

          for (const answerVal of answer) {
            if (answerVal["bolest"]) {
              // if a selected option is from the "bolest" counter then store it in a single object
              bolestiCodes.push(answerVal["bolest"]);
            } else {
              modifiedAnswerForm.push({ ...answerVal, bolest: [] });
            }
          }

          if (!isEmptyArray(bolestiCodes)) {
            modifiedAnswerForm.push({
              oblastZdravotnychProblemovCis:
                CisOblastZdravotnychProblemov_Bolest_Kod,
              bolest: bolestiCodes,
            });
          }

          toSubmit[question.value.id] = modifiedAnswerForm;
        }
      } else {
        if (!isEmptyString(state.answers[question.value.id])) {
          // use for submit the same answer form as is used inside the request's generator
          toSubmit[question.value.id] = state.answers[question.value.id];
        }
      }
    }
  }

  toSubmit["ockovanie"] = state.answers["ockovanie"] === true;

  return [
    {
      ...getBaseRequestSubmitData(state),
      ...toSubmit,
    },
  ];
};

const SC_KIO_050601_customSubmit = (state: State): SummarySubmitData[] => {
  const toReturn: SummarySubmitData[] = [];
  const baseRequestSubmitData = getBaseRequestSubmitData(state);
  const answer = state.answers["aktivitaId"] as number[] | undefined;

  if (answer) {
    for (const selectedId of answer) {
      toReturn.push({ ...baseRequestSubmitData, aktivitaId: selectedId });
    }
  }

  return toReturn;
};

// TODO
const SC_KIO_050802_customSubmit = (state: State): SummarySubmitData[] => {
  const toSubmit: { [key: string]: unknown } = {};

  toSubmit["ziadamOPoskytnutieOdevuObuvi"] =
    state.answers["odev"] === "SC_KIO_050802_QUES_01_ANS_01";

  toSubmit["cestovneCestovnyListok"] =
    state.answers["cestovne"] !== "SC_KIO_050802_QUES_02_ANS_01" &&
    state.answers["cestovne"] !== undefined;

  toSubmit["trvalyPobyt"] =
    state.answers["cestovne"] === "SC_KIO_050802_QUES_02_ANS_02";

  toSubmit["prechodnyPobyt"] =
    state.answers["cestovne"] === "SC_KIO_050802_QUES_02_ANS_03";

  toSubmit["najblizsiZastupitelskyUrad"] =
    state.answers["cestovne"] === "SC_KIO_050802_QUES_02_ANS_04";

  toSubmit["poHranicu"] =
    state.answers["cestovne"] === "SC_KIO_050802_QUES_02_ANS_05";

  toSubmit["ziadostOStravu"] =
    state.answers["strava"] === "SC_KIO_050802_QUES_03_ANS_01";

  return [
    {
      ...getBaseRequestSubmitData(state),
      ...toSubmit,
    },
  ];
};

const SC_KIO_051004_customSubmit = (state: State): SummarySubmitData[] => {
  return [
    {
      ...getBaseRequestSubmitData(state),
      vyskaDisponibilnehoKonta: state.userCalcData["AVAILABLE_ACCOUNT_AMOUNT"],
      vyskaKreditu: state.userCalcData["CREDIT_AMOUNT"],
      vyskaVyzivnehoAPohladavok:
        state.userCalcData["REGISTERED_ALIMONY_AND_CLAIMS_AMOUNT"],
    },
  ];
};

/**
 * Methods used to prepare a custom submit data object (array) for request submit to szoo.
 * Usage: In some cases you need to prepare a custom request submit object instead of using default/generic one which is being constructed automatically for each request
 */
export const onCustomRequestSubmitData: CustomRequestSubmitDataFunctionType = {
  SC_KIO_050401: SC_KIO_050401_customSubmit,
  SC_KIO_050601: SC_KIO_050601_customSubmit,
  SC_KIO_050802: SC_KIO_050802_customSubmit,
  SC_KIO_051004: SC_KIO_051004_customSubmit,
};

type CustomRequestSubmitDataFunctionType = {
  [key in `${RequestInternalCode}`]?: (state: State) => SummarySubmitData[];
};
