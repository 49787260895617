import { useEffect, useState } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useUITranslation } from "../../../store/context/translation-context";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ReactComponent as DetailIcon } from "../../../assets/icons/Other/detail.svg";
import { useNavigate } from "react-router-dom";
import { ZvjsButton, ZvjsTable } from "../../../common";
import { defer } from "react-router";
import { withLoader } from "../../../hoc/withLoader";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../common/components/ZvjsTable";
import { PromiseElement } from "../../../utils/types";
import { API_Clients } from "../../../store/context/dataApi/Data";

const loadData = async () => {
  const { SHARE_INT_Post } = await API_Clients();

  const data = await Promise.all([
    //get("IneRozhodnutie", localStorage.getItem("klientObjectId") ?? ""),
    SHARE_INT_Post("/api/interfaces/Er/ListZoznamInychRozhodnutiOKlientovi", {
      body: {
        objectKlientId: {
          eq: localStorage.getItem("klientObjectId"),
        },
      },
    }),
    //get("DisciplinarnaOdmena", localStorage.getItem("klientObjectId") ?? ""),
    SHARE_INT_Post(
      "/api/interfaces/Er/ListZoznamDisciplinarnychOdmienKlienta",
      {
        body: {
          objectKlientId: {
            eq: localStorage.getItem("klientObjectId"),
          },
        },
      }
    ),
    // get("DisciplinarnyTrest", localStorage.getItem("klientObjectId") ?? ""),
    SHARE_INT_Post(
      "/api/interfaces/Er/ListZoznamDisciplinarnychTrestovKlienta",
      {
        body: {
          objectKlientId: {
            eq: localStorage.getItem("klientObjectId"),
          },
        },
      }
    ),
  ]);
  return {
    ineRozhodnutia: data[0],
    disciplinarneOdmeny: data[1],
    disciplinarneTresty: data[2],
  };
};

export const DisciplinarneTrestyAIneRozhodnutiaLoader = async () => {
  return defer({
    data: loadData(),
  });
};

interface DisciplinarneTrestyAIneRozhodnutiaProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const DisciplinarneTrestyAIneRozhodnutia = (
  props: DisciplinarneTrestyAIneRozhodnutiaProps
) => {
  const loaderData = props.data;
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const dataStyleIneRozhodnutia: TableStyle[] = [
    {
      align: "left",
      width: "calc(100% / 3)",
    },
    {
      align: "left",
      width: "calc(100% / 3)",
    },
    {
      align: "right",
      width: "calc(100% / 3)",
    },
  ];

  const dataStyleDisciplinarneOdmeny: TableStyle[] = [
    {
      align: "left",
      width: "calc(100% / 3)",
    },
    {
      align: "left",
      width: "calc(100% / 3)",
    },
    {
      align: "right",
      width: "calc(100% / 3)",
    },
  ];

  const dataStyleDisciplinarneTresty: TableStyle[] = [
    {
      align: "left",
      width: "20%",
    },
    {
      align: "left",
      width: "20%",
    },
    {
      align: "left",
      width: "20%",
    },
    {
      align: "left",
      width: "20%",
    },
    {
      align: "right",
      width: "20%",
    },
  ];

  const headerDataIneRozhodnutia: Array<CellData> = [
    {
      value: capitalize(tui("osobneUdaje.ineRozhodnutia.datum")), // TODO: datum <-> druh
      ...dataStyleIneRozhodnutia[0],
    },
    {
      value: capitalize(tui("osobneUdaje.ineRozhodnutia.datumPravoplatnosti")),
      ...dataStyleIneRozhodnutia[1],
    },
    {
      value: capitalize(tui("akcia")),
      ...dataStyleIneRozhodnutia[2],
    },
  ];

  const headerDataDisciplinarneOdmeny: Array<CellData> = [
    {
      value: capitalize(tui("osobneUdaje.disciplinarneOdmeny.datumUlozenia")),
      ...dataStyleDisciplinarneOdmeny[0],
    },
    {
      value: capitalize(tui("osobneUdaje.disciplinarneOdmeny.druh")),
      ...dataStyleDisciplinarneOdmeny[1],
    },
    {
      value: capitalize(tui("akcia")),
      ...dataStyleDisciplinarneOdmeny[2],
    },
  ];

  const headerDataDisciplinarneTresty: Array<CellData> = [
    {
      value: capitalize(tui("osobneUdaje.disciplinarneTresty.datumUlozenia")),
      ...dataStyleDisciplinarneTresty[0],
    },
    {
      value: capitalize(tui("osobneUdaje.disciplinarneTresty.stav")),
      ...dataStyleDisciplinarneTresty[1],
    },
    {
      value: capitalize(tui("osobneUdaje.disciplinarneTresty.druh")),
      ...dataStyleDisciplinarneTresty[2],
    },
    {
      value: capitalize(
        tui("osobneUdaje.disciplinarneTresty.porusenaPovinnost")
      ),
      ...dataStyleDisciplinarneTresty[3],
    },
    {
      value: capitalize(tui("akcia")),
      ...dataStyleDisciplinarneTresty[4],
    },
  ];

  const [tableDataIneRozhodnutia, setTableDataIneRozhodnutia] =
    useState<TableData>({
      label: capitalize(tui("osobneUdaje.ineRozhodnutia.ine")),
      header: headerDataIneRozhodnutia,
      body: [],
    });

  const [tableDataDisciplinarneOdmeny, setTableDataDisciplinarneOdmeny] =
    useState<TableData>({
      label: capitalize(tui("osobneUdaje.disciplinarneOdmeny.odmeny")),
      header: headerDataDisciplinarneOdmeny,
      body: [],
    });

  const [tableDataDisciplinarneTresty, setTableDataDisciplinarneTresty] =
    useState<TableData>({
      label: capitalize(tui("osobneUdaje.disciplinarneTresty.tresty")),
      header: headerDataDisciplinarneTresty,
      body: [],
    });

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (loaderData.ineRozhodnutia.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      loaderData.ineRozhodnutia.data
        .sort(
          (p1, p2) =>
            new Date(p2.datumPravoplatnosti ?? new Date()).valueOf() -
            new Date(p1.datumPravoplatnosti ?? new Date()).valueOf()
        )
        .forEach((disciplinaryOtherDecision) => {
          bodyData.push({
            row: [
              {
                value: disciplinaryOtherDecision.druhRozhodnutiaNazov ?? "",
                ...dataStyleIneRozhodnutia[0],
              },
              {
                value: new Date(
                  disciplinaryOtherDecision.datumPravoplatnosti ?? new Date()
                ).zvjsToString(),
                ...dataStyleIneRozhodnutia[1],
              },
              {
                value: (
                  <ZvjsButton
                    text={capitalize(
                      //tui("osobneUdaje.ineRozhodnutia.detailRozhodnutia") TODO: too long use just "Detail"
                      tui("Detail")
                    )}
                    zvjsVariant="secondaryAction"
                    startIcon={
                      <DetailIcon
                        style={{ fill: "white", width: 28, height: 28 }}
                      />
                    }
                    onClick={() => {
                      navigate(
                        `DisciplinaryOtherDecision/${disciplinaryOtherDecision.erRozhodnutieId}`
                      );
                    }}
                  />
                ),
                ...dataStyleIneRozhodnutia[2],
              },
            ],
          });
        });
      setTableDataIneRozhodnutia({
        ...tableDataIneRozhodnutia,
        body: bodyData,
      });
    }

    if (loaderData.disciplinarneOdmeny.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      loaderData.disciplinarneOdmeny.data
        .sort(
          (p1, p2) =>
            // TODO: "datum udelenia" - taky atribut nie je
            new Date(
              p2.datumUstnehoOznameniaKlientovi ?? new Date()
            ).valueOf() -
            new Date(p1.datumUstnehoOznameniaKlientovi ?? new Date()).valueOf()
        )
        .forEach((disciplinarnaOdmena) => {
          bodyData.push({
            row: [
              {
                value: new Date(
                  disciplinarnaOdmena.datumUstnehoOznameniaKlientovi ??
                    new Date()
                ).zvjsToString(),
                ...dataStyleDisciplinarneOdmeny[0],
              },
              {
                value: disciplinarnaOdmena.odmenaDruhNazov ?? "",
                ...dataStyleDisciplinarneOdmeny[1],
              },
              {
                value: (
                  <ZvjsButton
                    text={capitalize(
                      // tui(
                      //   "osobneUdaje.disciplinarneOdmeny.detailOdmeny" // TODO: "too long"
                      // )
                      tui("Detail")
                    )}
                    zvjsVariant="secondaryAction"
                    startIcon={
                      <DetailIcon
                        style={{ fill: "white", width: 28, height: 28 }}
                      />
                    }
                    onClick={() => {
                      navigate(
                        `DisciplinaryReward/${disciplinarnaOdmena.erRozhDiscOdmenaId}`
                      );
                    }}
                  />
                ),
                ...dataStyleDisciplinarneOdmeny[2],
              },
            ],
          });
        });
      setTableDataDisciplinarneOdmeny({
        ...tableDataDisciplinarneOdmeny,
        body: bodyData,
      });
    }

    if (loaderData.disciplinarneTresty.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      loaderData.disciplinarneTresty.data
        .sort(
          (p1, p2) =>
            new Date(
              p2.datumUstnehoOznameniaKlientovi ?? new Date()
            ).valueOf() -
            new Date(p1.datumUstnehoOznameniaKlientovi ?? new Date()).valueOf()
        )
        .forEach((disciplinarnyTrest) => {
          bodyData.push({
            row: [
              {
                // TODO: "datum ulozenia" - taky atribut nie je
                value: new Date(
                  disciplinarnyTrest.datumUstnehoOznameniaKlientovi ??
                    new Date()
                ).zvjsToString(),
                ...dataStyleDisciplinarneTresty[0],
              },
              {
                value: disciplinarnyTrest.zrusene
                  ? "<zruseny stav>"
                  : "<nezruseny stav>",
                ...dataStyleDisciplinarneTresty[1],
              },
              {
                value: disciplinarnyTrest.druhDisciplinarnehoTrestuNazov ?? "",
                ...dataStyleDisciplinarneTresty[2],
              },
              {
                value: disciplinarnyTrest.porusenePovinnostiId ?? "",
                ...dataStyleDisciplinarneTresty[3],
              },
              {
                value: (
                  <ZvjsButton
                    text={capitalize(
                      // tui("osobneUdaje.disciplinarneTresty.detailTrestu") TODO: Too long
                      tui("Detail")
                    )}
                    zvjsVariant="secondaryAction"
                    startIcon={
                      <DetailIcon
                        style={{ fill: "white", width: 28, height: 28 }}
                      />
                    }
                    onClick={() => {
                      navigate(
                        `DisciplinarySanction/${disciplinarnyTrest.erRozhDiscTrestId}`
                      );
                    }}
                  />
                ),
                ...dataStyleDisciplinarneTresty[4],
              },
            ],
          });
        });
      setTableDataDisciplinarneTresty({
        ...tableDataDisciplinarneTresty,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderData]);

  // const mySubmitHandler = (
  //   dateFrom: Date,
  //   dateTo: Date,
  //   dropDownValue: string
  // ) => {
  //   console.log(dateFrom);
  //   console.log(dateTo);
  // };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(
              tui("osobneUdaje.kategorieOsobnychUdajov.disciplinarneTresty")
            )}
          </Typography>
        </Stack>
      </Grid>

      <ZvjsTable
        data={tableDataDisciplinarneTresty}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
        hintText={tui("osobneUdaje.disciplinarneTresty.napovedaTresty")}
      />

      <ZvjsTable
        data={tableDataDisciplinarneOdmeny}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
        hintText={tui("osobneUdaje.disciplinarneOdmeny.napovedaOdmeny")}
      />

      <ZvjsTable
        data={tableDataIneRozhodnutia}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
        hintText={tui("osobneUdaje.ineRozhodnutia.napovedaIneRozhodnutia")}
      />
    </Grid>
  );
};

export default withLoader(DisciplinarneTrestyAIneRozhodnutia);
