import { dbPromise } from "../services/database";
import { KEY_SVC_URL } from "../store/context/envConfigContext";

export const targetApi = async (apiPath: string) => {
  const database = await dbPromise;
  const svcUrl = await database.get("EnvironmentVariables", KEY_SVC_URL);

  const targetUrl = `${svcUrl}${apiPath}`;
  console.debug(`TARGET API [${targetUrl}]`);

  return targetUrl;
};

export const targetUiApi = (apiPath: string) => {
  const svcUrl = localStorage.getItem(KEY_SVC_URL);

  const targetUrl = `${svcUrl}${apiPath}`;
  console.debug(`TARGET API [${targetUrl}]`);

  return targetUrl;
};

export const BaseApiCallHeaders = {
  "X-API-Key": "KioskZVJSApiKey",
};

export const FileApiCallHeaders = {
  ...BaseApiCallHeaders,
  Accept: "application/*, image/* ,audio/*, video/*, text/javascript",
};

export const FileDirectoryApiCallHeaders = {
  ...BaseApiCallHeaders,
  Accept: "application/xml",
};

export const JsonApiCallHeaders = {
  ...BaseApiCallHeaders,
  Accept: "application/json",
};

export const JsonApiCallWithContentHeaders = {
  ...JsonApiCallHeaders,
  "Content-Type": "application/json",
};
