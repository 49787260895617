import {
  ZvjsCustomQuestionTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_051404",
  basicInfo: [
    {
      text: "ziadost.ziadostOumiestnenie.zakladneInfoOdsudeny",
    },
  ],
  counters: ["/api/CisDovodPodaniaZiadosti/List"],
  items: [
    {
      id: "miestoPozadovanehoUmiestnenia",
      type: ZvjsCustomQuestionTypes.SC_KIO_051404_PLACE_OF_LOCATION_QUESTION,
      title: "ziadost.ziadostOumiestnenie.labelMiestoUmiestnenia",
      isRequired: true,
    },
    {
      id: "dovodPodaniaZiadosti",
      title: "Dôvod podania žiadosti",
      type: ZvjsQuestionTypes.DROPDOWN_NEW,
      isRequired: true,
      options: {
        counterKey: "/api/CisDovodPodaniaZiadosti/List",
        textKey: "dovod",
        optValues: [
          {
            id: "ROD",
          },
          {
            id: "BEZP",
          },
          {
            id: "ZMENA",
          },
          {
            id: "INED",
          },
        ],
      },
    },
    {
      id: "doplnujuceInformacie",
      title: "Bližšia špecifikácia dôvodu žiadosti",
      type: ZvjsQuestionTypes.TEXT,
    },
  ],
};
