import { UserCalcData, ZvjsAnswers } from "../../../redux/model";
import reusable from "./calculations/reusable";
import custom from "./calculations/custom";

const calculations: CalculationType = {
  ...reusable,
  ...custom,
};

export type CalculationType = {
  [key: string]: ({
    userCalcData,
    answers,
  }: {
    userCalcData: UserCalcData;
    answers: ZvjsAnswers;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => number | boolean | string | any;
};

export default calculations;
