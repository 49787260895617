import * as constants from "./constants";
import reducer, { sliceActions } from "./slice";
import selectors from "./selectors";
import actions from "./actions";

const reducerExport = {
  reducer,
  sliceActions,
  constants,
  selectors,
  actions,
};

export default reducerExport;
