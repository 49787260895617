import React, { useEffect, useState } from "react";
import { PeniazeAPoplatkyProps } from "../PeniazeAPoplatky";
import {
  Dashboard_SC_KIO_0200,
  MoneyClaimAndAlimony_SC_KIO_03603,
  ZvjsButton,
  ZvjsTable,
} from "../../../../common";
import { Grid, Stack } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import ChevronRight from "@mui/icons-material/ChevronRight";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../../store/context/translation-context";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DetailIcon } from "../../../../assets/icons/Other/detail.svg";

const PohladavkyTab: React.FC<PeniazeAPoplatkyProps> = ({ data }) => {
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const dataStyleSumarPohladavok: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "right",
    },
    {
      align: "right",
      width: 170,
    },
  ];

  const headerDataSumarPohladavok: Array<CellData> = [
    {
      value: capitalize(
        tui(
          "Názov pohľadávky" // TODO: use proper KEY
        )
      ),
      ...dataStyleSumarPohladavok[0],
    },
    {
      value: capitalize(
        tui(
          "Suma pohľadávky" // TODO: use proper KEY
        )
      ),
      ...dataStyleSumarPohladavok[1],
    },
    {
      value: capitalize(
        tui(
          // TODO: osobneUdaje.vyplatnePasky VS osobneUdaje.blokacie
          "Akcia" // TODO: use proper KEY
        )
      ),
      ...dataStyleSumarPohladavok[2],
    },
  ];

  const [tableDataSumarPohladavok, setTableDataSumarPohladavok] =
    useState<TableData>({
      header: headerDataSumarPohladavok,
      body: [],
    });

  useEffect(() => {
    if (data.feooKlient.data) {
      const bodyData: Array<RowData> = new Array<RowData>();

      // TODO: Apply amounts to appropriate elements
      // TODO: Add correct hint to the elements
      // data.moneyClaimSummarys
      // .filter(moneyClaimSummary => moneyClaimSummary.klientId === authCtx.loggedUserCardId)
      // // .sort((p1, p2) =>
      // //   p1.name > p2.name)

      [
        MoneyClaimAndAlimony_SC_KIO_03603.PenitentiaryClaims,
        MoneyClaimAndAlimony_SC_KIO_03603.GRZVJSClaims,
        MoneyClaimAndAlimony_SC_KIO_03603.OtherAuthorizedClaims,
        MoneyClaimAndAlimony_SC_KIO_03603.Alimonys,
        MoneyClaimAndAlimony_SC_KIO_03603.SummaryClaimAmount,
      ].forEach((typPohladavky) => {
        bodyData.push({
          row: [
            {
              value: capitalize(
                tui(
                  typPohladavky ===
                    MoneyClaimAndAlimony_SC_KIO_03603.GRZVJSClaims
                    ? "osobneUdaje.pohladavky.pohladavkyGRZVJS"
                    : typPohladavky ===
                      MoneyClaimAndAlimony_SC_KIO_03603.PenitentiaryClaims
                    ? "Zostatková suma pohľadávok ústavu"
                    : typPohladavky ===
                      MoneyClaimAndAlimony_SC_KIO_03603.OtherAuthorizedClaims
                    ? "osobneUdaje.pohladavky.pohladavkyOstatni"
                    : typPohladavky ===
                      MoneyClaimAndAlimony_SC_KIO_03603.Alimonys
                    ? "osobneUdaje.pohladavky.pohladavkyVyzivne"
                    : "osobneUdaje.pohladavky.zostatkovaSuma"
                )
              ),
              ...dataStyleSumarPohladavok[0],
            },
            {
              //value: moneyClaimSummary.amount.toFixed(2) + " Eur",
              value:
                typPohladavky ===
                MoneyClaimAndAlimony_SC_KIO_03603.PenitentiaryClaims
                  ? `${data.feooKlient.data?.data?.rozsireneUdajeKlienta?.zostatkovaSumaPohladavokNP?.toFixed(
                      2
                    )} EUR`
                  : typPohladavky ===
                    MoneyClaimAndAlimony_SC_KIO_03603.GRZVJSClaims
                  ? `${data.feooKlient.data?.data?.rozsireneUdajeKlienta?.zostatkovaSumaPohladavokGR_Zvjs?.toFixed(
                      2
                    )} EUR`
                  : typPohladavky ===
                    MoneyClaimAndAlimony_SC_KIO_03603.OtherAuthorizedClaims
                  ? `${data.feooKlient.data?.data?.rozsireneUdajeKlienta?.zostatkovaSumaPohladavokCP?.toFixed(
                      2
                    )} EUR`
                  : typPohladavky === MoneyClaimAndAlimony_SC_KIO_03603.Alimonys
                  ? `${data.feooKlient.data?.data?.rozsireneUdajeKlienta?.zostatkovaSumaVyzivneho?.toFixed(
                      2
                    )} EUR`
                  : `${(
                      (data.feooKlient.data?.data?.rozsireneUdajeKlienta
                        ?.zostatkovaSumaPohladavokNP ?? 0) +
                      (data.feooKlient.data?.data?.rozsireneUdajeKlienta
                        ?.zostatkovaSumaPohladavokGR_Zvjs ?? 0) +
                      (data.feooKlient.data?.data?.rozsireneUdajeKlienta
                        ?.zostatkovaSumaPohladavokCP ?? 0) +
                      (data.feooKlient.data?.data?.rozsireneUdajeKlienta
                        ?.zostatkovaSumaVyzivneho ?? 0)
                    ).toFixed(2)} EUR`,
              ...dataStyleSumarPohladavok[1],
              bold: true,
            },
            {
              value:
                typPohladavky ===
                MoneyClaimAndAlimony_SC_KIO_03603.SummaryClaimAmount ? (
                  ""
                ) : (
                  <ZvjsButton
                    text={capitalize(tui("detail"))}
                    zvjsVariant="secondaryAction"
                    startIcon={
                      <DetailIcon
                        style={{ fill: "white", width: 28, height: 28 }}
                      />
                    }
                    onClick={() => {
                      navigate(typPohladavky);
                    }}
                  />
                ),
              ...dataStyleSumarPohladavok[2],
            },
          ],
        });
      });
      setTableDataSumarPohladavok({
        ...tableDataSumarPohladavok,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Stack direction="column" mt={3} spacing={2}>
      <ZvjsTable
        data={tableDataSumarPohladavok}
        height={540}
        variant={ZvjsTableVariant.NORMAL}
      />
      <Grid width="25rem">
        <ZvjsButton
          text={capitalize(tui("Žiadosť o úhradu"))}
          zvjsVariant="secondaryAction"
          endIcon={<ChevronRight style={{ height: 42, width: 42 }} />}
          onClick={() =>
            navigate(`/auth/${Dashboard_SC_KIO_0200.Requests}/09.01.02`)
          }
        />
      </Grid>
    </Stack>
  );
};

export default PohladavkyTab;
