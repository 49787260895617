import { ZakladneUdajeOTresteProps } from "../ZakladneUdajeOTreste";
import { ZvjsButton, ZvjsTable } from "../../../../common";
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { capitalize } from "@mui/material/utils";
import { useTranslation } from "react-i18next";
import { ReactComponent as DetailIcon } from "../../../../assets/icons/Other/detail.svg";
import { useNavigate } from "react-router-dom";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";

const ZastupcoviaAOpatreniaTab: React.FC<ZakladneUdajeOTresteProps> = ({
  data,
}) => {
  const { t } = useTranslation();
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const dataStylePravnyZastupca: TableStyle[] = [
    {
      align: "left",
      width: "calc(100% / 3)",
    },
    {
      align: "center",
      width: "calc(100% / 3)",
    },
    {
      align: "right",
      width: "calc(100% / 3)",
    },
  ];

  const headerDataPravnyZastupca: Array<CellData> = [
    {
      value: capitalize(tui("osobneUdaje.vseobecneOsobneUdaje.meno")),
      ...dataStylePravnyZastupca[0],
    },
    {
      value: capitalize(tui("osobneUdaje.vseobecneOsobneUdaje.priezvisko")),
      ...dataStylePravnyZastupca[1],
    },
    {
      value: capitalize(tui("Postavenie")),
      ...dataStylePravnyZastupca[2],
    },
  ];

  const [tableDataPravnyZastupca, setTableDataPravnyZastupca] =
    useState<TableData>({
      label: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.RepresentativesAndMeasures.legalRepresentatives"
        )
      ),
      header: headerDataPravnyZastupca,
      body: [],
    });

  const dataStyleOchranneOpatrenie: TableStyle[] = [
    {
      align: "left",
      width: "calc(100% / 3)",
    },
    {
      align: "center",
      width: "calc(100% / 3)",
    },
    {
      align: "right",
      width: "calc(100% / 3)",
    },
  ];

  const headerDataOchranneOpatrenie: Array<CellData> = [
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.RepresentativesAndMeasures.title"
        )
      ),
      ...dataStyleOchranneOpatrenie[0],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.RepresentativesAndMeasures.date"
        )
      ),
      ...dataStyleOchranneOpatrenie[1],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.RepresentativesAndMeasures.action"
        )
      ),
      ...dataStyleOchranneOpatrenie[2],
    },
  ];

  const [tableDataProtectiveMeasure, setTableDataProtectiveMeasure] =
    useState<TableData>({
      label: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.RepresentativesAndMeasures.protectiveMeasures"
        )
      ),
      header: headerDataOchranneOpatrenie,
      body: [],
    });

  useEffect(() => {
    if (data.pravniZastupcovia) {
      const bodyData: Array<RowData> = new Array<RowData>();
      data.pravniZastupcovia.data?.records?.forEach((pravnyZastupca) => {
        // .sort((p1, p2) =>
        //   p1.name > p2.name)
        // .forEach((pravnyZastupca) => {
        bodyData.push({
          row: [
            {
              value: pravnyZastupca.meno ?? "",
              ...dataStylePravnyZastupca[0],
            },
            {
              value: pravnyZastupca.priezvisko ?? "",
              ...dataStylePravnyZastupca[1],
            },
            {
              value: pravnyZastupca.postaveniePravnehoZastupcuString ?? "",
              ...dataStylePravnyZastupca[2],
            },
          ],
        });
      });
      setTableDataPravnyZastupca({
        ...tableDataPravnyZastupca,
        body: bodyData,
      });
    }

    if (data.ochranneOpatrenia) {
      const bodyData: Array<RowData> = new Array<RowData>();
      data.ochranneOpatrenia.data?.records?.forEach((ochranneOpatrenie) => {
        // .sort((p1, p2) =>
        //   p1.name > p2.name)
        // .forEach((ochranneOpatrenie) => {
        bodyData.push({
          row: [
            {
              value: ochranneOpatrenie.typOchrannehoOpatreniaNazov ?? "",
              ...dataStyleOchranneOpatrenie[0],
            },
            {
              value: "<API?>", //TODO: kde ziskat datum new Date(ochranneOpatrenie.datum).zvjsToString(),
              ...dataStyleOchranneOpatrenie[1],
            },
            {
              value: (
                <ZvjsButton
                  text={capitalize(
                    t(
                      "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.RepresentativesAndMeasures.detail"
                    )
                  )}
                  zvjsVariant="secondaryAction"
                  startIcon={
                    <DetailIcon
                      style={{ fill: "white", width: 28, height: 28 }}
                    />
                  }
                  onClick={() => {
                    navigate(`ProtectiveMeasure/${ochranneOpatrenie.id}`);
                  }}
                />
              ),
              ...dataStyleOchranneOpatrenie[2],
            },
          ],
        });
      });
      setTableDataProtectiveMeasure({
        ...tableDataProtectiveMeasure,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <Stack direction="column" mt={1} spacing={3}>
      <ZvjsTable
        data={tableDataPravnyZastupca}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
      <ZvjsTable
        data={tableDataProtectiveMeasure}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Stack>
  );
};

export default ZastupcoviaAOpatreniaTab;
