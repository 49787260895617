import { Button, Grid, Stack, Typography } from "@mui/material";
import { ZvjsCard } from "../../common";
import React from "react";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as BookIcon } from "../../assets/icons/Other/book.svg";
import { ReactComponent as Book2Icon } from "../../assets/icons/Other/book2.svg";
import { ReactComponent as LettersIcon } from "../../assets/icons/Other/letters.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/Other/phone.svg";
import { ReactComponent as MealIcon } from "../../assets/icons/Other/meal.svg";
import { ReactComponent as EducationIcon } from "../../assets/icons/Other/education.svg";
import { ReactComponent as NewspapersIcon } from "../../assets/icons/Other/newspapers.svg";
import { ReactComponent as PenaltyIcon } from "../../assets/icons/Other/penalty.svg";
import { ReactComponent as InformationsIcon } from "../../assets/icons/Other/informations.svg";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../assets/icons/Other/keyboard_arrow_left.svg";
import { useNavigate } from "react-router-dom";
import {
  Dashboard_SC_KIO_0200,
  Noticeboard_SC_KIO_0400,
} from "../../common/specs/sections";
import { useUITranslation } from "../../store/context/translation-context";

const Nastenka = () => {
  const { t } = useTranslation();
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const icon = (key: Noticeboard_SC_KIO_0400): React.FunctionComponent<any> => {
    const iconMap = new Map<
      Noticeboard_SC_KIO_0400,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      React.FunctionComponent<any>
    >();
    iconMap.set(Noticeboard_SC_KIO_0400.ConstitutionalOrder, Book2Icon);
    iconMap.set(Noticeboard_SC_KIO_0400.PacketsAndLetters, LettersIcon);
    iconMap.set(Noticeboard_SC_KIO_0400.Telephony, PhoneIcon);
    iconMap.set(Noticeboard_SC_KIO_0400.Meal, MealIcon);
    iconMap.set(
      Noticeboard_SC_KIO_0400.CulturalAndEducationalActivities,
      EducationIcon
    );
    iconMap.set(Noticeboard_SC_KIO_0400.Library, BookIcon);
    iconMap.set(Noticeboard_SC_KIO_0400.DailyPressAndMagazines, NewspapersIcon);
    iconMap.set(Noticeboard_SC_KIO_0400.ProtectionOfRights, PenaltyIcon);
    iconMap.set(Noticeboard_SC_KIO_0400.GeneralInformation, InformationsIcon);
    return iconMap.get(key) ?? InformationsIcon;
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(tui(tui("dashboard.nastenka")))}
          </Typography>
        </Stack>
      </Grid>
      <Grid container spacing={3}>
        <>
          {Object.keys(Noticeboard_SC_KIO_0400).map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={6} lg={4}>
              <ZvjsCard
                title={capitalize(
                  t(`spec.section.Noticeboard_SC_KIO_0400.${item}`)
                )}
                icon={icon(
                  Noticeboard_SC_KIO_0400[
                    item as keyof typeof Noticeboard_SC_KIO_0400
                  ]
                )}
                sx={{ height: "8em" }}
                onClick={() => {
                  if (
                    item === Noticeboard_SC_KIO_0400.ConstitutionalOrder ||
                    item ===
                      Noticeboard_SC_KIO_0400.CulturalAndEducationalActivities ||
                    item === Noticeboard_SC_KIO_0400.PacketsAndLetters ||
                    item === Noticeboard_SC_KIO_0400.Telephony ||
                    item === Noticeboard_SC_KIO_0400.DailyPressAndMagazines ||
                    item === Noticeboard_SC_KIO_0400.ProtectionOfRights
                  ) {
                    navigate(item + "/" + encodeURIComponent("/"));
                  } else {
                    navigate(item);
                  }
                }}
              />
            </Grid>
          ))}
        </>
      </Grid>
    </Grid>
  );
};

export default Nastenka;
