import React from "react";
import { useSelector } from "react-redux";
import questionnaireRedux from "../../../../../../redux";
import { Grid, Stack } from "@mui/material";
import { ZvjsAlert } from "../../../../../../../components";
import {
  getSeverityOfValidationFlag,
  ZvjsValidationFlagTypes,
} from "../../../../../../redux/model";
import CustomQuestionProps from "../CustomQuestionProps";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";

const SC_KIO_051003_NotAllowedToSendRequestLabels: React.FC<
  CustomQuestionProps
> = ({ location }) => {
  const { tuiz } = useUITranslation();

  console.log(
    `SC_KIO_051003 NOT ALLOWED TO SEND REQUEST LABELS RERENDER ${location.join(
      "-"
    )}`
  );

  const displayClientHasEnoughMoneyOnAccount = useSelector(
    questionnaireRedux.selectors.displayClientHasEnoughMoneyOnAccount()
  );

  const clientAvailableBalance = useSelector(
    questionnaireRedux.selectors.getClientClientAvailableBalance()
  );

  const displayClientShoppedLastCalendarMonth = useSelector(
    questionnaireRedux.selectors.displayClientShoppedLastCalendarMonth()
  );

  return (
    <Grid item xs>
      <Stack spacing={2}>
        {displayClientHasEnoughMoneyOnAccount && (
          <Grid item xs>
            <ZvjsAlert
              severity={getSeverityOfValidationFlag(
                ZvjsValidationFlagTypes.ERROR
              )}
            >
              {/*TODO add correct translations*/}
              <div
                dangerouslySetInnerHTML={{
                  __html: tuiz(
                    "ziadost.ziadostOposkytnutiePotriebOsHygieny.napovedaNemoznoPodatZiadost"
                  ).replace(
                    "###AVAILABLE_BALANCE###",
                    clientAvailableBalance.toString()
                  ),
                }}
              />
            </ZvjsAlert>
          </Grid>
        )}
        {displayClientShoppedLastCalendarMonth && (
          <Grid item xs>
            <ZvjsAlert
              severity={getSeverityOfValidationFlag(
                ZvjsValidationFlagTypes.ERROR
              )}
            >
              {/*TODO add correct translations*/}
              <div
                dangerouslySetInnerHTML={{
                  __html: tuiz(
                    "ziadost.ziadostOposkytnutiePotriebOsHygieny.napovedaNemoznoPodatZiadostNakupVpredajni"
                  ),
                }}
              />
            </ZvjsAlert>
          </Grid>
        )}
      </Stack>
    </Grid>
  );
};

export default SC_KIO_051003_NotAllowedToSendRequestLabels;
