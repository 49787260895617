import React from "react";
import { useSelector } from "react-redux";
import questionnaireRedux from "../../../redux";
import { Label, LabelWithSingleValue, Section } from "./index";
import {
  ZvjsCustomQuestionTypes,
  ZvjsItemTypes,
  ZvjsQuestionTypes,
} from "../../../redux/model";
import { Grid } from "@mui/material";

interface GeneralItemProps {
  location: number[];
}

const GeneralItem: React.FC<GeneralItemProps> = ({ location }) => {
  const itemInfo = useSelector(
    questionnaireRedux.selectors.getGeneralItemInfo(location)
  );
  const isDisplayed = useSelector(
    questionnaireRedux.selectors.getIsItemDisplayed(location)
  );

  console.log(`ITEM RERENDER ${location.join("-")}`);

  const buildItem = () => {
    const getItemComponent = (
      itemInfo: {
        type: ZvjsItemTypes | ZvjsQuestionTypes | ZvjsCustomQuestionTypes;
      },
      location: number[]
    ) => {
      switch (itemInfo.type) {
        case ZvjsItemTypes.SECTION:
          return <Section location={location} />;
        case ZvjsItemTypes.LABEL:
          return <Label location={location} />;
        case ZvjsItemTypes.SINGLE_VALUE_LABEL:
          return <LabelWithSingleValue location={location} />;
      }
      console.error(
        `Unsupported item type ${itemInfo.type} with location ${location}`
      );
      return <></>;
    };

    // if you are not in section limit your item width
    if (location.length === 1) {
      return (
        <Grid container>
          <Grid item md={8} xs={12}>
            {getItemComponent(itemInfo, location)}
          </Grid>
        </Grid>
      );
    } else {
      return getItemComponent(itemInfo, location);
    }
  };

  return isDisplayed ? buildItem() : <></>;
};

export default GeneralItem;
