import {
  Dashboard_SC_KIO_0200,
  GeneralInformation_SC_KIO_0490,
  Noticeboard_SC_KIO_0400,
  PersonalDataCategory_SC_KIO_0313,
} from "../../specs";

const AUTH = "auth";

/**
 * @description mapping of search bot keywords to Router navigation paths
 */
export const ChatBotRoutes: Record<string, string | undefined> = {
  "Žiadosť o telefonovanie": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/13.00.01`,
  "Žiadosť o návštevu": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/04.00.01`,
  "Žiadosť o vydanie povolenia na prijatie balíka": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/01.01.01`,
  "Žiadosť o odoslanie korešpondencie na náklady ústavu": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/01.02.01`,
  "Žiadosť o vykonanie zrážok z čistej pracovnej odmeny odsúdeného na úhradu výživného nezaopatrených detí": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/09.01.01`,
  "Žiadosť o vydanie zaevidovaných cenín, cenných papierov a dokladov preukazujúcich existenciu účtu vedeného v banke alebo v pobočke zahraničnej banky": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/09.03.01`,
  "Žiadosť o výmenu cudzozemských platidiel": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/09.04.01`,
  "Žiadosť o dobrovoľnú úhradu výživného alebo pohľadávky": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/09.01.02`,
  "Žiadosť o vyplatenie peňažných prostriedkov z konta odsúdeného premiestneného do otvoreného oddelenia": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/09.05.01`,
  "Žiadosť o zaevidovanie peňažných prostriedkov na vreckové konto odsúdeného umiestneného v otvorenom oddelení": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/09.05.02`,
  "Žiadosť o poukázanie peňažných prostriedkov v prospech osôb alebo subjektov": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/09.06.01`,
  "Žiadosť o ďalšie použitie vrátenej platby z dobrovoľnej úhrady výživného alebo pohľadávky":
    undefined,
  "Žiadosť o vyplatenie peňažných prostriedkov pri udelení mimoriadneho voľna na opustenie ústavu": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/09.07.01`,
  "Žiadosť o poskytnutie ambulantnej zdravotnej starostlivosti": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/15.00.01`,
  "Žiadosť o povolenie zdravotnej pomôcky": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/15.00.02`,
  "Žiadosť o používanie vlastného odevu, bielizne, obuvi": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/05.00.01`,
  "Žiadosť o obmenu odevu, bielizne a obuvi (ústavných výstrojných súčastí)": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/05.00.02`,
  "Žiadosť o poskytnutie potrieb osobnej hygieny": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/07.01.01`,
  // TODO test from here
  "Žiadosť o častejšie sprchovanie": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/07.02.01`,
  "Žiadosť o ostrihanie": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/07.03.01`,
  "Žiadosť o zmenu poskytovaného druhu stravy": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/12.00.01`,
  "Žiadosť o nákup sortimentu v ústavnej predajni v obmedzenom rozsahu": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/07.01.02`,
  "Žiadosť o používanie vlastného elektrospotrebiča a/alebo jeho technickú kontrolu": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/08.01.01`,
  "Žiadosť o vydanie vecí uložených v úschove alebo vecí v užívaní": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/08.00.01`,
  "Žiadosť o poskytnutie psychologickej služby": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/10.01.01`,
  "Žiadosť o pohovor so sociálnym pracovníkom s cieľom zabezpečenia príslušnej sociálnej služby": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/10.02.01`,
  "Žiadosť o poskytnutie pomoci pri prepustení (odev, strava, cestovný lístok a pod.)": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/10.03.01`,
  "Žiadosť o poskytnutie duchovnej a pastoračnej služby": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/10.04.01`,
  "Žiadosť o prijatie do vzdelávacieho programu alebo rekvalifikačného kurzu": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/11.01.01`,
  "Žiadosť o zaradenie do špecializovaného resocializačného a výchovno-vzdelávacieho programu": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/11.02.01`,
  "Žiadosť o zaradenie do krúžku": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/11.03.01`,
  "Žiadosť o účasť na hromadnej kuktúrno-osvetovej a športovej aktivite": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/11.04.01`,
  "Žiadosť odsúdeného o umiestnenie": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/03.00.01`,
  "Žiadosť o umiestnenie v rámci aktuálneho ústavu": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/03.00.02`,
  "Žiadosť o umiestnenie v oddiele so štandardným režimom": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/03.00.03`,
  "Žiadosť odsúdeného o premiestnenie": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/03.00.04`,
  "Univerzálna žiadosť": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/14.00.01`,
  "Žiadosť o pohovor s riaditeľom ústavu alebo poskytnutie informácie iným pracovníkom zboru": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/10.02.01`,
  "Hlasenie porúch v objekte výkonu väzby a výkonu trestu": `${AUTH}/${Dashboard_SC_KIO_0200.Requests}/02.00.01`,
  "Ústavný poriadok": `${AUTH}/${Dashboard_SC_KIO_0200.NoticeBoard}/${
    Noticeboard_SC_KIO_0400.ConstitutionalOrder
  }/${encodeURIComponent("/")}`,
  "Časový plán dňa": `${AUTH}/${Dashboard_SC_KIO_0200.NoticeBoard}/${
    Noticeboard_SC_KIO_0400.ConstitutionalOrder
  }/${encodeURIComponent("/")}`,
  "Ordinačné hodiny v ambulancii": `${AUTH}/${
    Dashboard_SC_KIO_0200.NoticeBoard
  }/${Noticeboard_SC_KIO_0400.ConstitutionalOrder}/${encodeURIComponent("/")}`,
  "Poplatky za poukázanie peňažných prostriedkov vrámci Slovenska prostredníctvom pošty": `${AUTH}/${
    Dashboard_SC_KIO_0200.NoticeBoard
  }/${Noticeboard_SC_KIO_0400.PacketsAndLetters}/${encodeURIComponent("/")}`,
  "Cenník telefónnych služieb": `${AUTH}/${Dashboard_SC_KIO_0200.NoticeBoard}/${
    Noticeboard_SC_KIO_0400.Telephony
  }/${encodeURIComponent("/")}`,
  "Pravidlá telefonovania": `${AUTH}/${Dashboard_SC_KIO_0200.NoticeBoard}/${
    Noticeboard_SC_KIO_0400.Telephony
  }/${encodeURIComponent("/")}`,
  "Reklamačný poriadok telefonovania": `${AUTH}/${
    Dashboard_SC_KIO_0200.NoticeBoard
  }/${Noticeboard_SC_KIO_0400.Telephony}/${encodeURIComponent("/")}`,
  "Jedálny lístok": `${AUTH}/${Dashboard_SC_KIO_0200.NoticeBoard}/${Noticeboard_SC_KIO_0400.Meal}`,
  "Ponukové listy kultúrno-osvetovej a športovej činnosti v ústave": `${AUTH}/${
    Dashboard_SC_KIO_0200.NoticeBoard
  }/${
    Noticeboard_SC_KIO_0400.CulturalAndEducationalActivities
  }/${encodeURIComponent("/")}`,
  "Výpožičné hodiny knižnice": `${AUTH}/${Dashboard_SC_KIO_0200.NoticeBoard}/${Noticeboard_SC_KIO_0400.Library}/OfficeHours`,
  "Katalóg titulov knižnice": `${AUTH}/${Dashboard_SC_KIO_0200.NoticeBoard}/${Noticeboard_SC_KIO_0400.Library}/Catalog`,
  "Oznámenie o spôsobe predplatenia dennej tlače a časopisov v ústave": `${AUTH}/${
    Dashboard_SC_KIO_0200.NoticeBoard
  }/${Noticeboard_SC_KIO_0400.DailyPressAndMagazines}/${encodeURIComponent(
    "/"
  )}`,
  "Katalóg periodickej tlače": `${AUTH}/${Dashboard_SC_KIO_0200.NoticeBoard}/${
    Noticeboard_SC_KIO_0400.DailyPressAndMagazines
  }/${encodeURIComponent("/")}`,
  "Zoznam a kontaktné údaje na vnútroštátne a medzinárodné organizácie poskytujúce ochranu ľudských práv": `${AUTH}/${
    Dashboard_SC_KIO_0200.NoticeBoard
  }/${Noticeboard_SC_KIO_0400.ProtectionOfRights}/${encodeURIComponent("/")}`,
  "Kontaktné údaje na sociálneho kurátora, sociálneho pracovníka a ďalšie organizácie postpenitenciárnej starostlivosti": `${AUTH}/${
    Dashboard_SC_KIO_0200.NoticeBoard
  }/${Noticeboard_SC_KIO_0400.ProtectionOfRights}/${encodeURIComponent("/")}`,
  Audionahrávky: `${AUTH}/${Dashboard_SC_KIO_0200.NoticeBoard}/${
    Noticeboard_SC_KIO_0400.GeneralInformation
  }/${GeneralInformation_SC_KIO_0490.AudioRecordings}/${encodeURIComponent(
    "/"
  )}`,
  "Informačné brožúry": `${AUTH}/${Dashboard_SC_KIO_0200.NoticeBoard}/${
    Noticeboard_SC_KIO_0400.GeneralInformation
  }/${
    GeneralInformation_SC_KIO_0490.InformationalBrochures
  }/${encodeURIComponent("/")}`,
  "Interné predpisy ZVJS": `${AUTH}/${Dashboard_SC_KIO_0200.NoticeBoard}/${
    Noticeboard_SC_KIO_0400.GeneralInformation
  }/${
    GeneralInformation_SC_KIO_0490.InternalRegulationsZVJS
  }/${encodeURIComponent("/")}`,
  "Iné súbory": `${AUTH}/${Dashboard_SC_KIO_0200.NoticeBoard}/${
    Noticeboard_SC_KIO_0400.GeneralInformation
  }/${GeneralInformation_SC_KIO_0490.OtherFiles}/${encodeURIComponent("/")}`,
  "Miesto výkonu väzby a výkonu trestu": `${AUTH}/${Dashboard_SC_KIO_0200.MyData}/${PersonalDataCategory_SC_KIO_0313.PlaceOfCustodyAndExecutionOfSentence}`,
  Balíky: `${AUTH}/${Dashboard_SC_KIO_0200.MyData}/${PersonalDataCategory_SC_KIO_0313.Packets}`,
  "Disciplinárne odmeny, tresty a iné rozhodnutia": `${AUTH}/${Dashboard_SC_KIO_0200.MyData}/${PersonalDataCategory_SC_KIO_0313.DisciplinarySanctionsAndOtherDecisions}`,
  Návštevy: `${AUTH}/${Dashboard_SC_KIO_0200.MyData}/${PersonalDataCategory_SC_KIO_0313.Visits}`,
  Listy: `${AUTH}/${Dashboard_SC_KIO_0200.MyData}/${PersonalDataCategory_SC_KIO_0313.Letters}`,
  "Osobné veci": `${AUTH}/${Dashboard_SC_KIO_0200.MyData}/${PersonalDataCategory_SC_KIO_0313.PersonalBelongings}`,
  "Konto a kredit": `${AUTH}/${Dashboard_SC_KIO_0200.MyData}/${PersonalDataCategory_SC_KIO_0313.MoneyAndFees}`,
  "Pohľadávky a vyživovacie povinnosti": `${AUTH}/${Dashboard_SC_KIO_0200.MyData}/${PersonalDataCategory_SC_KIO_0313.MoneyAndFees}?tab=2`,
  "Výplatné pásky": `${AUTH}/${Dashboard_SC_KIO_0200.MyData}/${PersonalDataCategory_SC_KIO_0313.MoneyAndFees}?tab=3`,
  "Resocializačné a športové aktivity": `${AUTH}/${Dashboard_SC_KIO_0200.MyData}/${PersonalDataCategory_SC_KIO_0313.ResocializationAndLeisureActivities}`,
  Telefonovanie: `${AUTH}/${Dashboard_SC_KIO_0200.MyData}/${PersonalDataCategory_SC_KIO_0313.Telephony}`,
  "Osobné údaje": `${AUTH}/${Dashboard_SC_KIO_0200.MyData}/${PersonalDataCategory_SC_KIO_0313.BasicClientData}`,
  Trest: `${AUTH}/${Dashboard_SC_KIO_0200.MyData}/${PersonalDataCategory_SC_KIO_0313.BasicPunishmentData}`,
  Väzba: `${AUTH}/${Dashboard_SC_KIO_0200.MyData}/${PersonalDataCategory_SC_KIO_0313.BasicArrestData}`,
};
