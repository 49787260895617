import en from "./en/translation.json";
import sk from "./sk/translation.json";
import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import flagSk from "flag-icons/flags/4x3/sk.svg";
import flagGb from "flag-icons/flags/4x3/gb.svg";
import flagDe from "flag-icons/flags/4x3/de.svg";
import flagFr from "flag-icons/flags/4x3/fr.svg";
import flagHu from "flag-icons/flags/4x3/hu.svg";
import flagPl from "flag-icons/flags/4x3/pl.svg";
import flagRo from "flag-icons/flags/4x3/ro.svg";
import flagRu from "flag-icons/flags/4x3/ru.svg";
import flagEs from "flag-icons/flags/4x3/es.svg";
import flagRs from "flag-icons/flags/4x3/rs.svg";
import flagUa from "flag-icons/flags/4x3/ua.svg";

export enum Jazyk {
  sk = "sk",
  en = "en",
  de = "de",
  fr = "fr",
  hu = "hu",
  pl = "pl",
  ro = "ro",
  ru = "ru",
  es = "es",
  sr = "sr",
  uk = "uk",
}

type JazykDataType = Map<
  Jazyk,
  {
    nazov: string;
    json: object;
    icon: string;
    cisJazyk: string;
  }
>;

export const JazykoveUdaje: JazykDataType = new Map([
  [
    Jazyk.sk,
    {
      nazov: "Slovenčina",
      json: sk,
      icon: flagSk,
      cisJazyk: "slk",
    },
  ],
  [
    Jazyk.en,
    {
      nazov: "English",
      json: en,
      icon: flagGb,
      cisJazyk: "eng",
    },
  ],
  [
    Jazyk.de,
    {
      nazov: "Deutsch",
      json: en,
      icon: flagDe,
      cisJazyk: "deu",
    },
  ],
  [
    Jazyk.fr,
    {
      nazov: "Français",
      json: en,
      icon: flagFr,
      cisJazyk: "fra",
    },
  ],
  [Jazyk.hu, { nazov: "Magyar", json: en, icon: flagHu, cisJazyk: "hun" }],
  [Jazyk.pl, { nazov: "Polski", json: en, icon: flagPl, cisJazyk: "pol" }],
  [Jazyk.ro, { nazov: "Română", json: en, icon: flagRo, cisJazyk: "ron" }],
  [Jazyk.ru, { nazov: "Русский", json: en, icon: flagRu, cisJazyk: "rus" }],
  [
    Jazyk.es,
    {
      nazov: "Español",
      json: en,
      icon: flagEs,
      cisJazyk: "spa",
    },
  ],
  [Jazyk.sr, { nazov: "Српски", json: en, icon: flagRs, cisJazyk: "srp" }],
  [
    Jazyk.uk,
    {
      nazov: "Українська",
      json: en,
      icon: flagUa,
      cisJazyk: "ukr",
    },
  ],
]);

export const getCiselnikJazyk = (jazyk: string): string => {
  let ciselnikJazyk: string | undefined = undefined;
  switch (jazyk) {
    case Jazyk.sk:
      ciselnikJazyk = JazykoveUdaje.get(Jazyk.sk)?.cisJazyk;
      break;
    case Jazyk.en:
      ciselnikJazyk = JazykoveUdaje.get(Jazyk.en)?.cisJazyk;
      break;
    case Jazyk.de:
      ciselnikJazyk = JazykoveUdaje.get(Jazyk.de)?.cisJazyk;
      break;
    case Jazyk.fr:
      ciselnikJazyk = JazykoveUdaje.get(Jazyk.fr)?.cisJazyk;
      break;
    case Jazyk.hu:
      ciselnikJazyk = JazykoveUdaje.get(Jazyk.hu)?.cisJazyk;
      break;
    case Jazyk.pl:
      ciselnikJazyk = JazykoveUdaje.get(Jazyk.pl)?.cisJazyk;
      break;
    case Jazyk.ro:
      ciselnikJazyk = JazykoveUdaje.get(Jazyk.ro)?.cisJazyk;
      break;
    case Jazyk.ru:
      ciselnikJazyk = JazykoveUdaje.get(Jazyk.ru)?.cisJazyk;
      break;
    case Jazyk.es:
      ciselnikJazyk = JazykoveUdaje.get(Jazyk.es)?.cisJazyk;
      break;
    case Jazyk.sr:
      ciselnikJazyk = JazykoveUdaje.get(Jazyk.sr)?.cisJazyk;
      break;
    case Jazyk.uk:
      ciselnikJazyk = JazykoveUdaje.get(Jazyk.uk)?.cisJazyk;
      break;
  }
  if (ciselnikJazyk) return ciselnikJazyk;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  return JazykoveUdaje.get(Jazyk.sk)?.cisJazyk!;
};

const resources: Resource = {};
JazykoveUdaje.forEach((value, key) => {
  resources[key] = {
    translation: value.json,
  };
});

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: [Jazyk.sk, Jazyk.en],
    lng: localStorage.getItem("i18nextLng") ?? Jazyk.sk,
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

export default i18n;
