import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";
import { useNavigate } from "react-router-dom";
import { ZvjsSummaryTable } from "../../../common";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { ZvjsSummaryTableComponent } from "../../../common/components/ZvjsSummaryTable";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router";
import { withLoader } from "../../../hoc/withLoader";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { PromiseElement } from "../../../utils/types";

const loadData = async (id: string) => {
  const { SHARE_INT_Post } = await API_Clients();

  const data = await Promise.all([
    SHARE_INT_Post(
      "/api/interfaces/Er/ListZoznamDisciplinarnychTrestovKlienta",
      {
        body: {
          erRozhDiscTrestId: {
            eq: Number(id),
          },
        },
      }
    ),
  ]);
  return {
    disciplinarneTresty: data[0],
  };
};

export const DisciplinarnyTrestDetailLoader = async (id: string) => {
  return defer({
    data: loadData(id),
  });
};

interface DisciplinarnyTrestDetailProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const DisciplinarnyTrestDetail = (props: DisciplinarnyTrestDetailProps) => {
  const loaderData = props.data;
  const { t } = useTranslation();
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(
                t(
                  "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.DisciplinarySanctionsAndOtherDecisions_SC_KIO_0340.DisciplinarySanction.detailWindow"
                )
              )}
            </Typography>
          </Stack>
        </Grid>

        <Stack direction="column" mt={3} spacing={2}>
          <ZvjsSummaryTable>
            <ZvjsSummaryTableComponent
              label={t(
                "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.DisciplinarySanctionsAndOtherDecisions_SC_KIO_0340.DisciplinarySanction.detailWindow"
              )}
              value={
                loaderData.disciplinarneTresty.data?.at(0)
                  ?.datumUstnehoOznameniaKlientovi
                  ? new Date(
                      loaderData.disciplinarneTresty.data?.at(0)
                        ?.datumUstnehoOznameniaKlientovi ?? new Date()
                    ).zvjsToString()
                  : ""
              }
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={t(
                "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.DisciplinarySanctionsAndOtherDecisions_SC_KIO_0340.DisciplinarySanction.sanctionState"
              )}
              value={
                loaderData.disciplinarneTresty.data?.at(0)?.zrusene
                  ? "<zruseny stav>"
                  : "<nezruseny stav>"
              }
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={t(
                "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.DisciplinarySanctionsAndOtherDecisions_SC_KIO_0340.DisciplinarySanction.sanctionType"
              )}
              value={
                loaderData.disciplinarneTresty.data?.at(0)
                  ?.druhDisciplinarnehoTrestuNazov ?? ""
              }
              isBorder={true}
              fullWidth={true}
            />
            <ZvjsSummaryTableComponent
              label={t(
                "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.DisciplinarySanctionsAndOtherDecisions_SC_KIO_0340.DisciplinarySanction.dutyBreached"
              )}
              value={
                loaderData.disciplinarneTresty.data?.at(0)
                  ?.porusenePovinnostiId ?? ""
              }
              isBorder={true}
              fullWidth={true}
            />
          </ZvjsSummaryTable>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default withLoader(DisciplinarnyTrestDetail);
