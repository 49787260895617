import React, { useEffect, useRef, useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { ZVJS_COLORS } from "../../theme/zvjs_theme";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as Decline } from "../../assets/icons/Other/decline.svg";

interface ZvjsIframeProps {
  iframeTitle: string;
  id?: string;
  url: string;
  onClose?: () => void;
  header?: boolean;
}

const ZvjsIframe: React.FC<ZvjsIframeProps> = ({
  iframeTitle,
  id,
  url,
  ...rest
}) => {
  const [height, setHeight] = useState(80);
  const ref = useRef<HTMLDivElement>(null);
  const header = rest.header ?? true;
  const onClose =
    rest.onClose === undefined && header !== undefined
      ? () => {
          throw new Error("onClose is not defined when is defined header");
        }
      : rest.onClose;

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  }, [ref]);

  return (
    <>
      {header && (
        <Stack
          direction="row"
          style={{
            position: "fixed",
            width: "100vw",
            height: "5rem",
            backgroundColor: ZVJS_COLORS.BLACK_2,
          }}
          ref={ref}
          alignItems="center"
        >
          <IconButton
            sx={{ backgroundColor: ZVJS_COLORS.GREY_6, marginLeft: "2rem" }}
            onClick={onClose}
          >
            <Decline style={{ fill: ZVJS_COLORS.WHITE_2 }} />
          </IconButton>
          <Typography
            variant="h4"
            style={{ color: ZVJS_COLORS.WHITE_2, padding: "1rem" }}
          >
            {capitalize("zavrieť túto aplikáciu")} {/*TODO use proper key*/}
          </Typography>
        </Stack>
      )}
      <iframe
        title={iframeTitle}
        id={id}
        src={url}
        style={{
          height: `calc(100vh - ${height}px)`,
          width: "100vw",
          position: "fixed",
          marginTop: height,
          border: 0,
        }}
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      />
    </>
  );
};

export default ZvjsIframe;
