import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../../../redux";
import { AppDispatch } from "../../../../../../../../store/redux";
import ZvjsDateSelect, {
  DateSelectMode,
} from "../../../../../../../components/ZvjsDateSelect";
import { Grid } from "@mui/material";
import CustomQuestionProps from "../CustomQuestionProps";

const SC_KIO_050101_VisitDateQuestion: React.FC<CustomQuestionProps> = ({
  location,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  console.log(
    `SC_KIO_050101 VISIT DATE QUESTION RERENDER ${location.join("-")}`
  );

  const visitDateQuestionData = useSelector(
    questionnaireRedux.selectors.getVisitDateQuestionDisplayData(location),
    shallowEqual
  );

  const answerVal = useSelector(
    questionnaireRedux.selectors.getQuestionAnswerData(visitDateQuestionData.id)
  ) as string | undefined;

  const onChangeHandler = (newDate: string) => {
    dispatch(
      questionnaireRedux.actions.addNewAnswer(
        location,
        visitDateQuestionData.id,
        newDate
      )
    );
  };

  const today = new Date();
  const nextMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    today.getDate()
  );

  const minDate = visitDateQuestionData.thisMonthRequestAvailable
    ? today
    : nextMonth;
  const maxDate = visitDateQuestionData.nextMonthRequestAvailable
    ? nextMonth
    : today;

  return (
    <Grid item xs>
      <ZvjsDateSelect
        id={`visit-date-question-${location.join("-")}`}
        text={visitDateQuestionData.title}
        onChange={onChangeHandler}
        value={Array.isArray(answerVal) ? answerVal[0] : answerVal ?? ""}
        maxDate={maxDate}
        minDate={minDate}
        mode={DateSelectMode.month}
      />
    </Grid>
  );
};

export default SC_KIO_050101_VisitDateQuestion;
