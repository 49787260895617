import {
  Calculate,
  ZvjsConditionTypes,
  ZvjsQuestionnaireTemplate,
  ZvjsQuestionTypes,
} from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050603",
  basicInfo: [
    {
      text: "ziadost.ziadostOPrijatieDoVzdelProgramu.zakladneInfoObvineny",
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "IS_USER_SENTENCED",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      text: "ziadost.ziadostOPrijatieDoVzdelProgramu.zakladneInfoOdsudeny",
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "IS_USER_ACCUSED",
          conditionMetWhenValueIs: true,
        },
      ],
    },
  ],
  dataCalculations: {
    IS_USER_ACCUSED: {
      calculateAt: Calculate.AT_INIT,
    },
    IS_USER_SENTENCED: {
      calculateAt: Calculate.AT_INIT,
    },
    SC_KIO_050603_IS_USER_ILLITERATE_OR_WITHOUT_PRIMARY_SCHOOLING: {
      calculateAt: Calculate.AT_INIT,
    },
    // SC_KIO_050603_IS_USER_NOT_ILLITERATE_OR_WITHOUT_PRIMARY_SCHOOLING: {
    //   calculateAt: Calculate.LIVE,
    // },
    IS_USER_FOREIGNER: {
      calculateAt: Calculate.AT_INIT,
    },
  },
  items: [
    {
      id: "kurzPreNegramotnych",
      title: "ziadost.ziadostOPrijatieDoVzdelProgramu.labelKurzPreNegramotnych",
      type: ZvjsQuestionTypes.SINGLE_CHECKBOX,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc:
            "SC_KIO_050603_IS_USER_ILLITERATE_OR_WITHOUT_PRIMARY_SCHOOLING",
          conditionMetWhenValueIs: true,
        },
      ],
    },
    {
      id: "kurzSlovenskehoJazyka",
      title:
        "ziadost.ziadostOPrijatieDoVzdelProgramu.labelKurzSlovenskehoJazyka",
      type: ZvjsQuestionTypes.SINGLE_CHECKBOX,
      conditions: [
        {
          type: ZvjsConditionTypes.FUNCTION,
          conFunc: "IS_USER_FOREIGNER",
          conditionMetWhenValueIs: true,
        },
      ],
    },
  ],
};
