/**
 * Store selected counters ids/codes here which are being used in code logic
 */
export const CisDruhAdresy_Trvaly_Pobyt_Kod = "100001";
export const CisDruhAdresy_Prechodny_Pobyt_Kod = "100002";

export const CisDruhElektrospotrebica_Iny_Elektricky_Spotrebic_Kod = "INY";

export const CisOblastZdravotnychProblemov_Bolest_Kod = "1";

export const CisPohlavie_ZENA_ID = 3;

export const CisUstavZvjs_GR_Kod = "75";

export const CisZdrojZiadosti_Tablet_Kod = "1";
export const CisZdrojZiadosti_Kiosk_Kod = "2";
