import React, { ComponentType } from "react";
import { Await, useLoaderData } from "react-router-dom";
import { GenericLoadedData } from "../router/Router";

interface WrapperProps {
  // loaded data (needs to be included otherwise you will get an error when trying to use wrapped component)
  data: never;
}

// HoC which waits until generic loader function is evaluated, after that resolved data is passed to wrapped component
export function withLoader<T>(
  WrappedComponent: ComponentType<T & WrapperProps>
) {
  return function WrapperComponent(props: Omit<T, keyof WrapperProps>) {
    // load generic data (type of the loaded data will be determined in wrapped component)
    const loaderData = useLoaderData() as GenericLoadedData<never>;

    return (
      <Await resolve={loaderData.data}>
        {(data) => {
          return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <WrappedComponent {...props} data={data} />
          );
        }}
      </Await>
    );
  };
}
