import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import questionnaireRedux from "../../../../../../redux";
import { AppDispatch } from "../../../../../../../../store/redux";
import {
  ZvjsCheckBox,
  ZvjsCustomQuestionFullWidthBox,
  ZvjsTable,
} from "../../../../../../../components";
import {
  CellData,
  RequestFontSizes,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../../../../components/ZvjsTable";
import { capitalize } from "@mui/material/utils";
import { ZvjsCheckBoxStateValues } from "../../../../../../../components/ZvjsCheckBox";
import CustomQuestionProps from "../CustomQuestionProps";
import { useUITranslation } from "../../../../../../../../store/context/translation-context";

const SC_KIO_050601_ActivitiesGrid: React.FC<CustomQuestionProps> = ({
  location,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { tuiz } = useUITranslation();

  console.log(
    `SC_KIO_050601 ACTIVITIES GRID QUESTION RERENDER ${location.join("-")}`
  );

  const questionData = useSelector(
    questionnaireRedux.selectors.getActivitiesGridDisplayData(location),
    shallowEqual
  );

  const answerVal = useSelector(
    questionnaireRedux.selectors.getQuestionAnswerData(questionData.id),
    shallowEqual
  ) as undefined | string[];

  const onChangeHandler = (
    label: string | undefined,
    key: string,
    checked: boolean,
    checkedValues: ZvjsCheckBoxStateValues[]
  ) => {
    if (checked) {
      const answersCopy: string[] = answerVal
        ? JSON.parse(JSON.stringify(answerVal))
        : [];
      answersCopy.push(key);
      dispatch(
        questionnaireRedux.actions.addNewAnswer(
          location,
          questionData.id,
          answersCopy
        )
      );
    } else {
      let answersCopy: string[] = answerVal
        ? JSON.parse(JSON.stringify(answerVal))
        : [];
      answersCopy = answersCopy.filter((answer) => answer !== key);
      dispatch(
        questionnaireRedux.actions.addNewAnswer(
          location,
          questionData.id,
          answersCopy
        )
      );
    }
  };

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "right",
      sx: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(tuiz("ziadost.ziadostOucastnaKOC.labelNazovAktivity")),
      ...dataStyle[0],
    },
    {
      value: capitalize(
        tuiz("ziadost.ziadostOucastnaKOC.labelChcemSaZucastnit")
      ),
      ...dataStyle[1],
    },
  ];

  const bodyData: Array<RowData> = new Array<RowData>();
  for (const activity of questionData.availableActivities) {
    bodyData.push({
      row: [
        {
          value: activity.name,
          ...dataStyle[0],
        },
        {
          value: (
            <ZvjsCheckBox
              onChange={onChangeHandler}
              data={[
                {
                  key: activity.id.toString(),
                  checked: answerVal?.some((answer) => {
                    return Number(answer) === activity.id;
                  }),
                },
              ]}
            />
          ),
          ...dataStyle[1],
        },
      ],
    });
  }

  const tableData: TableData = {
    header: headerData,
    body: bodyData,
    label: tuiz(questionData.title),
  };

  return (
    <ZvjsCustomQuestionFullWidthBox location={location}>
      <ZvjsTable
        data={tableData}
        variant={ZvjsTableVariant.NORMAL}
        fontSizes={RequestFontSizes}
      />
    </ZvjsCustomQuestionFullWidthBox>
  );
};

export default SC_KIO_050601_ActivitiesGrid;
