import {
  NestedDropdownAnswerValueType,
  UserCalcData,
  ZvjsAnswerValueType,
  ZvjsCustomQuestionTypes,
} from "../../../../../redux/model";
import {
  SC_KIO_050501_DevicesRevisionControlGrid_answerType,
  SC_KIO_050501_DevicesRevisionControlGrid_getCustomSubmitAnswerData,
} from "./SC_KIO_050501_DevicesRevisionControlGrid/redux";
import { SC_KIO_051003_PersonalHygieneNeedsGrid_getCustomSubmitAnswerData } from "./SC_KIO_051003_PersonalHygieneNeedsGrid/redux";
import { SC_KIO_051404_PlaceOfLocationQuestion_getCustomSubmitAnswerData } from "./SC_KIO_051404_PlaceOfLocationQuestion/redux";
import { SC_KIO_051403_PlaceOfRelocationQuestion_getCustomSubmitAnswerData } from "./SC_KIO_051403_PlaceOfRelocationQuestion/redux";

/**
 * The List of functions that return data based on the answer to the question in the correct form for submitted answers (e.g., customAnswers can be more complex)
 */
const getCustomSubmitAnswerData: getCustomSubmitAnswerDataType = {
  [ZvjsCustomQuestionTypes.SC_KIO_050501_DEVICES_REVISION_CONTROL_GRID]: (
    answer,
    questionId
  ) =>
    SC_KIO_050501_DevicesRevisionControlGrid_getCustomSubmitAnswerData(
      answer as SC_KIO_050501_DevicesRevisionControlGrid_answerType,
      questionId
    ),
  [ZvjsCustomQuestionTypes.SC_KIO_051003_PERSONAL_HYGIENE_NEEDS_GRID]: (
    answer,
    questionId,
    userCalcData
  ) =>
    SC_KIO_051003_PersonalHygieneNeedsGrid_getCustomSubmitAnswerData(
      answer as string[],
      questionId,
      userCalcData
    ),
  [ZvjsCustomQuestionTypes.SC_KIO_051403_PLACE_OF_RELOCATION_QUESTION]: (
    answer,
    questionId
  ) =>
    SC_KIO_051403_PlaceOfRelocationQuestion_getCustomSubmitAnswerData(
      answer as NestedDropdownAnswerValueType[],
      questionId
    ),
  [ZvjsCustomQuestionTypes.SC_KIO_051404_PLACE_OF_LOCATION_QUESTION]: (
    answer,
    questionId
  ) =>
    SC_KIO_051404_PlaceOfLocationQuestion_getCustomSubmitAnswerData(
      answer as NestedDropdownAnswerValueType[],
      questionId
    ),
};

type getCustomSubmitAnswerDataType = {
  [key in ZvjsCustomQuestionTypes]?: (
    answer: ZvjsAnswerValueType,
    questionId: string,
    userCalcData: UserCalcData
  ) => {
    [key: string]: unknown;
  };
};

export default getCustomSubmitAnswerData;
