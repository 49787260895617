import React, { createContext, useContext } from "react";
import { paths } from "../../api/types/auditing_v1";
import { components } from "../../api/types/auditing_v1";
import { operations } from "../../api/types/auditing_v1";

import { Module } from "../../utils/module";

type AuditLogsContextType = {
  auditLog: (message: string) => void;
};

const AuditLogsContext = createContext<AuditLogsContextType | null>(null);

export const useAuditLogs = () =>
  useContext(AuditLogsContext) as AuditLogsContextType;

type Props = {
  children: React.ReactNode;
};

export const AuditLogsProvider: React.FC<Props> = ({ children }) => {
  const auditLog = (message: string) => {
    const url: keyof paths = "/api/AuditLog/Create";
    const api = `/${Module.AUDITING}${url}`;

    const auditLog: components["schemas"]["AuditLog"] = {
      datumACas: Date.now().toString(),
      funkcnyCelok: { text: "KIOSK" },
      ustavZvjs: { kod: "kod ustavu", text: "Ustav Zilina" },
      poznamka: message,
    };

    fetch(api, {
      body: JSON.stringify(auditLog),
      method: "POST",
    });

    // TODO: use appropriate logging method (fetch, etc...)
    // THEN: add table SyncAuditLog and register SW route which puts
    //       audit log into table, when POST fails (when offline)
    console.log(`[AUDIT] ${message}`);
  };

  return (
    <AuditLogsContext.Provider
      value={{
        auditLog,
      }}
    >
      {children}
    </AuditLogsContext.Provider>
  );
};
