import React, { ReactNode } from "react";
import {
  Breadcrumbs,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { SECTION_LEFT_PADDING } from "../../request/requestPages/requestStyles/requestStyles";
import { useUITranslation } from "../../../store/context/translation-context";

interface ZvjsSectionUIProps {
  children?: ReactNode;
  sectionTitles: string[];
}

const ZvjsSectionUI: React.FC<ZvjsSectionUIProps> = ({
  children,
  sectionTitles,
}) => {
  const theme = useTheme();
  const { tuiz } = useUITranslation();

  return (
    <Grid
      // sx={
      //   location.length === 1
      //     ? { background: "#d8d8d8" }
      //     : { background: "inherit" }
      // }
      // borderRadius={2}
      container
      flexWrap={"nowrap"}
    >
      <Grid item>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          color={theme.palette.primary.main}
          sx={{ height: "100%" }}
        />
      </Grid>
      <Grid
        // bgcolor={"rgba(255,255,255,0.4)"}
        borderRadius={2}
        pt={2}
        pb={2}
        pl={`${SECTION_LEFT_PADDING}px`}
        item
        xs
      >
        {/*display title of section in breadcrumb*/}
        {sectionTitles.length !== 0 && (
          <Breadcrumbs
            sx={{ paddingBottom: 2 }}
            color={theme.palette.primary.main}
            aria-label="breadcrumb"
          >
            {sectionTitles.map((value, index) => (
              <Typography key={index} variant={"h6"}>
                {tuiz(value)}
              </Typography>
            ))}
          </Breadcrumbs>
        )}
        {children}
      </Grid>
    </Grid>
  );
};

export default ZvjsSectionUI;
