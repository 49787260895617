import {
  State,
  UserCalcData,
  ZvjsCustomQuestion,
} from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelf } from "../../../../../../redux/selectors";
import { findItem } from "../../../../../../redux/slice";

const BANKNOTES = "BANKNOTES";
export const BanknotesGridConstants = {
  BANKNOTES,
};

/**
 * OnInit
 */

export interface BanknoteType {
  id: string;
  value: number;
  currency: string;
}

export const SC_KIO_050201_BanknotesGrid_onInit = async (
  calcData: UserCalcData
): Promise<void> => {
  // TODO replace with actual fetch - filter out blocked and not transferable banknotes
  const listOfBanknotes: BanknoteType[] = [
    {
      id: "001",
      value: 50,
      currency: "USD",
    },
    {
      id: "002",
      value: 20,
      currency: "EUR",
    },
    {
      id: "003",
      value: 1000,
      currency: "JPY",
    },
    {
      id: "004",
      value: 10,
      currency: "GBP",
    },
    {
      id: "005",
      value: 5,
      currency: "CAD",
    },
  ];

  calcData[BanknotesGridConstants.BANKNOTES] = listOfBanknotes;
};

/**
 * Selectors
 */

const getBanknotesGridDisplayData = (location: number[]) =>
  createSelector(selectSelf, (state: State) => {
    const question = findItem(
      state.questionnaire,
      location
    ) as ZvjsCustomQuestion;

    return {
      id: question.id,
      title: question.title,
      banknotes: state.userCalcData[
        BanknotesGridConstants.BANKNOTES
      ] as BanknoteType[],
    };
  });

export const allSelectors = {
  getBanknotesGridDisplayData,
};
