import { ZvjsQuestionnaireTemplate } from "../redux/model";

export const questionnaire: ZvjsQuestionnaireTemplate = {
  id: "SC_KIO_050502",
  basicInfo: [
    {
      text: "ziadost.ziadostOvydanieVeci.zakladneInfo",
    },
  ],
  items: [],
};
