import React, { useState } from "react";
import { capitalize } from "@mui/material/utils";
import { Stack, Typography } from "@mui/material";
import { LoginStepProps } from "../Login";
import { ZvjsButton, ZvjsTextField } from "../../common";
import { ArrowForwardIos } from "@mui/icons-material";
import { useUITranslation } from "../../store/context/translation-context";

const LoginStep3: React.FC<LoginStepProps> = ({ onLoginStepData }) => {
  const { tui } = useUITranslation();

  const [response, setResponse] = useState<string>("");

  const onClick = async () => {
    onLoginStepData(3, response);
    setResponse("");
  };

  return (
    <Stack
      spacing={1}
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
      alignItems="center"
    >
      <Stack spacing={-1}>
        <Typography variant="h3" textAlign="center">
          {capitalize(tui("prihlasenie.bezpecnostnasOtazka"))}
          {":"}
        </Typography>
        <Typography
          variant="h3"
          sx={{ fontStyle: "italic" }}
          textAlign="center"
          width="80vw"
        >
          {capitalize(tui("prihlasenie.rokNarodenia"))}
          {":"}
        </Typography>
      </Stack>
      <Stack spacing={3} sx={{ width: "30rem" }}>
        <ZvjsTextField
          autoFocus
          label=""
          value={response}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setResponse(event.target.value);
          }}
          onKeyDown={(
            event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            if (event.code === "Enter") {
              onClick();
            }
          }}
        />
        <Stack direction="row">
          <ZvjsButton
            text={capitalize(tui("prihlasenie.prihlasitSa"))}
            endIcon={<ArrowForwardIos />}
            zvjsVariant={"secondaryAction"}
            onClick={onClick}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LoginStep3;
