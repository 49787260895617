import { UserCalcData } from "../../../../../../redux/model";
import { createSelector } from "@reduxjs/toolkit";
import { selectUserCalcData } from "../../../../../../redux/selectors";

/**
 * Selectors
 */

const displayClientInNTGroupLabel = () =>
  createSelector(selectUserCalcData, (state: UserCalcData) => {
    return state["SC_KIO_051403_IS_CLIENT_IN_NT_GROUP"];
  });

const displayRequestRefusedInPastSixMonths = () =>
  createSelector(
    selectUserCalcData,
    (state: UserCalcData): boolean | undefined => {
      return state["SC_KIO_051403_CLIENT_HAS_REFUSED_REQUEST_IN_PAST_6_MONTHS"];
    }
  );

export const allSelectors = {
  displayClientInNTGroupLabel,
  displayRequestRefusedInPastSixMonths,
};
