import { UserCalcData, ZvjsAnswers } from "../../../../redux/model";
import { LoaderError } from "../../../../../../router/LoaderError";
import { isEmptyArray } from "../../../../../../utils/helpers";
import { API_Clients } from "../../../../../../store/context/dataApi/Data";
import { MAX_NUMBER_OF_ITEMS } from "../../../../../../store/context/dataApi/CIS_Ciselnik";
import { CisPohlavie_ZENA_ID } from "../../../../../specs/countersValues";

const getAvailableAccountAmount = async () => {
  const { FEOO_Post } = await API_Clients();

  const response = await FEOO_Post(
    "/api/integracie/Kiosk/ListStavKontaAKreditu",
    {
      body: {
        idKlienta: localStorage.getItem("klientObjectId"),
      },
    }
  );

  if (response.error || !response.data.at(0)?.disponibilnyZostatok) {
    throw new LoaderError();
  }

  return response.data.at(0)?.disponibilnyZostatok;
};

const getCreditAmount = () => {
  // TODO replace with actual logic
  return 200;
};

const getPossibleWithdrawalAmount = () => {
  // TODO replace with actual logic
  return 300;
};

const getRegisteredAlimonyAndClaimsAmount = () => {
  // TODO replace with actual logic
  return 60;
};

const getRefundAmount = () => {
  // TODO replace with actual logic
  return 100;
};

const getTotalUnpaidAlimonysAndClaimsAmount = () => {
  return 200;
};

const getCurrentDietType = () => {
  // TODO replace with actual logic
  return "Štandardná strava";
};

const getAllUserElectricDevicesList = async (): Promise<
  ElectricDevice[] | undefined
> => {
  const { EDPOO_Post, CIS_Post } = await API_Clients();
  const userDevicesResponse = await EDPOO_Post(
    "/api/OsobneVeci/ListElektrospotrebic",
    {
      body: {
        filters: [
          {
            id: Number(localStorage.getItem("klientId")),
            ustavZvjsId: Number(localStorage.getItem("klientUstavId")),
          },
        ],
      },
    }
  );

  if (userDevicesResponse.error) {
    throw new LoaderError();
  }

  const userDevices = userDevicesResponse.data;

  if (!isEmptyArray(userDevices.records)) {
    const devicesCounter = await CIS_Post(
      "/api/CisDruhElektrospotrebica/List",
      {
        body: {
          filters: [
            {
              aktualny: true,
              platny: true,
            },
          ],
          paging: {
            currentPage: 1,
            recordsPerPage: MAX_NUMBER_OF_ITEMS,
          },
        },
      }
    );

    if (devicesCounter.data?.records) {
      return (
        (userDevices.records ?? [])
          // filter out devices with unknown deviceCounterId
          .filter(
            (device) =>
              devicesCounter.data.records?.find(
                (counterItem) =>
                  // TODO remove when swagger is correct
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  counterItem.id === device.druhElektrospotrebicaId
              ) !== undefined
          )
          .map((device): ElectricDevice => {
            const deviceCode = devicesCounter.data.records?.find(
              (counterItem) =>
                // TODO remove when swagger is correct
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                counterItem.id === device.druhElektrospotrebicaId
            )?.kod;
            if (deviceCode === undefined || deviceCode === null) {
              // you should never end up here as items falling to this path should be filtered out in filter above
              throw new LoaderError();
            }

            return {
              id: device.id,
              typeCode: deviceCode,
              // TODO remove when swagger is correct
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              controlValidUntil: device.datumPlatnostiRevizie,
              ongoingInspection: false,
              // TODO remove when swagger is correct
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              nameOfOtherDevice: device.nazovInehoElektrospotrebica,
            };
          })
      );
    }
  } else {
    // if a user does not have any electric devices return an empty array
    return [];
  }

  throw new LoaderError();

  // // TODO replace with actual logic
  // return [
  //   {
  //     id: 1,
  //     typeCode: "TV",
  //     controlValidUntil: "2023-11-20T00:00:00.000Z",
  //     ongoingInspection: false,
  //   },
  //   {
  //     id: 2,
  //     typeCode: "RADIO",
  //     controlValidUntil: "2024-12-01T00:00:00.000Z",
  //     ongoingInspection: false,
  //   },
  //   {
  //     id: 3,
  //     typeCode: "PVRK",
  //     controlValidUntil: "2023-10-01T00:00:00.000Z",
  //     ongoingInspection: false,
  //   },
  // ];
};

const getVisitRequestsList = () => {
  return {
    thisMonth: {
      placeholder: 1,
    },
    nextMonth: {
      placeholder: 2,
    },
  };
};

const getCanRequestShowerReasonJob = () => {
  // TODO replace with actual logic
  return true;
};

const getCanRequestShowerReasonHealth = () => {
  // TODO replace with actual logic
  return true;
};

const getCanRequestShowerReasonOther = () => {
  // TODO replace with actual logic
  return true;
};

export const getIsFemale = async () => {
  // check if a client is female
  const { EOO_Get } = await API_Clients();
  const userDetail = await EOO_Get(
    "/api/Klient/DetailZakladneUdajeKlientaData",
    {
      params: {
        query: {
          Id: Number.parseInt(localStorage.getItem("klientId") ?? "") ?? 1,
          UstavZvjsId:
            Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
        },
      },
    }
  );

  if (userDetail.error || !userDetail.data?.data) {
    throw new Error();
  }

  const userBaseDetail = await EOO_Get("/api/FyzickaOsoba/DetailData", {
    params: {
      query: {
        request: userDetail.data.data.fyzickaOsobaId,
      },
    },
  });

  if (userBaseDetail.error || !userBaseDetail.data?.data) {
    throw new Error();
  }

  const isUserFemale =
    userBaseDetail.data.data.pohlavieId === CisPohlavie_ZENA_ID;
  if (isUserFemale) {
    return true;
  }
  return false;
};

const canAcceptPackageWithPersonalItem = () => {
  // TODO replace with actual logic
  return true;
};

const canAcceptPackageWithClothesAndFootwear = () => {
  // TODO replace with actual logic
  return true;
};

const canTransferFundsToClosePerson = () => {
  // TODO replace with actual logic
  return true;
};

const canTransferFundsToOtherPersonOrHarmCaused = () => {
  // TODO replace with actual logic
  return true;
};

const canTransferFundsToCertainPerson = () => {
  // TODO replace with actual logic
  return true;
};

const isUserAccused = () => {
  // TODO replace with actual logic
  return true;
};

const isUserSentenced = () => {
  // TODO replace with actual logic
  return true;
};

const isUserAccusedInStandardMode = ({
  userCalcData,
}: {
  userCalcData: UserCalcData;
}) => {
  // check if the is user accused value was already calculated, if not calculate it
  if (userCalcData["IS_USER_ACCUSED"] === true || isUserSentenced()) {
    // TODO replace with actual logic
    return true;
  }
};

const isUserAccusedInSoftenedMode = ({
  userCalcData,
}: {
  userCalcData: UserCalcData;
}) => {
  // check if the is user accused value was already calculated, if not calculate it
  if (userCalcData["IS_USER_ACCUSED"] === true || isUserSentenced()) {
    // TODO replace with actual logic
    return true;
  }
};

const isUserForeigner = ({ userCalcData }: { userCalcData: UserCalcData }) => {
  // TODO replace with actual logic
  return true;
};

const shoppedLastCalendarMonth = ({
  userCalcData,
}: {
  userCalcData: UserCalcData;
}) => {
  // TODO replace with actual logic
  return false;
};

const minimumSubsistenceAmount = async ({
  userCalcData,
}: {
  userCalcData: UserCalcData;
}) => {
  const { FEOO_Get } = await API_Clients();

  const response = await FEOO_Get("/api/integracie/Kiosk/GetZivotneMinimum");

  if (response.error || !response.data.hodnota) {
    throw new Error();
  }

  return response.data.hodnota;
};

const minimumSubsistenceAmount_3x = async ({
  userCalcData,
}: {
  userCalcData: UserCalcData;
}) => {
  const minimumSubsistenceAmountVal = await minimumSubsistenceAmount({
    userCalcData: userCalcData,
  });

  return 3 * minimumSubsistenceAmountVal;
};

const reusable: CalculationType = {
  AVAILABLE_ACCOUNT_AMOUNT: getAvailableAccountAmount,
  CREDIT_AMOUNT: getCreditAmount,
  POSSIBLE_WITHDRAWAL_AMOUNT: getPossibleWithdrawalAmount,
  REGISTERED_ALIMONY_AND_CLAIMS_AMOUNT: getRegisteredAlimonyAndClaimsAmount,
  REFUND_AMOUNT: getRefundAmount,
  TOTAL_UNPAID_CHILD_SUPPORT_OBLIGATIONS_AND_CLAIMS_AMOUNT:
    getTotalUnpaidAlimonysAndClaimsAmount,
  CURRENT_DIET_TYPE: getCurrentDietType,
  ALL_USER_ELECTRIC_DEVICES_LIST: getAllUserElectricDevicesList,
  VISIT_REQUESTS_LIST: getVisitRequestsList,
  CAN_REQUEST_SHOWER_REASON_JOB: getCanRequestShowerReasonJob,
  CAN_REQUEST_SHOWER_REASON_HEALTH: getCanRequestShowerReasonHealth,
  CAN_REQUEST_SHOWER_REASON_OTHER: getCanRequestShowerReasonOther,
  IS_FEMALE: getIsFemale,
  CAN_ACCEPT_PACKAGE_WITH_PERSONAL_ITEMS: canAcceptPackageWithPersonalItem,
  CAN_ACCEPT_PACKAGE_WITH_CLOTHES_AND_FOOTWEAR:
    canAcceptPackageWithClothesAndFootwear,
  CAN_TRANSFER_FUNDS_TO_CLOSE_PERSON: canTransferFundsToClosePerson,
  CAN_TRANSFER_FUNDS_TO_OTHER_PERSON_OR_HARM_CAUSED:
    canTransferFundsToOtherPersonOrHarmCaused,
  CAN_TRANSFER_FUNDS_TO_CERTAIN_PERSON: canTransferFundsToCertainPerson,
  IS_USER_ACCUSED: isUserAccused,
  IS_USER_SENTENCED: isUserSentenced,
  IS_USER_ACCUSED_IN_STANDARD_MODE: isUserAccusedInStandardMode,
  IS_USER_ACCUSED_IN_SOFTENED_MODE: isUserAccusedInSoftenedMode,
  IS_USER_FOREIGNER: isUserForeigner,
  SHOPPED_LAST_CALENDAR_MONTH: shoppedLastCalendarMonth,
  MINIMUM_SUBSISTENCE_AMOUNT: minimumSubsistenceAmount,
  "3_x_MINIMUM_SUBSISTENCE_AMOUNT": minimumSubsistenceAmount_3x,
};

type CalculationType = {
  [key: string]: ({
    userCalcData,
    answers,
  }: {
    userCalcData: UserCalcData;
    answers: ZvjsAnswers;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => number | boolean | string | any;
};

export default reusable;

/**
 * Return models
 */

export interface ElectricDevice {
  id: number | undefined;
  typeCode: string;
  controlValidUntil: string | undefined;
  ongoingInspection: boolean;
  nameOfOtherDevice?: string;
}
