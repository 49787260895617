import { ResocializacneAVolnocasoveAktivityProps } from "../ResocializacneAVolnocasoveAktivity";
import React, { useEffect, useState } from "react";
import { ZvjsSearchBar, ZvjsTable } from "../../../../common";
import { ZvjsSearchBarVariant } from "../../../../common/components/ZvjsSearchBar";
import { capitalize } from "@mui/material/utils";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUITranslation } from "../../../../store/context/translation-context";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../common/components/ZvjsTable";

const ProgramZaobchadzaniaTab: React.FC<
  ResocializacneAVolnocasoveAktivityProps
> = ({ data }) => {
  const { t } = useTranslation();
  const { tui } = useUITranslation();

  const mySubmitHandler = (comboBoxValue: string, comboBoxValue2: string) => {
    console.log(comboBoxValue);
    console.log(comboBoxValue2);
  };

  const dataStyleProgramZaobchadzania: TableStyle[] = [
    {
      align: "left",
      width: "calc(100% / 3)",
    },
    {
      align: "center",
      width: "calc(100% / 3)",
    },
    {
      align: "right",
      width: "calc(100% / 3)",
    },
  ];

  const headerDataProgramZaobchadzania: Array<CellData> = [
    {
      value: capitalize(tui("osobneUdaje.programZaobchadzania.ulohyProgramu")),
      ...dataStyleProgramZaobchadzania[0],
    },
    {
      value: capitalize(tui("osobneUdaje.programZaobchadzania.cielProgramu")),
      ...dataStyleProgramZaobchadzania[1],
    },
    {
      value: capitalize(tui("osobneUdaje.programZaobchadzania.stavPlnenia")),
      ...dataStyleProgramZaobchadzania[2],
    },
  ];

  const [tableDataProgramZaobchadzania, setTableDataProgramZaobchadzania] =
    useState<TableData>({
      header: headerDataProgramZaobchadzania,
      label: tui("osobneUdaje.programZaobchadzania.programZaobchadzania"),
      body: [],
    });

  useEffect(() => {
    const bodyData: Array<RowData> = new Array<RowData>();
    data.programZaobchadzaniaCieleSUlohami.data
      // .sort((p1, p2) =>
      //   p1.name > p2.name)
      ?.forEach((programZaobchadzaniaCielSUlohami) => {
        bodyData.push({
          row: [
            {
              value:
                programZaobchadzaniaCielSUlohami.programZaobchadzaniaTypUlohyNazov ??
                "",
              ...dataStyleProgramZaobchadzania[0],
            },
            {
              value:
                programZaobchadzaniaCielSUlohami.cieleProgramuZaobchadzaniaNazov ??
                "",
              ...dataStyleProgramZaobchadzania[1],
            },
            {
              value:
                programZaobchadzaniaCielSUlohami.ulohaStavHodnoteniaNazov ?? "",
              ...dataStyleProgramZaobchadzania[2],
            },
          ],
        });
      });
    setTableDataProgramZaobchadzania({
      ...tableDataProgramZaobchadzania,
      body: bodyData,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Stack direction="column" mt={1} spacing={3}>
      <ZvjsSearchBar
        title={t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.ResocializationAndLeisureActivities_SC_KIO_0350.TreatmentProgram.filterTreatmentProgram"
        )}
        type={ZvjsSearchBarVariant.VARIANT_E_CoCo}
        comboboxLabel={capitalize(
          tui("osobneUdaje.programZaobchadzania.oblast")
        )}
        items={[
          { value: 3, text: "Tri" },
          { value: 5, text: "Pat" },
          { value: 10, text: "Desat" },
        ]}
        comboboxLabel2={capitalize(
          tui("osobneUdaje.programZaobchadzania.stavPlnenia")
        )}
        items2={[
          { value: 3, text: "Tri" },
          { value: 5, text: "Pat" },
          { value: 10, text: "Desat" },
        ]}
        onSubmit={mySubmitHandler}
      />
      <ZvjsTable
        data={tableDataProgramZaobchadzania}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
        hintText={tui("osobneUdaje.programZaobchadzania.napovedaProgram")}
      />
    </Stack>
  );
};

export default ProgramZaobchadzaniaTab;
