import { capitalize, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { ZVJS_COLORS } from "../../theme/zvjs_theme";
import { useUITranslation } from "../../store/context/translation-context";
import { ZvjsHintModalFragment } from "./ZvjsModal";
import Link from "@mui/material/Link";

interface ZvjsSummaryTableProps {
  children: React.ReactNode;
  header?: string | string[];
  hintTitle?: string;
  hintText?: string;
  hintTitle2?: string;
  hintText2?: string;
}

interface ZvjsSummaryTableComponentProps {
  label: string;
  value: string;
  isBorder: boolean;
  children?: React.ReactNode;
  fullWidth?: boolean;
  hintTitle?: string;
  hintText?: string;
  textAlign?: "left" | "center" | "right";
}

interface ZvjsSummaryTableRequestHeaderProps {
  label: string;
}

interface ZvjsSummaryRequestSectionProps {
  label: string;
  children?: React.ReactNode;
  onEditClick?:
    | (React.MouseEventHandler<HTMLAnchorElement> &
        React.MouseEventHandler<HTMLSpanElement>)
    | undefined;
  isBorder: boolean;
}

interface ZvjsSummaryTableRequestComponentProps {
  label: string;
  value: string | ReactElement;
  isBorder: boolean;
  children?: React.ReactNode;
  textAlign?: "left" | "center" | "right";
  longText?: boolean;
  applyJsFromat?: boolean;
  // when set to true, the min height of the component is decreased
  dense?: boolean;
}

export const ZvjsSummaryTableRequestComponent: React.FC<
  ZvjsSummaryTableRequestComponentProps
> = ({ label, value, isBorder, children, textAlign, dense, ...rest }) => {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  });

  return (
    <Grid
      item
      xs={12}
      color={ZVJS_COLORS.GREY_2}
      borderBottom={isBorder ? 1 : 0}
    >
      <Grid
        container
        justifyContent="start"
        alignItems="center"
        minHeight={dense ? 35 : 60}
        paddingLeft={1}
        paddingRight={2}
        color={ZVJS_COLORS.BLACK}
        ref={ref}
      >
        <Grid item xs={3}>
          <Typography
            variant="body1"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitBoxOrient: "vertical",
              paddingLeft: 1,
            }}
          >
            {capitalize(label)}
          </Typography>
        </Grid>
        <Grid item xs={typeof children !== "undefined" ? 8 : 9}>
          <Typography
            textAlign={textAlign !== undefined ? textAlign : "left"}
            variant="h5"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: rest.longText ? 20 : 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitBoxOrient: "vertical",
              paddingLeft: 1,
              marginBottom: rest.longText ? "1rem" : 0,
              marginTop: rest.longText ? "1rem" : 0,
            }}
          >
            {typeof value === "string"
              ? // TODO Either replace with translation or filter out items with no values in getSummaryData in selectors.ts
                value && typeof rest.applyJsFromat
                ? value.split("\n").map((str, index) => (
                    <p key={index} style={{ margin: 0 }}>
                      {str === "" ? <br /> : str}
                    </p>
                  ))
                : capitalize(value ?? "")
              : value}
          </Typography>
        </Grid>
        {typeof children !== "undefined" ? (
          <Grid
            item
            xs
            justifyContent={"flex-end"}
            display={"flex"}
            height={height}
            width={"100%"}
          >
            <div style={{ height: height, marginTop: "0.7rem" }}>
              {children}
            </div>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

export const ZvjsSummaryTableRequestHeader: React.FC<
  ZvjsSummaryTableRequestHeaderProps
> = ({ label }) => {
  return (
    <Grid
      item
      xs={12}
      borderBottom={0}
      display="flex"
      justifyContent="start"
      alignItems="center"
      minHeight={60}
      paddingLeft={1}
      paddingRight={2}
    >
      <Typography variant={"h4"}>{capitalize(label)}</Typography>
    </Grid>
  );
};

export const ZvjsSummaryRequestSection: React.FC<
  ZvjsSummaryRequestSectionProps
> = ({ label, children, onEditClick, isBorder }) => {
  return (
    <Grid
      item
      xs={12}
      display="flex"
      justifyContent="start"
      alignItems="center"
      minHeight={60}
      paddingLeft={1}
      paddingRight={2}
      borderColor={`${ZVJS_COLORS.GREY_2} !important`}
      borderBottom={isBorder ? 1 : 0}
      paddingBottom={1}
    >
      <Grid container>
        <Grid item xs>
          <Grid
            container
            justifyContent={"space-between"}
            minHeight={45}
            alignItems={"center"}
          >
            <Typography variant={"h5"}>{capitalize(label)}</Typography>
            {/*TODO replace translation*/}
            {onEditClick && (
              <Link component="button" onClick={onEditClick}>
                {"Zmeň"}
              </Link>
            )}
          </Grid>
        </Grid>
        {children}
      </Grid>
    </Grid>
  );
};

export const ZvjsSummaryTableComponent: React.FC<
  ZvjsSummaryTableComponentProps
> = ({
  label,
  value,
  isBorder,
  fullWidth,
  hintTitle,
  hintText,
  textAlign,
  ...rest
}) => {
  const { tui } = useUITranslation();
  return (
    <Grid
      item
      xs={fullWidth ? 12 : 6}
      color={ZVJS_COLORS.GREY_2}
      borderBottom={isBorder ? 1 : 0}
    >
      <Grid container>
        <Grid item xs={fullWidth ? 9 : 12}>
          <Grid
            container
            justifyContent="start"
            alignItems="center"
            minHeight={60}
            paddingLeft={1}
            paddingRight={2}
            color={ZVJS_COLORS.BLACK}
          >
            <Grid item xs={typeof rest.children !== "undefined" ? 5 : 7}>
              <Typography variant="body1">{capitalize(label)}</Typography>
            </Grid>
            {typeof rest.children !== "undefined" ? (
              <Grid item xs>
                {rest.children}
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={3}>
              <Typography
                textAlign={textAlign !== undefined ? textAlign : "right"}
                variant="h5"
              >
                {capitalize(value)}
              </Typography>
            </Grid>
            {hintText ? (
              <Grid item xs={2} textAlign={"center"}>
                <ZvjsHintModalFragment
                  title={hintTitle !== undefined ? hintTitle : tui("nápoveda")}
                  text={hintText}
                  color={ZVJS_COLORS.BLUE_2}
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ZvjsSummaryTable: React.FC<ZvjsSummaryTableProps> = ({
  children,
  hintTitle,
  hintText,
  hintTitle2,
  hintText2,
  ...rest
}) => {
  const { tui } = useUITranslation();
  const generateHeader = () => {
    if (rest.header === undefined) {
      return <></>;
    }
    if (Array.isArray(rest.header)) {
      return rest.header.map((item, index) => (
        <Grid item xs={6}>
          <Stack direction="row" spacing={1} alignItems={"center"}>
            <Typography
              height={60}
              paddingLeft={index === 0 ? 2 : 1}
              paddingTop={1.5}
              variant="h3"
              fontSize={32}
            >
              {capitalize(item)}
            </Typography>
            {hintText && (
              <ZvjsHintModalFragment
                title={hintTitle !== undefined ? hintTitle : tui("nápoveda")}
                text={hintText}
                color={ZVJS_COLORS.BLUE_2}
              />
            )}
            {index === 1 && hintText2 && (
              <ZvjsHintModalFragment
                title={hintTitle2 !== undefined ? hintTitle2 : tui("nápoveda")}
                text={hintText2}
                color={ZVJS_COLORS.BLUE_2}
              />
            )}
          </Stack>
        </Grid>
      ));
    } else {
      return (
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <Typography
              height={60}
              paddingLeft={2}
              paddingTop={1.5}
              variant="h3"
              fontSize={32}
            >
              {rest.header && capitalize(rest.header)}
            </Typography>
            {hintText && (
              <ZvjsHintModalFragment
                title={hintTitle !== undefined ? hintTitle : tui("nápoveda")}
                text={hintText}
                color={ZVJS_COLORS.BLUE_2}
              />
            )}
          </Stack>
        </Grid>
      );
    }
  };

  return (
    <Grid container direction="row">
      {generateHeader()}
      <Grid item xs={12}>
        <Paper
          sx={{
            borderRadius: "4px !important",
          }}
        >
          <Grid
            container
            padding={2}
            paddingBottom={0}
            paddingTop={0}
            direction="row"
          >
            {children}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ZvjsSummaryTable;
