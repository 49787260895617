import { useEffect, useState } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useNavigate } from "react-router-dom";
import {
  CellData,
  RowData,
  TableData,
  TableStyle,
  ZvjsTableVariant,
} from "../../../../../common/components/ZvjsTable";
import { useUITranslation } from "../../../../../store/context/translation-context";
import { defer } from "react-router";
import { withLoader } from "../../../../../hoc/withLoader";
import { ZvjsTable } from "../../../../../common";
import { API_Clients } from "../../../../../store/context/dataApi/Data";
import { PromiseElement } from "../../../../../utils/types";

const loadData = async (pohladavkaId: string) => {
  const { FEOO_Post } = await API_Clients();

  const data = await Promise.all([
    FEOO_Post("/api/Pohladavka/ListSplatkyPohladavky", {
      body: {
        pohladavkaId: Number(pohladavkaId),
      },
    }),
  ]);

  return {
    splatky: data[0],
  };
};

export const SplatkyPohladavokGRZVJSLoader = async (pohladavkaId: string) => {
  return defer({
    data: loadData(pohladavkaId),
  });
};

interface SplatkyPohladavokGRZVJSProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const SplatkyPohladavokGRZVJS = (props: SplatkyPohladavokGRZVJSProps) => {
  const loaderData = props.data;
  const { t, i18n } = useTranslation();
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const dataStyle: TableStyle[] = [
    {
      align: "left",
    },
    {
      align: "right",
    },
  ];

  const headerData: Array<CellData> = [
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.PaymentSchedule_SC_KIO_036033.date"
        )
      ),
      ...dataStyle[0],
    },
    {
      value: capitalize(
        t(
          "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.PaymentSchedule_SC_KIO_036033.amount"
        )
      ),
      ...dataStyle[1],
    },
  ];

  const [tableData, setTableData] = useState<TableData>({
    header: headerData,
    body: [],
  });

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (loaderData.splatky.data) {
      const bodyData: Array<RowData> = new Array<RowData>();
      loaderData.splatky.data.records?.forEach((splatka) => {
        bodyData.push({
          row: [
            {
              value: splatka.datumSplatky
                ? new Date(splatka.datumSplatky).zvjsToString()
                : "",
              ...dataStyle[0],
            },
            {
              value: splatka.suma?.toFixed(2) + " EUR",
              ...dataStyle[1],
              bold: true,
            },
          ],
        });
      });
      setTableData({
        ...tableData,
        body: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderData, i18n.language]);

  // const mySubmitHandler = (
  //   dateFrom: Date,
  //   dateTo: Date,
  //   dropDownValue: string
  // ) => {
  //   console.log(dateFrom);
  //   console.log(dateTo);
  // };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={4}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            variant="outlined"
            onClick={onClickNavigationBackButton}
            startIcon={<KeyboardArrowLeftIcon height={15} />}
          >
            {capitalize(tui("tlacidla.spat"))}
          </Button>
          <Typography variant={"h1"} mb={3}>
            {capitalize(
              t(
                "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.PaymentSchedule_SC_KIO_036033.title"
              )
            )}
          </Typography>
        </Stack>
      </Grid>

      <ZvjsTable
        data={tableData}
        height={360}
        variant={ZvjsTableVariant.NORMAL}
      />
    </Grid>
  );
};

export default withLoader(SplatkyPohladavokGRZVJS);
