import { AppDispatch, RootState } from "../../../store/redux";
import questionnaireReducer from "./index";
import {
  Calculate,
  RequestUserPersonalData,
  UserCalcData,
  ZvjsCustomQuestionTypes,
  ZvjsQuestion,
  ZvjsValidationError,
  ZvjsAnswerValueType,
  RequestCounter,
} from "./model";
import { findItem, questionsIteratorWithLocation } from "./slice";
import { selectSelf } from "./selectors";
import {
  calculations,
  validations,
} from "../requestPages/questionnaire/utils/index";
import { onCustomInit } from "../requestPages";
import { requestTemplates } from "../requestTemplates";
import { LoaderError } from "../../../router/LoaderError";
import { isEmptyArray } from "../../../utils/helpers";
import isAvailableToUserFunctions from "../requestPages/questionnaire/utils/isAvailableToUser";
import { API_Clients } from "../../../store/context/dataApi/Data";
import { MAX_NUMBER_OF_ITEMS } from "../../../store/context/dataApi/CIS_Ciselnik";
import { RequestSzooCode } from "../requestTemplates/requestTemplates";

const fetchRequest = (requestCode: string) => {
  return async (dispatch: AppDispatch) => {
    const { CIS_Post, EOO_Get } = await API_Clients();

    console.log("FETCHING QUESTIONNAIRE");

    const allRequestsCounter = await CIS_Post("/api/CisTypZiadosti/List", {
      body: {
        filters: [{ aktualny: true, platny: true }],
        paging: {
          currentPage: 1,
          recordsPerPage: MAX_NUMBER_OF_ITEMS,
        },
        sorting: [{}],
      },
    });

    const requestCounter = {
      records: allRequestsCounter?.data?.records?.filter(
        (request) => request.kod === requestCode
      ),
    };

    if (!requestCounter?.records || isEmptyArray(requestCounter.records)) {
      console.error(`Request with code ${requestCode} not found in counter`);
      // if request counter is empty, display error page
      throw new LoaderError();
    }

    const requestTemplate =
      requestTemplates[requestCode as `${RequestSzooCode}`];

    if (!!requestTemplate.isAvailableToUser) {
      if (
        !(await isAvailableToUserFunctions[requestTemplate.isAvailableToUser]())
      ) {
        // TODO add translation
        throw new LoaderError("Zvolenú žiadosť nemôžete vytvoriť");
      }
    }

    // if you were no able to find request template display error page
    if (requestTemplate === undefined) {
      console.error(
        `Request with code ${requestCode} not found in request templates`
      );
      throw new LoaderError();
    }

    // object which holds precalculated values
    const precalculatedData: UserCalcData = {};

    for (const calcName in requestTemplate.dataCalculations) {
      if (
        requestTemplate.dataCalculations[calcName].calculateAt ===
        Calculate.AT_INIT
      ) {
        precalculatedData[calcName] = await calculations[calcName]({
          userCalcData: {},
          answers: {},
        });
      }
    }

    const counters: RequestCounter = {};
    // fetch needed counters
    for (const counter of requestTemplate.counters ?? []) {
      const fetchedCounter = await CIS_Post(counter, {
        body: {
          filters: [{ aktualny: true, platny: true }],
          paging: {
            currentPage: 1,
            recordsPerPage: MAX_NUMBER_OF_ITEMS,
          },
          sorting: [{}],
        },
      });
      if (
        fetchedCounter?.data?.records === undefined ||
        fetchedCounter?.data?.records === null ||
        isEmptyArray(fetchedCounter.data?.records)
      ) {
        console.error(
          `Failed to fetch ${counter} counter or the fetched counter is empty`
        );
        // if any counter is empty, display error page
        throw new LoaderError();
      }
      counters[counter] = fetchedCounter.data;
    }

    const questionsIterator = questionsIteratorWithLocation(
      requestTemplate,
      []
    );

    for (const question of questionsIterator) {
      // check for custom questions which might need to do some custom calculation on init
      if (question.value.type in ZvjsCustomQuestionTypes) {
        const questCustomType = question.value.type as ZvjsCustomQuestionTypes;
        const onInit = onCustomInit[questCustomType];
        if (onInit !== undefined) {
          await onInit(precalculatedData);
        }
      }
    }

    // TODO handle the situation when request fails
    const personalData = await EOO_Get(
      "/api/Klient/DetailZakladneUdajeKlientaData",
      {
        params: {
          query: {
            Id: Number.parseInt(localStorage.getItem("klientId") ?? "") ?? 1,
            UstavZvjsId:
              Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
          },
        },
      }
    );

    const userPersonalData: RequestUserPersonalData = {
      fullName: `${personalData.data?.data?.meno} ${personalData.data?.data?.priezvisko}`,
      birthDate: personalData.data?.data?.datumNarodenia
        ? new Date(personalData.data?.data.datumNarodenia).zvjsToString()
        : undefined,
    };

    dispatch(
      questionnaireReducer.sliceActions.setQuestionnaire({
        questionnaire: requestTemplate,
        precalculatedData: precalculatedData,
        userPersonalData: userPersonalData,
        requestCounter: requestCounter,
        counters: counters,
      })
    );
  };
};

const addNewSingleCheckBoxAnswer = (
  location: number[],
  questionId: string,
  newAnswer: boolean
) => {
  return async (dispatch: AppDispatch) => {
    if (newAnswer) {
      dispatch(addNewAnswer(location, questionId, newAnswer));
    } else {
      dispatch(
        questionnaireReducer.sliceActions.removeAnswer({
          questionId: questionId,
        })
      );
    }
  };
};

const addNewAnswer = (
  location: number[],
  questionId: string,
  newAnswer: ZvjsAnswerValueType
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    // Do async tasks here
    const state = selectSelf(getState());
    const question = findItem(state.questionnaire, location) as ZvjsQuestion;
    let newValidationError: { [key: string]: ZvjsValidationError | undefined } =
      {
        [questionId]: undefined,
      };

    if (Array.isArray(question.validations)) {
      for (const validation of question.validations) {
        const validationResult = validations[validation]({
          newAnswer: newAnswer,
          questionId: questionId,
          questionnaire: state.questionnaire,
          userCalcData: state.userCalcData,
          answers: state.answers,
        });
        if (validationResult !== undefined) {
          newValidationError = { [questionId]: validationResult };
          break;
        }
      }
    }

    dispatch(
      questionnaireReducer.sliceActions.addNewAnswer({
        questionId: questionId,
        newAnswer: newAnswer,
        newValidationError: newValidationError,
      })
    );
  };
};

const allActions = {
  fetchRequest,
  addNewSingleCheckBoxAnswer,
  addNewAnswer,
};

export default allActions;
