/**
 * Loaders classes / interfaces
 */
export class LoaderError extends Error {
  constructor(message?: string) {
    // TODO replace with code from tui
    super(message ?? "pri načítavaní stránky nastala chyba");
    this.name = "LoaderError";
  }
}
