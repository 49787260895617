import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { capitalize } from "@mui/material/utils";
import { useNavigate } from "react-router-dom";
import { ZvjsTabs } from "../../../common";
import { ReactComponent as KeyboardArrowLeftIcon } from "../../../assets/icons/Other/keyboard_arrow_left.svg";
import { useUITranslation } from "../../../store/context/translation-context";
import { defer } from "react-router";
import { withLoader } from "../../../hoc/withLoader";
import {
  UdajeOTresteTab,
  ZastupcoviaAOpatreniaTab,
  RozhodnutiaTab,
} from "./ZakladneUdajeOTresteTabs";
import TrestyAVazbyTab from "./ZakladneUdajeOTresteTabs/TrestyAVazbyTab";

import { API_Clients } from "../../../store/context/dataApi/Data";
import { PromiseElement } from "../../../utils/types";

const loadData = async () => {
  const { EDPOO_Post, EOO_Get, EOO_Post, FEOO_Get, EPVVVT_Post } =
    await API_Clients();

  const eoo_klient_DetailZakladneUdajeKlientaData = await EOO_Get(
    "/api/Klient/DetailZakladneUdajeKlientaData",
    {
      params: {
        query: {
          Id: Number.parseInt(localStorage.getItem("klientId") ?? "") ?? 1,
          UstavZvjsId:
            Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
        },
      },
    }
  );

  const edpooKlient = await EDPOO_Post("/api/Klient/Get", {
    body: {
      klientId: Number.parseInt(localStorage.getItem("klientId") ?? "") ?? 1,
      ustavZvjsId:
        Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
    },
  });

  const data = await Promise.all([
    EOO_Post("/api/VazbyATresty/ListVazbaAleboTrestKlientaData", {
      body: {
        filters: [
          {
            id: Number.parseInt(localStorage.getItem("klientId") ?? "") ?? 1,
            ustavZvjsId:
              Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
          },
        ],
      },
    }),
    EOO_Post("/api/PravniZastupcoviaKlienta/PravnyZastupcaKlientaList", {
      body: {
        filters: [
          { klientObjectId: localStorage.getItem("klientObjectId") ?? "" },
        ],
        paging: {
          currentPage: 0,
          recordsPerPage: 999999,
        },
        // sorting: [{}],
      },
    }),
    EOO_Post("/api/OchranneOpatrenie/ListOchranneOpatrenieData", {
      body: {
        //TODO: Uistit sa, ze filter skutocne filtruje cez klientId (podla vysledkov sa zda, ze ano)
        filters: [
          {
            id: Number.parseInt(localStorage.getItem("klientId") ?? "") ?? 1,
            ustavZvjsId:
              Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
          },
        ],
        paging: {
          currentPage: 0,
          recordsPerPage: 999999,
        },
        // sorting: [{}],
      },
    }),
    EOO_Post("/api/Rozhodnutie/ListRozhodnutieData", {
      body: {
        filters: [
          {
            klientId:
              Number.parseInt(localStorage.getItem("klientId") ?? "") ?? 1,
          },
        ],
      },
    }),
    EOO_Post("/api/Klient/List", {
      body: {
        filters: [
          {
            zakladneCislo:
              eoo_klient_DetailZakladneUdajeKlientaData.data?.data
                ?.zakladneCislo,
          },
        ],
      },
    }),
    FEOO_Get("/api/KlientUni/Get", {
      params: {
        query: {
          id: localStorage.getItem("klientObjectId") ?? undefined,
        },
      },
    }),
    EPVVVT_Post("/api/stitkovnicaklientov/ListVsetciKlienti", {
      body: {
        ustavId:
          Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
      },
    }),
    EOO_Post("/api/VazbyATresty/DetailTDVData", {
      body: {
        id: Number.parseInt(localStorage.getItem("klientId") ?? "") ?? 1,
        ustavZvjsId:
          Number.parseInt(localStorage.getItem("klientUstavId") ?? "") ?? 1,
      },
    }),
  ]);

  return {
    trestyAVazby: data[0],
    pravniZastupcovia: data[1],
    ochranneOpatrenia: data[2],
    rozhodnutia: data[3],
    klient: data[4].data?.records?.at(0),
    klientUni: data[5].data?.data,
    edpooKlient: edpooKlient,
    epvvvtKlient: data[6].data
      ?.filter(
        (klient) =>
          klient.klientObjectId === localStorage.getItem("klientObjectId")
      )
      .at(0),
    detailTDVData: data[7],
  };
};

export const ZakladneUdajeOTresteLoader = async () => {
  return defer({
    data: loadData(),
  });
};

export interface ZakladneUdajeOTresteProps {
  data: PromiseElement<ReturnType<typeof loadData>>;
}

const ZakladneUdajeOTreste = (props: ZakladneUdajeOTresteProps) => {
  const loaderData = props.data;
  const { t } = useTranslation();
  const { tui } = useUITranslation();
  const navigate = useNavigate();

  const onClickNavigationBackButton = () => {
    navigate(-1);
  };

  return (
    <Grid p={5} pt={3} mb={12}>
      <Grid mb={3}>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="outlined"
              onClick={onClickNavigationBackButton}
              startIcon={<KeyboardArrowLeftIcon height={15} />}
            >
              {capitalize(tui("tlacidla.spat"))}
            </Button>
            <Typography variant="h1" mb={3}>
              {capitalize(
                tui("osobneUdaje.kategorieOsobnychUdajov.zakladneUdajeTrest")
              )}
            </Typography>
          </Stack>
        </Grid>
        <Grid item mt={4}>
          <ZvjsTabs
            onChange={() => {
              return;
            }}
            data={[
              {
                label: t(
                  "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.PunishmentData.tabLabel"
                ),
                element: <UdajeOTresteTab data={loaderData} />,
              },
              {
                label: t(
                  "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.RepresentativesAndMeasures.tabLabel"
                ),
                element: <ZastupcoviaAOpatreniaTab data={loaderData} />,
              },
              {
                label: t(
                  "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.Verdicts.tabLabel"
                ),
                element: <RozhodnutiaTab data={loaderData} />,
              },
              {
                label: t(
                  "spec.section.PersonalDataCategory_SC_KIO_0313.Screen.BasicPunishmentData_SC_KIO_0310.PunishmentsAndDetentions.tabLabel"
                ),
                element: <TrestyAVazbyTab data={loaderData} />,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withLoader(ZakladneUdajeOTreste);
